import { IEnvironment } from './ienviroments';

export const environment: IEnvironment = {
  env: 'prod',
  production: true,
  baseApiSegment: 'cardapi',
  bestEggDomain: 'www.bestegg.com',
  bestEggCardDomain: 'card.bestegg.com',
  optimizelyCdn: 'https://cdn.optimizely.com/js/11204382927.js',
  appVersion: require('../../package.json').version,
  dataDogApplicationId: '0f7e9ed4-4595-466b-b70a-671a34bb7426',
  dataDogClientToken: 'pub8b9467569c2ec125c3ade5ef8d4ad583',
  dataDogServiceName: 'Card Funnel',
  mainDomain: '.bestegg.com',
  enterpriseEventsEnv: 'PRD',
  PROSPECT_SESSION_ID_COOKIE_NAME: 'prospect__session_id',
  PROSPECT_ID_COOKIE_NAME: 'prospect__id',
  localDpidDomainOverride: null,
  localEnterpriseEventsEndpoint: null,
  disableIdleTracking: false,
  adaChatbotHandleName: 'bestegg-prod',
  enterpriseAuthAccountEnv: 'PRD',
  BEFH_URL: 'https://www.bestegg.com/financial-health',
  CSW_AUTH_TOKEN_COOKIE_NAME: 'X-Access-Token',
};
