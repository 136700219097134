// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromApplicationReducer from '../reducers/application.reducer';
// models
import { Application } from '../../models';

// application state
export const selectApplicationState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.application
);

// data
export const selectApplicationData = createSelector(
  selectApplicationState,
  fromApplicationReducer.selectApplicationStateData
);

// error
export const selectApplicationError = createSelector(
  selectApplicationState,
  fromApplicationReducer.selectApplicationStateError
);

// primary applicant
export const selectPrimaryApplicant = createSelector(
  selectApplicationData,
  (application: Application) => application?.primaryApplicant
);

// decision
export const selectApplicationDecision = createSelector(
  selectApplicationData,
  (application: Application) => application?.applicationDecision
);

// loaded
export const selectApplicationLoaded = createSelector(
  selectApplicationState,
  fromApplicationReducer.selectApplicationStateLoaded
);

// loading
export const selectApplicationLoading = createSelector(
  selectApplicationState,
  fromApplicationReducer.selectApplicationStateLoading
);
