<app-application-step
  class="c-contact-information"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <div *ngIf="(prospectOfferLoaded$ | async) && prefillStatus; else contactInformationForm">
      <app-prospect-card [prospectData]="form.value" (edit)="onEdit()"></app-prospect-card>
      <p class="c-contact-information__invalid-prefill-message" *ngIf="invalidPrefill && !editStatus">
        <span>There seems to be some issues with your information. Please click “Edit” to review and update it.</span>
      </p>
    </div>
    <ng-template #contactInformationForm>
      <app-dynamic-form
        [form]="form"
        [formConfig]="subFormConfigs[0]"
        [questionOptions]="applicationStepData.applicationQuestionOptions"
        [ignoreBackendErrors]="true"
      ></app-dynamic-form>
    </ng-template>
    <app-dynamic-form
      [form]="form"
      [formConfig]="subFormConfigs[1]"
      [questionOptions]="applicationStepData.applicationQuestionOptions"
      [errorValidator]="errorValidator"
      class="c-contact-information__second-dynamic-form"
    ></app-dynamic-form>
    <app-text-bubble [designType]="TextBubbleTypes.NOTIFICATION">
      <div class="content">There will be no impact to your credit score if we’re unable to approve you.</div>
    </app-text-bubble>
    <app-form-step-controls
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
