<ng-container *ngIf="introOffer$ | async as introOffer">
  <app-text-bubble class="offer-banner" [designType]="TextBubbleTypes.TAG">
    <div class="title">Introductory offer</div>
    <div class="content">
      {{ introOffer }}
    </div>
  </app-text-bubble>
</ng-container>
<ng-container *ngIf="cardProductOfferPromotionalApr$ | async as cardProductOfferPromotionalApr">
  <app-text-bubble class="promo-banner" [designType]="TextBubbleTypes.TAG">
    <div class="title">Introductory APR</div>
    <div class="content">
      {{ cardProductOfferPromotionalApr.purchasePromoRate }}% Purchase APR for the first
      {{ cardProductOfferPromotionalApr.purchasePromoRateDuration }} months
    </div>
  </app-text-bubble>
</ng-container>
