import { PII_FIELDS, APP_STATUS_TYPES, DEFAULT_APP_STATUS } from './consts';

// configs
import { MAIN_URL_SEGMENT } from '../../../main/configs';

export const getAppStatus = (application): string => {
  if (!application) {
    return DEFAULT_APP_STATUS;
  } else {
    const mappedStatus = APP_STATUS_TYPES[application.applicationDecision.decisionType];
    return mappedStatus || application.applicationDecision.decisionType;
  }
};

/**
 * Remove query params and base URL path
 */
export const getSimplePath = (route): string => {
  try {
    return route.replace(`/${MAIN_URL_SEGMENT}/`, '').split('?')[0];
  } catch (e) {
    return route;
  }
};

/**
 * Return question values that and are not PII and are not null/undefined.
 */
export const getQuestionValuesWithoutPII = (formValues): Record<string, any> => {
  const values = {};
  Object.keys(formValues).forEach((key: string) => {
    if (![null, undefined].includes(formValues[key])) {
      values[key] = !PII_FIELDS.includes(key) ? formValues[key] : 'PII redacted';
    }
  });
  return values;
};

export const getOfferCodeFromQueryParams = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('offerCode');
};
