// models
import { ApplicationStep, ApplicationStepNameTypes } from '../../../models';
// configs
import * as fromProspect from '../best-egg/prospect';
import { applicationStepFormConfigMap } from '../best-egg/prospect';
import { AffiliateLogoConfigMap } from '../affiliate-experiences/affiliate-logo-config';

// #walkup-information
export const baseWalkupInformationApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.WalkupInformation,
  // 'title' will differ based on experience. This is what contains the logo.
  supertitle: 'Thank you for visiting from',
  order: 1,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.WalkupInformation],
};

export const breakingBECCStep1: ApplicationStep = {
  name: ApplicationStepNameTypes.BreakingBECCStep1,
  // 'title' will differ based on experience. This is what contains the logo.
  supertitle: 'Thank you for visiting from',
  order: 1,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.BreakingBECCStep1],
};

export const breakingBECCStep2: ApplicationStep = {
  name: ApplicationStepNameTypes.BreakingBECCStep2,
  title: 'This additional information will help verify your identity.',
  order: 3,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.BreakingBECCStep2],
};

export const baseApplicationSteps: ApplicationStep[] = [
  breakingBECCStep1,
  breakingBECCStep2,
  fromProspect.physicalAddressApplicationStep,
  fromProspect.personalInformationApplicationStep,
  fromProspect.bankingApplicationStep,
  fromProspect.housingApplicationStep,
  fromProspect.residentialLengthApplicationStep,
  fromProspect.cashAdvancesApplicationStep,
  fromProspect.termsAndConditionsStep,
  fromProspect.socialSecurityNumberStep,
  fromProspect.agreementStep,
];

export const affiliateLandingStep: ApplicationStep = {
  name: ApplicationStepNameTypes.AffiliateLanding,
  title: '',
  supertitle: '',
  order: 0,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.AffiliateLanding],
};

export const getAffiliateImage = (affiliateName: string) =>
  '<img id=' +
  AffiliateLogoConfigMap[affiliateName].id +
  ' alt=' +
  AffiliateLogoConfigMap[affiliateName].altText +
  ' height=' +
  AffiliateLogoConfigMap[affiliateName].height +
  ' width=' +
  AffiliateLogoConfigMap[affiliateName].width +
  ' src=' +
  AffiliateLogoConfigMap[affiliateName].src +
  '>';
