// ngrx
import { createSelector } from '@ngrx/store';
import * as fromQuestionsReducer from '../reducers';
import * as fromValidationsReducer from '../reducers/validations.reducer';

// validations state
export const selectValidationsState = createSelector(
  fromQuestionsReducer.selectQuestionsFeature,
  (state: fromValidationsReducer.ValidationsState) => state[fromValidationsReducer.VALIDATIONS_STATE_KEY]
);

// validations data
export const selectValidationsData = createSelector(
  selectValidationsState,
  fromValidationsReducer.selectValidationsStateData
);

// validation value
export const selectValidationValue = createSelector(selectValidationsData, (validations, props) =>
  validations ? validations[props.name] : null
);
