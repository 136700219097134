// imports
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { SocialSecurityNumberComponent } from './social-security-number/social-security-number.component';
import { AgreementComponent } from './agreement/agreement.component';
import { OpportunityMicrofunnelComponent } from './opportunity-microfunnel/opportunity-microfunnel.component';
import { WalkupInformationComponent } from './walkup-information/walkup-information.component';
import { DynamicStepComponent } from './dynamic-step/dynamic-step.component';
import { AffiliateLandingComponent } from './affiliate-landing/affiliate-landing.component';
import { VerifyIdentityComponent } from './verify-identity/verify-identity.component';
import { ConfirmInfoComponent } from './confirm-info/confirm-info.component';
import { AgreementDisclosuresComponent } from './agreement-disclosures/agreement-disclosures.component';
// exports
export * from './contact-information/contact-information.component';
export * from './terms-and-conditions/terms-and-conditions.component';
export * from './agreement/agreement.component';
export * from './opportunity-microfunnel/opportunity-microfunnel.component';
export * from './social-security-number/social-security-number.component';
export * from './walkup-information/walkup-information.component';
export * from './dynamic-step/dynamic-step.component';
export * from './affiliate-landing/affiliate-landing.component';
export * from './verify-identity/verify-identity.component';
export * from './confirm-info/confirm-info.component';
export * from './agreement-disclosures/agreement-disclosures.component';

// exports group
export const components: any[] = [
  ContactInformationComponent,
  TermsAndConditionsComponent,
  AgreementComponent,
  OpportunityMicrofunnelComponent,
  SocialSecurityNumberComponent,
  WalkupInformationComponent,
  DynamicStepComponent,
  AffiliateLandingComponent,
  VerifyIdentityComponent,
  ConfirmInfoComponent,
  AgreementDisclosuresComponent,
];
