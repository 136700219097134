// vendor
import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// store
import { select } from '@ngrx/store';
import * as fromOffersStore from '../../../../offers/store';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
// models
import { SimpleFormConfig, QuestionNameTypes } from '../../../../questions/models';
import { TextBubbleTypes } from '../../../../shared/configs/text-bubble-types';

// consts
const prospectQuestionNames = [
  QuestionNameTypes.FirstName,
  QuestionNameTypes.LastName,
  QuestionNameTypes.Address,
  QuestionNameTypes.City,
  QuestionNameTypes.State,
  QuestionNameTypes.ZipCode,
];
@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent extends BaseApplicationStepComponent implements OnInit {
  TextBubbleTypes = TextBubbleTypes;
  editStatus: boolean;
  subFormConfigs: SimpleFormConfig[];
  prospectOfferLoaded$: Observable<boolean>;

  get prefillStatus(): boolean {
    if (this.form) {
      return this.form.value && !this.editStatus;
    }
  }
  get invalidPrefill(): boolean {
    if (this.form) {
      return prospectQuestionNames.some((questionName) => this.form.get(questionName).invalid);
    }
  }

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.prospectOfferLoaded$ = this.store.pipe(select(fromOffersStore.selectCardProspectOfferLoaded));
    this.createSubFormConfigs();
  }

  /**
   * create sub form configs
   */
  createSubFormConfigs(): void {
    const { formConfig } = this.applicationStepData.applicationStep;
    const prospectQuestionNamesOrdered = [];
    const remainingQuestionNames = [];

    /** Keep the field ordering dictated by the formConfig when splitting these questions into two forms */
    formConfig.questionNames.forEach((questionName: QuestionNameTypes) => {
      if (prospectQuestionNames.includes(questionName)) {
        prospectQuestionNamesOrdered.push(questionName);
      } else {
        remainingQuestionNames.push(questionName);
      }
    });
    /**
     * The `grid` that we want to use for each subFormConfig is based on the `subgrids` we
     * have set in the step's `formConfig`. Ie: the first item will get the first subgrid item, and
     * the second will get the second item. Only the `grid` property is referenced in the dynamic form,
     * (which is where these subFormConfigs are used), not the `subgrids` property.
     */
    this.subFormConfigs = [
      {
        ...formConfig,
        questionNames: prospectQuestionNamesOrdered,
        // allow form level validators to display on bottom form
        validators: null,
        layout: {
          ...formConfig.layout,
          grid: formConfig.layout.subgrids[0],
        },
      },
      {
        ...formConfig,
        questionNames: remainingQuestionNames,
        layout: {
          ...formConfig.layout,
          grid: formConfig.layout.subgrids[1],
        },
      },
    ];
  }

  /**
   * on edit
   */
  onEdit() {
    this.editStatus = true;
    // TODO_UPDATE: verbiage?
    this.liveAnnouncer.announce('Form fields are now editable');
    // TODO_ADD: focus on first form field when switching to edit mode
  }
}
