/**
 * module: application
 * desc: used for application apply
 * notes: none
 */

// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApplicationStore from './store';
// modules
import { LeadModule } from '../lead/lead.module';
import { AbTestsModule } from '../ab-tests/ab-test.module';
import { DigitalExperienceModule } from '../digital-experience/digital-experience.module';
import { OffersModule } from '../offers/offers.module';
import { QuestionsModule } from '../questions/questions.module';
import { SharedModule } from '../shared/shared.module';
// containers
import * as fromContainers from './containers';
// components
import * as fromComponents from './components';
// directives
import * as fromDirectives from './directives';
import { VerifyIdentityComponent } from './components/individual-steps/verify-identity/verify-identity.component';
import { ConfirmInfoComponent } from './components/individual-steps/confirm-info/confirm-info.component';

// routes
export const applicationRoutes: Routes = [
  {
    path: '',
    component: fromContainers.ApplicationComponent,
  },
];

@NgModule({
  declarations: [
    ...fromContainers.containers,
    ...fromComponents.components,
    ...fromDirectives.directives,
    VerifyIdentityComponent,
    ConfirmInfoComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonModule,
    MatFormFieldModule,
    StoreModule.forFeature(fromApplicationStore.APPLICATION_FEATURE_KEY, fromApplicationStore.reducers),
    EffectsModule.forFeature(fromApplicationStore.effects),
    LeadModule,
    AbTestsModule,
    DigitalExperienceModule,
    OffersModule,
    QuestionsModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
})
export class ApplicationModule {}
