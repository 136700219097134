<div class="c-form-radio-group">
  <div class="c-form-radio-group__header">
    <h2 mat-header class="c-form-radio-group__label" [innerHTML]="question.label"></h2>
  </div>
  <mat-form-field class="mat-form-field-plain" [id]="question.name">
    <mat-radio-group aria-label="Select an option" [formControl]="formControl">
      <mat-radio-button
        [class.checked]="formControl.value === option.value"
        *ngFor="let option of question.options"
        [value]="option.value"
        (click)="updateValue(option.value)"
        ><div class="c-form-radio-group__option-label">{{ option.label }}</div>
        <div class="c-form-radio-group__option-sublabel" *ngIf="option.sublabel">
          {{ option.sublabel }}
        </div></mat-radio-button
      >
    </mat-radio-group>
    <input
      [formControl]="formControl"
      [errorStateMatcher]="matcher"
      (input)="blockInput()"
      readonly="readonly"
      hidden="true"
      [attr.aria-label]="question.label"
      matInput
    />
    <mat-error class="mat-error" *ngIf="errorValidator">
      {{ errorValidator.message }}
    </mat-error>
  </mat-form-field>
  <app-text-bubble *ngIf="question.sublabel" [designType]="TextBubbleTypes.NOTIFICATION">
    <div class="content">
      {{ question.sublabel }}
    </div>
  </app-text-bubble>
</div>
