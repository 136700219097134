// models
import { StateAgeRestriction } from '../../models';

export const MIN_AGE_DEFAULT = 18;
export const MAX_AGE_DEFAULT = null;

// Leave this config and logic in place so that we can easily
// add in state-age restrictions if needed in the future
export const stateAgeRestrictions: StateAgeRestriction[] = [
  {
    states: [],
    minAge: null,
  },
];
