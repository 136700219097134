import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, ElementRef, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  private host: HTMLElement;
  private focused: Element;
  private shouldAutoFocus = true;

  @Input()
  set appAutofocus(value: boolean) {
    this.shouldAutoFocus = coerceBooleanProperty(value);
  }

  constructor(el: ElementRef, @Inject(DOCUMENT) private document: HTMLDocument) {
    this.host = el.nativeElement;
    this.focused = this.document.activeElement;
  }

  ngAfterViewInit() {
    if (this.shouldAutoFocus && this.host !== this.focused) {
      this.host.focus();
    }
  }
}
