// imports
import { ApplicationLoadProcessEffects } from './application-load-process.effects';
import { ApplicationEffects } from './application.effects';
import { CardBrandEffects } from './card-brand.effects';
import { DocumentsEffects } from './documents.effects';
import { OptionsEffects } from './options.effects';
import { TokenizedCidEffects } from './tokenized-cid.effects';
import { DashboardLoadProcessEffects } from './dashboard-load.effect';
// exports
export * from './application-load-process.effects';
export * from './application.effects';
export * from './card-brand.effects';
export * from './documents.effects';
export * from './options.effects';
export * from './tokenized-cid.effects';
export * from './dashboard-load.effect';

// exports group
export const effects: any[] = [
  ApplicationLoadProcessEffects,
  ApplicationEffects,
  CardBrandEffects,
  DocumentsEffects,
  OptionsEffects,
  TokenizedCidEffects,
  DashboardLoadProcessEffects,
];
