import { Component } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DigitalExperienceTypes } from 'src/app/features/digital-experience/models';
import { selectDigitalExperienceName } from 'src/app/features/digital-experience/store';
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import * as fromOffersStore from '../../../../offers/store';
import { AffiliateLogoConfigMap } from 'src/app/features/digital-experience/configs/experiences/affiliate-experiences/affiliate-logo-config';

const EXPERIENCES_WITH_NO_GREETING_TEXT = [DigitalExperienceTypes.AffiliateCreditSoup];

@Component({
  selector: 'app-affiliate-landing',
  templateUrl: './affiliate-landing.component.html',
  styleUrls: ['./affiliate-landing.component.scss'],
})
export class AffiliateLandingComponent extends BaseApplicationStepComponent {
  experiencesWithNoGreetingText = EXPERIENCES_WITH_NO_GREETING_TEXT;
  experienceNameLogoPathMap = AffiliateLogoConfigMap;
  isRewardProgram$ = this.store.pipe(select(fromOffersStore.selectCardProductIsRewardProgram));
  digitalExperienceName$: Observable<string> = this.store
    .select(selectDigitalExperienceName)
    .pipe(filter<string>(Boolean), take(1));

  applyNow() {
    if (!this.stepChangeStatus) {
      this.onNext({});
    }
  }
}
