// models
import { ApplicationStep, ApplicationStepNameTypes, StepComponentMap } from '../../../../models';
// configs
import { applicationStepFormConfigMap } from './application-step-form.config';
import { defaultStepComponentMap as baseDefaultStepComponentMap } from '../../../shared';

// #begin
// [9/16/21] requested step be removed from current experience

// #contact-information
export const contactInformationApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.ContactInformation,
  title: "Let's get started! Is the information below correct?",
  order: 1,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.ContactInformation],
};

// #physical-address
export const physicalAddressApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.PhysicalAddress,
  title: 'Physical address',
  message: `We can continue to mail any further correspondence to your PO Box, APO, or FPO address,
            but we are required by federal law to collect your physical address.`,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.PhysicalAddress],
  order: 2,
};

// #personal-information
export const personalInformationApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.PersonalInformation,
  title: "Let's collect your basic info.",
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.PersonalInformation],
  order: 3,
};

// #banking
export const bankingApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.Banking,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.Banking],
  order: 4,
};

// #housing-type
export const housingApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.Housing,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.Housing],
  order: 5,
};

// #residential-length
export const residentialLengthApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.ResidentialLength,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.ResidentialLength],
  order: 6,
};

// #cash-advances
export const cashAdvancesApplicationStep: ApplicationStep = {
  name: ApplicationStepNameTypes.CashAdvances,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.CashAdvances],
  order: 7,
};

// #terms-and-conditions
export const termsAndConditionsStep: ApplicationStep = {
  name: ApplicationStepNameTypes.TermsAndConditions,
  title: 'Please review the details for your credit card below.',
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.TermsAndConditions],
  order: 8,
};

// #social security number
export const socialSecurityNumberStep: ApplicationStep = {
  name: ApplicationStepNameTypes.SocialSecurityNumber,
  title: "You're almost done.",
  message: `Just so you know, if approved, your credit score may be impacted.
            However, there will be no impact to your credit score if you don’t qualify.*`,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.SocialSecurityNumber],
  order: 9,
};

// #agreement
export const agreementStep: ApplicationStep = {
  name: ApplicationStepNameTypes.Agreement,
  title: 'One last thing...',
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.Agreement],
  order: 10,
};

export const applicationSteps: ApplicationStep[] = [
  contactInformationApplicationStep,
  physicalAddressApplicationStep,
  personalInformationApplicationStep,
  bankingApplicationStep,
  housingApplicationStep,
  residentialLengthApplicationStep,
  cashAdvancesApplicationStep,
  termsAndConditionsStep,
  socialSecurityNumberStep,
  agreementStep,
];

export const stepComponentMap: StepComponentMap = {
  ...baseDefaultStepComponentMap,
};
