import { ApplicationStep, StepComponentMap } from '../../../../models';
import { defaultStepComponentMap } from '../../../shared';
import {
  baseWalkupInformationApplicationStep,
  baseApplicationSteps,
  affiliateLandingStep,
} from '../../base-walkup/application-steps.config';
import { walkupInformationApplicationStep as noBrandingWalkupInformationApplicationStep } from '../no-branding/application-steps.config';

// #walkup-information
const walkupInformationApplicationStep: ApplicationStep = {
  ...baseWalkupInformationApplicationStep,
  ...noBrandingWalkupInformationApplicationStep,
};

export const applicationSteps: ApplicationStep[] = [
  affiliateLandingStep,
  walkupInformationApplicationStep,
  ...baseApplicationSteps,
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
