// ngrx
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
// models
import { CardFunnelAvailability } from '../../models';

export enum CardFunnelAvailabilityActionTypes {
  LoadCardFunnelAvailability = '[Offers] Load Card Funnel Availability',
  LoadCardFunnelAvailabilitySuccess = '[Offers] Load Card Funnel Availability Success',
  LoadCardFunnelAvailabilityFail = '[Offers] Load Card Funnel Availability Fail',
  ClearCardFunnelAvailability = '[Offers] Clear Card Funnel Availability',
}

// load Card Funnel Availability
export const loadCardFunnelAvailability = createAction(CardFunnelAvailabilityActionTypes.LoadCardFunnelAvailability);

export const loadCardFunnelAvailabilitySuccess = createAction(
  CardFunnelAvailabilityActionTypes.LoadCardFunnelAvailabilitySuccess,
  props<{ cardFunnelAvailability: CardFunnelAvailability }>()
);

export const loadCardFunnelAvailabilityFail = createAction(
  CardFunnelAvailabilityActionTypes.LoadCardFunnelAvailabilityFail,
  props<{ error: HttpErrorResponse }>()
);

export const clearCardFunnelAvailability = createAction(CardFunnelAvailabilityActionTypes.ClearCardFunnelAvailability);

export type CardFunnelAvailabilityAction = ReturnType<
  | typeof loadCardFunnelAvailability
  | typeof loadCardFunnelAvailabilitySuccess
  | typeof loadCardFunnelAvailabilityFail
  | typeof clearCardFunnelAvailability
>;
