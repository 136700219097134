// models
import { ApplicationStepNameTypes } from '../../../../models';
import { SimpleFormConfig } from '../../../../../questions/models';
import * as fromShared from '../../../shared';

// form config map
export const applicationStepFormConfigMap: Record<any, SimpleFormConfig> = {
  [ApplicationStepNameTypes.AffiliateLanding]: fromShared.landingPageFormConfig,
  [ApplicationStepNameTypes.VerifyIdentity]: fromShared.verifyIdentityStepFormConfig,
  [ApplicationStepNameTypes.ConfirmInfo]: fromShared.confirmInfoStepFormConfig,
  [ApplicationStepNameTypes.WalkupInformation]: fromShared.baseWalkupInformationStepFormConfig,
  [ApplicationStepNameTypes.ContactInformation]: fromShared.contactInformationStepFormConfig,
  [ApplicationStepNameTypes.PhysicalAddress]: fromShared.physicalAddressStepFormConfig,
  [ApplicationStepNameTypes.PersonalInformation]: fromShared.personalInformationStepFormConfig,
  [ApplicationStepNameTypes.Banking]: fromShared.bankingSimpleFormConfig,
  [ApplicationStepNameTypes.Housing]: fromShared.housingStepFormConfig,
  [ApplicationStepNameTypes.ResidentialLength]: fromShared.residentialLengthStepFormConfig,
  [ApplicationStepNameTypes.CashAdvances]: fromShared.cashAdvancesSimpleFormConfig,
  [ApplicationStepNameTypes.TermsAndConditions]: fromShared.termsAndConditionsStepFormConfig,
  [ApplicationStepNameTypes.SocialSecurityNumber]: fromShared.socialSecurityNumberStepFormConfig,
  [ApplicationStepNameTypes.Agreement]: fromShared.agreementStepFormConfig,
  [ApplicationStepNameTypes.OpportunityMicrofunnel]: fromShared.opportunityMicrofunnelStepFormConfig,
  [ApplicationStepNameTypes.BreakingBECCStep1]: fromShared.breakingBECCStep1FormConfig,
  [ApplicationStepNameTypes.BreakingBECCStep2]: fromShared.breakingBECCStep2FormConfig,
};
