import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable } from 'rxjs';
import { retryWhen, delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
  // providedIn: SharedModule,
})
export class WebSocketService {
  connection$: WebSocketSubject<any>;
  RETRY_MILLISECONDS = 1000;
  url = 'ws://localhost:7777'; // testing with a local server

  constructor() {}

  connect(wsUrl = this.url): Observable<any> {
    if (!this.connection$) {
      this.connection$ = webSocket(wsUrl);
    }

    return this.connection$.pipe(
      // Faking an error occasionally passing through,
      // like if the websocket connection dropped.
      // map(res => {
      //   if(Math.random() < 0.25 ) {
      //     console.log('error');
      //     throw res;
      //   }
      //   return res;
      // }),
      retryWhen((errors) => {
        console.log('errors', errors);
        return errors.pipe(delay(this.RETRY_MILLISECONDS));
      })
    );
  }

  send(data: any) {
    if (this.connection$) {
      // TODO_ADD: if we choose to dispatch an action as a notifier
      // this.store.dispatch()
      this.connection$.next(data);
    } else {
      console.error('Did not send data, open a connection first');
    }
  }

  closeConnection() {
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
      // TODO_ADD: if we choose to dispatch an action as a notifier
      // this.store.dispatch();
    }
  }
}
