// vendor
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, first, switchMap, withLatestFrom } from 'rxjs/operators';
import { FullstoryEventTypes } from '../../analytics/models';
import { BEFHAccountCreationEventName } from '../../analytics/models/enterprise-event.model';
import { FullstoryService } from '../../analytics/services';
import { EnterpriseEventTrackingService } from '../../analytics/services/enterprise-event/enterprise-event.service';
import * as fromApplicationStore from '../../application/store';

@Injectable({
  providedIn: 'root',
})
export class BEFHAccountCreationService {
  constructor(
    private store: Store,
    private eeService: EnterpriseEventTrackingService,
    private fullStoryService: FullstoryService
  ) {}

  selectTokenizedCid = this.store.pipe(
    select(fromApplicationStore.selectApplicationTokenizedCidLoaded),
    filter(Boolean),
    first(),
    withLatestFrom(this.store.pipe(select(fromApplicationStore.selectApplicationTokenizedCid))),
    first()
  );

  shouldDisplayBEFHAccountCreation() {
    return this.selectTokenizedCid.pipe(switchMap(([_, tokenizedCid]: [any, string]) => of(Boolean(tokenizedCid))));
  }

  fireInitialBEFHAnalyticsEvent() {
    this.shouldDisplayBEFHAccountCreation().subscribe((shouldDisplay: boolean) => {
      if (shouldDisplay) {
        this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_START);
        this.fullStoryService.event(FullstoryEventTypes.BEFHAccountCreationStart, {});
      } else {
        this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_ALREADY_EXISTS);
      }
    });
  }
}
