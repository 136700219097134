<div class="c-dialog c-idle-dialog">
  <div mat-dialog-title class="c-dialog__header c-dialog__header--small">
    <svg viewBox="0 0 24 24" class="c-dialog__header-icon--warning">
      <use href="#warning-triangle-bold"></use>
    </svg>
    <h2 class="mat-dialog-title mat-dialog-title--small" cdkFocusInitial tabindex="-1">Are you still there?</h2>
    <button type="button" class="c-dialog__header-close" mat-dialog-close>
      <svg viewBox="0 0 24 24">
        <use href="#close"></use>
      </svg>
    </button>
  </div>
  <div class="mat-typography mat-mdc-dialog-content--small mat-mdc-dialog-content">
    <p>Your session will soon time out for inactivity.</p>
  </div>
  <div class="mat-mdc-dialog-actions mat-mdc-dialog-actions--small">
    <button mat-button mat-dialog-close><u>Close</u></button>
  </div>
</div>
