// vendor
import { Directive, Input, OnInit, ViewContainerRef, ComponentRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// models
import { FieldInteraction, Question, QuestionComponentMap } from '../models';
import { defaultQuestionComponentMap } from '../configs';
import { Observable } from 'rxjs';
import { IAbTestData } from '../../ab-tests/models';

@Directive({
  selector: '[dynamicFormField]',
})
export class DynamicFormFieldDirective implements OnInit {
  componentMap: QuestionComponentMap = defaultQuestionComponentMap;
  component: ComponentRef<any>;
  abTestData: IAbTestData;
  abTestDataReady$: Observable<boolean>;

  @Input() question: Question;
  @Input() form: UntypedFormGroup;

  @Output() fieldInteractionChange: EventEmitter<FieldInteraction>;

  constructor(private container: ViewContainerRef) {
    this.fieldInteractionChange = new EventEmitter();
  }

  ngOnInit() {
    const component = this.componentMap[this.question.formElType];
    this.container.clear();
    this.component = this.container.createComponent(component);
    this.component.instance.parent = this.form;
    this.component.instance.question = this.question;

    this.component.instance.fieldInteractionChange.subscribe((res) => {
      this.fieldInteractionChange.emit(res);
    });
  }
}
