// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardTermsFunnelAvailability from '../reducers/card-funnel-terms-availability.reducer';

// card funnel terms availability state
export const selectCardFunnelTermsAvailabilityState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardFunnelTermsAvailability
);

// card funnel terms availability terms errors
export const selectCardFunnelAvailabilityTermsErrors = createSelector(
  selectCardFunnelTermsAvailabilityState,
  fromCardTermsFunnelAvailability.selectCardFunnelAvailabilityTermsErrors
);
