// vendor
import { Component, Input, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
// ngrx
import * as fromQuestionsReducers from '../../../store/reducers';
import * as fromQuestionsSelectors from '../../../store/selectors';
// models
import * as fromQuestionsModels from '../../../models';
import { Observable, of } from 'rxjs';
import { fieldErrorEnterAnimation } from 'src/app/features/shared/helpers/animations';

@Component({
  selector: 'app-form-validation',
  templateUrl: './form-validation.component.html',
  styleUrls: ['../form-fields.scss', './form-validation.component.scss'],
  animations: [fieldErrorEnterAnimation],
})
export class FormValidationComponent implements OnChanges {
  @Input() validation: fromQuestionsModels.QuestionValidator; // passing in directly
  @Input() validatorType: fromQuestionsModels.ValidatorTypes; // referencing from the store
  @Input() noMargin = false;
  validation$: Observable<any>;

  constructor(private store: Store<fromQuestionsReducers.QuestionsState>) {}

  ngOnChanges() {
    /**
     * Using the validatorType to reference the store takes higher priority.
     * Otherwise, reference the validation object that's passed in directly.
     */
    this.validation$ = this.validatorType
      ? this.store.pipe(
          select(fromQuestionsSelectors.selectValidationValue, {
            name: this.validatorType,
          })
        )
      : of(this.validation);
  }
}
