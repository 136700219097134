// vendor
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// modules
import { MainModule } from './features/main/main.module';
// configs
import { MAIN_URL_SEGMENT } from './features/main/configs';

const routes: Routes = [
  {
    path: '',
    redirectTo: MAIN_URL_SEGMENT,
    pathMatch: 'full',
  },
  {
    path: MAIN_URL_SEGMENT,
    loadChildren: () => MainModule,
  },
  {
    path: 'lightbox',
    redirectTo: MAIN_URL_SEGMENT,
  },
  {
    path: '**',
    redirectTo: `${MAIN_URL_SEGMENT}/unavailable`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
