// vendor
import { Component } from '@angular/core';
import { StylableDialogComponent } from '../../../../shared/components/dialogs';

@Component({
  selector: 'app-application-terms-dialog',
  templateUrl: './application-terms-dialog.component.html',
  styles: ['.c-application-terms-dialog__title { min-height: 20px;}'],
})
export class ApplicationTermsDialogComponent extends StylableDialogComponent {}
