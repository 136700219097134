<app-panel class="c-confirm-info-card">
  <div class="title">
    <span class="c-confirm-info-card__name">{{ sectionName }}</span>
    <button class="c-confirm-info-card__edit-btn" (click)="onEdit()">
      <svg class="c-confirm-info-card__edit-btn-icon" viewBox="0 0 24 24">
        <use href="#edit"></use>
      </svg>
      <span class="c-confirm-info-card__edit-btn-label">Edit</span>
    </button>
  </div>
  <div class="content">
    <div class="l-container">
      <ng-container [ngSwitch]="sectionName">
        <ng-container *ngSwitchCase="formSection.CONTACT_INFO">
          <ng-container *ngTemplateOutlet="contactInformation"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="formSection.HOUSING">
          <ng-container *ngTemplateOutlet="housingInfo"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="formSection.EMPLOYMENT">
          <ng-container *ngTemplateOutlet="employmentInfo"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="formSection.INCOME">
          <ng-container *ngTemplateOutlet="incomeInfo"></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</app-panel>

<!----------------------------------CONTACT INFORMATION---------------------------------------------------->
<ng-template #contactInformation>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">name</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">{{ displayData.firstName }} {{ displayData.lastName }}</div>
    </div>
  </div>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">address</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">{{ displayData.address }},</div>
      <div>
        <span class="c-confirm-info-card__value-item c-confirm-info-card__value--margin">{{ displayData.city }}</span>
        <span class="c-confirm-info-card__value-item c-confirm-info-card__value--margin">{{
          displayData.state | stateTransform
        }}</span>
        <span>{{ displayData.zipCode }}</span>
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item" *ngIf="displayData.mobilePhone">
    <label class="c-confirm-info-card__label">mobile phone number</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.mobilePhone | phone }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item" *ngIf="displayData.homePhone">
    <label class="c-confirm-info-card__label">home phone number</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.homePhone | phone }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__phone-help-text">
    {{ phoneHelpText }}
    <button class="button-link-style" mat-button color="primary" (click)="openCommunicationsConsentDialog()">
      specified here
    </button>
  </div>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">email</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.emailAddress }}
      </div>
    </div>
  </div>
</ng-template>

<!----------------------------------HOUSING INFORMATION---------------------------------------------------->
<ng-template #housingInfo>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">home status</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.housingType }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">housing payment</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.housingMonthlyPayment }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">length of residence</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.residentialLength }}
      </div>
    </div>
  </div>
</ng-template>

<!----------------------------------EMPLOYMENT INFORMATION---------------------------------------------------->
<ng-template #employmentInfo>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">current employment status</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ transformEmploymentStatus(displayData.employmentStatus) }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item" *ngIf="displayData.employerPhone">
    <label class="c-confirm-info-card__label">employer phone</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.employerPhone | phone }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item" *ngIf="displayData.employerName">
    <label class="c-confirm-info-card__label">employer name</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ lodash.capitalize(displayData.employerName) }}
      </div>
    </div>
  </div>
</ng-template>

<!----------------------------------INCOME INFORMATION---------------------------------------------------->
<ng-template #incomeInfo>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">annual income before taxes</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item">
        {{ displayData.annualIncome | currency }}
      </div>
    </div>
  </div>
  <div class="c-confirm-info-card__item">
    <label class="c-confirm-info-card__label">bank account types</label>
    <div class="c-confirm-info-card__value">
      <div class="c-confirm-info-card__value-item" *ngFor="let bankType of displayData.banking">
        {{ lodash.capitalize(bankType) }}
      </div>
    </div>
  </div>
</ng-template>
