import { ApplicationStepComponentTypes } from '../../../application/models';
import { ApplicationStepNameTypes, StepComponentMap } from '../../models';

export const defaultStepComponentMap: StepComponentMap = {
  [ApplicationStepNameTypes.AffiliateLanding]: ApplicationStepComponentTypes.AffiliateLanding,
  [ApplicationStepNameTypes.VerifyIdentity]: ApplicationStepComponentTypes.VerifyIdentity,
  [ApplicationStepNameTypes.ConfirmInfo]: ApplicationStepComponentTypes.ConfirmInfo,
  [ApplicationStepNameTypes.ContactInformation]: ApplicationStepComponentTypes.ContactInformationComponent,
  [ApplicationStepNameTypes.BreakingBECCStep1]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.BreakingBECCStep2]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.WalkupInformation]: ApplicationStepComponentTypes.WalkupInformationComponent,
  [ApplicationStepNameTypes.PhysicalAddress]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.PersonalInformation]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.Banking]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.Housing]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.ResidentialLength]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.CashAdvances]: ApplicationStepComponentTypes.DynamicStepComponent,
  [ApplicationStepNameTypes.TermsAndConditions]: ApplicationStepComponentTypes.TermsAndConditionsComponent,
  [ApplicationStepNameTypes.SocialSecurityNumber]: ApplicationStepComponentTypes.SocialSecurityNumberComponent,
  [ApplicationStepNameTypes.Agreement]: ApplicationStepComponentTypes.AgreementComponent,
  [ApplicationStepNameTypes.OpportunityMicrofunnel]: ApplicationStepComponentTypes.OpportunityMicrofunnelComponent,
};
