import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';

/**
 * add script
 * @param src source script
 */
const addScript = (src: string): void => {
  if (src) {
    const scriptEl = document.createElement('script');
    scriptEl.src = src;
    window.document.head.appendChild(scriptEl);
  }
};

/**
 * add script before any CSS:
 * ex: necessary to load Optimizely before CSS to avoid content flashes.
 * @param src source script
 */
const addScriptBeforeStyles = (src: string): void => {
  if (/optimizely/.test(src)) {
    const preLoadEl = document.createElement('link');
    preLoadEl.rel = 'preload';
    preLoadEl.as = 'script';
    preLoadEl.href = src;
    const preConnectEl = document.createElement('link');
    preConnectEl.rel = 'preconnect';
    preConnectEl.href = '//logx.optimizely.com';
    const viewPortEl = document.querySelector('meta[name="viewport"]');
    viewPortEl.after(preLoadEl);
    viewPortEl.after(preConnectEl);
  }

  const scriptEl = document.createElement('script');
  const styleElements = document.getElementsByTagName('style');
  scriptEl.src = src;

  if (styleElements.length) {
    const firstStyleElement = styleElements[0];
    firstStyleElement.parentNode.insertBefore(scriptEl, firstStyleElement);
  } else {
    window.document.head.appendChild(scriptEl);
  }
};

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.production) {
  // only run DataDog in production
  datadogRum.init({
    applicationId: environment.dataDogApplicationId,
    clientToken: environment.dataDogClientToken,
    site: 'datadoghq.com',
    service: environment.dataDogServiceName,
    version: environment.appVersion,
    sampleRate: 100,
    trackInteractions: true,
  });
}

if (environment.production) {
  enableProdMode();
}

if (environment.env !== 'local') {
  addScriptBeforeStyles(environment.optimizelyCdn);
}
