// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromApplicationMetaReducer from '../reducers/application-meta.reducer';

// application state
export const selectApplicationMetaState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.applicationMeta
);

// data
export const selectApplicationMetaData = createSelector(
  selectApplicationMetaState,
  fromApplicationMetaReducer.selectApplicationMetaStateData
);
