// store
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromCardProductOffersActions from '../actions/card-product-offers.actions';
// models
import { CardProductOffer } from '../../models';

// key
export const CARD_PRODUCT_OFFERS_STATE_KEY = 'cardProductOffers';

// state
export interface CardProductOffersState extends EntityState<CardProductOffer> {
  loading: boolean;
  loaded: boolean;
}

export const initialState: CardProductOffersState = {
  ids: [],
  entities: null,
  loaded: false,
  loading: false,
};

// adapter
export const selectCardProductOfferId = (cardProductOffer: CardProductOffer): string => cardProductOffer.id;

export const adapter: EntityAdapter<CardProductOffer> = createEntityAdapter<CardProductOffer>({
  selectId: selectCardProductOfferId,
});

// reducer
const cardProductOffersReducer = createReducer(
  initialState,
  // load
  on(fromCardProductOffersActions.loadCardProductOffers, (state: CardProductOffersState) => ({
    ...state,
    loading: true,
  })),
  on(
    fromCardProductOffersActions.loadCardProductOffersSuccess,
    (
      state: CardProductOffersState,
      action: ReturnType<typeof fromCardProductOffersActions.loadCardProductOffersSuccess>
    ) =>
      adapter.setAll(action.cardProductOffers, {
        ...state,
        loading: false,
        loaded: true,
      })
  ),
  on(
    fromCardProductOffersActions.loadCardProductOffersFail,
    (
      state: CardProductOffersState,
      action: ReturnType<typeof fromCardProductOffersActions.loadCardProductOffersFail>
    ) => ({
      ...state,
      loaded: false,
      loading: false,
    })
  ),
  on(fromCardProductOffersActions.clearCardProductOffers, (state: CardProductOffersState) => initialState)
);

export const reducer = (state: CardProductOffersState, action: fromCardProductOffersActions.CardProductOffersAction) =>
  cardProductOffersReducer(state, action);

/* state selectors */
export const { selectEntities } = adapter.getSelectors();
export const selectCardProductOffersStateEntities = (state: CardProductOffersState) => state.entities;
export const selectCardProductOffersStateLoaded = (state: CardProductOffersState) => state.loaded;
export const selectCardProductOffersStateLoading = (state: CardProductOffersState) => state.loading;
