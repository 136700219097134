import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash-es';
import { Subject, of, zip } from 'rxjs';
import { catchError, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { getAbTestsAction } from 'src/app/features/shared/helpers/helper-functions';
import { noop } from 'src/app/store';
import { loadAbTestsSuccess } from '../../../ab-tests/store/actions/ab-tests.actions';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import { Lead, LeadResponseV2 } from '../../../lead/models';
import { LeadService } from '../../../lead/services';
import * as fromLeadStore from '../../../lead/store';
import * as fromOffersStore from '../../../offers/store';
import * as fromApplicationActions from '../actions';
import * as fromQuestionsStore from '../../../questions/store';
import { FullstoryService } from '../../../analytics/services';
import { FullstoryEventTypes } from '../../../analytics/models';
import { SyncService } from 'src/app/features/shared/services';
import { offerTypeToExperienceMapKeysLowerCase } from 'src/app/features/digital-experience/services';
import { DigitalExperienceTypes } from 'src/app/features/digital-experience/models';
import { leadHelpers } from 'src/app/features/lead/services/helpers/lead.helpers';
import { AbTestNames, ToggleApplicationSubmitPopulations } from 'src/app/features/ab-tests/models';
import { defaultErrorMessages } from 'src/app/features/shared/configs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationLoadProcessEffects {
  stopProcessWatch$ = new Subject();

  constructor(
    private action$: Actions,
    private store: Store<any>,
    private leadService: LeadService,
    private syncService: SyncService,
    private readonly fullStoryService: FullstoryService
  ) {}

  /**
   * process success watch
   * purpose: all actions to watch for success load process
   */
  applicationLoadProcessSuccessWatch$ = zip(
    this.action$.pipe(ofType(fromOffersStore.cardOfferLoadProcessSuccess)),
    this.action$.pipe(ofType(fromApplicationActions.loadOptionsSuccess)),
    this.action$.pipe(ofType(fromDigitalExperienceStore.updateDigitalExperienceSuccess)),
    this.action$.pipe(ofType(fromLeadStore.createLeadSuccess))
  ).pipe(
    takeUntil(this.stopProcessWatch$),
    tap(() => {
      this.store.dispatch(fromApplicationActions.applicationLoadProcessSuccess());
      this.stopProcessWatch();
    })
  );

  /**
   * process fail watch
   * purpose: any actions to watch for load process fail
   */
  applicationLoadProcessFailWatch$ = this.action$.pipe(
    ofType(
      fromOffersStore.cardOfferLoadProcessFail,
      fromApplicationActions.loadOptionsFail,
      fromLeadStore.createLeadFail
    ),
    takeUntil(this.stopProcessWatch$),
    tap((action) => {
      this.store.dispatch(fromApplicationActions.applicationLoadProcessFail({ action }));
      this.stopProcessWatch();
    })
  );

  /**
   * success card offer load process
   * [action props]: none
   * [data dependencies]: none
   * [purpose]: actions to dispatch once offer load process successful
   */
  successCardOfferLoadProcess$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersStore.cardOfferLoadProcessSuccess),
      switchMap(() => [fromApplicationActions.loadOptions()])
    )
  );

  /**
   * application load process
   * [action props]: none
   * [data dependencies]: applicationMeta
   * [purpose]: clearing and init process actions
   */
  applicationLoadProcess$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.applicationLoadProcess),
      switchMap((action) => {
        this.startProcessWatch();
        const { queryParams } = action;
        return [
          fromLeadStore.clearLead(),
          fromApplicationActions.clearOptions(),
          fromApplicationActions.leadLoadProcess({ queryParams }),
        ];
      })
    )
  );

  // card offer load process
  leadLoadProcess$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.leadLoadProcess),
      switchMap((action) =>
        this.leadService.postLead(action.queryParams).pipe(
          switchMap((leadRes: LeadResponseV2) => [
            // Once we get back the response containing all the data (lead, campaign and prospect), then return
            // actions for populating each of the stores that contain this data.
            fromOffersStore.clearCardProspectOffer(),
            fromOffersStore.clearCardProductOffer(),
            fromOffersStore.clearCardProduct(),
            fromApplicationActions.setApplicationMeta({
              applicationMeta: action.queryParams,
            }),
            fromDigitalExperienceStore.setDigitalExperienceName({
              leadRes,
            }),
            ...this.prospectActions(leadRes),
            ...this.expressLaneActions(leadRes),
            fromOffersStore.loadCardProductOfferSuccess({
              cardProductOffer: leadRes.productOffer,
            }),
            getAbTestsAction(leadRes, action.queryParams),
            fromDigitalExperienceStore.loadDigitalExperience({
              cardProductOfferId: leadRes.productOffer.id,
            }),
            fromDigitalExperienceStore.updateDigitalExperience(),
            fromOffersStore.cardOfferLoadProcessSuccess(),
            this.leadActions(action, leadRes),
          ])
        )
      ),
      catchError((error: HttpErrorResponse) => of(fromLeadStore.createLeadFail({ error })))
    )
  );

  leadActions(action, leadRes: LeadResponseV2) {
    if (
      leadRes.abTests.find((test) => test.testName === AbTestNames.TOGGLE_APPLICATION_SUBMIT)?.population ===
      ToggleApplicationSubmitPopulations.NOTSUBMIT
    ) {
      return fromLeadStore.createLeadFail({
        error: {
          error: {
            message: defaultErrorMessages.serviceClosed,
          },
        },
      });
    } else {
      return fromLeadStore.createLeadSuccess(
        leadRes.opportunityData
          ? this.combineLeadDataWithOpportunityData(leadRes)
          : this.combineLeadDataWithProspectAndQueryParam(leadRes, action.queryParams)
      );
    }
  }

  abTestFullStoryEvents$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(loadAbTestsSuccess),
        first(),
        tap((action) => {
          const eventPayload = action.abTestResponseData.reduce((payload, diceRollResult) => {
            payload[`${diceRollResult.testName}_str`] = diceRollResult.population;
            return payload;
          }, {} as Record<string, string>);

          this.fullStoryService.event(FullstoryEventTypes.AbTestData, eventPayload);
        })
      ),
    { dispatch: false }
  );

  expressLaneActions(leadRes: LeadResponseV2): Action[] {
    const returnActions = [];
    // Only prefill data if it's valid express lane request
    if (leadRes.auth && leadRes.kcf) {
      returnActions.push(
        fromQuestionsStore.updateQuestionValues({
          questionValues: { ...leadHelpers.mapCustomerToQuestionValue(leadRes.casCustomerData) },
        })
      );
    }
    return returnActions;
  }

  /**
   * Determine whether to dispatch prospect related actions or not after
   * post lead/ post walkup lead is called
   */
  prospectActions(leadRes: LeadResponseV2): Action[] {
    const returnActions = [];
    if (_.isEmpty(leadRes.prospectOffer)) {
      returnActions.push(noop());
    } else {
      returnActions.push(
        fromOffersStore.loadCardProspectOfferSuccess({
          cardProspectOffer: leadRes.prospectOffer,
        })
      );
      returnActions.push(
        fromOffersStore.syncCardProspectToQuestions({
          prospect: leadRes.prospectOffer.prospect,
        })
      );
    }
    return returnActions;
  }

  combineLeadDataWithOpportunityData(leadRes: LeadResponseV2) {
    const { leadId, lead, opportunityData, productOffer } = leadRes;
    const partialLead: Partial<Lead> = {
      id: leadId || lead.id,
      productOfferId: productOffer.id,
      primaryApplicant: {
        emailAddress: opportunityData.emailAddress,
        fullName: {
          firstName: opportunityData.applicantFirstName,
          lastName: null,
        },
      },
    };

    const casCustomerData = {
      firstName: opportunityData.applicantFirstName,
      lastName: opportunityData.applicantLastName,
      customerId: opportunityData.customerId,
    };

    return { lead: partialLead, opportunityData, casCustomerData };
  }

  /**
   * In case primaryApplicant in leadRes is missing important information (fullName/ email)
   * grab from prospect data & casCustomerData and combine to update the lead store.
   */
  combineLeadDataWithProspectAndQueryParam(leadRes: LeadResponseV2, queryParam: Params) {
    const { lead, prospectOffer, casCustomerData, auth, kcf } = leadRes;
    const partialLead: Partial<Lead> = {
      ...lead,
      primaryApplicant: { ...lead.primaryApplicant, ...prospectOffer?.prospect },
    };
    // if casCustomerData available, use fullName and email from it (highest priority),
    // we should always try to have them populated as early as we can
    // that way chat bot can init with suffice meta data
    if (!_.isEmpty(casCustomerData) && casCustomerData.firstName && casCustomerData.lastName) {
      const { primaryApplicant = {} } = partialLead;
      partialLead.primaryApplicant = {
        ...primaryApplicant,
        emailAddress: casCustomerData.email || primaryApplicant.emailAddress,
        fullName: {
          ...primaryApplicant.fullName,
          firstName: casCustomerData.firstName,
          lastName: casCustomerData.lastName,
        },
      };
    }
    // Use the email from query param as last resort if we still missing it at this point
    if (!partialLead.primaryApplicant.emailAddress) {
      partialLead.primaryApplicant.emailAddress = queryParam?.emailAddress;
    }
    let expressLaneData: fromLeadStore.ExpressLaneData;
    if (!_.isNil(auth) && !_.isNil(kcf)) {
      expressLaneData = {
        auth,
        kcf,
      };
    }
    return { lead: partialLead, casCustomerData, expressLaneData };
  }

  /**
   * start process watch
   */
  startProcessWatch() {
    this.applicationLoadProcessSuccessWatch$.subscribe();
    this.applicationLoadProcessFailWatch$.subscribe();
  }

  /**
   * stop process watch
   */
  stopProcessWatch() {
    this.stopProcessWatch$.next();
  }
}
