// ngrx
import { createSelector } from '@ngrx/store';
import * as fromQuestionsReducer from '../reducers';
import * as fromQuestionsValuesReducer from '../reducers/question-values.reducer';
// models
import { QuestionValues, QuestionNameTypes } from '../../models';

// question values state
export const selectQuestionValuesState = createSelector(
  fromQuestionsReducer.selectQuestionsFeature,
  (state: fromQuestionsReducer.QuestionsState) => state[fromQuestionsValuesReducer.QUESTION_VALUES_STATE_KEY]
);

// question values data
export const selectQuestionValuesData = createSelector(
  selectQuestionValuesState,
  fromQuestionsValuesReducer.selectQuestionValuesStateData
);

// question value
export const selectQuestionValue = createSelector(selectQuestionValuesData, (questionValues, props) =>
  questionValues ? questionValues[props.name] : null
);

// address
export const selectAddress = createSelector(selectQuestionValuesData, (questionValues: QuestionValues) => {
  if (questionValues) {
    return {
      [QuestionNameTypes.Address]: questionValues[QuestionNameTypes.Address] || null,
      [QuestionNameTypes.City]: questionValues[QuestionNameTypes.City] || null,
      [QuestionNameTypes.State]: questionValues[QuestionNameTypes.State] || null,
      [QuestionNameTypes.ZipCode]: questionValues[QuestionNameTypes.ZipCode] || null,
    };
  } else {
    return null;
  }
});
