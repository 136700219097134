// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDigitalExpReducers from '../reducers';
import { DigitalExperienceState } from '../reducers';

// key
export const DIGITAL_EXPERIENCE_FEATURE_KEY = 'digitalExperience';

// feature selector
export const selectDigitalExperienceFeature =
  createFeatureSelector<fromDigitalExpReducers.DigitalExperienceState>(DIGITAL_EXPERIENCE_FEATURE_KEY);

// get the entity selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  fromDigitalExpReducers.adapter.getSelectors(selectDigitalExperienceFeature);

// select the array of ids
export const selectAppStepIds = selectIds;

// select the dictionary of entities
export const selectAppStepEntities = selectEntities;

// select the array of entities
export const selectAllAppSteps = selectAll;

// select the total count
export const selectAppStepTotal = selectTotal;

// experience name
export const selectDigitalExperienceName = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceName
);

// currentStep
export const selectDigitalExperienceCurrentStep = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceCurrentStep
);

// stepDirection
export const selectDigitalExperienceStepDirection = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceStepDirection
);

// step component override map
export const selectDigitalExperienceStepComponentMap = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceStepComponentMap
);

export const selectDigitalExperienceInitiallyUpdated = createSelector(
  selectDigitalExperienceFeature,
  (state: DigitalExperienceState) => state.digitalExperienceInitiallyUpdated
);

// progress
export const selectDigitalExperienceProgress = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceProgress
);

// select step indicators
export const selectDigitalExperienceStepIndicators = createSelector(selectDigitalExperienceFeature, (state) => {
  const { currentStep, isFirstStep, isLastStep, stepDirection } = state;
  return {
    currentStep,
    isFirstStep,
    isLastStep,
    stepDirection,
  };
});

// current step data
export const selectDigitalExperienceCurrentApplicationStepData = createSelector(
  selectDigitalExperienceStepComponentMap,
  selectAppStepEntities,
  selectDigitalExperienceStepIndicators,
  (stepComponentOverrideMap, applicationStepEntities, applicationStepIndicators) => {
    const { currentStep } = applicationStepIndicators;
    return {
      overrideComponent: stepComponentOverrideMap[currentStep],
      applicationStep: applicationStepEntities[currentStep],
      applicationStepIndicators,
    };
  }
);

// loaded
export const selectDigitalExperienceLoaded = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceStateLoaded
);

// loading
export const selectDigitalExperienceLoading = createSelector(
  selectDigitalExperienceFeature,
  fromDigitalExpReducers.digitalExperienceStateLoading
);
