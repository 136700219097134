// vendor
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserDataCollectionService } from '../user-data-collection.service';
import { FullstoryService } from '../fullstory/fullstory.service';
import { FullstoryEventTypes } from '../../models';
import { EnterpriseEventTrackingService } from '../enterprise-event/enterprise-event.service';
import { WindowUtilService } from 'src/app/features/shared/services/window-util.service';

@Injectable({
  providedIn: 'root',
})
export class AdaChatbotService {
  private renderer: Renderer2;
  constructor(
    private windowUtilService: WindowUtilService,
    private userDataCollectService: UserDataCollectionService,
    private fullStoryService: FullstoryService,
    private EEService: EnterpriseEventTrackingService,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  init() {
    const ada = document.querySelector('#__ada');
    // If the load event is fired before the init is called
    // (script is downloaded before the application bundle finish)
    if (this.windowUtilService.nativeWindow.adaEmbed) {
      this.setUpChatbot();
    } else {
      ada.addEventListener('load', () => this.setUpChatbot());
    }
  }

  setUpChatbot() {
    // Need to provide handle name in ada chatbot start method
    try {
      this.windowUtilService.nativeWindow.adaEmbed.start({
        handle: environment.adaChatbotHandleName,
        // Ada docs on chatterTokenCallback:
        // "Specifies a callback for when the chatter token has been set in Chat.
        // This is called when chat is first opened by a chatter."
        chatterTokenCallback: () => {
          this.collectUserDataForChatbot();
          this.fireAnalyticsEvents();
        },
      });
    } catch (e) {
      console.error('[ADA_CHATBOT_SERVICE] Failed to start Ada chatbot', e.message);
    }
  }

  fireAnalyticsEvents() {
    this.fullStoryService.event(FullstoryEventTypes.AdaChatbotOpened, {});
    this.EEService.reportAdaChatbotInitiated();
  }

  collectUserDataForChatbot() {
    this.userDataCollectService.emailAddress$.pipe(first()).subscribe((email) => {
      this.setMetaField('email', email);
    });
    this.userDataCollectService.firstName$.pipe(first()).subscribe((firstName) => {
      this.setMetaField('name', firstName);
    });
    this.userDataCollectService.leadData$.pipe(first()).subscribe((lead) => {
      this.setMetaField('leadid', lead.id);
    });
  }

  // Pass in metadata to Ada
  setMetaField(type: string, value: string) {
    try {
      const metadata = {};
      metadata[type] = value;
      this.windowUtilService.nativeWindow.adaEmbed.setMetaFields(metadata);
    } catch (e) {
      console.error(`[ADA_CHATBOT_SERVICE] Failed to set ${type} metadata for Ada chatbot`, e.message);
    }
  }

  adjustAdaChatbotMobileOffset(offset: string) {
    try {
      const adaWidget = this.renderer.selectRootElement('.ada-button-frame');
      this.renderer.setStyle(adaWidget, 'bottom', offset);
    } catch (e) {
      console.error('[ADA_CHATBOT_SERVICE] Failed to adjust chatbot position');
    }
  }
}
