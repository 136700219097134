// vendor
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// models
import { CardFunnelAvailability, CardProductOffer, CardProspectOffer, CardProduct, ProductOfferTypes } from '../models';
// configs
import * as fromOffersConfigs from '../configs';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @param cardProspectOffer
   * @returns
   */
  getCardFunnelAvailability(): Observable<CardFunnelAvailability> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/availability`;
    return this.http.get<CardFunnelAvailability>(url).pipe(catchError((err) => throwError(err)));
  }

  /**
   * add card prospect offer
   * @param cardProspectOffer
   * @returns observable of prospect offer id
   */
  addCardProspectOffer(cardProspectOffer: CardProspectOffer): Observable<any> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.prospectsOfferPartialUrl}`;
    return this.http.post<any>(url, cardProspectOffer).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card prospect offer by id
   * @params offerId card prospect offer identifier
   * @returns observable of card prospect offer
   */
  getCardProspectOfferById(cardProspectOfferId: string): Observable<CardProspectOffer> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.prospectsOfferPartialUrl}/${cardProspectOfferId}`;
    return this.http.get<CardProspectOffer>(url).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card prospect offer by offer code
   * @params offerCode offer code
   * @returns observable of card prospect offer
   */
  getCardProspectOfferByOfferCode(offerCode: string): Observable<CardProspectOffer> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/prospects/offercode`;
    const params: HttpParams = new HttpParams().set('offerCode', offerCode);
    return this.http.get<CardProspectOffer>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card prospect offer by params
   * @params emailAddress email address
   * @params offerCode offer code
   * @returns observable of card prospect offer
   */
  getCardProspectOfferByParams(emailAddress: string, offerCode: string): Observable<CardProspectOffer> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.prospectsOfferPartialUrl}`;
    const params: HttpParams = new HttpParams().set('emailAddress', emailAddress).set('offerCode', offerCode);
    return this.http.get<CardProspectOffer>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card product offer
   * @params cardProductOfferId card product offer id
   * @returns observable of product offer
   */
  getCardProductOffer(cardProductOfferId: string): Observable<CardProductOffer> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.productsOfferPartialUrl}/${cardProductOfferId}`;
    return this.http.get<CardProductOffer>(url).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card product default offer
   * @returns observable card product offer
   */
  getCardProductDefaultOffer(): Observable<CardProductOffer> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.productsOfferPartialUrl}`;
    const params: HttpParams = new HttpParams().set('offerDisplayType', ProductOfferTypes.DefaultWeb);
    return this.http.get<CardProductOffer>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card product offers
   * @params active active status
   * @returns observable of card product offers
   */
  getCardProductOffers(active: boolean = true): Observable<CardProductOffer[]> {
    const url = `${fromOffersConfigs.offerServiceBaseUrl.v1}/${fromOffersConfigs.productsOffersPartialUrl}`;
    const params: HttpParams = new HttpParams().set('active', active.toString());
    return this.http.get<CardProductOffer[]>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get card product
   * @params cardProductId card product identifier
   * @returns observable of card product
   */
  getCardProduct(cardProductId: string): Observable<CardProduct> {
    const url = `${fromOffersConfigs.productServiceBaseUrl.v1}/products/${cardProductId}`;
    return this.http.get<CardProduct>(url).pipe(catchError((err) => throwError(err)));
  }
}
