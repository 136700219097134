import {
  FormCheckboxComponent,
  FormCheckboxGroupComponent,
  FormInputComponent,
  FormRadioGroupComponent,
  FormSelectDropdownComponent,
  FormAutocompleteComponent,
} from '../components/form-fields';
import { FormElTypes, QuestionComponentMap, QuestionMap } from '../models';
import {
  addressQuestion,
  agreementQuestion,
  annualIncomeQuestion,
  autopayEnrollmentQuestion,
  bankingQuestion,
  cashAdvancesQuestion,
  cityQuestion,
  confirmInformationCorrect,
  dateOfBirthQuestion,
  emailAddressQuestion,
  employerNameQuestion,
  employerPhoneQuestion,
  employmentStatusQuestion,
  firstNameQuestion,
  homePhoneQuestion,
  housingMonthlyPaymentQuestion,
  housingTypeQuestion,
  lastFourSocialSecurityNumberQuestion,
  lastNameQuestion,
  mobilePhoneQuestion,
  paperlessStatementPreferenceQuestion,
  paperStatementPreferenceQuestion,
  physicalAddressQuestion,
  physicalCityQuestion,
  physicalStateQuestion,
  physicalZipCodeQuestion,
  residentialLengthQuestion,
  secondaryPhoneTypeQuestion,
  socialSecurityNumberQuestion,
  stateQuestion,
  zipCodeQuestion,
} from './questions';
/**
 * Question Mapping
 */
export const defaultQuestionMap: QuestionMap = {
  [addressQuestion.name]: addressQuestion,
  [agreementQuestion.name]: agreementQuestion,
  [annualIncomeQuestion.name]: annualIncomeQuestion,
  [autopayEnrollmentQuestion.name]: autopayEnrollmentQuestion,
  [bankingQuestion.name]: bankingQuestion,
  [cashAdvancesQuestion.name]: cashAdvancesQuestion,
  [confirmInformationCorrect.name]: confirmInformationCorrect,
  [cityQuestion.name]: cityQuestion,
  [dateOfBirthQuestion.name]: dateOfBirthQuestion,
  [emailAddressQuestion.name]: emailAddressQuestion,
  [employerNameQuestion.name]: employerNameQuestion,
  [employmentStatusQuestion.name]: employmentStatusQuestion,
  [firstNameQuestion.name]: firstNameQuestion,
  [housingMonthlyPaymentQuestion.name]: housingMonthlyPaymentQuestion,
  [housingTypeQuestion.name]: housingTypeQuestion,
  [lastFourSocialSecurityNumberQuestion.name]: lastFourSocialSecurityNumberQuestion,
  [lastNameQuestion.name]: lastNameQuestion,
  [paperStatementPreferenceQuestion.name]: paperStatementPreferenceQuestion,
  [paperlessStatementPreferenceQuestion.name]: paperlessStatementPreferenceQuestion,
  [physicalAddressQuestion.name]: physicalAddressQuestion,
  [physicalCityQuestion.name]: physicalCityQuestion,
  [physicalStateQuestion.name]: physicalStateQuestion,
  [physicalZipCodeQuestion.name]: physicalZipCodeQuestion,
  [residentialLengthQuestion.name]: residentialLengthQuestion,
  [mobilePhoneQuestion.name]: mobilePhoneQuestion,
  [homePhoneQuestion.name]: homePhoneQuestion,
  [employerPhoneQuestion.name]: employerPhoneQuestion,
  [secondaryPhoneTypeQuestion.name]: secondaryPhoneTypeQuestion,
  [socialSecurityNumberQuestion.name]: socialSecurityNumberQuestion,
  [stateQuestion.name]: stateQuestion,
  [zipCodeQuestion.name]: zipCodeQuestion,
};

/**
 * Question Component Mapping
 */
export const defaultQuestionComponentMap: QuestionComponentMap = {
  [FormElTypes.Input]: FormInputComponent,
  [FormElTypes.Select]: FormSelectDropdownComponent,
  [FormElTypes.Checkbox]: FormCheckboxComponent,
  [FormElTypes.CheckboxGroup]: FormCheckboxGroupComponent,
  [FormElTypes.RadioGroup]: FormRadioGroupComponent,
  [FormElTypes.AutoComplete]: FormAutocompleteComponent,
};
