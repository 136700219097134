// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardProspectOfferReducer from '../reducers/card-prospect-offer.reducer';

// card prospect offer state
export const selectCardProspectOfferState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardProspectOffer
);

// data
export const selectCardProspectOfferData = createSelector(
  selectCardProspectOfferState,
  fromCardProspectOfferReducer.selectCardProspectOfferStateData
);

// loading
export const selectCardProspectOfferLoading = createSelector(
  selectCardProspectOfferState,
  fromCardProspectOfferReducer.selectCardProspectOfferStateLoading
);

// loaded
export const selectCardProspectOfferLoaded = createSelector(
  selectCardProspectOfferState,
  fromCardProspectOfferReducer.selectCardProspectOfferStateLoaded
);

// error
export const selectCardProspectOfferError = createSelector(
  selectCardProspectOfferState,
  fromCardProspectOfferReducer.selectCardProspectOfferStateError
);
