// vendor
import { HttpErrorResponse } from '@angular/common/http';
// rxjs
import { createReducer, on } from '@ngrx/store';
import * as fromLeadActions from '../actions';
// models
import { Lead } from '../../models';
import { CasCustomerData, OpportunityData } from 'fakesl/src/models/generated/leadmanagementservice.model';

// key
export const LEAD_STATE_KEY = 'lead';

export interface ExpressLaneData {
  auth: boolean;
  kcf: boolean;
}
// state
export interface LeadState {
  data: Partial<Lead>;
  error: Record<string, any>;
  loaded: boolean;
  loading: boolean;
  casCustomerData?: CasCustomerData;
  opportunityData?: OpportunityData;
  expressLaneData?: ExpressLaneData;
}

export const initialState: LeadState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
  casCustomerData: null,
  opportunityData: null,
  expressLaneData: null,
};

// reducer
const leadReducer = createReducer(
  initialState,
  //load
  on(
    fromLeadActions.loadLead,
    fromLeadActions.loadLeadByParams,
    fromLeadActions.updateLead,
    fromLeadActions.updateOrCreateLead,
    fromLeadActions.validateKcfVerifyIdentity,
    (state: LeadState) => ({
      ...state,
      loading: true,
      loaded: false,
    })
  ),
  on(fromLeadActions.loadLeadSuccess, (state: LeadState, action) => ({
    ...state,
    data: action.lead,
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromLeadActions.createLeadSuccess, (state: LeadState, action) => ({
    ...state,
    data: action.lead,
    error: null,
    loading: false,
    loaded: true,
    casCustomerData: action.casCustomerData,
    opportunityData: action.opportunityData,
    expressLaneData: action.expressLaneData,
  })),
  on(fromLeadActions.updateLeadSuccess, (state: LeadState, action) => ({
    ...state,
    data: { ...state.data, ...action.lead },
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromLeadActions.validateKcfVerifyIdentitySuccess, (state: LeadState, action) => ({
    ...state,
    error: null,
    loading: false,
    loaded: true,
  })),
  on(
    fromLeadActions.loadLeadFail,
    fromLeadActions.createLeadFail,
    fromLeadActions.updateLeadFail,
    (state: LeadState, action) => ({
      ...state,
      error: action.error,
      loading: false,
      loaded: false,
    })
  ),
  on(fromLeadActions.validateKcfVerifyIdentityFail, (state: LeadState, action) => {
    if (action.retryLimitReached) {
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
      };
    } else {
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    }
  }),
  // clear
  on(fromLeadActions.clearLead, (state: LeadState) => initialState),
  on(fromLeadActions.clearLeadExtraData, (state: LeadState) => ({
    ...state,
    casCustomerData: null,
    opportunityData: null,
    expressLaneData: null,
  }))
);

export const reducer = (state: LeadState, action: fromLeadActions.LeadAction) => leadReducer(state, action);

// state selectors
export const selectLeadStateData = (state: LeadState) => state.data;
export const selectLeadStateError = (state: LeadState) => state.error;
export const selectLeadStateLoading = (state: LeadState) => state.loading;
export const selectLeadStateLoaded = (state: LeadState) => state.loaded;
export const selectLeadStateCasData = (state: LeadState) => state.casCustomerData;
export const selectLeadStateOdlData = (state: LeadState) => state.opportunityData;
