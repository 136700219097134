// imports
import { CardFunnelAvailabilityEffects } from './card-funnel-availability.effects';
// import { CardOfferLoadProcessEffects } from './card-offer-load-process.effects';
import { CardProductOffersEffects } from './card-product-offers.effects';
import { CardProspectOffersEffects } from './card-prospect-offers.effects';
import { CardProductEffects } from './card-product.effects';
// exports
export * from './card-funnel-availability.effects';
// export * from './card-offer-load-process.effects';
export * from './card-product-offers.effects';
export * from './card-prospect-offers.effects';
export * from './card-product.effects';
// exports group
export const effects: any[] = [
  CardFunnelAvailabilityEffects,
  // CardOfferLoadProcessEffects,
  CardProductOffersEffects,
  CardProspectOffersEffects,
  CardProductEffects,
];
