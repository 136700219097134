// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromApplicationSubmitReducer from '../reducers/application-submit.reducer';

// application submit state
export const selectApplicationSubmitState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.applicationSubmit
);

// data
export const selectApplicationSubmitData = createSelector(
  selectApplicationSubmitState,
  fromApplicationSubmitReducer.selectApplicationSubmitStateData
);

// loaded
export const selectApplicationSubmitLoaded = createSelector(
  selectApplicationSubmitState,
  fromApplicationSubmitReducer.selectApplicationSubmitStateLoaded
);

// loading
export const selectApplicationSubmitLoading = createSelector(
  selectApplicationSubmitState,
  fromApplicationSubmitReducer.selectApplicationSubmitStateLoading
);

// error
export const selectApplicationSubmitError = createSelector(
  selectApplicationSubmitState,
  fromApplicationSubmitReducer.selectApplicationSubmitStateError
);
