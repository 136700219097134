<ng-template #generalError>
  <app-text-bubble [designType]="TextBubbleTypes.ERROR">
    <div class="title">
      <ng-container *ngIf="isServiceClosed$ | async; else usualTitle">
        <p>
          Thank you for your interest in the Best Egg Credit Card. We are currently not offering the Card product;
          however, Best Egg offers other products that may be of interest to you. <br /><br />
          For more details, please go to <a href="https://www.bestegg.com" target="_blank">www.bestegg.com</a>.
        </p>
      </ng-container>
      <ng-template #usualTitle> We're sorry </ng-template>
    </div>
    <div class="content" *ngIf="(isServiceClosed$ | async) === false">
      <p>An issue occurred processing your request.</p>
      <ng-container *ngIf="message$ | async as message">
        <p class="c-unavailable__message" [innerHTML]="message"></p>
      </ng-container>
    </div>
  </app-text-bubble>
</ng-template>

<div class="c-unavailable">
  <ng-container *ngIf="isWalkupLeadRequestError$ | async; else generalError">
    <app-text-bubble [designType]="TextBubbleTypes.ERROR">
      <div class="title">We could not find an open application with your email address</div>
      <div class="content">
        <p class="c-unavailable__walkup-error-message">
          Please visit
          <a mat-button class="button-link-style" target="_blank" [href]="bestEggDomain">www.bestegg.com</a> to start a
          new Best Egg Credit Card application.
        </p>
      </div>
    </app-text-bubble>
  </ng-container>
</div>
