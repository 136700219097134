import { HttpErrorResponse } from '@angular/common/http';
// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Options } from '../../models';

export enum OptionsActionTypes {
  LoadOptions = '[Application] Load Options',
  LoadOptionsSuccess = '[Application] Load Options Success',
  LoadOptionsFail = '[Application] Load Options Fail',
  ClearOptions = '[Application] Clear Options',
}

// load options
export const loadOptions = createAction(OptionsActionTypes.LoadOptions);

export const loadOptionsSuccess = createAction(OptionsActionTypes.LoadOptionsSuccess, props<{ options: Options }>());

export const loadOptionsFail = createAction(OptionsActionTypes.LoadOptionsFail, props<{ error: HttpErrorResponse }>());

export const clearOptions = createAction(OptionsActionTypes.ClearOptions);

export type OptionsAction = ReturnType<
  typeof loadOptions | typeof loadOptionsSuccess | typeof loadOptionsFail | typeof clearOptions
>;
