import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { WindowRefService } from '@marlettefunding/frontend-common';

@Injectable({
  providedIn: 'root',
})
// Can use this service as a wrapper for WindowRefService
// Refactor any WindowRefService imports in the codebase
export class WindowUtilService {
  private renderer: Renderer2;
  constructor(private windowRef: WindowRefService, private rendererFactory: RendererFactory2) {
    // https://stackoverflow.com/questions/44989666/service-no-provider-for-renderer2
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  get nativeWindow() {
    return this.windowRef.nativeWindow;
  }

  resetScroll() {
    this.windowRef.nativeWindow.scrollTo(0, 0);
  }

  scrollToElementById(id: string, offset: number) {
    const elementPosition = this.renderer.selectRootElement(`#${id}`, true).getBoundingClientRect().top;
    const offsetPosition = elementPosition + this.nativeWindow.pageYOffset - offset;
    this.nativeWindow.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  isElementInViewport(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (this.nativeWindow.innerHeight || this.renderer.selectRootElement('body').clientHeight) &&
      rect.right <= (this.nativeWindow.innerWidth || this.renderer.selectRootElement('body').clientWidth)
    );
  }
}
