// services
import { regex } from '../../services/helpers';
// models
import { formatCurrency } from '@angular/common';
import { IconTypes } from '../../../shared/models';
import * as fromQuestionsModels from '../../models';

export const housingMonthlyPaymentQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Tel,
  name: fromQuestionsModels.QuestionNameTypes.HousingMonthlyPayment,
  label: 'Monthly housing expense',
  placeholder: 'Monthly housing expense',
  mask: {
    mask: fromQuestionsModels.MaskTypes.Money,
    thousandSeparator: ',',
  },
  prefixIcon: IconTypes.DollarSign,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter monthly housing expense',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: regex.decimalNumber,
        message: 'Invalid value',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Max,
        value: 99999,
        get message() {
          return `Maximum of ${formatCurrency(this.value, 'en', '$')}`;
        },
      },
    ],
  },
};
