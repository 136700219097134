// store
import { createReducer, on } from '@ngrx/store';
import * as fromApplicationActions from '../actions';
// models
import { Application } from '../../models';

// key
export const APPLICATION_STATE_KEY = 'application';

// state
export interface ApplicationState {
  data: Partial<Application>;
  error: Record<string, any>;
  loaded: boolean;
  loading: boolean;
}

export const initialState: ApplicationState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

// reducer
const applicationReducer = createReducer(
  initialState,
  // load
  on(fromApplicationActions.loadApplication, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(fromApplicationActions.loadApplicationSuccess, (state, action) => ({
    ...state,
    data: action.application,
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromApplicationActions.loadApplicationFail, (state, action) => ({
    ...state,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(fromApplicationActions.clearApplication, () => initialState)
);

export const reducer = (state: ApplicationState, action: fromApplicationActions.ApplicationAction) =>
  applicationReducer(state, action);

// state selectors
export const selectApplicationStateData = (state: ApplicationState) => state.data;
export const selectApplicationStateError = (state: ApplicationState) => state.error;
export const selectApplicationStateLoaded = (state: ApplicationState) => state.loaded;
export const selectApplicationStateLoading = (state: ApplicationState) => state.loading;
