// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers';
import * as fromSessionsReducer from '../reducers/funnel-sessions.reducer';

// state
export const selectFunnelSessionsState = createSelector(
  fromReducer.selectFunnelSessions,
  (state: fromReducer.FunnelSessionsState) => state.funnelSessions
);

// form session id
export const selectFormSessionsId = createSelector(selectFunnelSessionsState, fromSessionsReducer.selectFormSessionId);

// funnel session id
export const selectFunnelSessionsId = createSelector(
  selectFunnelSessionsState,
  fromSessionsReducer.selectFunnelSessionId
);

// lead session id
export const selectLeadSessionsId = createSelector(selectFunnelSessionsState, fromSessionsReducer.selectLeadSessionId);

// post submit session id
export const selectPostSubmitSessionsId = createSelector(
  selectFunnelSessionsState,
  fromSessionsReducer.selectPostSubmitSessionId
);
