// models
import { StaticMask, ValidatorTypes } from '../../models';
// exports
export * from './state-age.config';
export * from './warnings.config';

export const formValidatorMsgMap = {
  [ValidatorTypes.MobileOrHomePhoneRequired]: 'Either mobile or home phone required',
  [ValidatorTypes.SecondaryPhoneType]: 'Please select a phone type',
  [ValidatorTypes.StatementPreferenceRequired]: 'Please select a statement delivery preference',
};

export const PhoneMask: Partial<StaticMask> = {
  mask: '(200) 000-0000',
  patterns: {
    2: { pattern: /[2-9]/ },
    0: { pattern: /\d/ },
  },
};
