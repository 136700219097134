// vendor
import { Component, Input } from '@angular/core';
// ngrx
import { ApplicationDecision } from '../../../application/models';
// misc/helpers
import { createValueIdGenericFactory } from '../../helpers/helper-functions';

const idGeneralLabel = 'creditScoreNotice';
const createValueId: (valuId: any) => string = createValueIdGenericFactory(idGeneralLabel);

@Component({
  selector: 'app-credit-score-notice',
  templateUrl: './credit-score-notice.component.html',
  styleUrls: ['./credit-score-notice.component.scss'],
})
export class CreditScoreNoticeComponent {
  @Input() applicationDecision: ApplicationDecision;
  public createValueId: (valuId: any) => string = createValueId;
  public idSelectors = {
    FICO_SCORE: createValueId('ficoScore'),
    DECISION_DATE: createValueId('decisionDate'),
  };
}
