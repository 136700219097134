<div class="c__notice" [class]="'c__notice--' + designType">
  <div class="c__notice__message">
    <svg class="c__notice__icon" viewBox="0 0 24 24">
      <use [attr.href]="'#' + iconAssetMap[designType]"></use>
    </svg>
    <div>
      <div class="c__notice__header">
        <ng-content select=".title"></ng-content>
      </div>
      <div class="c__notice__body">
        <ng-content select=".content"></ng-content>
      </div>
    </div>
  </div>
</div>
