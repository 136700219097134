// vendor
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
// store
import * as fromApplicationMetaReducer from './application-meta.reducer';
import * as fromApplicationReducer from './application.reducer';
import * as fromApplicationSubmitReducer from './application-submit.reducer';
import * as fromCardBrandReducer from './card-brand.reducer';
import * as fromDocumentsReducer from './documents.reducer';
import * as fromDocumentUploadReducer from './document-upload.reducer';
import * as fromOptionsReducer from './options.reducer';
import * as fromTokenizedCidReducer from './tokenized-cid.reducer';
import * as fromDashboardLoadReducer from './dashboard-load.reducer';

// key
export const APPLICATION_FEATURE_KEY = 'application';

export interface ApplicationState {
  // TODO_UPDATE: computed property
  [id: string]:
    | any
    | fromApplicationReducer.ApplicationState
    | fromApplicationSubmitReducer.ApplicationSubmitState
    | fromCardBrandReducer.CardBrandState
    | fromDocumentsReducer.DocumentsState
    | fromDocumentUploadReducer.DocumentUploadState
    | fromOptionsReducer.OptionsState
    | fromTokenizedCidReducer.TokenizedCidState
    | fromDashboardLoadReducer.DashboardLoadState;
}

export const reducers: ActionReducerMap<ApplicationState> = {
  [fromApplicationMetaReducer.APPLICATION_META_STATE_KEY]: fromApplicationMetaReducer.reducer,
  [fromApplicationReducer.APPLICATION_STATE_KEY]: fromApplicationReducer.reducer,
  [fromApplicationSubmitReducer.APPLICATION_SUBMIT_STATE_KEY]: fromApplicationSubmitReducer.reducer,
  [fromCardBrandReducer.CARD_BRAND_STATE_KEY]: fromCardBrandReducer.reducer,
  [fromDocumentsReducer.DOCUMENTS_STATE_KEY]: fromDocumentsReducer.reducer,
  [fromDocumentUploadReducer.DOCUMENT_UPLOAD_STATE_KEY]: fromDocumentUploadReducer.reducer,
  [fromOptionsReducer.OPTIONS_STATE_KEY]: fromOptionsReducer.reducer,
  [fromTokenizedCidReducer.TOKENIZED_CID_STATE_KEY]: fromTokenizedCidReducer.reducer,
  [fromDashboardLoadReducer.DASHBOARD_LOAD_STATE_KEY]: fromDashboardLoadReducer.reducer,
};

export const selectApplicationFeature = createFeatureSelector(APPLICATION_FEATURE_KEY);
