// vstopor
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
// ngrx
import { Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromApplicationActions from '../actions';
import * as fromApplicationReducer from '../reducers';
import * as fromApplicationSelectors from '../selectors';
// services
import { ApplicationService } from '../../services';
// models
import { Application } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CardBrandEffects {
  constructor(
    private applicationService: ApplicationService,
    private action$: Actions,
    private store: Store<fromApplicationReducer.ApplicationState>
  ) {}

  // select card brand
  selectCardBrand$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.selectCardBrand),
      withLatestFrom(this.store.pipe(select(fromApplicationSelectors.selectApplicationData))),
      switchMap(([action, application]: [ReturnType<typeof fromApplicationActions.selectCardBrand>, Application]) => {
        const { id: applicationId } = application;
        const { cardBrand, employerPhone } = action;
        return this.applicationService.selectCardBrand(applicationId, cardBrand, employerPhone).pipe(
          map(() => fromApplicationActions.selectCardBrandSuccess()),
          catchError((error: Record<string, any>) => of(fromApplicationActions.selectCardBrandFail({ error })))
        );
      })
    )
  );
}
