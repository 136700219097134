// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { CardProduct } from '../../models';

export enum CardProductActionTypes {
  LoadCardProduct = '[Offers] Load Card Product',
  LoadCardProductSuccess = '[Offers] Load Card Product Success',
  LoadCardProductFail = '[Offers] Load Card Product Fail',
  ClearCardProduct = '[Offers] Clear Card Product',
}

// load card product
export const loadCardProduct = createAction(CardProductActionTypes.LoadCardProduct, props<{ cardProductId: string }>());

export const loadCardProductSuccess = createAction(
  CardProductActionTypes.LoadCardProductSuccess,
  props<{ cardProduct: CardProduct }>()
);

export const loadCardProductFail = createAction(
  CardProductActionTypes.LoadCardProductFail,
  props<{ error: Record<string, any> }>()
);

export const clearCardProduct = createAction(CardProductActionTypes.ClearCardProduct);

export type CardProductAction = ReturnType<
  typeof loadCardProduct | typeof loadCardProductSuccess | typeof loadCardProductFail | typeof clearCardProduct
>;
