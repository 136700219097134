// vendor
import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
// models
import { QuestionValidator } from '../../models';

/**
 * Override how we determine when to show the material errors.
 * This one will also display the error if we have cross field errors.
 * We can maybe use this if we use cross field validators.
 */
export class WithFormGroupErrorStateMatcher implements ErrorStateMatcher {
  focused: boolean;
  errorStatus: boolean;
  warningValidators: QuestionValidator[];
  errorValidators: QuestionValidator[];

  constructor(private validators: QuestionValidator[]) {}

  /**
   * is error state
   * @param control
   * @param form
   * @returns error state status
   */
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    this.errorValidators = this.getErrorValidators(control, form);
    this.warningValidators = this.getWarningValidators(control);
    this.errorStatus = !!this.errorValidators.length && control?.dirty && !this.focused;
    return this.errorStatus;
  }

  /**
   * get error validators
   * @param control
   * @param form
   * @returns error validators
   */
  getErrorValidators(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): QuestionValidator[] {
    const errors = { ...form?.errors, ...control?.errors };
    return this.getModifiedErrorValidators(control, this.validators, errors);
  }

  getModifiedErrorValidators(
    control: UntypedFormControl | null,
    validators: QuestionValidator[],
    errors: Record<any, any>
  ) {
    return validators
      .map((validator) => {
        const error = errors[validator.validatorName];
        if (error) {
          // override static question validator properties if provided in async response
          if (error.message) {
            /**
             * If it's an async response, but we also want to display certain fields in an error state,
             * remove the field's error message - just the error outline/styling will show. The form error will still use the async-provided
             * error message.
             */
            const message = control ? '' : error.message;
            validator = { ...validator, message };
          }
          if (error.errors) {
            validator = { ...validator, errors: error.errors };
          }
          return validator;
        }
      })
      .filter(Boolean);
  }

  /**
   * get warning validators
   * @param control
   * @param errorValidators
   * @return warning validators
   */
  getWarningValidators(control: UntypedFormControl | null): QuestionValidator[] {
    const warnings = control ? control['warnings'] : null;
    let warningValidators = [];
    if (warnings && !this.errorValidators.length) {
      warningValidators = this.validators
        .map((validator) => {
          const warning = warnings[validator.validatorName];
          if (warning) {
            return validator;
          }
        })
        .filter(Boolean);
    }
    return warningValidators;
  }
}
