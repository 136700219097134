// rxjs
import { createReducer, on } from '@ngrx/store';
import * as fromFunnelSessionsActions from '../actions';

// key
export const FUNNEL_SESSIONS_STATE_KEY = 'funnelSessions';

// state
export interface FunnelSessionsState {
  funnelSessionId: string;
  formSessionId: string;
  leadSessionId: string;
  postSubmitSessionId: string;
}

export const initialState: FunnelSessionsState = {
  funnelSessionId: null,
  formSessionId: null,
  leadSessionId: null,
  postSubmitSessionId: null,
};

// reducer
const funnelSessionsReducer = createReducer(
  initialState,
  on(
    fromFunnelSessionsActions.createFunnelSessionId,
    (state: FunnelSessionsState, action: ReturnType<typeof fromFunnelSessionsActions.createFunnelSessionId>) => ({
      ...state,
      funnelSessionId: action.sessionId,
    })
  ),
  on(
    fromFunnelSessionsActions.createFormSessionId,
    (state: FunnelSessionsState, action: ReturnType<typeof fromFunnelSessionsActions.createFormSessionId>) => ({
      ...state,
      formSessionId: action.sessionId,
    })
  ),
  on(
    fromFunnelSessionsActions.createLeadSessionId,
    (state: FunnelSessionsState, action: ReturnType<typeof fromFunnelSessionsActions.createLeadSessionId>) => ({
      ...state,
      leadSessionId: action.sessionId,
    })
  ),
  on(
    fromFunnelSessionsActions.createPostSubmitSessionId,
    (state: FunnelSessionsState, action: ReturnType<typeof fromFunnelSessionsActions.createPostSubmitSessionId>) => ({
      ...state,
      postSubmitSessionId: action.sessionId,
    })
  ),
  on(fromFunnelSessionsActions.clearFormSessionId, (state: FunnelSessionsState) => ({
    ...state,
    formSessionId: null,
  }))
);

export const reducer = (state: FunnelSessionsState, action: fromFunnelSessionsActions.FunnelSessionsAction) =>
  funnelSessionsReducer(state, action);

// state selectors
export const selectFunnelSessionId = (state: FunnelSessionsState) => state.funnelSessionId;
export const selectFormSessionId = (state: FunnelSessionsState) => state.formSessionId;
export const selectLeadSessionId = (state: FunnelSessionsState) => state.leadSessionId;
export const selectPostSubmitSessionId = (state: FunnelSessionsState) => state.postSubmitSessionId;
