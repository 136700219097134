import { Injectable } from '@angular/core';
import { EnterpriseEventService, DigitalProspectService } from '@marlettefunding/frontend-common';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import {
  concatMap,
  delay,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  skipWhile,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { Application } from 'src/app/features/application/models';
import { environment } from 'src/environments/environment';
import * as fromFunnelSessionStore from '../../../main/store';
import * as fromQuestionsStore from '../../../questions/store';
import * as fromApplicationStore from '../../../application/store';
import * as fromLeadStore from '../../../lead/store';
import * as fromAppStore from '../../../../store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import * as fromOfferStore from '../../../offers/store';
import {
  BEFHAccountCreationEventName,
  BEFHAccountEventsAdditionalData,
  BEFHAccountEventsData,
  CardEnterpriseEvent,
  CardEnterpriseEventName,
  EnterpriseEventAppPendedStatus,
  EnterpriseEventAppStatus,
  EnterpriseEventAppStatusEnum,
  EnterpriseEventVerificationDocument,
  PostSubmitStepViewStepNames,
} from '../../models/enterprise-event.model';
import * as fromQuestionsModels from '../../../questions/models';

import { APP_PENDED_REASON_TYPES, APP_STATUS_TYPES, OFFER_EVENTS, TEALIUM_LEAD_EVENTS } from './consts';
import { getAppStatus, getQuestionValuesWithoutPII, getSimplePath } from './helpers';
import { v4 } from 'uuid';
import { CookieService } from 'ngx-cookie-service';
import { Lead } from 'src/app/features/lead/models';
import { ApplicationDecision } from '../../../application/models';
import * as _ from 'lodash-es';
import { UserDataCollectionService } from '../user-data-collection.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { ApplicationStepNameTypes } from 'src/app/features/digital-experience/models';
import { STEP_TRANSITION_DURATION } from 'src/app/features/shared/helpers/animations';
import { CardProductOffer } from 'src/app/features/offers/models';
import { adverseActionFormName } from 'src/app/features/adverse-action/configs';
import { UntypedFormGroup } from '@angular/forms';
import { defaultQuestionMap, formValidatorMsgMap } from 'src/app/features/questions/configs';
import { QuestionMap } from '../../../questions/models';
import { IDashboardLoad } from 'src/app/features/dashboard/models';
import { selectDashboardLoadData } from 'src/app/features/application/store/selectors/dashboard-load.selector';

export interface EnterpriseEventParentSubjects {
  funnelSessionId: string;
  formSessionId: string;
  leadSessionId: string;
  postSubmitSessionId: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnterpriseEventTrackingService {
  private questionMap: QuestionMap = defaultQuestionMap;

  constructor(
    private readonly enterpriseEventService: EnterpriseEventService,
    private readonly digitalProspectService: DigitalProspectService,
    private store: Store,
    private cookieService: CookieService,
    private userDataCollectionService: UserDataCollectionService,
    private router: Router
  ) {}

  initEnterpriseEventTracking() {
    this.digitalProspectService.init();
    // digitalProspectService sets cookie synchronously
    const dpid = this.cookieService.get(environment.PROSPECT_ID_COOKIE_NAME);
    const funnelSessionId = v4();
    this.store.dispatch(
      fromFunnelSessionStore.createFunnelSessionId({
        sessionId: funnelSessionId,
      })
    );
    this.store.pipe(select(fromAppStore.selectRouterQueryParams), first()).subscribe((queryParams) => {
      this.trackEnterpriseEvent({
        subject: funnelSessionId,
        eventName: CardEnterpriseEventName.SESSION_START,
        parentSubject: dpid,
        data: { funnelsessionid: funnelSessionId, crossBuyOfferType: queryParams.cbot },
      });
    });
    this.setListenersToTriggerEnterpriseEvents();
    this.reportLeadCreateOrResume();
  }

  setListenersToTriggerEnterpriseEvents() {
    // Listen to router to continually extend prospect session id.
    this.isStandardDataReady()
      .pipe(
        concatMap(() => {
          // init prospect session id
          this.extendProspectSessionId();
          // router change
          return this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
        })
      )
      .subscribe((_routerEvent: Event) => {
        this.extendProspectSessionId();
      });

    // Listen to store to continually report application step.
    this.isStandardDataReady()
      .pipe(
        concatMap((_isComplete) =>
          // Report ongoing page views.
          combineLatest([
            this.store.select(fromDigitalExperienceStore.selectDigitalExperienceCurrentStep),
            this.store.select(fromFunnelSessionStore.selectLeadSessionsId),
          ]).pipe(
            filter(
              ([applicationStepName, leadSessionId]: [string, string]) => !!(applicationStepName && leadSessionId)
            ),
            concatMap((res) => of(res).pipe(delay(STEP_TRANSITION_DURATION + 20)))
          )
        )
      )
      .subscribe(([applicationStepName, _leadSessionId]: [string, string]) => {
        this.reportStepView(applicationStepName);
        this.extendProspectSessionId();
        if (applicationStepName === ApplicationStepNameTypes.TermsAndConditions) {
          this.reportOfferEvent(OFFER_EVENTS.PREOFFER_VIEWED_TERMS);
        }
      });

    // Listen to store to report changes to application status.
    this.isStandardDataReady()
      .pipe(
        concatMap(() => this.store.pipe(select(fromApplicationStore.selectApplicationData))),
        filter(Boolean),
        map((application: Application) => application),
        distinctUntilKeyChanged('applicationState'),
        withLatestFrom(this.store.pipe(select(fromApplicationStore.selectApplicationSubmitLoaded)))
      )
      .subscribe(([application, applicationSubmitLoaded]) => {
        if (getAppStatus(application) === APP_STATUS_TYPES.APPROVED && applicationSubmitLoaded) {
          this.reportOfferEvent(OFFER_EVENTS.VIEWED);
        }
      });
  }

  /**
   * @returns observable indicating that the standardData values have all been set.
   *
   * Sometimes enterprise events, like PageView, will be triggered before we actually have all the data back from the API.
   * Then values are missing, and Alan is sad.
   * This method makes sure that we have all the initial data that we can get. That will vary depending on the the
   * specifics of the user's app:
   *    Pre-Decision: need to include lead, offers and digital experience data
   *    Post-Decision: we may not have access to offers and digital experience anymore. Must include application data.
   */
  private isStandardDataReady(): Observable<boolean> {
    const application$ = this.userDataCollectionService.applicationData$.pipe(first());

    const productOffer$ = this.userDataCollectionService.productOfferData$.pipe(first());

    /** Check to make sure certain digital experience data is ready. */
    const digitalExperience$ = this.userDataCollectionService.digitalExperienceState$.pipe(first());

    const lead$ = this.userDataCollectionService.leadData$.pipe(first());

    const earlyAppStatusDataReady = combineLatest([productOffer$, digitalExperience$, lead$]).pipe(take(1));

    const laterAppStatusDataReady = application$.pipe(take(1));

    // Once one set of data is ready, proceed.
    return merge(earlyAppStatusDataReady, laterAppStatusDataReady).pipe(
      first(),
      map(() => true)
    );
  }

  /**
   *  @param
   *  @returns Observable
   *
   *  returns an observable object of funnelSessionId, formSessionId, leadSessionId
   *  the consuming subscription will decide which value to feed into the parent subject
   */
  getParentSubject(): Observable<EnterpriseEventParentSubjects> {
    return forkJoin([
      this.store.pipe(select(fromFunnelSessionStore.selectFunnelSessionsId), take(1)),
      this.store.pipe(select(fromFunnelSessionStore.selectFormSessionsId), take(1)),
      this.store.pipe(select(fromFunnelSessionStore.selectLeadSessionsId), take(1)),
      this.store.pipe(select(fromFunnelSessionStore.selectPostSubmitSessionsId), take(1)),
    ]).pipe(
      map(([funnelSessionId, formSessionId, leadSessionId, postSubmitSessionId]) => ({
        funnelSessionId,
        formSessionId,
        leadSessionId,
        postSubmitSessionId,
      }))
    );
  }

  private getEnterpriseEventAppStatus(application: Partial<Application>): EnterpriseEventAppStatus {
    let mappedStatus = EnterpriseEventAppStatusEnum[application.applicationDecision.decisionType];
    if (mappedStatus === EnterpriseEventAppStatusEnum.PENDED) {
      mappedStatus = this.getEnterpriseEventPendedReason(application.applicationDecision);
    }
    return mappedStatus;
  }

  private getEnterpriseEventPendedReason(applicationDecision: ApplicationDecision): EnterpriseEventAppPendedStatus {
    let mappedStatus: EnterpriseEventAppPendedStatus;
    switch (applicationDecision.reason) {
      case APP_PENDED_REASON_TYPES.PENDED_FRAUD: {
        mappedStatus = EnterpriseEventAppStatusEnum.PENDED_FRAUD;
        break;
      }
      case APP_PENDED_REASON_TYPES.PENDED_KYC: {
        mappedStatus = EnterpriseEventAppStatusEnum.PENDED_KYC;
        break;
      }
      case APP_PENDED_REASON_TYPES.PENDED_UNAVAILABLE: {
        mappedStatus = EnterpriseEventAppStatusEnum.PENDED_UNAVAILABLE;
        break;
      }
      case APP_PENDED_REASON_TYPES.PENDED_UNKNOWN: {
        mappedStatus = EnterpriseEventAppStatusEnum.PENDED_UNKNOWN;
        break;
      }
    }
    return mappedStatus;
  }

  /**
   *  @param applicationId string
   *  @returns
   *
   *  Report POST_SUBMIT_SESSION_START, APP_SUBMIT and APP_DECISION
   *  after user go through all funnel steps and submit the application
   */
  reportEventsAfterSubmit(applicationId) {
    const postSubmitSessionId = v4();
    this.store.dispatch(fromFunnelSessionStore.createPostSubmitSessionId({ sessionId: postSubmitSessionId }));

    combineLatest([
      this.store.select(fromLeadStore.selectLeadData),
      this.store.select(fromQuestionsStore.selectQuestionValuesData),
    ])
      .pipe(
        filter(([lead, questionValue]: [Lead, Record<string, any>]) => lead && !!questionValue),
        first()
      )
      .subscribe(([lead, questionValue]: [Lead, Record<string, any>]) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.POST_SUBMIT_SESSION_START,
          subject: postSubmitSessionId,
          parentSubject: this.cookieService.get(environment.PROSPECT_ID_COOKIE_NAME),
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplicationid: applicationId,
            cardleadid: lead.id,
          },
        });

        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.APP_SUBMIT,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplicationformfieldvalues: getQuestionValuesWithoutPII(questionValue),
            cardapplicationstatus: 'submitted',
          },
          metadata: {
            tealiumeventname: 'AppStatusChanged',
            tealiumsubeventname: 'submitted',
          },
        });
      });

    this.store
      .select(fromApplicationStore.selectApplicationData)
      .pipe(
        filter(Boolean),
        map((application: Application) => application),
        take(1)
      )
      .subscribe((application: Application) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.APP_DECISION,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplicationstatus: this.getEnterpriseEventAppStatus(application),
          },
          metadata: {
            tealiumeventname: 'AppStatusChanged',
            tealiumsubeventname: APP_STATUS_TYPES[application.applicationDecision.decisionType],
          },
        });
      });
  }

  /**
   *  Report POST_SUBMIT_SESSION_START event when user access dashboard/ adver-action directly.
   *  If postSubmitSessionId already in store, a user is redirected from decision component
   *  due to pending/ decline reasons.
   *  In this case, don't report POST_SUBMIT_SESSION_START
   */
  reportPostSubmitSessionStartOnly() {
    combineLatest([
      this.store.select(fromApplicationStore.selectApplicationData),
      this.store.select(fromFunnelSessionStore.selectPostSubmitSessionsId),
    ])
      .pipe(
        filter(([application, postSubmitSessionId]: [Application, string]) => !!application && !postSubmitSessionId),
        take(1)
      )
      .subscribe(([application, _]) => {
        const postSubmitSessionId = v4();
        this.store.dispatch(fromFunnelSessionStore.createPostSubmitSessionId({ sessionId: postSubmitSessionId }));
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.POST_SUBMIT_SESSION_START,
          subject: postSubmitSessionId,
          parentSubject: this.cookieService.get(environment.PROSPECT_ID_COOKIE_NAME),
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplicationid: application.id,
            cardleadid: application.leadId,
          },
        });
      });
  }

  reportPendingDecisionViewed(applicationDecision: ApplicationDecision) {
    this.store
      .select(fromFunnelSessionStore.selectPostSubmitSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((postSubmitSessionId: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.PENDING_DECISION_VIEWED,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplypendreason: this.getEnterpriseEventPendedReason(applicationDecision),
          },
        });
      });
  }

  reportAdverseActionViewed() {
    this.store
      .select(fromFunnelSessionStore.selectPostSubmitSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((postSubmitSessionId: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.ADVERSE_ACTION_VIEWED,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
          },
        });
      });
  }

  reportVerificationDocumentUploaded(fileType: string) {
    this.store
      .select(fromFunnelSessionStore.selectPostSubmitSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((postSubmitSessionId: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.VERIFICATION_DOCUMENT_UPLOADED,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplyverificationdocumenttype: fileType,
          },
        });
      });
  }

  reportVerificationDocumentsViewed(documentList: EnterpriseEventVerificationDocument[]) {
    this.store
      .select(fromFunnelSessionStore.selectPostSubmitSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((postSubmitSessionId: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.VERIFICATION_DOCUMENTS_VIEWED,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplyverificationdocuments: documentList,
          },
        });
      });
  }

  reportAdaChatbotInitiated() {
    this.store
      .select(fromFunnelSessionStore.selectFunnelSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((funnelsessionid: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.CHAT_INITIATED,
          parentSubject: funnelsessionid,
          data: {
            funnelsessionid,
          },
          metadata: {
            tealiumeventname: 'ChatInitiated',
          },
        });
      });
  }

  reportKCFAuthOptOut(tealiumLinkText: string) {
    this.store
      .select(fromFunnelSessionStore.selectFunnelSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((funnelsessionid: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.KCF_AUTH_OPT_OUT,
          parentSubject: funnelsessionid,
          data: {
            funnelsessionid,
          },
          metadata: {
            tealiumeventname: 'LinkClick',
            tealiumLinkType: 'kcfAuthOptOut',
            tealiumLinkText,
          },
        });
      });
  }

  reportKCFAuthFail() {
    this.store
      .select(fromFunnelSessionStore.selectFunnelSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((funnelsessionid: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.KCF_AUTH_FAIL,
          parentSubject: funnelsessionid,
          data: {
            funnelsessionid,
          },
          metadata: {
            tealiumeventname: 'FormAuthentication',
            tealiumsubeventname: 'Failed',
          },
        });
      });
  }

  reportKCFAuthSuccess(panelStatus) {
    this.store
      .select(fromFunnelSessionStore.selectFunnelSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((funnelsessionid: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.KCF_AUTH_PASS,
          parentSubject: funnelsessionid,
          data: {
            funnelsessionid,
            ...panelStatus,
          },
          metadata: {
            tealiumeventname: 'FormAuthentication',
            tealiumsubeventname: 'Passed',
          },
        });
      });
  }

  reportPostSubmitStepViewEvents(stepName: PostSubmitStepViewStepNames, status?: EnterpriseEventAppPendedStatus) {
    combineLatest([
      this.store.select(fromFunnelSessionStore.selectPostSubmitSessionsId),
      this.store.select(fromApplicationStore.selectApplicationData),
      this.store.select(fromAppStore.selectRouterState),
    ])
      .pipe(
        filter(([_, application, _routerState]) => Boolean(application.id)),
        first()
      )
      .subscribe(([postSubmitSessionId, application, routerState]) => {
        const url = routerState.state.url;
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.POST_SUBMIT_STEP_VIEW,
          parentSubject: postSubmitSessionId,
          data: {
            cardapplicationstepname: stepName,
            url,
            cardapplicationstatus: status || this.getEnterpriseEventAppStatus(application),
            cardapplypostsubmitsessionid: postSubmitSessionId,
          },
        });
      });
  }

  reportBEFHAccountCreationEvents(
    eventName: BEFHAccountCreationEventName,
    additionalData?: BEFHAccountEventsAdditionalData
  ) {
    combineLatest([
      this.store.select(fromFunnelSessionStore.selectPostSubmitSessionsId),
      this.store.select(fromApplicationStore.selectApplicationData),
      this.store.select(selectDashboardLoadData),
    ])
      .pipe(
        filter(
          ([_submitSessionId, application, _dashboardLoadStateData]: [string, Application, IDashboardLoad]) =>
            !!application.id
        ),
        first()
      )
      .subscribe(
        ([postSubmitSessionId, application, dashboardLoadStateData]: [string, Application, IDashboardLoad]) => {
          const status = this.getEnterpriseEventAppStatus(application);
          if (status === EnterpriseEventAppStatusEnum.PENDED_KYC) {
            additionalData = {
              ...additionalData,
              cardapplicationstepname: dashboardLoadStateData.isPendDashboard ? 'dashboard' : 'befhRegister',
            };
          }

          let data: BEFHAccountEventsData = {
            cardapplypostsubmitsessionid: postSubmitSessionId,
            cardapplicationstatus: this.getEnterpriseEventAppStatus(application),
            cid: application.acquisition?.customerId,
          };

          if (!_.isNil(additionalData)) {
            data = { ...data, ...additionalData };
          }
          this.trackEnterpriseEvent({
            eventName,
            parentSubject: postSubmitSessionId,
            data,
          });
        }
      );
  }

  trackEnterpriseEvent(event: CardEnterpriseEvent) {
    let eventPayload: any = {
      type: `com.bestegg.card.apply.${event.eventName}`,
      source: 'card.bestegg.com',
      parentsubject: event.parentSubject,
      ...event.data,
    };
    if (event.subject) {
      eventPayload = { ...eventPayload, subject: event.subject };
    }
    if (event.metadata) {
      eventPayload = { ...eventPayload, metadata: event.metadata };
    }
    this.enterpriseEventService.dispatchEnterpriseEvent(eventPayload);
  }

  extendProspectSessionId() {
    this.digitalProspectService.extendSession();
  }

  reportTooltipViewedEvent(tooltipName: string, stepName: string, cardStatus: string) {
    this.store
      .select(fromFunnelSessionStore.selectFunnelSessionsId)
      .pipe(filter(Boolean), take(1))
      .subscribe((funnelsessionid: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.TOOLTIP_VIEWED,
          parentSubject: funnelsessionid,
          data: {
            cardApplicationTooltipName: tooltipName,
            cardapplicationstepname: stepName,
            cardapplicationstatus: cardStatus,
            funnelsessionid,
          },
        });
      });
  }

  public reportOfferEvent(eventType: string) {
    const cardProductOffer$ = this.store.pipe(
      select(fromOfferStore.selectCardProductOfferData),
      filter(Boolean),
      take(1)
    );

    let application$ = of({});

    if (eventType === OFFER_EVENTS.ACCEPTED) {
      // ACCEPTED event only fired in approve decision view
      application$ = this.store.pipe(select(fromApplicationStore.selectApplicationData), filter(Boolean), take(1));
    }

    forkJoin([cardProductOffer$, this.getParentSubject(), application$]).subscribe(
      ([cardProductOffer, subjects, application]: [CardProductOffer, EnterpriseEventParentSubjects, Application]) => {
        switch (eventType) {
          case OFFER_EVENTS.PREOFFER_VIEWED_TERMS: {
            // PREOFFER_VIEWED_TERMS event type is called when user land on terms & condition page
            this.trackEnterpriseEvent({
              eventName: CardEnterpriseEventName.TERMS_VIEWED,
              parentSubject: subjects.leadSessionId,
              data: {
                cardleadsessionid: subjects.leadSessionId,
                cardofferapr: cardProductOffer.purchaseAprs[0].rate.toString(), // use the first apr in the purchaseAprs array
              },
              metadata: {
                tealiumeventname: 'OfferEvent',
                tealiumsubeventname: 'PreofferViewedTerms',
              },
            });
            break;
          }
          case OFFER_EVENTS.ACCEPTED: {
            this.trackEnterpriseEvent({
              eventName: CardEnterpriseEventName.OFFER_ACCEPTED,
              parentSubject: subjects.postSubmitSessionId,
              data: {
                cardofferapr: cardProductOffer.purchaseAprs[0].rate.toString(),
                cardoffercreditlimit: application.applicationDecision.creditLine.toString(),
                cardapplypostsubmitsessionid: subjects.postSubmitSessionId,
              },
              metadata: {
                tealiumeventname: 'OfferEvent',
                tealiumsubeventname: 'OfferAccepted',
              },
            });
            break;
          }
          case OFFER_EVENTS.VIEWED: {
            this.trackEnterpriseEvent({
              eventName: CardEnterpriseEventName.OFFER_VIEWED,
              parentSubject: subjects.postSubmitSessionId,
              data: {
                cardofferapr: cardProductOffer.purchaseAprs[0].rate.toString(),
                cardapplypostsubmitsessionid: subjects.postSubmitSessionId,
              },
              metadata: {
                tealiumeventname: 'OfferEvent',
                tealiumsubeventname: 'OfferViewed',
              },
            });
            break;
          }
        }
      }
    );
  }

  /**
   * Upon loading the app, report if it's a new lead or a resumed one.
   */
  reportLeadCreateOrResume(): void {
    // grab the lead data once it's loaded
    const leadData$ = this.store.pipe(
      select(fromLeadStore.selectLeadState),
      skipWhile((state: fromLeadStore.LeadState) => !state.loaded),
      first(),
      switchMap(() => this.store.pipe(select(fromLeadStore.selectLeadData)))
    );
    // skip firing lead events while dashboard loads lead just for abTest data
    if (getSimplePath(this.router.url) !== 'dashboard') {
      // task 1: Using the lead, standardData, and also the dig experience data, handle the EE firing for lead create and resume.
      combineLatest([
        this.getParentSubject(),
        this.store.pipe(select(fromDigitalExperienceStore.selectDigitalExperienceName)),
        this.store.pipe(select(fromOfferStore.selectCardProductOfferData)),
        leadData$,
      ])
        .pipe(
          filter(([_, digitalExperienceName, productOffer]) => !!digitalExperienceName && !!productOffer),
          take(1)
        )
        .subscribe(([subjects, experienceName, productOffer, leadData]) => {
          const leadSessionId = v4();
          this.store.dispatch(fromFunnelSessionStore.createLeadSessionId({ sessionId: leadSessionId }));
          this.trackEnterpriseEvent({
            parentSubject: subjects.funnelSessionId,
            subject: leadSessionId,
            eventName: leadData.isResume ? CardEnterpriseEventName.LEAD_RESUME : CardEnterpriseEventName.LEAD_CREATE,
            data: {
              cpc: productOffer.campaignParticipantCode, // from productOffer as the cpc on the lead is not always accurate CARD-363
              cardproductofferid: leadData.productOfferId,
              cardoffercode: leadData.offerCode ? leadData.offerCode : undefined, // convert falsy value to undefined so payload can omit
              carddigitalexperiencename: experienceName,
              cardleadid: leadData.id,
              funnelsessionid: subjects.funnelSessionId,
              cardleadsessionid: leadSessionId,
            },
            metadata: {
              tealiumeventname: 'LeadEvent',
              tealiumsubeventname: leadData.isResume ? TEALIUM_LEAD_EVENTS.RESUMED : TEALIUM_LEAD_EVENTS.CREATED,
            },
          });
        });
    }
  }

  /**
   * Reports each time the application step changes.
   * This reports steps like they are separate page views, though the route is unchanged.
   */
  public reportStepView(stepName: string): void {
    this.getParentSubject().subscribe((subjects) => {
      this.trackEnterpriseEvent({
        eventName: CardEnterpriseEventName.STEP_VIEW,
        parentSubject: subjects.leadSessionId,
        data: {
          funnelsessionid: subjects.funnelSessionId,
          cardapplicationstepname: stepName,
          // convert falsy value to undefined so payload can omit
          cardleadsessionid: subjects.leadSessionId ? subjects.leadSessionId : undefined,
        },
        metadata: {
          tealiumeventname: 'StepView',
        },
      });
    });
    /**
     * Make step name available to Widerfunnel for Optimizley tests.
     * Maybe not the best place for this, but it is to keep Optimizely analytics in sync
     * with EEv2 so not the worst place.
     */
    window['WF'] = window['WF'] || {};
    window['WF']['lastReportedStepName'] = stepName;
  }

  reportLeadSubmitted() {
    this.getParentSubject().subscribe((subjects: EnterpriseEventParentSubjects) => {
      this.trackEnterpriseEvent({
        eventName: CardEnterpriseEventName.LEAD_SUBMIT,
        parentSubject: subjects.leadSessionId,
        data: {
          cardleadsessionid: subjects.leadSessionId,
        },
        metadata: {
          tealiumeventname: 'LeadEvent',
          tealiumsubeventname: TEALIUM_LEAD_EVENTS.SUBMITTED,
        },
      });
    });
  }

  /**
   * Reports when user interacts with and filled in the values in of each of the form fields.
   */
  public reportFormFieldInteraction(formField: string): void {
    this.getParentSubject().subscribe((subjects) => {
      this.trackEnterpriseEvent({
        parentSubject: subjects.formSessionId,
        eventName: CardEnterpriseEventName.FORM_FIELD_INTERACTION,
        data: {
          cardapplicationformfieldname: formField,
          cardapplicationformsessionid: subjects.formSessionId,
        },
        metadata: {
          tealiumeventname: 'FormFieldInteraction',
        },
      });
    });
  }

  /**
   * Report first change to a form field.
   * Currently the formName is the same as the stepName.
   */
  public reportFormStart(formName: string) {
    this.getParentSubject().subscribe((subjects) => {
      if (!subjects.formSessionId) {
        const formSessionId = v4();
        this.store.dispatch(fromFunnelSessionStore.createFormSessionId({ sessionId: formSessionId }));
        const isAdverseActionForm = formName === adverseActionFormName;
        const parentSubject = isAdverseActionForm ? subjects.postSubmitSessionId : subjects.leadSessionId;
        this.trackEnterpriseEvent({
          parentSubject,
          subject: formSessionId,
          eventName: CardEnterpriseEventName.FORM_FILL_START,
          data: {
            cardapplicationformtype: 'Application',
            cardapplicationformname: formName,
            cardapplicationformsessionid: formSessionId,
            // convert falsy value to undefined so payload can omit
            cardapplypostsubmitsessionid: isAdverseActionForm ? subjects.postSubmitSessionId : undefined,
            cardleadsessionid: !isAdverseActionForm ? subjects.leadSessionId : undefined,
            funnelsessionid: subjects.funnelSessionId,
          },
          metadata: {
            tealiumeventname: 'FormFillStart',
          },
        });
      }
    });
  }

  /**
   * Report successful form submission events
   */
  public reportFormSubmission(formName: string, formValues: Record<string, any>): void {
    this.store.pipe(select(fromFunnelSessionStore.selectFormSessionsId), take(1)).subscribe((formSessionid) => {
      if (!formSessionid) {
        // for steps that don't contain any form fields(T&Cs page)
        // create new formSessionId by calling reportFormStart
        this.reportFormStart(formName);
      }
    });
    this.store
      .select(fromFunnelSessionStore.selectFormSessionsId)
      .pipe(filter(Boolean), first())
      .subscribe((formSessionId: string) => {
        this.trackEnterpriseEvent({
          eventName: CardEnterpriseEventName.FORM_SUBMIT,
          parentSubject: formSessionId,
          data: {
            cardapplicationformsessionid: formSessionId,
            cardapplicationformfieldvalues: getQuestionValuesWithoutPII(formValues),
          },
          metadata: {
            tealiumeventname: 'FormSubmit',
          },
        });
      });
    this.store.dispatch(fromFunnelSessionStore.clearFormSessionId());
  }

  /**
   * Reports any errors preventing form submission.
   */
  public reportFormSubmissionError(formName: string, formField: string, message: string): void {
    this.store.pipe(select(fromFunnelSessionStore.selectFormSessionsId), take(1)).subscribe((formSessionid) => {
      if (!formSessionid) {
        // make sure formSessionId exist, if not create new one
        this.reportFormStart(formName);
      }
    });

    this.getParentSubject().subscribe((subjects) => {
      this.trackEnterpriseEvent({
        eventName: CardEnterpriseEventName.FORM_FIELD_ERROR,
        parentSubject: subjects.formSessionId,
        data: {
          cardapplicationformfieldname: formField,
          cardapplicationformsessionid: subjects.formSessionId,
          cardapplicationformfielderrormessage: message,
        },
        metadata: {
          tealiumeventname: 'FormFieldError',
        },
      });
    });
  }

  /**
   * Called when a user enters the funnel to apply, but cannot proceed.
   * @param message from the Maintenance or Unavailable component.
   */
  reportApplyIntent() {
    this.getParentSubject().subscribe((subjects) => {
      this.trackEnterpriseEvent({
        parentSubject: subjects.funnelSessionId,
        data: {
          funnelsessionid: subjects.funnelSessionId,
        },
        metadata: {
          tealiumeventname: 'ApplyIntentEvent',
        },
        eventName: CardEnterpriseEventName.APPLY_INTENT,
      });
    });
  }

  /**
   * Receives form data, extracts field names and error messages for reporting to enterprise event.
   */
  public processPageErrors(formName: string, form: UntypedFormGroup): void {
    // Report FormControl errors.
    for (const [key, control] of Object.entries(form.controls)) {
      if (control.errors) {
        Object.keys(control.errors).forEach((errorKey: fromQuestionsModels.ValidatorTypes) => {
          const erroredValidator = this.questionMap[key].validators.sync.filter(
            (item) => errorKey === item.validatorName
          )[0];
          // Some errors will not have a corresponding validator.
          // Ex: ngx-mask appends a 'mask' error, but the formControl may not have ValidatorTypes.Mask.
          if (erroredValidator) {
            this.reportFormSubmissionError(formName, key, erroredValidator.message);
          }
        });
      }
    }

    if (form.errors) {
      this.reportFormWideErrorsPerControl(formName, form);
    }
  }

  /**
   * Cross-field errors will appear on formControls but not in the control's error object.
   * This maps the name of the cross-field validator to the relevant form fields on the current page
   * to send the error to enterprise event per field.
   */
  private reportFormWideErrorsPerControl(formName: string, form: UntypedFormGroup) {
    Object.keys(form.errors).forEach((formWideValidator: string) => {
      // Determine which form controls are impacted by this form-wide error:
      for (const [key, control] of Object.entries(form.controls)) {
        const questionInfo = this.questionMap[key];
        ['sync', 'async'].forEach((validatorType) => {
          const validators = questionInfo.validators ? questionInfo.validators[validatorType] : null;
          if (!validators) {
            return;
          }
          questionInfo.validators[validatorType].forEach((validator) => {
            if (validator.validatorName !== formWideValidator) {
              return;
            }
            let message: string;
            if (form.errors[formWideValidator].message) {
              message = form.errors[formWideValidator].message;
            } else {
              message = formValidatorMsgMap[formWideValidator];
            }
            this.reportFormSubmissionError(formName, key, message);
          });
        });
      }
    });
  }

  reportESignConsentViewed() {
    this.getParentSubject().subscribe((subjects) => {
      this.trackEnterpriseEvent({
        parentSubject: subjects.funnelSessionId,
        data: {
          funnelsessionid: subjects.funnelSessionId,
        },
        eventName: CardEnterpriseEventName.E_SIGN_CONSENT_VIEWED,
      });
    });
  }
}
