import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { take } from 'rxjs/operators';
import { UserDataCollectionService } from './user-data-collection.service';

@Injectable({
  providedIn: 'root',
})
export class DataDogService {
  constructor(private readonly userDataCollectionService: UserDataCollectionService) {}

  collectUserSessionData() {
    this.userDataCollectionService.emailAddress$.pipe(take(1)).subscribe((emailAddress) => {
      datadogRum.setUserProperty('email', emailAddress);
    });

    this.userDataCollectionService.applicationData$.pipe(take(1)).subscribe((application) => {
      datadogRum.setUserProperty('cardApplicationId', application.id);
    });

    this.userDataCollectionService.leadData$.pipe(take(1)).subscribe((lead) => {
      datadogRum.setUserProperty('cardLeadId', lead.id);
    });

    this.userDataCollectionService.productOfferData$.pipe(take(1)).subscribe((productOffer) => {
      datadogRum.setUserProperty('cpid', productOffer.campaignParticipantCode);
    });
  }

  startSessionReplayRecording() {
    datadogRum.startSessionReplayRecording();
  }
}
