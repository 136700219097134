// models
import { Map, DialogTypes } from '../../models';

// imports
import { CommunicationsConsentDialogComponent } from './communications-consent-dialog/communications-consent-dialog.component';
import { CreditScoreNoticeDialogComponent } from './credit-score-notice-dialog/credit-score-notice-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { IdleDialogComponent } from './idle-dialog/idle-dialog.component';
import { DialogCloseComponent } from './dialog-close/dialog-close.component';
// exports
export * from './communications-consent-dialog/communications-consent-dialog.component';
export * from './credit-score-notice-dialog/credit-score-notice-dialog.component';
export * from './error-dialog/error-dialog.component';
export * from './idle-dialog/idle-dialog.component';
export * from './stylable-dialog/stylable-dialog.component';
export * from './dialog-close/dialog-close.component';

// exports group
export const components: any[] = [
  CommunicationsConsentDialogComponent,
  CreditScoreNoticeDialogComponent,
  ErrorDialogComponent,
  IdleDialogComponent,
  DialogCloseComponent,
];

export const dialogComponentMap: Map<any> = {
  [DialogTypes.Communications]: CommunicationsConsentDialogComponent,
  [DialogTypes.CreditScoreNotice]: CreditScoreNoticeDialogComponent,
  [DialogTypes.Error]: ErrorDialogComponent,
  [DialogTypes.Idle]: IdleDialogComponent,
};
