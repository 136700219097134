import { createSelector } from '@ngrx/store';
import * as fromDashboardLoadStateReducer from '../reducers/dashboard-load.reducer';
import * as fromReducers from '../reducers';

// state selector
export const selectDashboardLoadState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.dashboardLoad
);

// data
export const selectDashboardLoadData = createSelector(
  selectDashboardLoadState,
  (state: fromDashboardLoadStateReducer.DashboardLoadState) => state.data
);
