// vendor
import {
  AfterViewInit,
  Directive,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  HostListener,
  ElementRef,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, Subject, timer, of, forkJoin, ReplaySubject, combineLatest } from 'rxjs';
import {
  filter,
  first,
  take,
  takeUntil,
  tap,
  switchMap,
  startWith,
  map,
  skipWhile,
  withLatestFrom,
} from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { LiveAnnouncer } from '@angular/cdk/a11y';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromAppStore from '../../../../store';
import * as fromApplicationStore from '../../store';
import * as fromApplicationActions from '../../store/actions';
import * as fromQuestionsStore from '../../../questions/store';
import * as fromLeadStore from '../../../lead/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import * as fromFunnelSessionsStore from '../../../main/store';
import * as fromAbTestStore from '../../../ab-tests/store';
// services
import { QuestionsService, ValidatorsService, WithFormGroupErrorStateMatcher } from '../../../questions/services';
import { validatorHelpers } from '../../../questions/services/helpers/validators-helpers';
import { FullstoryService } from '../../../analytics/services';
import { STEP_TRANSITION_DURATION } from '../../../shared/helpers/animations';
// components
import { dialogComponentMap } from '../../../shared/components/dialogs';
// models
import * as fromQuestionsModels from '../../../questions/models';
import * as fromDigitalExperienceModels from '../../../digital-experience/models';
import { FullstoryEventTypes } from '../../../analytics/models';
import { Map, DialogTypes } from '../../../shared/models';
import { IAbTestData } from 'src/app/features/ab-tests/models';

// configs
import { MAIN_URL_SEGMENT } from '../../../main/configs';
import * as selectOptions from '../../../../../../fakesl/src/api/data/options.json';
// utils
import {
  DependencyConfig,
  Question,
  QuestionNameTypes,
  QuestionValidator,
  ValidatorTypes,
} from '../../../questions/models';
import { AbTestsStatusUpdatesService } from '../../../ab-tests/services/ab-tests-status-updates.service';
import { defaultQuestionMap } from '../../../questions/configs';
import { DigitalExperienceState } from '../../../digital-experience/store';
import { ApplicationStepNameTypes } from '../../../digital-experience/models';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
import { ApplicationStepService } from '../../services';
import { WindowUtilService } from 'src/app/features/shared/services/window-util.service';
// consts
const { Back } = fromQuestionsModels.StepActionTypes;
const { Forward, Backward } = fromDigitalExperienceModels.ApplicationStepDirectionTypes;
const ELEMENT_TAGS_TO_OVERRIDE_ENTER_KEY_SUBMISSION = ['BUTTON', 'A'];
const { states } = selectOptions;

@Directive()
export abstract class BaseApplicationStepComponent implements OnInit, AfterViewInit, OnDestroy {
  // inputs
  @Input() applicationStepData: fromDigitalExperienceModels.ApplicationStepData;

  form: UntypedFormGroup;
  questionMap: Map<fromQuestionsModels.Question> = defaultQuestionMap;
  questionNameTypes: Map<string>;
  hidden: boolean;
  isSubmitting: boolean;
  stepChangeStatus: boolean;
  stepDirection$: Observable<string>;
  ngUnsubscribe$: Subject<boolean>;
  // the form field interaction will trigger before form fill start (probably due to
  // form control level events will fire faster than form group level events).
  // To prevent this behaviour, map this subject in the form field interaction pipe
  // and call next() after reportFormStart is called
  formFillStarted = new ReplaySubject<boolean>(1);

  // services
  fb: UntypedFormBuilder;
  dialog: MatDialog;
  questionsService: QuestionsService;
  validatorsService: ValidatorsService;
  fullstoryService: FullstoryService;
  EEService: EnterpriseEventTrackingService;
  abTestStatusUpdatesService: AbTestsStatusUpdatesService;
  liveAnnouncer: LiveAnnouncer;
  windowUtilService: WindowUtilService;
  eleRef: ElementRef;
  // stores
  store: Store<any>;
  // actions
  actions$: Actions;

  stepFormConfig: fromQuestionsModels.SimpleFormConfig;
  enterKeyDown$: Subject<Event> = new Subject();
  stepService: ApplicationStepService;
  // This is form level validator
  errorValidator: fromQuestionsModels.QuestionValidator;
  errorMatcher: WithFormGroupErrorStateMatcher;

  constructor(
    @Inject(Injector)
    public injector: Injector
  ) {
    this.questionNameTypes = fromQuestionsModels.QuestionNameTypes;
    this.hidden = false;
    this.stepChangeStatus = false;
    this.ngUnsubscribe$ = new Subject();
    this.setInjectors();
  }

  @HostListener('keydown.enter', ['$event']) enterSubmit(event: KeyboardEvent): void {
    // create Subject so we can subscribe and debounce to prevent multiple submissions
    this.enterKeyDown$.next(event);
  }

  /**
   * set injectors
   */
  setInjectors(): void {
    this.fb = this.injector.get(UntypedFormBuilder);
    this.questionsService = this.injector.get(QuestionsService);
    this.validatorsService = this.injector.get(ValidatorsService);
    this.fullstoryService = this.injector.get(FullstoryService);
    this.abTestStatusUpdatesService = this.injector.get(AbTestsStatusUpdatesService);
    this.dialog = this.injector.get(MatDialog);
    this.liveAnnouncer = this.injector.get(LiveAnnouncer);
    this.store = this.injector.get(Store);
    this.actions$ = this.injector.get(Actions);
    this.windowUtilService = this.injector.get(WindowUtilService);
    this.eleRef = this.injector.get(ElementRef);
    this.EEService = this.injector.get(EnterpriseEventTrackingService);
    this.stepService = this.injector.get(ApplicationStepService);
  }

  ngAfterViewInit() {
    this.windowUtilService.resetScroll();
    const combinedValidators = validatorHelpers.combine(this.applicationStepData.applicationStep.formConfig.validators);
    this.errorMatcher = new WithFormGroupErrorStateMatcher(combinedValidators);
    this.form.statusChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      this.errorMatcher.isErrorState(null, this.form as any);
      this.errorValidator = this.errorMatcher.errorValidators[0];
    });
  }

  ngOnInit() {
    this.form = this.createForm(this.applicationStepData.applicationStep);
    this.stepDirection$ = this.store.pipe(
      select(fromDigitalExperienceStore.selectDigitalExperienceStepDirection),
      takeUntil(this.ngUnsubscribe$)
    );

    this.updateInitialFormValues();

    this.initDynamicQuestions();
    this.track();

    this.initiateEnterKeyDownListener();

    this.handleInitialAbTestStatusUpdates();
  }

  /** patch form values on init, for steps like kcf confirm or personal information */
  public updateInitialFormValues() {
    this.store
      .pipe(
        select(fromQuestionsStore.selectQuestionValuesData),
        first(),
        filter(Boolean),
        // Only skip auto population of form data when on verifyIdentity step
        skipWhile((_) => this.applicationStepData.applicationStep.name === ApplicationStepNameTypes.VerifyIdentity)
      )
      .subscribe((questionValues: Map<any>) => {
        this.form.patchValue(questionValues);
        const { controls } = this.form;
        Object.keys(controls).forEach((controlKey) => {
          const { [controlKey]: control } = controls;
          if (['state', 'physicalState'].includes(controlKey) && control) {
            let stateLabel = '';
            if (control.value?.length === 2) {
              stateLabel = states.find((state) => state.value === control.value.toUpperCase())?.label || '';
            } else if (control.value) {
              stateLabel =
                states.find((state) => state.label.toUpperCase() === control.value.toUpperCase())?.label || '';
            }
            control.reset(stateLabel);
          }
          if (control.value) {
            control.markAsDirty();
          }
        });
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.formFillStarted.complete();
  }

  /** public just for unit tests */
  public initiateEnterKeyDownListener() {
    this.enterKeyDown$
      .pipe(
        filter((event: KeyboardEvent) => {
          const target = event.target as HTMLElement;
          const tagName = target.tagName;
          /** We still want certain elements to trigger via ENTER key when focused, instead of executing submit behavior. */
          return !ELEMENT_TAGS_TO_OVERRIDE_ENTER_KEY_SUBMISSION.includes(tagName.toUpperCase()) && !this.isSubmitting;
        }),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((event: Event) => {
        event.preventDefault();
        this.onNext(this.form.value);
      });
  }

  // ======================================================
  // PRE STEP
  // ======================================================

  /**
   * @description create form
   * @param applicationStep application step
   * @returns form group
   */
  public createForm(applicationStep: fromDigitalExperienceModels.ApplicationStep): UntypedFormGroup {
    this.stepFormConfig = applicationStep.formConfig;
    const controlsConfig = this.createControlsConfig(this.stepFormConfig.questionNames);
    const form = this.fb.group(controlsConfig);
    // account for form level validators
    if (this.stepFormConfig.validators) {
      const [syncFormValidators, asyncFormValidators] = this.validatorsService.getValidators(
        this.stepFormConfig.validators,
        form
      );
      form.setValidators(syncFormValidators);
      form.setAsyncValidators(asyncFormValidators);
    }
    return form;
  }

  public initDynamicQuestions(): void {
    const { questionDependencies } = this.stepFormConfig;
    if (questionDependencies) {
      Object.entries(questionDependencies).forEach(([questionName, dependencies]: [QuestionNameTypes, []]) => {
        const { validators: questionValidators } = this.questionMap[questionName];
        dependencies.forEach((config: DependencyConfig) => {
          this.initDynamicQuestion(questionName, config, questionValidators);
        });
      });
    }
  }
  /**
   * @description create controls config
   * @param questionNames question names
   * @returns controls config
   */
  private createControlsConfig(questionNames: string[]): Map<any> {
    return questionNames.reduce((controlsConfig, questionName) => {
      const question = this.questionMap[questionName];
      const [syncControlValidators, asyncControlValidators] = this.validatorsService.getValidators(question.validators);
      const control = {
        [question.name]: this.fb.control(question.defaultValue ?? null, syncControlValidators, asyncControlValidators),
      };
      return { ...controlsConfig, ...control };
    }, {});
  }

  /**
   * track
   * - fullstory
   * - eev2
   */
  track() {
    const { name: stepName } = this.applicationStepData.applicationStep;
    this.fullstoryService.event(FullstoryEventTypes.ApplicationStepView, {
      name_str: stepName,
    });
    this.form.valueChanges
      .pipe(
        // prevent triggering event when value is changed programmatically or
        // form has no value (e.g. personal-informatin step is doing phone reset on ng init)
        filter(() => this.form.dirty || !Object.values(this.form.value).every((value) => !value)),
        take(1),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        this.EEService.reportFormStart(stepName);
        this.formFillStarted.next(true);
      });

    /**
     * Check to see if the field's value is different from the initial value.
     * This is to prevent field reporting if a field with a prefilled value is dynamically being
     * appended to the form, or altered as a result of the dictator's value changing.
     * A value/validity update is triggered to reinitialize the validators
     * since they potentially could be different, but we don't want this to also trigger
     * field reporting.
     */
    const initialFormValues = this.form.value;

    Object.entries(this.form.controls).forEach(([controlName, control]) => {
      combineLatest([this.formFillStarted, control.valueChanges])
        .pipe(
          filter(([, value]) => !!value && value !== initialFormValues[controlName]),
          take(1),
          takeUntil(this.ngUnsubscribe$)
        )
        .subscribe(() => {
          this.EEService.reportFormFieldInteraction(controlName);
        });
    });
  }

  // ======================================================
  // POST STEP
  // ======================================================

  /**
   * @description step action
   * @param stepActionType emitted action type
   */
  onStepAction(stepActionType: string): void {
    switch (stepActionType) {
      case Back: {
        this.onBack();
        break;
      }
      default:
        this.onNext(this.form.value);
    }
  }

  /**
   * on back
   */
  public onBack(): void {
    /** Comment out question value saves onBack for now. */
    // this.store.dispatch(
    //   fromQuestionsStore.updateQuestionValues({
    //     questionValues: this.form.value,
    //   })
    // );
    this.stepChangeStatus = true;

    const { applicationStep } = this.applicationStepData;
    this.store.dispatch(
      fromApplicationStore.processApplicationStepChange({
        applicationStep,
        direction: Backward,
        questionValues: {},
      })
    );
    // Clear question values on confirm info step back ward
    if (applicationStep.name === ApplicationStepNameTypes.ConfirmInfo) {
      this.store.dispatch(fromQuestionsStore.clearQuestionValues());
    }
    this.store.dispatch(fromFunnelSessionsStore.clearFormSessionId());
    this.adjustStep(Backward);
  }

  /**
   * on next
   * (public just for unit tests)
   */
  public onNext(formValue: any): void {
    this.isSubmitting = true;
    const { applicationStep } = this.applicationStepData;
    if (this.validatorsService.isFormValid.call(this)) {
      const updatedFormValue = { ...formValue };
      this.stepService.patchQuestionValueBeforeSubmit(updatedFormValue, this.questionMap, applicationStep);
      /**
       * Update the questions store first.
       * Since this is a synchronous action, these values -should- be updated in the store
       * before the updateOrCreateLead effect gets dispatched, in case we need to
       * reference the up-to-date questionValues during the processApplicationStepChange effect.
       */
      this.store.dispatch(
        fromQuestionsStore.updateQuestionValues({
          questionValues: updatedFormValue,
        })
      );

      this.dispatchNextStoreActionByStepName(
        this.applicationStepData.applicationStep.name,
        updatedFormValue,
        applicationStep
      );

      this.stepChangeStatus = true;
      /**
       * Listen for changes at state level, and once loading status has been switched back
       * to false, proceed accordingly.
       * (similar to what we do for app submit)
       * Also need to take into account app submit actions if this is the last step.
       */
      this.store
        .pipe(
          select(fromLeadStore.selectLeadState),
          filter((state: fromLeadStore.LeadState) => !state.loading),
          first(),
          tap((state) => {
            if (state.loaded && this.isLastStep()) {
              this.store.dispatch(fromApplicationStore.submitApplication());
            }
          }),
          switchMap((state: fromLeadStore.LeadState) =>
            forkJoin([
              of(state),
              /**
               * If we don't do a lead PATCH, then the first emitted action might end up being the success or fail actions instead of
               * the initial submitApplication action, so just listen for those 2 instead?
               */
              this.isLastStep() && state.loaded
                ? this.actions$.pipe(
                    ofType(
                      fromApplicationActions.submitApplicationSuccess,
                      fromApplicationActions.submitApplicationFail
                    ),
                    take(1)
                  )
                : of(null),
            ])
          )
        )
        .subscribe(([state]) => {
          /** Success */
          if (state.loaded) {
            // TODO_FIX: There's still a nested subscribe here:
            this.finishUpAndDetermineNextAction(applicationStep, updatedFormValue);
          }
          // Or we can probably just use else..
          /** Error */
          if (state.error) {
            this.isSubmitting = false;
            this.stepChangeStatus = false;
          }
        });
    } else {
      (document.activeElement as any)?.blur(); // remove focus from active element to display validation error
      this.EEService.processPageErrors(applicationStep.name, this.form);
      this.validatorsService.srAnnounceFieldErrors(this.form, this.questionMap);
      this.isSubmitting = false;
    }
  }

  private dispatchNextStoreActionByStepName(stepName, updatedFormValue, applicationStep) {
    switch (stepName) {
      case ApplicationStepNameTypes.VerifyIdentity: {
        this.store.dispatch(fromLeadStore.validateKcfVerifyIdentity({ formValue: updatedFormValue }));
        break;
      }
      default: {
        /**
         * Try to handle the extra app step processing here so that any question value resetting due
         * to step changes can be picked up by the updateOrCreateLead process.
         */
        this.store.dispatch(
          fromApplicationStore.processApplicationStepChange({
            applicationStep,
            direction: Forward,
            questionValues: updatedFormValue,
          })
        );
      }
    }
  }

  private finishUpAndDetermineNextAction(applicationStep, formValue) {
    this.stepChangeStatus = true;
    this.validatorsService.srClearAnnouncements();
    this.EEService.reportFormSubmission(applicationStep.name, formValue);
    this.determineNextAction();
  }

  /**
   * determine next action
   * @param stepDirection
   */
  private determineNextAction(): void {
    if (this.isLastStep()) {
      this.processApplicationSubmit();
    } else {
      this.adjustStep(Forward);
    }
  }

  /**
   * Used to determine whether app submit should occur on this page.
   */
  private isLastStep(): boolean {
    const { applicationStepIndicators: stepIndicators } = this.applicationStepData;
    return stepIndicators.isLastStep;
  }

  /**
   * handle application submit
   */
  private processApplicationSubmit(): void {
    this.store
      .pipe(
        select(fromApplicationStore.selectApplicationSubmitState),
        filter((state) => !state.loading),
        first()
      )
      .subscribe((applicationSubmitState: any) => {
        if (applicationSubmitState.data) {
          this.store.dispatch(fromAppStore.go({ path: [MAIN_URL_SEGMENT, 'decision'] }));
          this.EEService.reportLeadSubmitted();
        }
        if (applicationSubmitState.error) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            error: applicationSubmitState.error,
          };
          this.dialog.open(dialogComponentMap[DialogTypes.Error], dialogConfig);
          this.isSubmitting = false;
          this.stepChangeStatus = false;
        }

        // [LOG_ERROR] monitoring just in case any unhandled submit instance
        // were to occur, current handling being in the onNext fn
        if (!applicationSubmitState.data && !applicationSubmitState.error) {
          console.error('[PROCESS_APPLICATION_SUBMIT] unhandled submit');
        }
      });
  }

  /**
   * @description adjust step
   * @params stepDirection
   */
  adjustStep(stepDirection: string): void {
    this.store.dispatch(
      fromDigitalExperienceStore.setApplicationStepDirection({
        payload: stepDirection,
      })
    );
    this.store.dispatch(fromQuestionsStore.resetBackendErrors());
    this.hidden = true;
    timer(STEP_TRANSITION_DURATION).subscribe(() =>
      this.store.dispatch(
        fromDigitalExperienceStore.setApplicationStep({
          payload: { type: stepDirection },
        })
      )
    );
  }

  /**
   * init dynamic question
   * @param questionName
   * @param dictatorQuestionName
   * @param questionValidators
   *
   * TODO: The dependency configuration allow for dependencies on multiple dictator questions,
   * this function does not yet handle that case. Update to allow multiple when that becomes
   * a requirement. (It may look like that works, but the dependency functions should be run
   * for all dictator questions.)
   */
  private initDynamicQuestion(
    questionName: QuestionNameTypes,
    dependencyConfig: DependencyConfig,
    questionValidators: fromQuestionsModels.QuestionValidators
  ) {
    const questionConfig: Question = this.questionMap[questionName];
    const requiredValidatorFromConfig: QuestionValidator = questionConfig.validators?.sync?.find(
      (validator: QuestionValidator) => validator.validatorName === ValidatorTypes.Required
    );
    const requiredValidator: QuestionValidator = requiredValidatorFromConfig || {
      validatorName: ValidatorTypes.Required,
      message: `Please enter ${questionName.replace(/([a-z])([A-Z]+)/g, (_match, p1, p2) =>
        `${p1} ${p2}`.toLowerCase()
      )}`,
    };

    this.form
      .get(dependencyConfig.dictatorName)
      .valueChanges.pipe(
        startWith(this.form.value[dependencyConfig.dictatorName]),
        map((valueChange) => ({
          requiredStatus: dependencyConfig.dictatorFunction(valueChange),
          dictatorValue: valueChange,
        })),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(({ requiredStatus, dictatorValue }) => {
        // position after dictator question
        const questionIndex = this.stepFormConfig.questionNames.indexOf(dependencyConfig.dictatorName) + 1;
        /*
          Simplify by filtering out any required validators and add validator on each required status.
          Avoid check if required validator already exists to then replace/add/or remove and ensure
          validator there by adding on each step.
        */
        const updatedSyncValidators = questionValidators.sync.filter(
          (questionValidator) => questionValidator.validatorName !== ValidatorTypes.Required
        );
        if (requiredStatus) {
          // add required validator
          updatedSyncValidators.push(requiredValidator);
          questionValidators.sync = updatedSyncValidators;
          this.addQuestion(questionName, questionValidators, questionIndex, dictatorValue);
        } else {
          questionValidators.sync = updatedSyncValidators;
          this.removeQuestion(questionName);
        }
      });
  }

  /*
  These function manipulate the form config and control validations to achieve desired
  outcome. Form config question names determines which questions are displayed, adding
  or removing names allows the display to be altered along while also changing corresponding
  control validations. Control validator manipulation was done to retain form values vs
  control remove or disable. Adding/removing controls would remove property from form value
  which updates store state on each next/back action. Disabling would do same.
  Potential future alternative may be to use raw form value.
  */

  /**
   * @description add question
   * @param questionName
   * @param validators
   * @param index
   */
  protected addQuestion(
    questionName: QuestionNameTypes,
    questionValidators: fromQuestionsModels.QuestionValidators,
    index: number = null,
    dictatorValue: string
  ) {
    const questionNames = [...this.stepFormConfig.questionNames];
    /**
     * In some cases, the question config data (like the labels) might need to change
     * based on the value of the dictator field that was entered.
     */
    this.questionsService.modifyQuestionsData(this.questionMap, questionName, questionValidators, dictatorValue);

    /**
     * Update the validators in case they've changed due to a change
     * in the dictator's value.
     */
    const [syncValidators, asyncValidators] = this.validatorsService.getValidators(questionValidators);
    const control = this.form.get(questionName);
    control.setValidators(syncValidators);
    control.setAsyncValidators(asyncValidators);
    control.updateValueAndValidity();

    /**
     * Append the added question to the questionNames array if it doesn't already exist. This is what will
     * allow the new field to get added in the UI.
     * If there are any changes to this question's config object, the field UI should be able to update accordingly.
     * Assuming the question doesn't need to be added - only its details altered - the questionNames list should remain unaffected.
     */
    if (!questionNames.includes(questionName)) {
      index = index || questionNames.length;
      questionNames.splice(index, 0, questionName);
    }

    this.stepFormConfig = { ...this.stepFormConfig, questionNames };
  }

  /**
   * @description remove question
   * @param questionName
   */
  protected removeQuestion(questionName: string): void {
    let questionNames = [...this.stepFormConfig.questionNames];
    if (questionNames.includes(questionName)) {
      const control = this.form.get(questionName);
      questionNames = this.stepFormConfig.questionNames.filter((name) => name !== questionName);
      this.stepFormConfig = { ...this.stepFormConfig, questionNames };
      /** value clearing/resetting logic. */
      control.setValue(null);
      control.clearValidators();
      control.clearAsyncValidators();
      control.reset();
      control.updateValueAndValidity();
      /** */
    }
  }

  /**
   * Initial A/B test status updates that would occur when the step component first loads.
   * This will run for each step component, and we will make a status update request if there are any status updates we need to run
   * for that step.
   */
  private handleInitialAbTestStatusUpdates() {
    combineLatest([
      this.store.pipe(select(fromAbTestStore.selectAbTestLoaded)),
      this.store.pipe(select(fromDigitalExperienceStore.selectDigitalExperienceLoaded)),
    ])
      .pipe(
        filter(
          ([abTestLoaded, digitalExperienceLoaded]: [boolean, boolean]) => abTestLoaded && digitalExperienceLoaded
        ),
        first(),
        withLatestFrom(
          this.store.pipe(select(fromDigitalExperienceStore.selectDigitalExperienceName)),
          this.store.pipe(select(fromAbTestStore.selectAbTestsData))
        )
      )
      .subscribe(([_, digitalExperienceName, abTestData]: [boolean[], string, IAbTestData]) => {
        this.abTestStatusUpdatesService.buildInitialAbTestStatusChangesAndUpdate(
          this.applicationStepData.applicationStep.name,
          digitalExperienceName,
          abTestData
        );
      });
  }
}
