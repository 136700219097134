import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCoreStore from '../../../store';
import * as fromCoreConfigs from '../../../configs';
import { privacyPolicyDisclosureLink, termsOfUseDisclosureLink } from '../../../configs';
import { Observable } from 'rxjs';
import { CoreData } from '../../../models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  privacyPolicyDisclosure = privacyPolicyDisclosureLink;
  termsOfUseDisclosure = termsOfUseDisclosureLink;
  vendorCerts = fromCoreConfigs.vendorCerts;
  applyDisclosures: string[];
  coreData$: Observable<CoreData>;

  constructor(protected coreStore: Store<fromCoreStore.CoreState>) {
    this.applyDisclosures = fromCoreConfigs.applyDisclosures;
  }

  ngOnInit() {
    this.coreData$ = this.coreStore.pipe(select(fromCoreStore.selectCoreData));
  }
}
