// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDigitalExperienceStore from './store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromDigitalExperienceStore.DIGITAL_EXPERIENCE_STATE_KEY, fromDigitalExperienceStore.reducer),
    EffectsModule.forFeature(fromDigitalExperienceStore.effects),
  ],
})
export class DigitalExperienceModule {}
