// models
import { DecisionTypes, DecisionAliasTypes } from '../models';
import { Map } from '../../shared/models';

export const applicationBasePath = 'applicationservice';
export const decisionBasePath = 'decisionservice';

export const applicationServiceBaseUrl: Map<string> = {
  v1: `${applicationBasePath}/api/v1/applications`,
  v2: `${applicationBasePath}/api/v2/applications`,
};

export const decisionServiceBaseUrl: Map<string> = {
  v1: `${decisionBasePath}/api/v1/decisions`,
};

export const decisionTypeAliasMap = {
  [DecisionTypes.Approved]: DecisionAliasTypes.Approved,
  [DecisionTypes.Pended]: DecisionAliasTypes.Pended,
  [DecisionTypes.Declined]: DecisionAliasTypes.Declined,
};
