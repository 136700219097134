// models
import * as fromQuestionsModels from '../../models';

export const confirmInformationCorrect: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Checkbox,
  inputElType: fromQuestionsModels.InputElTypes.Checkbox,
  name: fromQuestionsModels.QuestionNameTypes.ConfirmInformationCorrect,
  label: 'I agree that all information is correct and up-to-date to the best of my knowledge.',
  noButton: true,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.RequiredTrue,
        message: 'You must agree in order to proceed',
      },
    ],
  },
};
