import { FormElTypes, HelpTextTypes, InputElTypes, Question, ValidatorTypes } from '../../models';
import { PhoneMask } from '../validators';

const PHONE_DIGITS = 10;

// Common phone field confirmation for the Home/Mobile number on Contact Info form
export const commonPhoneQuestion: Question = {
  formElType: FormElTypes.Input,
  inputElType: InputElTypes.Tel,
  placeholder: '(XXX) XXX-XXXX',
  mask: PhoneMask,
  helpText: HelpTextTypes.Phone,
  validators: {
    sync: [
      {
        validatorName: ValidatorTypes.Mask,
        message: 'Invalid format',
        readonly: true,
      },
      {
        validatorName: ValidatorTypes.Pattern,
        value: `[0-9]{${PHONE_DIGITS}}`,
        message: `Please enter a ${PHONE_DIGITS} digit phone number`,
      },
      {
        validatorName: ValidatorTypes.PhoneNoLeadingDigitOfOne,
        message: 'Phone numbers starting with 1 are not acceptable',
      },
    ],
  },
};
