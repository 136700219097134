// ngrx
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCoreReducers from '../reducers';

// key
export const CORE_FEATURE_KEY = 'core';

// feature selector
export const selectCoreFeature = createFeatureSelector<fromCoreReducers.CoreState>(CORE_FEATURE_KEY);

// data
export const selectCoreData = createSelector(selectCoreFeature, fromCoreReducers.selectCoreStateData);
