// vendor
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// store
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromCardFunnelAvailabilityActions from '../actions/card-funnel-availability.actions';
// services
import { OffersService } from '../../services';
// models
import { CardFunnelAvailability } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CardFunnelAvailabilityEffects {
  constructor(private offersService: OffersService, private action$: Actions) {}

  // load card funnel availability
  loadCardFunnelAvailability$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromCardFunnelAvailabilityActions.loadCardFunnelAvailability),
      switchMap((action) =>
        this.offersService.getCardFunnelAvailability().pipe(
          map((cardFunnelAvailability: CardFunnelAvailability) =>
            fromCardFunnelAvailabilityActions.loadCardFunnelAvailabilitySuccess({ cardFunnelAvailability })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              fromCardFunnelAvailabilityActions.loadCardFunnelAvailabilityFail({
                error,
              })
            )
          )
        )
      )
    )
  );
}
