// vendor
import { Component, Injector, OnInit } from '@angular/core';
import * as _ from 'lodash-es';
import { filter, first, map } from 'rxjs/operators';
// ngrx
import { select } from '@ngrx/store';
import * as fromLeadStore from '../../../../lead/store';

// components
import { AgreementComponent } from '../agreement/agreement.component';
import { CardFeaturesService } from 'src/app/features/shared/services/card-features.service';
import { MatDialog } from '@angular/material/dialog';
import { CARD_FEATURE_SERVICE_CONSUMER } from 'src/app/features/shared/configs';
import { E_SIGN_CONSENT_SEGMENT, MAIN_URL_SEGMENT } from 'src/app/features/main/configs';

@Component({
  selector: 'app-opportunity-microfunnel.component',
  templateUrl: '../agreement/agreement.component.html',
  styleUrls: [
    './opportunity-microfunnel.component.scss',
    '../agreement/agreement.component.scss',
    '../shared/individual-steps.scss',
  ],
  providers: [CardFeaturesService, { provide: CARD_FEATURE_SERVICE_CONSUMER, useValue: 'opportunity-microfunnel' }],
})
export class OpportunityMicrofunnelComponent extends AgreementComponent implements OnInit {
  shouldShowPanel = true;

  shouldShowSsn$ = this.store.pipe(
    select(fromLeadStore.selectLeadOdlData),
    filter((odlData) => !_.isNil(odlData)),
    first(),
    map((odlData) => odlData.collectSsn)
  );

  onNext(formValue: any): void {
    super.onNext(formValue);
  }

  openConsentLink() {
    this.windowUtilService.nativeWindow.open(`/${MAIN_URL_SEGMENT}/${E_SIGN_CONSENT_SEGMENT}`);
  }

  constructor(public injector: Injector, public dialog: MatDialog) {
    super(injector, dialog);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
