// vendor
import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ValidatorFn,
  ValidationErrors,
  AsyncValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { first, filter, map, withLatestFrom } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromQuestionsActions from '../../questions/store/actions';
import * as fromQuestionsReducers from '../../questions/store/reducers';
import * as fromQuestionsSelectors from '../../questions/store/selectors';
// services
import { validatorHelpers } from './helpers/validators-helpers';
import { SyncService } from '../../shared/services';
// models
import { Map } from '../../shared/models';
import * as fromQuestionsModels from '../../questions/models';
// config
import * as fromQuestionsConfigs from '../../questions/configs';
// misc
import { regex } from './helpers/validators-regex';
// consts
const ValidatorTypes = fromQuestionsModels.ValidatorTypes;

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  validatorFnMap: Map<ValidationErrors>;

  constructor(
    private syncService: SyncService,
    private store: Store<fromQuestionsReducers.QuestionsState>,
    private liveAnnouncer: LiveAnnouncer
  ) {
    this.setValidatorFnMap();
  }

  /**
   * set validator fn map
   */
  private setValidatorFnMap() {
    this.validatorFnMap = {
      sync: {
        [ValidatorTypes.Required]: Validators.required,
        [ValidatorTypes.RequiredTrue]: this.validateRequiredTrue,
        [ValidatorTypes.Pattern]: Validators.pattern,
        [ValidatorTypes.MinLength]: Validators.minLength,
        [ValidatorTypes.MaxLength]: Validators.maxLength,
        [ValidatorTypes.Min]: Validators.min,
        [ValidatorTypes.Max]: Validators.max,
        [ValidatorTypes.IsPoBox]: this.validateIsPoBox,
        [ValidatorTypes.DateFormat]: this.validateDateFormat,
        [ValidatorTypes.MobileOrHomePhoneRequired]: this.validateMobileOrHomePhoneRequired,
        [ValidatorTypes.SecondaryPhoneType]: this.validateSecondaryPhone,
        [ValidatorTypes.ProperName]: this.validateProperName,
        [ValidatorTypes.ContainsAnd]: this.validateContainsAnd,
        [ValidatorTypes.OverAgeWarning]: this.validateOverAgeWarning,
        [ValidatorTypes.OfAge]: this.validateOfAge,
        [ValidatorTypes.PhoneNoLeadingDigitOfOne]: this.validatePhoneNoLeadingDigitOfOne,
        [ValidatorTypes.StatementPreferenceRequired]: this.validateStatementPreferenceRequired,
        [ValidatorTypes.UnsupportedState]: this.supportedState,
        [ValidatorTypes.InvalidState]: this.validateState,
      },
      async: {
        [ValidatorTypes.Address]: this.validateAddress.bind(this),
        [ValidatorTypes.PhysicalAddress]: this.validatePhysicalAddress.bind(this),
      },
    };
  }

  /**
   * Call all of the methods to validate a form (, autofocus on errors, and set dirty/touched state)
   * IMPORTANT: Uses .call to utilize the component's context.
   */
  public isFormValid(form: UntypedFormGroup): boolean {
    if (!form) {
      form = (this as any).form;
    }
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].markAsDirty();
      form.controls[key].markAsTouched();
    });
    return form.valid;
  }

  /**
   * get validators
   * @param validators
   * @param form
   * @returns array of sync/async validators
   */
  getValidators(
    validators: fromQuestionsModels.QuestionValidators,
    form: UntypedFormGroup = null
  ): [ValidatorFn[], AsyncValidatorFn[]] {
    const syncValidators: ValidatorFn[] = validators.sync ? this.getSyncValidators(validators.sync, form) : null;
    const asyncValidators: AsyncValidatorFn[] = validators.async
      ? this.getAsyncValidators(validators.async, form)
      : null;
    return [syncValidators, asyncValidators];
  }

  /**
   * get sync validators
   * @param validators
   * @param form
   * @return array of Validator functions
   * Loops through each validator in the question's validators array,
   * and references validatorMap to return the validator function based on the validatorName
   */
  private getSyncValidators(
    validators: fromQuestionsModels.QuestionValidator[],
    form: UntypedFormGroup
  ): ValidatorFn[] {
    return validators
      .filter((validator: fromQuestionsModels.QuestionValidator) => !validator.readonly)
      .map((validator: fromQuestionsModels.QuestionValidator) => {
        const validatorName = validator.validatorName;
        if (validatorName && this.validatorFnMap.sync[validatorName]) {
          const fn = this.validatorFnMap.sync[validatorName];
          const value = form || validator.value;
          return value ? fn(value) : fn;
        }
        // undefined validator
        return null;
      });
  }

  /**
   * get async validators
   * @param validators
   * @param form
   * @return array of AsyncValidator functions
   * Loops through each async validator in the question's validators.async array,
   * and references validatorMaps.async to return the async validator function based on the validatorName
   */
  private getAsyncValidators(
    validators: fromQuestionsModels.QuestionValidator[],
    form: UntypedFormGroup
  ): AsyncValidatorFn[] {
    return validators
      .filter((validator: fromQuestionsModels.QuestionValidator) => !validator.readonly)
      .map((validator: fromQuestionsModels.QuestionValidator) => {
        if (validator.validatorName && this.validatorFnMap.async[validator.validatorName]) {
          const fn = this.validatorFnMap.async[validator.validatorName];
          const value = form || validator.value;
          return value ? fn(value) : fn;
        } else {
          // undefined validator
          return null;
        }
      });
  }

  /**
   * validate required true
   * @param control
   * @returns validation object
   */
  private validateRequiredTrue(control: UntypedFormControl): Record<string, any> | null {
    const error = Validators.requiredTrue(control);
    return error ? { [ValidatorTypes.RequiredTrue]: true } : null;
  }

  /**
   * validate date format
   * @params control
   * @returns validation object
   */
  private validateDateFormat(control: UntypedFormControl): Record<string, any> | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    if (!validatorHelpers.isValidDate(value)) {
      return { dateFormat: true };
    } else {
      return null;
    }
  }

  /**
   * validate of age
   * @param dateOfBirth
   * @param state
   * @returns validation object or null
   */
  validateOfAge(control: UntypedFormControl): Record<string, any> | null {
    const { value: dateOfBirth } = control;
    const { MIN_AGE_DEFAULT } = fromQuestionsConfigs;
    let validation = null;
    const validDateStatus = validatorHelpers.isValidDate(dateOfBirth);
    if (validDateStatus) {
      const ofAgeStatus = validatorHelpers.isOfAge(dateOfBirth, MIN_AGE_DEFAULT);
      if (!ofAgeStatus) {
        validation = { [ValidatorTypes.OfAge]: true };
      }
    }
    return validation;
  }

  /**
   * validate of age for state
   * @param control
   * @returns status
   */
  validateOfAgeForState(dateOfBirth: string, state: string): boolean {
    const normalizedState = state.toLowerCase();
    let minAge = fromQuestionsConfigs.MIN_AGE_DEFAULT;
    let maxAge = fromQuestionsConfigs.MAX_AGE_DEFAULT;
    const stateAgeRestriction = fromQuestionsConfigs.stateAgeRestrictions.find((stateAgeRestriction) =>
      stateAgeRestriction.states.includes(normalizedState)
    );
    if (stateAgeRestriction) {
      ({ minAge, maxAge } = stateAgeRestriction);
    }
    return validatorHelpers.isOfAge(dateOfBirth, minAge, maxAge);
  }

  /**
   * validate over age warning
   * @param control
   */
  private validateOverAgeWarning(control: UntypedFormControl): Record<string, any> | null {
    const dateOfBirth = control.value;
    const validDateStatus = validatorHelpers.isValidDate(dateOfBirth);
    if (validDateStatus) {
      const overAgeStatus = validatorHelpers.getAge(dateOfBirth) >= fromQuestionsConfigs.DEFAULT_OVER_AGE_WARNING_LIMIT;
      control['warnings'] = overAgeStatus ? { [ValidatorTypes.OverAgeWarning]: true } : null;
    }
    return null;
  }

  /**
   * validate proper name fields (name, city)
   * @params control
   * @return validation object
   */
  private validateProperName(control: UntypedFormControl): Record<string, any> | null {
    return !control.value || regex.properName.test(control.value) ? null : { properName: true };
  }

  private validateContainsAnd(control: UntypedFormControl): Record<string, any> | null {
    return !control.value || !validatorHelpers.containsAnd(control.value) ? null : { containsAnd: true };
  }

  /**
   * validate is po box
   * @params control
   * @returns validation object
   */
  private validateIsPoBox(control: UntypedFormControl): Record<string, any> | null {
    const value = control.value;
    if (!value) {
      return null;
    }
    if (validatorHelpers.isPoBox(value)) {
      return { isPoBox: true };
    }
  }

  /**
   * validate mobile or home phone required
   * @params form
   * @return validation object
   */
  private validateMobileOrHomePhoneRequired(form: UntypedFormGroup): Record<string, any> | null {
    return (form: UntypedFormGroup) => {
      const { mobilePhone, homePhone } = form.value as fromQuestionsModels.QuestionValues;
      let validation = null;
      if (!mobilePhone && !homePhone) {
        validation = {
          [ValidatorTypes.MobileOrHomePhoneRequired]: true,
        };
      }
      return validation;
    };
  }

  /**
   * validate secondary phone
   * @params form
   * @return validation object
   */
  private validateSecondaryPhone(form: UntypedFormGroup): Record<string, any> | null {
    return (form: UntypedFormGroup) => {
      const secondaryPhone = form.controls.secondaryPhone;
      const secondaryPhoneType = form.controls.secondaryPhoneType;
      if (secondaryPhone.valid && secondaryPhone.value && !secondaryPhoneType.value) {
        return {
          [ValidatorTypes.SecondaryPhoneType]: true,
        };
      }
      return null;
    };
  }

  /**
   * validate phone numbers - prevent numbers starting with '1'
   * @params control
   * @return validation object
   */
  private validatePhoneNoLeadingDigitOfOne(control: UntypedFormControl): Record<string, any> | null {
    return !control.value || regex.phoneNoLeadingDigitOfOne.exec(control.value)
      ? null
      : { phoneNoLeadingDigitOfOne: true };
  }

  private validateStatementPreferenceRequired() {
    return (form: UntypedFormGroup) => {
      const { paperStatementPreference, paperlessStatementPreference } = form.value;
      let validation = null;
      if (!paperStatementPreference && !paperlessStatementPreference) {
        validation = {
          [ValidatorTypes.StatementPreferenceRequired]: true,
        };
      }

      return validation;
    };
  }

  /**
   * validate address
   * @param form
   * @returns function
   */
  private validateAddress(form: UntypedFormGroup): (form: UntypedFormGroup) => Observable<any> {
    return (form: UntypedFormGroup): Observable<any> => {
      const { address, city, state, zipCode, dateOfBirth } = form.value;
      const preAddress = {
        address,
        city,
        state,
        zipCode,
      };
      return this.validateGenericAddress(ValidatorTypes.Address, preAddress, dateOfBirth);
    };
  }

  /**
   * validate physical address
   * @param form
   * @returns function
   */
  private validatePhysicalAddress(form: UntypedFormGroup): (form: UntypedFormGroup) => Observable<any> {
    return (form: UntypedFormGroup): Observable<any> => {
      const { physicalAddress, physicalCity, physicalState, physicalZipCode, dateOfBirth } = form.value;
      const preAddress = {
        address: physicalAddress,
        city: physicalCity,
        state: physicalState,
        zipCode: physicalZipCode,
      };
      return this.validateGenericAddress(
        ValidatorTypes.PhysicalAddress,
        preAddress,
        dateOfBirth,
        // do not allow po box on physical address
        false
      );
    };
  }

  /**
   * validate address
   * @params form
   */
  private validateGenericAddress(
    validatorName: fromQuestionsModels.ValidatorTypes,
    preAddress: fromQuestionsModels.PreAddress,
    dateOfBirth: string,
    poBoxAllowedStatus: boolean = true
  ): any {
    const address = this.syncService.syncAddress(preAddress);
    this.store.dispatch(fromQuestionsActions.validateAddress({ address, poBoxAllowedStatus }));
    const addressValidation$ = this.store.pipe(
      select(fromQuestionsSelectors.selectValidationValue, {
        name: ValidatorTypes.Address,
      }),
      filter(Boolean),
      first()
    );
    const appendedAddressValidationWithStateAgeCheck$ = addressValidation$.pipe(
      withLatestFrom(
        this.store.pipe(
          select(fromQuestionsSelectors.selectQuestionValue, {
            name: fromQuestionsModels.QuestionNameTypes.DateOfBirth,
          })
        )
      ),
      map(([addressValidation, storedDateOfBirth]: [any, any]) => {
        const {
          status,
          data: { isPoBox },
        } = addressValidation;
        /*
          Three experiences to account for:
          1. address step before dob step (dob not available)
          2. address step after dob step (dob available store level)
          3. address/dob same step (dob available form level)
        */
        const activeDateOfBirth = dateOfBirth || storedDateOfBirth;
        let validator = status ? null : { [validatorName]: addressValidation };
        /*
          criteria for state age check
          1. address valid (validator null)
          2. non po box address (age validation is done against non po box [physical] state)
          3. dob exists (run check only if dob to validate against)
        */
        const stateAgeCheckStatus = validator === null && isPoBox === false && !!activeDateOfBirth;
        if (stateAgeCheckStatus) {
          const isOfAgeForState = this.validateOfAgeForState(activeDateOfBirth, address.state);
          validator = isOfAgeForState
            ? null
            : {
                [ValidatorTypes.OfAgeForState]: {
                  message: "You don't meet the age requirements in your state.",
                },
              };
        }
        return validator;
      })
    );
    return appendedAddressValidationWithStateAgeCheck$;
  }

  /**
   * sr announce field errors
   * @param form
   * @param questionMap
   */
  srAnnounceFieldErrors(form: UntypedFormGroup, questionMap: Map<fromQuestionsModels.Question>) {
    const invalidFields: string = Object.entries(form.controls)
      .filter(([key, value]) => value.invalid)
      .map(([key, value]) => {
        // Some labels contain html tags. We don't want that here
        const tagsRegex = /(<|<\/)[a-z0-9]+>/gi;
        const label = questionMap[key].label;
        // Sometimes there is no field-wide label (ex: autopay options).
        return label ? label.replace(tagsRegex, '') : '';
      })
      .join(', ');
    let formErrors: any = '';
    // also check if we have any form group errors.
    if (form.errors) {
      formErrors = Object.entries(form.errors)
        .map(([key, value]) => value['message'] || fromQuestionsConfigs.formValidatorMsgMap[key])
        .join('. ');
    }
    this.liveAnnouncer.announce(
      `
      This form has invalid fields. Please review: ${invalidFields}.
      ${formErrors}
      `,
      'assertive'
    );
  }

  private supportedState(control: UntypedFormControl): Record<string, any> | null {
    return !control.value || !validatorHelpers.isUnsupportedState(control.value) ? null : { unsupportedState: true };
  }

  private validateState(control: UntypedFormControl): Record<string, any> | null {
    return !control.value || !validatorHelpers.isInvalidState(control.value) ? null : { invalidState: true };
  }

  /**
   * screen reader clear announcements
   */
  srClearAnnouncements() {
    this.liveAnnouncer.clear();
  }
}
