<app-panel class="c-prospect-card">
  <div class="title">
    <span class="c-prospect-card__name">{{ formatProspectName() }}</span>
    <button class="c-prospect-card__edit-btn" (click)="onEdit()">
      <svg class="c-prospect-card__edit-btn-icon" viewBox="0 0 24 24">
        <use href="#edit"></use>
      </svg>
      <span class="c-prospect-card__edit-btn-label">Edit</span>
    </button>
  </div>
  <div class="content">
    <section>
      <div class="c-prospect-card__item">
        <label class="c-prospect-card__label">Address</label>
        <div class="c-prospect-card__value">
          <div class="c-prospect-card__value-item">
            {{ prospectData.address }}
          </div>
          <div>
            <span class="c-prospect-card__value-item c-prospect-card__value--margin">{{ prospectData.city }},</span>
            <span class="c-prospect-card__value-item c-prospect-card__value--margin">{{
              prospectData.state | stateTransform
            }}</span>
            <span>{{ prospectData.zipCode | mask : '00000-0000' }} </span>
          </div>
        </div>
      </div>
    </section>
    <section>
      <p class="c-prospect-card__help-text">{{ residencyRequirementHelpText }}</p>
    </section>
  </div>
</app-panel>
