// vendor
import { Directive, Input, ViewContainerRef, ComponentRef, OnChanges, SimpleChanges } from '@angular/core';
// components
import * as fromStepComponents from '../components/individual-steps';
// models
import { ApplicationStepData } from '../../digital-experience/models';
// const
const APPLICATION_STEP_DATA = 'applicationStepData';

@Directive({ selector: '[applicationStep]' })
export class ApplicationStepDirective implements OnChanges {
  component: ComponentRef<any>;
  @Input() applicationStepData: ApplicationStepData;

  constructor(private container: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes[APPLICATION_STEP_DATA]) {
      if (changes.applicationStepData.previousValue) {
        const currentStepData = changes.applicationStepData.currentValue;
        const previousStepData = changes.applicationStepData.previousValue;
        if (
          previousStepData.applicationStep.name === currentStepData.applicationStep.name &&
          previousStepData.applicationStep.isAllowed !== currentStepData.applicationStep.isAllowed
        ) {
          // if the previous step and the current step are the same, but the isAllowed property is changed, should not
          // re-render the component
          return;
        }
      }
      this.resolveComponent();
    }
  }

  /**
   * resolve component
   */
  resolveComponent() {
    const { overrideComponent } = this.applicationStepData;
    const component = fromStepComponents[overrideComponent] || fromStepComponents.DynamicStepComponent;
    this.container.clear();
    this.component = this.container.createComponent(component);
    this.component.instance.applicationStepData = this.applicationStepData;
  }
}
