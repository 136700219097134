import { InjectionToken } from '@angular/core';
// environment
import { environment } from '../../../../environments/environment';
// models
import { IconTypes } from '../models';
import * as fromQuestionsModels from '../../questions/models';
// consts
export const BEST_EGG_LINK = `https://${environment.bestEggDomain}`;
export const BEST_EGG_LOGIN_LINK = `${BEST_EGG_LINK}/apply/login`;

// injection tokens
export const CARD_FEATURE_SERVICE_CONSUMER = new InjectionToken<'approve-dashboard' | 'opportunity-microfunnel'>(
  'card-feature-service_consumer'
);

// icon map
export const iconAssetMap: Record<IconTypes, string> = {
  [IconTypes.CheckMark]: '#check',
  [IconTypes.DollarSign]: '#dollar-sign',
};

// TODO-UPDATE: should backend provide these features per product
export const cardFeatures: Record<string, any> = {
  label: 'Additional Features:',
  value: [
    'No Annual Fee',
    'No Foreign Transaction Fees',
    'No Overlimit Fee',
    'Exclusive Access to our Customer Community',
  ],
};

// Functions to run on each offer attribute that defines the offers as
// being a rewards offer.
export const rewardsOffer: Record<string, (arg: string) => boolean> = {
  clientProductCode: (value: string) => value === 'MRW',
  rewardsProgramId1: (value: string) => Boolean(value),
};

export const stateValiatorConfig = [
  {
    validatorName: fromQuestionsModels.ValidatorTypes.Required,
    message: 'Please select state',
  },
  {
    validatorName: fromQuestionsModels.ValidatorTypes.UnsupportedState,
    message: "We're sorry, Best Egg credit cards are currently not available in your state.",
  },
  {
    validatorName: fromQuestionsModels.ValidatorTypes.InvalidState,
    message: 'Please select state',
  },
  {
    validatorName: fromQuestionsModels.ValidatorTypes.OfAgeForState,
    readonly: true,
  },
];
