// vendor
import { Injectable } from '@angular/core';
// ngrx
import { Store } from '@ngrx/store';
import * as fromDigitalExperienceStore from '../../digital-experience/store';
// services
import { SyncService } from '../../shared/services';
// models
import {
  ApplicationStep,
  ApplicationStepNameTypes,
  ApplicationStepSettings,
  ApplicationStepDirectionTypes,
} from '../../digital-experience/models';
import { ValidatorTypes } from '../../questions/models';

import * as fromQuestionsModels from '../../questions/models';
import { QuestionValues } from '../../questions/models';
import { Map } from '../../shared/models';
// misc
import { applicationStepExtraFieldsConfigMap, ExtraFieldsScenarios } from '../../digital-experience/configs/shared';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStepService {
  applicationStepInterceptFnMap: Map<any>;

  constructor(
    private syncService: SyncService,
    private store: Store<fromDigitalExperienceStore.DigitalExperienceState>
  ) {
    this.setApplicationStepInterceptFnMap();
  }

  /**
   * set application step intercept fn map
   */
  private setApplicationStepInterceptFnMap(): void {
    this.applicationStepInterceptFnMap = {
      [ApplicationStepNameTypes.ContactInformation]: this.interceptContactInformationStep.bind(this),
      [ApplicationStepNameTypes.WalkupInformation]: this.interceptContactInformationStep.bind(this), // Using the same intercept to deal with PO Box address
      [ApplicationStepNameTypes.ConfirmInfo]: this.interceptContactInformationStep.bind(this), // Using the same intercept to deal with PO Box address
      [ApplicationStepNameTypes.BreakingBECCStep1]: this.interceptContactInformationStep.bind(this), // Using the same intercept to deal with PO Box address
    };
  }

  /**
   * check any question values exist
   * @param questionNames
   * @param questionValues
   */
  private checkAnyQuestionValuesExist(questionNames: string[], questionValues: QuestionValues): boolean {
    const questionValuesSubset = questionNames.reduce(
      (values, name) => ((values[name] = questionValues[name]), values),
      {}
    );
    return this.syncService.checkAnyObjValuesExist(questionValuesSubset);
  }

  /**
   * intercept contact information step
   * @param applicationStep
   * @param direction
   * @param validations
   * @return modified steps
   */
  private interceptContactInformationStep(
    applicationStep: ApplicationStep,
    direction: ApplicationStepDirectionTypes,
    questionValues: QuestionValues,
    questionValidations: any,
    experienceSteps: Map<ApplicationStep>
  ): ApplicationStepSettings {
    let applicationStepSettings: any = {};
    if (direction === ApplicationStepDirectionTypes.Forward) {
      /**
       * Handle the altering of steps allowed.
       */
      const physicalAddressStepName = ApplicationStepNameTypes.PhysicalAddress;
      const { isPoBox } = questionValidations.address.data;
      applicationStepSettings = {
        stepsOn: [physicalAddressStepName],
      };

      /**
       * If the address submitted is not a P.O. box, then toggle the physical address step OFF.
       */
      if (isPoBox === false) {
        applicationStepSettings = {
          stepsOff: [physicalAddressStepName],
        };

        /**
         * In this case, we might have leftover physical address values from when the customer went
         * through the funnel prior, which we now want to clear.
         * IE. first entering a P.O. box address, then changing it to be non P.O. box.
         */
        if (questionValues.physicalAddress) {
          const questionNames = applicationStepExtraFieldsConfigMap[ExtraFieldsScenarios.ClearPhysicalAddress];

          // Create an object of all the fields we want to clear, where each field is set to `null`
          // It's referenced later on in updateQuestionValues and updateLead
          const nulledQuestionValues = questionNames?.reduce((values, name) => ((values[name] = null), values), {});
          applicationStepSettings.extraQuestionsValues = nulledQuestionValues;
        }
      }
    }
    return applicationStepSettings;
  }

  /**
   * process application step change
   * @param applicationStep
   * @param direction
   * @param validations
   * @param experienceSteps
   * @return observable of modified experience steps
   */
  processApplicationStepChange(
    applicationStep: ApplicationStep,
    direction: string,
    questionValues: QuestionValues,
    questionValidations: any,
    experienceSteps: Map<ApplicationStep>
  ): ApplicationStepSettings {
    let applicationStepSettings = {};
    const { [applicationStep.name]: stepInterceptFn } = this.applicationStepInterceptFnMap;
    if (stepInterceptFn) {
      applicationStepSettings = stepInterceptFn(
        applicationStep,
        direction,
        questionValues,
        questionValidations,
        experienceSteps
      );
    }
    return applicationStepSettings;
  }

  patchQuestionValueBeforeSubmit(
    formValue: Record<string, any>,
    questionMap: Record<string, fromQuestionsModels.Question>,
    applicationStep: ApplicationStep
  ) {
    // Quick check to see if form value object isn't empty (ie. termsAndConditions step has no form)
    if (Object.keys(formValue).length) {
      /**
       * Need to check dependency configuration for default values when a form field has been removed.
       * As is the case for monthly housing payment where the backend will not allow null so find the default
       * value configured in the dependency
       */
      Object.entries(formValue).forEach(([questionName, answer]) => {
        const question = questionMap[questionName];
        // all fields that are using 'properName' regex validator, trim the answers
        if (
          (question?.validators?.sync || []).some((validator) => validator.validatorName === ValidatorTypes.ProperName)
        ) {
          // setValue/patchValue directly to form control will trigger async validation results unexpected behavior
          formValue[questionName] = (formValue[questionName] as string).trim();
        }
        if (answer === null && applicationStep.formConfig.questionDependencies?.[questionName]) {
          // Current only a single dependency is ever used. When we support multiple we'll want
          // to find a better way to resolve the default.
          formValue[questionName] = applicationStep.formConfig.questionDependencies[questionName]
            .filter((config) => config.defaultValue !== undefined)
            .reduce((acc, curr) => acc + curr.defaultValue, '');
        }
      });
    }
  }
}
