<div class="c-mobile-phone-help-text">
  <p>
    {{ phoneHelpText }}
    <button
      mat-button
      color="primary"
      type="button"
      (click)="openCommunicationsConsentDialog()"
      aria-label="open communications consent dialog"
      class="c-mobile-phone-help-text__detail button-link-style"
    >
      specified here</button
    >.
  </p>
</div>
