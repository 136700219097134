// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromDocumentUploadReducer from '../reducers/document-upload.reducer';

// documents
export const selectDocumentUploadState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.documentUpload
);

// data
export const selectDocumentUploadData = createSelector(
  selectDocumentUploadState,
  fromDocumentUploadReducer.selectDocumentUploadStateData
);

// progress
export const selectDocumentUploadProgress = createSelector(
  selectDocumentUploadData,
  (documentUploadData) => documentUploadData?.progress
);

// error
export const selectDocumentUploadError = createSelector(
  selectDocumentUploadState,
  fromDocumentUploadReducer.selectDocumentUploadStateError
);

// loading
export const selectDocumentUploadLoading = createSelector(
  selectDocumentUploadState,
  fromDocumentUploadReducer.selectDocumentUploadStateLoading
);

// loaded
export const selectDocumentUploadedLoaded = createSelector(
  selectDocumentUploadState,
  fromDocumentUploadReducer.selectDocumentUploadStateLoaded
);
