// vendor
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// ngrx
import { select } from '@ngrx/store';
import * as fromCoreStore from '../../../../core/store';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
// models
import { CoreData } from '../../../../core/models';

@Component({
  selector: 'app-social-security-number',
  templateUrl: './social-security-number.component.html',
  styleUrls: ['./social-security-number.component.scss'],
})
export class SocialSecurityNumberComponent extends BaseApplicationStepComponent implements OnInit {
  coreData$: Observable<CoreData>;

  ngOnInit() {
    super.ngOnInit();
    this.coreData$ = this.store.pipe(select(fromCoreStore.selectCoreData));
  }
}
