// vendor
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
})
export class FormSectionComponent {
  @Input() orientation: boolean;

  constructor() {
    // default to flex direction row
    this.orientation = true;
  }
}
