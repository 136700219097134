// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardFunnelAvailability from '../reducers/card-funnel-availability.reducer';

// card funnel availability state
export const selectCardFunnelAvailabilityState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardFunnelAvailability
);

// card funnel availability data
export const selectCardFunnelAvailabilityData = createSelector(
  selectCardFunnelAvailabilityState,
  fromCardFunnelAvailability.selectCardFunnelAvailabilityData
);

// loading
export const selectCardFunnelAvailabilityLoading = createSelector(
  selectCardFunnelAvailabilityState,
  fromCardFunnelAvailability.selectCardFunnelAvailabilityLoading
);

// loaded
export const selectCardFunnelAvailabilityLoaded = createSelector(
  selectCardFunnelAvailabilityState,
  fromCardFunnelAvailability.selectCardFunnelAvailabilityLoaded
);
