<app-application-step
  class="c-agreement"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <app-panel *ngIf="shouldShowPanel">
      <div class="c-agreement__title title">
        Interest Rates and Charges
        <app-print-page-button [contentEl]="content" class="dark-print-button" [link]="true"> </app-print-page-button>
      </div>
      <div id="c-agreement__content" class="c-agreement__content content" #content>
        <app-default-application-terms></app-default-application-terms>
      </div>
    </app-panel>
    <form [formGroup]="form">
      <app-form-section [orientation]="false">
        <p class="c-agreement__message">By clicking the submit button below, you are agreeing to the following:</p>
        <app-form-checkbox
          class="c-agreement__esign-consent"
          [parent]="form"
          [question]="questionMap[questionNameTypes.Agreement]"
          sizeClass="large"
          (clickLabel)="openConsentLink()"
        ></app-form-checkbox>
        <p class="c-agreement__message">
          Please select one or both of the following options for the delivery of monthly statements:
        </p>
        <div class="c-agreement__statement-preference-container">
          <app-form-checkbox
            [parent]="form"
            [question]="questionMap[questionNameTypes.PaperStatementPreference]"
            sizeClass="large"
            [noPadding]="true"
          ></app-form-checkbox>
          <app-form-checkbox
            [parent]="form"
            [question]="questionMap[questionNameTypes.PaperlessStatementPreference]"
            sizeClass="large"
          ></app-form-checkbox>
          <app-form-validation [validation]="errorValidator" [noMargin]="true"></app-form-validation>
        </div>
        <ng-container *ngIf="shouldShowSsn$ | async">
          <div class="c-agreement__ssn">
            <app-form-input
              [parent]="form"
              [question]="questionMap[questionNameTypes.SocialSecurityNumber]"
              sizeClass="large"
            ></app-form-input>

            <app-ssn-details></app-ssn-details>
          </div>
        </ng-container>
        <p class="c-agreement__message">Your other important legal information:</p>
        <div class="c-agreement__disclosures">
          <!-- custom disclosures begin -->
          <button
            mat-button
            color="primary"
            type="button"
            class="c-agreement__disclosure-label button-link-style"
            (click)="openDialog(termsAndConditionsDisclosure.dialog)"
          >
            {{ termsAndConditionsDisclosure.label + ' ' + termsAndConditionsDisclosure.disclosure }}
          </button>
          <a
            target="_blank"
            [href]="privacyPolicyDisclosure.link"
            mat-button
            color="primary"
            class="c-agreement__disclosure-label button-link-style"
            >{{ privacyPolicyDisclosure.label }}</a
          >

          <!-- custom disclosures end -->
          <app-agreement-disclosures
            [disclosures]="disclosures"
            (dialogOpened)="openDialog($event)"
          ></app-agreement-disclosures>
        </div>
      </app-form-section>
      <app-form-validation [validatorType]="backendGeneral"></app-form-validation>
    </form>
    <app-form-step-controls
      class="card-print-none"
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
