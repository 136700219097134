// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Lead } from '../../models';
import { QuestionValues } from '../../../questions/models';
import { CasCustomerData, OpportunityData } from 'fakesl/src/models/generated/leadmanagementservice.model';
import { ExpressLaneData } from '../reducers';

export enum LeadActionTypes {
  CreateLeadSuccess = '[Lead] Create Lead Success',
  CreateLeadPlaceHolderSuccess = '[Lead] Create Lead Place Success',
  CreateLeadFail = '[Lead] Create Lead Fail',
  LoadLead = '[Lead] Load Lead',
  LoadLeadByParams = '[Lead] Load Lead (By Params)',
  LoadLeadSuccess = '[Lead] Load Lead Success',
  LoadLeadFail = '[Lead] Load Lead Fail',
  UpdateLead = '[Lead] Update Lead',
  UpdateLeadSuccess = '[Lead] Update Lead Success',
  UpdateLeadFail = '[Lead] Update Lead Fail',
  UpdateOrCreateLead = '[Lead] Update Or Create Lead;',
  // LeadLoadProcess = '[Lead] Load Process',
  // LeadLoadProcessSuccess = '[Lead] Load Process Success',
  ClearLead = '[Lead] Clear Lead',
  ValidateKcfVerifyIdentity = '[Lead] Validate KCF Verify Identity',
  ValidateKcfVerifyIdentitySuccess = '[Lead] Validate KCF Verify Identity Success',
  ValidateKcfVerifyIdentityFail = '[Lead] Validate KCF Verify Identity Fail',
  ClearLeadExtraData = '[Lead] Clear Lead Extra Data',
}

export const createLeadSuccess = createAction(
  LeadActionTypes.CreateLeadSuccess,
  props<{
    lead?: Partial<Lead>;
    casCustomerData?: CasCustomerData;
    opportunityData?: OpportunityData;
    expressLaneData?: ExpressLaneData;
  }>()
);

export const createLeadPlaceHolderSuccess = createAction(LeadActionTypes.CreateLeadPlaceHolderSuccess);

export const createLeadFail = createAction(LeadActionTypes.CreateLeadFail, props<{ error: Record<string, any> }>());

// load lead
export const loadLead = createAction(LeadActionTypes.LoadLead, props<{ leadId: string }>());

export const loadLeadByParams = createAction(
  LeadActionTypes.LoadLeadByParams,
  props<{ emailAddress: string; offerCode: string }>()
);

export const loadLeadSuccess = createAction(LeadActionTypes.LoadLeadSuccess, props<{ lead: Lead }>());

export const loadLeadFail = createAction(LeadActionTypes.LoadLeadFail, props<{ error: Record<string, any> }>());

// update lead
export const updateLead = createAction(
  LeadActionTypes.UpdateLead,
  props<{ questionValues: QuestionValues; extraQuestionsValues?: any }>()
);

export const updateLeadSuccess = createAction(LeadActionTypes.UpdateLeadSuccess, props<{ lead: Lead }>());

export const updateLeadFail = createAction(LeadActionTypes.UpdateLeadFail, props<{ error: Record<string, any> }>());

// update OR create lead
export const updateOrCreateLead = createAction(LeadActionTypes.UpdateOrCreateLead);

// validate kcf verify identity
export const validateKcfVerifyIdentity = createAction(
  LeadActionTypes.ValidateKcfVerifyIdentity,
  props<{ formValue: Record<string, any> }>()
);

export const validateKcfVerifyIdentitySuccess = createAction(LeadActionTypes.ValidateKcfVerifyIdentitySuccess);

export const validateKcfVerifyIdentityFail = createAction(
  LeadActionTypes.ValidateKcfVerifyIdentityFail,
  props<{ error: any; retryLimitReached?: boolean }>()
);

export const clearLeadExtraData = createAction(LeadActionTypes.ClearLeadExtraData);

// lead load process
// export const leadLoadProcess = createAction(
//   LeadActionTypes.LeadLoadProcess,
//   props<{ applicationMeta: ApplicationMeta }>()
// );

// export const leadLoadProcessSuccess = createAction(
//   LeadActionTypes.LeadLoadProcessSuccess
// );

export const clearLead = createAction(LeadActionTypes.ClearLead);

export type LeadAction = ReturnType<
  | typeof createLeadSuccess
  | typeof createLeadFail
  | typeof loadLead
  | typeof loadLeadByParams
  | typeof loadLeadSuccess
  | typeof loadLeadFail
  | typeof updateLead
  | typeof updateLeadFail
  | typeof updateLeadSuccess
  | typeof updateOrCreateLead
  // | typeof leadLoadProcess
  // | typeof leadLoadProcessSuccess
  | typeof clearLead
  | typeof validateKcfVerifyIdentity
  | typeof validateKcfVerifyIdentitySuccess
  | typeof validateKcfVerifyIdentityFail
  | typeof clearLeadExtraData
>;
