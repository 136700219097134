import { Component, Input } from '@angular/core';
import { TextBubbleTypes } from '../../configs/text-bubble-types';

@Component({
  selector: 'app-text-bubble',
  templateUrl: './text-bubble.component.html',
  styleUrls: ['./text-bubble.component.scss'],
})
export class TextBubbleComponent {
  @Input() designType: TextBubbleTypes;
  iconAssetMap = {
    [TextBubbleTypes.NOTIFICATION]: 'information-circle-bold',
    [TextBubbleTypes.ERROR]: 'error-diamond-bold',
    [TextBubbleTypes.TAG]: 'tag',
  };
}
