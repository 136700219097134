<ng-container *ngIf="applicationLoadProcessSuccess$ | async; else loading">
  <div id="c-application" class="c-application" [class.no-padding]="affiliateLandingStep === (stepName$ | async)">
    <!-- need to remove class if user on affiliate landng page, otherwise content can't spam to view width -->
    <div [ngClass]="{ 'c-application__content': affiliateLandingStep !== (stepName$ | async) }">
      <app-application-stepper
        [stepName$]="stepName$"
        [affiliateLandingStep]="affiliateLandingStep"
      ></app-application-stepper>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
