// imports
import { CustomerApplicationPreferences } from '../../../../../fakesl/src/models/generated/applicationservice.model';
import {
  ABTestWeightDiceRollResult,
  CasCustomerData,
  OpportunityData,
} from '../../../../../fakesl/src/models/generated/leadmanagementservice.model';
import { PrimaryApplicant } from '../../application/models';
import { CardProductOffer, CardProspectOffer } from '../../offers/models';

export interface DigitalTrackingData {
  ipAddress?: string;
  blackBoxValue?: string;
  campaign?: string;
  productCode?: string;
  // UTM params
  term?: string;
  content?: string;
  medium?: string;
  source?: string;
  // offerType camel case whereas in url param it's lowercase,
  // don't use the AffiliateParams enum here
  offerType?: string;
  [AffiliateParams.tid]?: string;
  [LightboxSpecificParams.subTrackingId]?: string;
  [LightboxSpecificParams.sourceCode]?: string;
  [LightboxSpecificParams.offerName]?: string;
  [LightboxSpecificParams.offerVariant]?: string;
  [LightboxSpecificParams.affiliateId]?: string;
  [LightboxSpecificParams.modelId]?: string;
}

export interface LeadCreateRequest {
  offerCode: string;
  campaignParticipantCode: string;
  channelMediumCode: string;
  prospectOfferId: string;
  productOfferId: string;
  primaryApplicant: Partial<PrimaryApplicant>;
  acceptTermsAndConditions?: boolean;
  cashAdvances?: string;
  customerApplicationPreferences?: CustomerApplicationPreferences;
}

export enum AffiliateParams {
  offerType = 'offertype',
  tid = 'tid',
}

export enum LightboxSpecificParams {
  affiliateId = 'affiliateid',
  channel = 'channel',
  offerName = 'offername',
  offerVariant = 'offervariant',
  sourceCode = 'sourcecode',
  subTrackingId = 'subid',
  modelId = 'modelid',
}

export enum LightboxOfferTypes {
  LBEXB = 'LBEXB', // “LBEXB” which aligns to the Excellent Badge.
  LBOSB = 'LBOSB', // “LBOSB” represents the Outstanding Badge.
}

export enum CreditKarmaOfferTypes {
  ITA = 'ITA',
}

export enum BestEggOfferTypes {
  WalkupEmail = 'walkup-email',
  WalkupNoEmail = 'walkup-no-email',
}

export interface LeadRequestV2 {
  offerCode?: string;
  primaryApplicant: Partial<PrimaryApplicant>;
  campaignParticipantCode?: string;
  digitalTrackingData?: DigitalTrackingData;
  incentiveCpid?: string;
}

export interface Lead {
  acceptTermsAndConditions: boolean;
  campaignParticipantCode: string;
  cashAdvances: string;
  channelId: string;
  channelMediumName: string;
  channelName: string;
  cid: string;
  cpid: string;
  createdDate: string;
  digitalTrackingData: DigitalTrackingData;
  id?: string;
  isActive: boolean;
  isPrimary: boolean;
  isResume: boolean;
  lastUsedBrowser: string;
  lastUsedDevice: string;
  offerCode: string;
  primaryApplicant: Partial<PrimaryApplicant>;
  product?: any;
  productOfferId: string;
  prospect?: any;
  prospectOfferId: string;
  referrer: string;
  updatedDate: string;
  abTestList: ABTestWeightDiceRollResult[];
  insertUser?: string;
  modifiedUser?: string;
  customerId?: string;
  applicationId?: string;

  channelCode?: string;
  channelMediumCode?: string;
  digitalProspectId?: string;
  customerApplicationPreferences?: CustomerApplicationPreferences;
  emplNameMatchPrediction?: number;
  emplNameMatchMethod?: string;
  resolvedCampaignParticipantCode?: string;
}

export interface LeadResponseV2 extends KcfLeadData, ExpressLaneLeadData, OpportunityLeadData {
  lead: Partial<Lead>;
  productOffer: CardProductOffer;
  prospectOffer: CardProspectOffer;
  abTests: ABTestWeightDiceRollResult[];
}
export interface KcfLeadData {
  casCustomerData?: CasCustomerData;
}

export interface ExpressLaneLeadData {
  auth?: boolean;
  kcf?: boolean;
}

export interface OpportunityLeadData {
  opportunityData?: OpportunityData;
  leadId?: string;
}
