// vendor
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, debounceTime, tap } from 'rxjs/operators';
// ngrx
import { Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromValidationsActions from '../actions/validations.actions';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
// services
import { QuestionsService } from '../../services';
// models
import { AddressValidation } from '../../../questions/models';
// consts
const DEBOUNCE_TIME = 500;

@Injectable({
  providedIn: 'root',
})
export class ValidationsEffects {
  constructor(
    private questionsService: QuestionsService,
    private action$: Actions,
    private store: Store<fromDigitalExperienceStore.DigitalExperienceState>
  ) {}

  // validate address
  validateAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromValidationsActions.validateAddress),
      debounceTime(DEBOUNCE_TIME),
      switchMap((action) =>
        this.questionsService.validateAddress(action.address, action.poBoxAllowedStatus).pipe(
          map((addressValidation: AddressValidation) =>
            fromValidationsActions.validateAddressSuccess({
              addressValidation,
            })
          ),
          catchError((error: Record<string, unknown>) =>
            of(
              fromValidationsActions.validateAddressFail({
                error,
              })
            )
          )
        )
      )
    )
  );
}
