// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { ApplicationStep, ApplicationStepNameTypes, ApplicationStepSettings } from '../../models';
import { Map } from '../../../shared/models';
import { IAbTestData } from '../../../ab-tests/models';
import { Update } from '@ngrx/entity/src/models';
import { LeadResponseV2 } from 'src/app/features/lead/models';

export enum DigitalExperienceActionTypes {
  LoadDigitalExperience = '[Digital Experience] Load Digital Experience',
  LoadDigitalExperienceSuccess = '[Digital Experience] Load Digital Experience Success',
  LoadDigitalExperienceFail = '[Digital Experience] Load Digital Experience Fail',
  SetDigitalExperienceAppSteps = '[Digital Experience] Set Digital Experience App Steps',
  SetApplicationStep = '[Digital Experience] Set Application Step',
  SetApplicationStepDirection = '[Digital Experience] Set Application Step Direction',
  UpdateStepsAllowed = '[Digital Experience] Update Steps Allowed',
  UpdateStepsContent = '[Digital Experience] Update Steps Content',
  UpdateDigitalExperience = '[Digital Experience] Update Digital Experience',
  UpdateDigitalExperienceSuccess = '[Digital Experience] Update Digital Experience Success',
  ClearDigitalExperience = '[Digital Experience] Clear Digital Experience',
  SetDigitalExperienceName = '[Digital Experience] Set Digital Experience Name',
  SetStepAndUpdateStepsAllowed = '[Digital Experience] Set Step And StepsAllowed in between steps',
  SetDigitalExperienceNameSuccess = '[Digital Experience] Set Digital Experience Name Success',
}

// load digital experience
export const loadDigitalExperience = createAction(
  DigitalExperienceActionTypes.LoadDigitalExperience,
  props<{ cardProductOfferId?: string }>()
);

export const setDigitalExperienceAppSteps = createAction(
  DigitalExperienceActionTypes.SetDigitalExperienceAppSteps,
  props<{ applicationSteps: ApplicationStep[] }>()
);

export const loadDigitalExperienceSuccess = createAction(
  DigitalExperienceActionTypes.LoadDigitalExperienceSuccess,
  props<{ stepComponentMap: Map<string> }>()
);

export const loadDigitalExperienceFail = createAction(
  DigitalExperienceActionTypes.LoadDigitalExperienceFail,
  props<{ error: Record<string, any> }>()
);

export const setApplicationStep = createAction(
  DigitalExperienceActionTypes.SetApplicationStep,
  props<{ payload: { type: string; stepName?: string; forceFirstStep?: boolean } }>()
);

export const setApplicationStepDirection = createAction(
  DigitalExperienceActionTypes.SetApplicationStepDirection,
  props<{ payload: string }>()
);

export const updateStepsAllowed = createAction(
  DigitalExperienceActionTypes.UpdateStepsAllowed,
  props<{ payload: ApplicationStepSettings }>()
);

export const updateStepsContent = createAction(
  DigitalExperienceActionTypes.UpdateStepsContent,
  props<{ payload: Update<ApplicationStep>[] }>()
);

/**
 * This would be dispatched as part of our initial load process.
 * We can do any number of things that might update the digital experience based on the a/b test data.
 * Once the digital experience is fully updated, the last action returned from this effect would be `UpdateDigitalExperienceSuccess`.
 */
export const updateDigitalExperience = createAction(DigitalExperienceActionTypes.UpdateDigitalExperience);
export const updateDigitalExperienceSuccess = createAction(DigitalExperienceActionTypes.UpdateDigitalExperienceSuccess);

/**
 * Similar to updateDigitalExperience. But only set current step and/or modify steps allowed during the apply process.
 * dispatch this action in scenarios like kcf failure path, or verify identity step user click 'Not xxx?'
 */
export const setStepAndUpdateStepsAllowed = createAction(
  DigitalExperienceActionTypes.SetStepAndUpdateStepsAllowed,
  props<{ skipStepsChange: boolean; metaData?: SetStepAndUpdateStepsAllowedMetaData }>()
);
export interface SetStepAndUpdateStepsAllowedMetaData {
  stepName: keyof SetStepAndUpdateStepsAllowedActionSubType;
  actionSubType: SetStepAndUpdateStepsAllowedActionSubType[keyof SetStepAndUpdateStepsAllowedActionSubType];
}

interface SetStepAndUpdateStepsAllowedActionSubType {
  [ApplicationStepNameTypes.VerifyIdentity]: 'verifySuccess' | 'verifyFail';
}

export const setDigitalExperienceName = createAction(
  DigitalExperienceActionTypes.SetDigitalExperienceName,
  props<{ leadRes: LeadResponseV2 }>()
);

export const setDigitalExperienceNameSuccess = createAction(
  DigitalExperienceActionTypes.SetDigitalExperienceNameSuccess,
  props<{ name: string }>()
);

// clear digital experience
export const clearDigitalExperience = createAction(DigitalExperienceActionTypes.ClearDigitalExperience);

export type DigitalExperienceAction = ReturnType<
  | typeof loadDigitalExperience
  | typeof setDigitalExperienceAppSteps
  | typeof loadDigitalExperienceSuccess
  | typeof loadDigitalExperienceFail
  | typeof setApplicationStep
  | typeof setApplicationStepDirection
  | typeof updateStepsAllowed
  | typeof updateStepsContent
  | typeof updateDigitalExperience
  | typeof updateDigitalExperienceSuccess
  | typeof clearDigitalExperience
  | typeof setDigitalExperienceName
  | typeof setStepAndUpdateStepsAllowed
  | typeof setDigitalExperienceNameSuccess
>;
