// vendor
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, Params, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';
// ngrx
import { RouterStateSerializer } from '@ngrx/router-store';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  extras: NavigationExtras;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  constructor(private router: Router) {}

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route: ActivatedRouteSnapshot = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params } = route;
    const extras = this.router.getCurrentNavigation()?.extras || {};
    return { url, params, queryParams, extras };
  }
}
