// vendor
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// store
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromOffersActions from '../actions';
import * as fromQuestionsStore from '../../../questions/store';
// services
import { OffersService } from '../../services';
// models
import { CardProspectOffer } from '../../models';
import { QuestionNameTypes } from '../../../questions/models';

@Injectable({
  providedIn: 'root',
})
export class CardProspectOffersEffects {
  constructor(private offersService: OffersService, private action$: Actions) {}

  // load card prospect offer by id
  loadCardProspectOfferById$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProspectOfferById),
      switchMap((action: ReturnType<typeof fromOffersActions.loadCardProspectOfferById>) =>
        this.offersService.getCardProspectOfferById(action.cardProspectOfferId).pipe(
          map((cardProspectOffer: CardProspectOffer) =>
            fromOffersActions.loadCardProspectOfferSuccess({
              cardProspectOffer,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              fromOffersActions.loadCardProspectOfferFail({
                error,
              })
            )
          )
        )
      )
    )
  );

  // load card prospect offer by offer code
  loadCardProspectOfferByOfferCode$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProspectOfferByOfferCode),
      switchMap((action) => {
        const { offerCode } = action;
        return this.offersService.getCardProspectOfferByOfferCode(offerCode).pipe(
          map((cardProspectOffer: CardProspectOffer) =>
            fromOffersActions.loadCardProspectOfferSuccess({
              cardProspectOffer,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              fromOffersActions.loadCardProspectOfferFail({
                error,
              })
            )
          )
        );
      })
    )
  );

  // load card prospect offer by params
  loadCardProspectOfferByParams$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProspectOfferByParams),
      switchMap((action: ReturnType<typeof fromOffersActions.loadCardProspectOfferByParams>) => {
        const { emailAddress, offerCode } = action;
        return this.offersService.getCardProspectOfferByParams(emailAddress, offerCode).pipe(
          map((cardProspectOffer: CardProspectOffer) =>
            // see below effect on success action
            fromOffersActions.loadCardProspectOfferSuccess({
              cardProspectOffer,
            })
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              fromOffersActions.loadCardProspectOfferFail({
                error,
              })
            )
          )
        );
      })
    )
  );

  // sync card prospect to questions
  syncCardProspectToQuestions$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.syncCardProspectToQuestions),
      switchMap((action) => {
        const prospect = action.prospect;
        const { firstFive, plusFour } = prospect.primaryAddress.zip || {};
        const questionValues = {
          [QuestionNameTypes.FirstName]: prospect.fullName.firstName,
          [QuestionNameTypes.LastName]: prospect.fullName.lastName,
          [QuestionNameTypes.Address]: prospect.primaryAddress.addressLineOne,
          [QuestionNameTypes.City]: prospect.primaryAddress.city,
          [QuestionNameTypes.State]: prospect.primaryAddress.state,
          [QuestionNameTypes.ZipCode]: firstFive ? firstFive.concat(plusFour || '') : null,
        };
        return [fromQuestionsStore.updateQuestionValues({ questionValues })];
      })
    )
  );
}
