// vendor
import { Inject, Injectable, Optional } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { RewardsProgramId } from 'src/app/features/offers/models';
import { CARD_FEATURE_SERVICE_CONSUMER, cardFeatures } from 'src/app/features/shared/configs';
import {
  selectCardProductOfferClientProductCode,
  selectCardProductOfferRewardsProgramId1,
  selectCardProductOfferRewardsProgramId2,
} from '../../../features/offers/store';
import * as fromOffersStore from '../../offers/store';

const consumerMap = {
  'approve-dashboard': {
    [RewardsProgramId.MRW00001]: '1% unlimited cash back',
    [RewardsProgramId.MRW00002]: '1% unlimited cash back on payments (made on your Best Egg Credit Card)',
    [RewardsProgramId.MRW00003]: '1.5% unlimited cash back',
    [RewardsProgramId.PAYMENT1]: '1.5% cash back on eligible payments for the first 6 months*',
  },
  'opportunity-microfunnel': {
    [RewardsProgramId.MRW00001]: '1% unlimited cash back rewards',
    [RewardsProgramId.MRW00002]: '1% unlimited cash back on payments (made on your Best Egg Credit Card)',
    [RewardsProgramId.MRW00003]: '1.5% unlimited cash back rewards',
  },
};

// This service does not injected in root level, please make sure to inject it into the module or component
// where it's needed.
@Injectable()
export class CardFeaturesService {
  currentConsumer: string;

  rewardOffer$ = combineLatest([
    this.store.pipe(select(selectCardProductOfferClientProductCode)),
    this.store.pipe(select(selectCardProductOfferRewardsProgramId1)),
  ]).pipe(
    map(([clientProductCode, rewardsProgramId]) => {
      // rewardProgramId1 (ARQ1) should not be mapped to PAYMENT1
      if (clientProductCode !== 'MRW' || rewardsProgramId === RewardsProgramId.PAYMENT1) {
        return null;
      }
      return consumerMap[this.currentConsumer][rewardsProgramId];
    })
  );

  introOffer$ = combineLatest([
    this.store.pipe(select(selectCardProductOfferClientProductCode)),
    this.store.pipe(select(selectCardProductOfferRewardsProgramId2)),
  ]).pipe(
    map(([clientProductCode, rewardsProgramId]) => {
      // rewardProgramId2 (ARQ2) should be mapped to PAYMENT1, not other value
      if (clientProductCode !== 'MRW' || rewardsProgramId !== RewardsProgramId.PAYMENT1) {
        return null;
      }
      return consumerMap[this.currentConsumer][rewardsProgramId];
    })
  );

  cardFeatures$ = this.rewardOffer$.pipe(
    map((rewardOfferBase) => {
      const result = cloneDeep(cardFeatures);
      if (rewardOfferBase) {
        result.value.unshift(rewardOfferBase);
      }
      return result;
    })
  );

  cardProductOfferPromotionalApr$ = this.store.pipe(select(fromOffersStore.selectCardProductOfferPromotionalApr));

  constructor(
    private readonly store: Store,
    @Inject(CARD_FEATURE_SERVICE_CONSUMER) @Optional() private consumer: string
  ) {
    this.currentConsumer = consumer || 'approve-dashboard';
  }
}
