// ngrx
import * as fromCoreReducers from '../../core/store/reducers';
const { data: coreData } = fromCoreReducers.initialState;

export const applicationErrorMessages = {
  applicationLoadProcessFail: `
  We were unable to retrieve your offer. Please try again or contact us at ${coreData.contactPhoneNumber}`,
  // eslint-disable-next-line max-len
  loadApplicationPollingLimit: `Processing your request is taking longer than expected, please contact us at ${coreData.contactPhoneNumber} or check back later.`,
};
