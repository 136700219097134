// imports
import { FormButtonComponent } from './form-button/form-button.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormHelpTextComponent } from './form-help-text/form-help-text.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormRadioGroupComponent } from './form-radio-group/form-radio-group.component';
import { FormSelectDropdownComponent } from './form-select-dropdown/form-select-dropdown.component';
import { FormValidationComponent } from './form-validation/form-validation.component';
import { FormCheckboxGroupComponent } from './form-checkbox-group/form-checkbox-group.component';
import { FormAutocompleteComponent } from './form-autocomplete/form-autocomplete.component';
// exports
export * from './form-checkbox/form-checkbox.component';
export * from './form-checkbox-group/form-checkbox-group.component';
export * from './form-help-text/form-help-text.component';
export * from './form-input/form-input.component';
export * from './form-radio-group/form-radio-group.component';
export * from './form-select-dropdown/form-select-dropdown.component';
export * from './form-validation/form-validation.component';
export * from './form-autocomplete/form-autocomplete.component';
// exports group
export const components: any[] = [
  FormButtonComponent,
  FormCheckboxComponent,
  FormCheckboxGroupComponent,
  FormHelpTextComponent,
  FormInputComponent,
  FormRadioGroupComponent,
  FormSelectDropdownComponent,
  FormValidationComponent,
  FormAutocompleteComponent,
];
