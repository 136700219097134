// vendor
import { Component, OnInit } from '@angular/core';
// component
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';
// models
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WithFormGroupErrorStateMatcher } from '../../../services';

@Component({
  selector: 'app-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['../form-fields.scss', 'form-autocomplete.scss'],
})
export class FormAutocompleteComponent extends BaseFormFieldComponent implements OnInit {
  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterOptions(value || ''))
    );
    const validators = [...(this.question.validators.sync || []), ...(this.question.validators.async || [])];
    this.matcher = new WithFormGroupErrorStateMatcher(validators);
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toUpperCase();
    return this.question.options.reduce((acc, option) => {
      if (option.value.startsWith(filterValue, 0) || option.label.toUpperCase().startsWith(filterValue, 0)) {
        acc.push(option.label);
      }
      return acc;
    }, []);
  }
}
