// vendor
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import * as fromFunnelSessionsStore from './store';
// containers
import * as fromContainers from './containers';
import * as fromCoreContainers from '../core/containers';
import * as fromApplicationContainers from '../application/containers';
// components
import * as fromCoreComponents from '../core/components';
// guard
import { MainGuardService } from './guards';
// configs
import {
  MAINTENANCE_URL_SEGMENT,
  UNAVAILABLE_URL_SEGMENT,
  TIMEOUT_URL_SEGMENT,
  CREDIT_PROFILE_AUTHORIZATION_SEGMENT,
  REWARDS_TERMS_CONDITIONS_SEGMENT,
  E_SIGN_CONSENT_SEGMENT,
} from './configs';

export const mainRoutes: Routes = [
  {
    path: '',
    component: fromContainers.MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'application',
        pathMatch: 'full',
      },
      // application module imported in main module, since used by other modules
      {
        path: 'application',
        component: fromApplicationContainers.ApplicationComponent,
      },
      {
        path: 'decision',
        loadChildren: async () => (await import('../decision/decision.module')).DecisionModule,
      },
      {
        path: 'dashboard',
        loadChildren: async () => (await import('../dashboard/dashboard.module')).DashboardModule,
      },
      {
        path: MAINTENANCE_URL_SEGMENT,
        component: fromCoreComponents.MaintenanceComponent,
      },
      {
        path: UNAVAILABLE_URL_SEGMENT,
        component: fromCoreContainers.UnavailableComponent,
      },
      {
        path: TIMEOUT_URL_SEGMENT,
        component: fromCoreContainers.TimeoutComponent,
      },
      {
        path: CREDIT_PROFILE_AUTHORIZATION_SEGMENT,
        loadComponent: () =>
          import(
            '../../stand-alone-components/credit-profile-authorization/credit-profile-authorization.component'
          ).then((m) => m.CreditProfileAuthorizationComponent),
      },
      {
        path: REWARDS_TERMS_CONDITIONS_SEGMENT,
        loadComponent: () =>
          import('../../stand-alone-components/rewards-terms-conditions/rewards-terms-conditions.component').then(
            (m) => m.RewardsTermsConditionsComponent
          ),
      },
      {
        path: E_SIGN_CONSENT_SEGMENT,
        loadComponent: () =>
          import('../../stand-alone-components/e-sign-consent/e-sign-consent.component').then(
            (m) => m.ESignConsentComponent
          ),
      },
      {
        path: '**',
        redirectTo: UNAVAILABLE_URL_SEGMENT,
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forChild(mainRoutes),
    StoreModule.forFeature(fromFunnelSessionsStore.SESSIONS_FEATURE_KEY, fromFunnelSessionsStore.reducers),
  ],
  exports: [RouterModule],
})
export class MainRoutingModule {}
