import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Option, QuestionOptionTypes } from '../../../models';
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';

@Component({
  selector: 'app-form-checkbox-group',
  templateUrl: './form-checkbox-group.component.html',
  styleUrls: ['../form-fields.scss', './form-checkbox-group.component.scss'],
})
export class FormCheckboxGroupComponent extends BaseFormFieldComponent implements OnInit {
  selectedChoicesSet: Set<string>;

  ngOnInit() {
    super.ngOnInit();
    this.setCheckboxes();
  }

  setCheckboxes(): void {
    const controlValue = this.parent.controls[this.question.name].value;
    this.selectedChoicesSet = controlValue ? new Set(controlValue) : new Set();
  }

  isChecked(option: Option): boolean {
    return this.selectedChoicesSet.has(option.value);
  }

  onChange(event: MatCheckboxChange) {
    const controlValue = this.getMultiSelectValue(event.source.value);
    this.formControl.setValue(controlValue);
  }

  /**
   * get multi select
   * This was taken from the FormSelectChipComponent, with only a minor alteration.
   *
   * Checkboxes or normally a single form element with a value that is either checked or not,
   * unlike a radio button group that is a single form element that has the value of whatever
   * option is checked.
   * To submit a single form value, which is what is expected, the values of the checked
   * are turned into a set to add/remove/whatever, then spread into an array and set into the
   * formControls value. That is submitted to the backend as:
   *
   * { banking: [ 'CHECKING', 'SAVINGS', 'RETIREMENT' ] }
   *
   */
  private getMultiSelectValue(optionValue: string): any {
    if (this.selectedChoicesSet.has(optionValue)) {
      this.selectedChoicesSet.delete(optionValue);
    } else {
      this.selectedChoicesSet.add(optionValue);
    }
    if (this.selectedChoicesSet.has(QuestionOptionTypes.None)) {
      if (optionValue === QuestionOptionTypes.None) {
        this.selectedChoicesSet.clear();
        this.selectedChoicesSet.add(QuestionOptionTypes.None);
      } else {
        this.selectedChoicesSet.delete(QuestionOptionTypes.None);
      }
    }
    return [...this.selectedChoicesSet];
  }
}
