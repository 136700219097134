<header id="c-header" class="c-header" role="banner">
  <button class="c-header__skiplink" (click)="skip()">Skip to main content</button>
  <div class="c-header__content">
    <div class="l-container l-container--left">
      <a class="c-header__logo-link" [href]="logoUrl" target="_blank">
        <img
          class="c-header__logo-link-img"
          src="assets/logos/best-egg/best-egg-logo-wordmark.svg"
          alt="best egg logo"
        />
      </a>
      <div class="c-header__product-label">Credit Card</div>
    </div>
    <div class="l-container l-container--right l-rgi">
      <!-- content here -->
    </div>
  </div>
</header>
