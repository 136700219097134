// vendor
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash-es';
// ngrx
import * as fromQuestionsModels from '../../../../questions/models';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import { applicationDialogComponentMap } from '../../application-dialogs';
// models
import { ApplicationDialogTypes } from '../../../models';
// models
import { Disclosure } from '../../../models';
// configs
import { environment } from '../../../../../../environments/environment';
import {
  CREDIT_PROFILE_AUTHORIZATION_SEGMENT,
  E_SIGN_CONSENT_SEGMENT,
  MAIN_URL_SEGMENT,
} from 'src/app/features/main/configs';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent extends BaseApplicationStepComponent implements OnInit {
  disclosures: Disclosure[];
  dialogTypes: any;
  privacyPolicyDisclosure: Disclosure;
  termsAndConditionsDisclosure: Disclosure;
  questionNames: string[];
  backendGeneral: fromQuestionsModels.ValidatorTypes = fromQuestionsModels.ValidatorTypes.BackendGeneral;
  shouldShowSsn$: Observable<boolean>;
  shouldShowPanel = false;

  constructor(public injector: Injector, public dialog: MatDialog) {
    super(injector);
    this.dialogTypes = ApplicationDialogTypes;
  }

  ngOnInit() {
    super.ngOnInit();
    this.setDisclosures();
  }

  /**
   * set disclosures
   */
  setDisclosures(): void {
    this.privacyPolicyDisclosure = {
      label: 'Bank Partners Privacy Notices',
      disclosure: null,
      link: `http://${environment.bestEggDomain}/privacy-policy/`,
    };
    this.termsAndConditionsDisclosure = {
      label: 'The Terms and Conditions',
      dialog: ApplicationDialogTypes.ApplicationTerms,
      disclosure: 'of the Best Egg Credit Card',
    };
    this.disclosures = [
      {
        label: 'Credit Profile Authorization',
        disclosure: `Your credit profile will be accessed upon submission,
        which could impact your credit score if you are approved. Rest assured,
        your credit score will
        not be impacted if we’re unable to approve you for a Best Egg Credit Card.`,
        link: `/${MAIN_URL_SEGMENT}/${CREDIT_PROFILE_AUTHORIZATION_SEGMENT}/`,
      },
    ];
  }

  openConsentLink() {
    this.windowUtilService.nativeWindow.open(`/${MAIN_URL_SEGMENT}/${E_SIGN_CONSENT_SEGMENT}`);
  }

  /**
   * open dialog
   */
  openDialog(dialogType: string) {
    const dialogComponent = applicationDialogComponentMap[dialogType];
    this.dialog.open(dialogComponent);
  }
}
