// models
import * as fromQuestionsModels from '../../models';

export const employmentStatusQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Select,
  name: fromQuestionsModels.QuestionNameTypes.EmploymentStatus,
  label: 'Employment status',
  placeholder: 'Employment status',
  optionType: fromQuestionsModels.QuestionOptionTypes.EmploymentStatuses,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select employment status',
      },
    ],
  },
};
