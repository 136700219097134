import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { AbTestStatuses } from '../../models';
import { IAbTestData, IAbTestDataSingle, IAbTestsMetadata } from '../../models/ab-tests.model';
import { getDefaultAbTestData } from '../../services/helpers/get-default-ab-test-data';
import {
  AbTestsAction,
  clearAbTests,
  loadAbTestsSuccess,
  overrideAbTests,
  updateAbTestsStatusesSuccess,
} from '../actions';

// state
export interface AbTestsState {
  data: IAbTestData;

  /**
   * store things like lead id here. We'd need to reference the lead id for things like status updates,
   * but probably wouldn't want to import the lead module just for this.
   */
  metadata: IAbTestsMetadata;

  error: HttpErrorResponse;
  loaded: boolean;
  override: boolean;
}

export const initialState: AbTestsState = {
  data: getDefaultAbTestData(),
  metadata: null,
  error: null,
  loaded: false,
  override: false,
};

// reducer
const abTestsReducer = createReducer(
  initialState,
  on(loadAbTestsSuccess, (state: AbTestsState, action) => {
    const { abTestResponseData, metadata } = action;

    const currentAbTestData = state.data;

    /** Convert the array of a/b tests to an object where the test name maps to the population and status */
    const newAbTestData: IAbTestData = abTestResponseData?.reduce((acc: IAbTestData, curr) => {
      acc[curr.testName] = {
        population: curr.population,
        status: curr.status,
        providedInLeadResponse: true,
      };

      return acc;
    }, {});

    return {
      ...state,
      data: { ...currentAbTestData, ...newAbTestData },
      metadata,
      loaded: true,
      override: false,
    };
  }),
  on(updateAbTestsStatusesSuccess, (state: AbTestsState, action) => {
    const updatedData = Object.entries(action.abTestStatusesUpdates).reduce((acc, [testName, updatedStatus]) => {
      acc[testName] = {
        ...state.data[testName],
        status: updatedStatus,
      };

      return acc;
    }, {});

    return {
      ...state,
      data: {
        ...state.data,
        ...updatedData,
      },
    };
  }),
  on(clearAbTests, () => initialState),
  on(overrideAbTests, (state: AbTestsState, action) => {
    const { metadata, overrideTestData } = action;
    return {
      ...state,
      data: { ...state.data, ...overrideTestData },
      metadata,
      loaded: true,
      override: true,
      error: null,
    };
  })
);

export const reducer = (state: AbTestsState, action: AbTestsAction) => abTestsReducer(state, action);
