import { Component } from '@angular/core';
import { MAIN_URL_SEGMENT, REWARDS_TERMS_CONDITIONS_SEGMENT } from 'src/app/features/main/configs';

@Component({
  selector: 'app-card-rewards-link',
  templateUrl: './card-rewards-link.component.html',
  styleUrls: ['./card-rewards-link.component.scss'],
})
export class CardRewardsLinkComponent {
  termsAndConditionsLink = `/${MAIN_URL_SEGMENT}/${REWARDS_TERMS_CONDITIONS_SEGMENT}/`;
}
