<div class="c-dialog c-communications-consent-dialog">
  <div class="c-dialog__header-actions">
    <app-dialog-close></app-dialog-close>
  </div>
  <h2 class="mat-dialog-title" cdkFocusInitial tabindex="-1">Communications Consent</h2>
  <br />
  <div class="mat-mdc-dialog-content mat-typography">
    <p>
      You agree that Best Egg and any of its affiliates, agents, service providers or assignees (and any of its
      assignee’s affiliates, agents or service providers) may call you, leave you a voice prerecorded, or artificial
      voice message, or send you a text, email, or other electronic message for any purpose related to the servicing of
      your application or any offer to extend credit; the servicing and collection of your account, for surveys or
      research, or for any other informational purpose related to your account (each a “Communication”). You agree that
      Best Egg and any of its affiliates, agents, service providers or assignees (and any of its assignee’s affiliates,
      agents or service providers) may call or text you at any telephone number associated with your application or
      account, including cellular telephone numbers, and may send an email to any email address associated with your
      account. You also agree that Best Egg and any of its affiliates, agents, service providers or assignees (and any
      of its assignee’s affiliates, agents or service providers) may include your personal information in a
      Communication and may conduct a Communication using an automatic telephone dialing system. We will not charge you
      for a Communication, but your data service provider may. In addition, you understand and agree that Best Egg and
      any of its affiliates, agents, service providers or assignees (and any of its assignee’s affiliates, agents or
      service providers) may always communicate with you in any manner permissible by law that does not require your
      prior consent.
    </p>
    <p>
      The foregoing consents will, with your further consent, be deemed to apply equally to Best Egg's lending partners
      as available from time to time.
    </p>
    <p>
      You may withdraw your consent to Communications at any time by contacting us at 833-707-1226 and PO Box 7606
      Philadelphia, PA 19101.
    </p>
  </div>
  <div class="mat-mdc-dialog-actions mat-mdc-dialog-actions--reverse">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </div>
</div>
