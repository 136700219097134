import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { TextBubbleTypes } from '../../configs/text-bubble-types';
import { Apr } from '../../models';
import { CardFeaturesService } from '../../services';
@Component({
  selector: 'app-intro-banner',
  templateUrl: './intro-banner.component.html',
  styleUrls: ['./intro-banner.component.scss'],
  providers: [CardFeaturesService],
})
export class IntroBannerComponent {
  cardProductOfferPromotionalApr$: Observable<Apr> = this.cardFeaturesService.cardProductOfferPromotionalApr$;
  introOffer$ = this.cardFeaturesService.introOffer$;
  TextBubbleTypes = TextBubbleTypes;

  constructor(private cardFeaturesService: CardFeaturesService) {}
}
