// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardBrandReducer from '../reducers/card-brand.reducer';

// card brand state
export const selectCardBrandState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.cardBrand
);

// data
export const selectCardBrandData = createSelector(selectCardBrandState, fromCardBrandReducer.selectCardBrandStateData);

// error
export const selectCardBrandError = createSelector(
  selectCardBrandState,
  fromCardBrandReducer.selectCardBrandStateError
);

// loaded
export const selectCardBrandLoaded = createSelector(
  selectCardBrandState,
  fromCardBrandReducer.selectCardBrandStateLoaded
);

// loading
export const selectCardBrandLoading = createSelector(
  selectCardBrandState,
  fromCardBrandReducer.selectCardBrandStateLoading
);
