// models
import * as fromQuestionsModels from '../../models';

export const secondaryPhoneTypeQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.RadioGroup,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  name: fromQuestionsModels.QuestionNameTypes.SecondaryPhoneType,
  label: 'Other Phone Type',
  options: [
    {
      label: 'Home',
      value: 'HOME',
    },
    {
      label: 'Work',
      value: 'WORK',
    },
  ],
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.SecondaryPhoneType,
        message: 'Please select alternative phone type',
        readonly: true,
      },
    ],
  },
};
