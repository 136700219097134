// vendor
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// store
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromCardProductActions from '../actions/card-product.actions';
// services
import { OffersService } from '../../services';
// models
import { CardProduct } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CardProductEffects {
  constructor(private offersService: OffersService, private action$: Actions) {}

  // load card product
  loadCardProduct$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromCardProductActions.loadCardProduct),
      switchMap((action: ReturnType<typeof fromCardProductActions.loadCardProduct>) =>
        this.offersService.getCardProduct(action.cardProductId).pipe(
          map((cardProduct: CardProduct) => fromCardProductActions.loadCardProductSuccess({ cardProduct })),
          catchError((error: Record<string, any>) => of(fromCardProductActions.loadCardProductFail({ error })))
        )
      )
    )
  );
}
