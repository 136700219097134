<form class="c-dynamic-form" [formGroup]="form">
  <div
    class="c-dynamic-form__section-wrapper"
    [ngClass]="{
      'c-dynamic-form__section-wrapper-row': formConfig.layout?.orientation,
      'c-dynamic-form__section-wrapper-column': !formConfig.layout?.orientation
    }"
  >
    <app-form-section
      *ngFor="let questionSection of questionSections; let isFirst = first"
      [orientation]="!formConfig.layout?.orientation"
    >
      <!-- 
          c-dynamic-form__question-container-offset-margin class is used in confirm info step, 
          we set extra margin-top on the outer wrapper app-form-section
          so that input/select elements' label won't be covered by panel header,
          but that also creates unwanted extra space for non-input/select elements,
          hence we offset the margin here but only on the first element (use the outer loop index)
      -->
      <div
        class="c-dynamic-form__question-container"
        [ngClass]="{
          'c-dynamic-form__question-container-offset-margin':
            !['input', 'select'].includes(question.formElType) && isFirst
        }"
        *ngFor="let question of questionSection"
      >
        <ng-container
          *ngIf="question.formElType"
          dynamicFormField
          [form]="form"
          [question]="question"
          (fieldInteractionChange)="handleFieldInteractionChange($event)"
        ></ng-container>
      </div>
    </app-form-section>
  </div>

  <app-form-validation [validation]="errorValidator"></app-form-validation>

  <ng-container *ngIf="!ignoreBackendErrors">
    <app-form-validation [validatorType]="backendGeneral"></app-form-validation>
  </ng-container>
</form>
