// vendor
import { Component, Input } from '@angular/core';
// services
import { fadeAnimation } from '../../../shared/helpers/animations';
// models
import { ApplicationStep, ApplicationStepNameTypes } from '../../../digital-experience/models';
import { Store } from '@ngrx/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';

@Component({
  selector: 'app-application-step',
  templateUrl: './application-step.component.html',
  styleUrls: ['./application-step.component.scss'],
  animations: [fadeAnimation],
})
export class ApplicationStepComponent {
  @Input() hidden: boolean;
  @Input() stepDirection: string;
  @Input() applicationStep: ApplicationStep;

  constructor(private store$: Store, private EEService: EnterpriseEventTrackingService) {}

  /**
   * Display the supertitle if the `supertitle` property exists in the step data.
   */
  public get displaySupertitle() {
    return this.applicationStep.supertitle;
  }

  public get hasNoVisibleHeaderContent() {
    return !this.applicationStep.title && !this.applicationStep.subtitle;
  }

  stepAction() {
    // set step directly for kcf identify step
    if (this.applicationStep.name === ApplicationStepNameTypes.VerifyIdentity) {
      this.EEService.reportKCFAuthOptOut(this.applicationStep.actionMessage);
      this.store$.dispatch(
        fromDigitalExperienceStore.setStepAndUpdateStepsAllowed({
          skipStepsChange: false,
          metaData: {
            stepName: ApplicationStepNameTypes.VerifyIdentity,
            actionSubType: 'verifyFail',
          },
        })
      );
    }
  }
}
