export enum ButtonDesignTypes {
  // large oval grey bg - used when proceeding to next step.
  BtnNext = 'btn-next',
  // small transparent button - used when navigating to previous step.
  BtnBack = 'btn-back',
  BtnSubmit = 'btn-submit',
  // resembles a link.
  BtnLink = 'btn-link',
  BtnDisabled = 'btn-disabled',
}

// TODO_REMOVE: may not use, if applying the Material directive dynamically ends up being tricky
export enum MatButtonTypes {
  BtnMatStroked = 'mat-stroked-button',
}

export enum ButtonBehaviorTypes {
  Submit = 'submit',
  Button = 'button',
}

export enum StepActionTypes {
  Next = 'next',
  Back = 'back',
  Submit = 'submit',
  Disabled = 'disabled',
}

export enum ButtonNameTypes {
  // used when proceeding to next step.
  BtnNext = 'btnNext',
  // used when submitting application.
  BtnSubmit = 'btnSubmit',
  // used when navigating to previous step.
  BtnBack = 'btnBack',
  // used when choosing card
  BtnCardSelect = 'btnCardSelect',
  // generic button that resembles link design
  BtnLink = 'btnLink',
  BtnDisabled = 'btnDisabled',
}
