// models
import * as fromQuestionsModels from '../../models';

import { regex } from '../../services/helpers/validators-regex';

export const emailAddressQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Email,
  name: fromQuestionsModels.QuestionNameTypes.EmailAddress,
  label: 'Email address',
  placeholder: 'Email address',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter email address',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MaxLength,
        value: 320,
        message: 'Maximum of 320 characters',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: regex.email,
        message: 'Invalid email address',
      },
    ],
  },
};
