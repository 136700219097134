<div class="c-annual-income-help-text">
  <section>
    <div>
      <p>What to include:</p>
      <ul>
        <li>Wages or salary before taxes</li>
        <li>Bonuses and commissions</li>
        <li>Any verifiable income</li>
        <li>Income you have access to from individuals who contribute to your household</li>
      </ul>
    </div>
  </section>
  <section>
    <p id="income-text-details">
      Please be precise since this information may need to be verified. You do not need to include income from alimony,
      child support, and separate maintenance if you do not want it to be considered as part of your application. If you
      are under 21, include only your independent sources of income.
      <ng-container *ngIf="isWisconsin$ | async">
        Residents of WI may include income from a spouse or domestic partner if applicable. Per WI state law, a notice
        will be provided to a spouse or domestic partner (if applicable) at account opening.
      </ng-container>
    </p>
  </section>
</div>
