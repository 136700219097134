// ngrx
import { createAction, props } from '@ngrx/store';

export enum TokenizedCidActionTypes {
  GetAuthUserToken = '[Application] Get Auth User Token',
  LoadAuthUserTokenSuccess = '[Application] Load Auth User Token Success',
  LoadAuthUserTokenFailed = '[Application] Load Auth User Token Failed',
}

export const getAuthUserToken = createAction(
  TokenizedCidActionTypes.GetAuthUserToken,
  props<{ applicationId: string }>()
);

export const loadAuthUserTokenSuccess = createAction(
  TokenizedCidActionTypes.LoadAuthUserTokenSuccess,
  props<{ tokenizedCid: string }>()
);

export const loadAuthUserTokenFailed = createAction(
  TokenizedCidActionTypes.LoadAuthUserTokenFailed,
  props<{ error: Record<string, any> }>()
);

export type TokenizedCidAction = ReturnType<
  typeof loadAuthUserTokenSuccess | typeof loadAuthUserTokenFailed | typeof getAuthUserToken
>;
