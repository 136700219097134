// vendor
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
// environment
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    // Express lane lead post requires auth-session-id in the header
    if (req.url.includes('postXLLead')) {
      headers = req.headers.set('auth-session-id', this.cookieService.get('prospect__session_id'));
    }
    // Our own api call will come as relative path here, and then append with the same domain
    // in next(). We can filter absolute path so that any external api calls that use our http client
    // by accident will not alter its absolute url.
    if (!req.url.includes('https')) {
      req = req.clone({
        url: `${environment.baseApiSegment}/${req.url}`,
        headers,
        // url: `https://card-api.uat.bestegg.com/${req.url}`
        // url: `https://card-api.sbx.bestegg.com/${req.url}`
      });
    }
    return next.handle(req);
  }
}
