// vendor
import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-form-tooltip',
  templateUrl: './form-tooltip.component.html',
  styleUrls: ['./form-tooltip.component.scss'],
})
export class FormTooltipComponent {
  @Input() tooltip: string;
  @Input() tooltipLabel: string;
  /**
   * Add the matTooltip* Inputs to pass down to the
   * matTooltip directive.
   */
  @Input() tooltipPosition: TooltipPosition = 'below';
  @ViewChild(MatTooltip) matTooltip: MatTooltip;
  @Input() iconSize = 'small';

  /**
   * on toggle tooltip
   * add toggle as alt to material tooltip native long press on mobile
   */
  onToggleTooltip($event: Event) {
    $event.stopPropagation();
    this.matTooltip.toggle();
  }
}
