export enum TaxIdentifierTypes {
  Ssn = 'ssn',
}

export enum IncomeTypes {
  Annual = 'annual',
}

export enum CountryCodeTypes {
  US = 'us',
}

export enum PhoneTypes {
  Mobile = 'mobile',
  Home = 'home',
  Work = 'work',
}

export enum DobFormatTypes {
  Question = 'MMDDYYYY',
  Api = 'YYYY-MM-DD',
}

export enum ApplicationStepVariableTypes {
  ApplicationStepData = 'applicationStepData',
  ApplicationStepFormConfig = 'applicationStepFormConfig',
}

export enum DecisionTypes {
  Approved = 'approved',
  Pended = 'pended',
  Declined = 'declined',
  // TODO: confirm this is the actual value
  Frozen = 'frozen',
}

export enum DecisionAliasTypes {
  Approved = 'approved',
  Pended = 'pending',
  Declined = 'declined',
}

export enum DecisionReasonTypes {
  Kyc = 'kyc',
  Fraud = 'fraud',
  VendorUnavailable = 'vendor_unavailable',
}

export enum ApplicationDialogTypes {
  ApplicationTerms = 'Application Terms',
  CreditReportAuthorization = 'creditReportAuthorization',
}

export enum ApplicationStepComponentTypes {
  DynamicStepComponent = 'DynamicStepComponent',
  ContactInformationComponent = 'ContactInformationComponent',
  PersonalInformationComponent = 'PersonalInformationComponent',
  TermsAndConditionsComponent = 'TermsAndConditionsComponent',
  SocialSecurityNumberComponent = 'SocialSecurityNumberComponent',
  AgreementComponent = 'AgreementComponent',
  OpportunityMicrofunnelComponent = 'OpportunityMicrofunnelComponent',
  WalkupInformationComponent = 'WalkupInformationComponent',
  AffiliateLanding = 'AffiliateLandingComponent',
  VerifyIdentity = 'VerifyIdentityComponent',
  ConfirmInfo = 'ConfirmInfoComponent',
}

export enum ApplicationDisclosureStatusTypes {
  CreditScoreNotice = 'CREDIT_SCORE_NOTICE',
}
