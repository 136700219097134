/* eslint-disable max-len */
// vendor
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromOffersStore from '../../../../offers/store';
import * as fromCoreStore from '../../../../core/store';
// services
import { DateTimeService, DateTypes } from '../../../../shared/services';
// pipes
import { CurrencySimplePipe, PercentSimplePipe } from '../../../../shared/pipes';
// models
import { ApplicationTerms } from '../../../models';
import { CardProductOffer } from '../../../../offers/models';
import { CoreData } from '../../../../core/models';
import { Item, ItemSection } from '../../../../shared/models';
import { createValueIdGenericFactory, helperFns } from '../../../../shared/helpers/helper-functions';

const idGeneralLabel = 'rules';
const createValueId: (valueId: any) => string = createValueIdGenericFactory(idGeneralLabel);

@Component({
  selector: 'app-default-application-terms',
  templateUrl: './default-application-terms.component.html',
})
export class DefaultApplicationTermsComponent implements OnInit {
  applicationTerms: ApplicationTerms;
  cardProductOffer: CardProductOffer;
  coreData$: Observable<CoreData>;
  currentYear = new Date().getFullYear();

  constructor(
    private dateTimeService: DateTimeService,
    private percentSimplePipe: PercentSimplePipe,
    private currencySimplePipe: CurrencySimplePipe,
    private store: Store<fromOffersStore.OffersState | fromCoreStore.CoreState>
  ) {}

  ngOnInit() {
    helperFns.resetCreatedIdsMap();
    this.store
      .pipe(select(fromOffersStore.selectCardProductOfferData), filter(Boolean), first())
      .subscribe((cardProductOffer: CardProductOffer) => {
        this.cardProductOffer = cardProductOffer;
        this.setApplicationTerms();
      });
    this.coreData$ = this.store.pipe(select(fromCoreStore.selectCoreData));
  }

  setApplicationTerms(): void {
    try {
      this.applicationTerms = {
        title: '',
        disclosureSummary: this.getDisclosureSummary(),
        sections: [
          this.getRulesSection(),
          this.getTermsAndConditionsSection(),
          this.getPrescreenAndOptOutNoticeSection(),
          this.getCommunicationsSection(),
        ],
      };
    } catch (err) {
      console.error('[CAW_AUD_APPLICATION_TERMS_COMP_ERR] applicationTerms ', err);
      this.store.dispatch(
        fromOffersStore.updateCardFunnelTermsAvailability({
          termsErrors: true,
        })
      );
      throw err;
    }
  }

  /**
   * get disclosure summary
   * @returns item
   */
  private getDisclosureSummary(): Item {
    const formattedAsOfDate = this.getFormattedAsOfDate();

    // use the product date if available
    this.currentYear = this.dateTimeService.getDate(formattedAsOfDate, DateTypes.LongDate, true).year();

    return {
      label: 'Terms & Conditions For Best Egg Visa® Accounts Disclosure Summary',
      sublabel: 'Rates and Fees Table',
      value: `
      The Disclosure Summary shows a table of application rates and fees as of ${formattedAsOfDate}.
      All terms, including fees and APRs for new transactions, are not guaranteed and may change
      in accordance with the Cardmember Agreement and applicable law based on information in
      your credit report, market conditions, business strategies, or for any other reason.
      Please review these terms so you are fully informed about this credit card offer.`,
    };
  }

  /**
   * get rules section
   * @returns item section
   */
  private getRulesSection(): ItemSection {
    // cashAdvancePct
    const cashAdvancePct = this.percentSimplePipe.transform(this.cardProductOffer.cashAdvancePct);
    const cashAdvancePctId = 'cashAdvancePct';
    // minCashAdvanceFee
    const minCashAdvanceFee = this.currencySimplePipe.transform(this.cardProductOffer.minCashAdvanceFee);
    const minCashAdvanceFeeId = 'minCashAdvanceFee';
    // multipleViolationFee
    const multipleViolationFee = this.currencySimplePipe.transform(this.cardProductOffer.multipleViolationFee);
    const multipleViolationFeeId = 'multipleViolationFee';
    // singleViolationFee
    const singleViolationFee = this.currencySimplePipe.transform(this.cardProductOffer.singleViolationFee);
    const singleViolationFeeId = 'singleViolationFee';
    // minPaymentAmt
    const minPaymentAmt = this.currencySimplePipe.transform(this.cardProductOffer.minPaymentAmt);
    const minPaymentAmtId = 'minPaymentAmt';
    // minPaymentPct
    const minPaymentPct = this.percentSimplePipe.transform(this.cardProductOffer.minPaymentPct);
    const minPaymentPctId = 'minPaymentPct';
    // variable rates section
    /**
     * We could have either a range of APRs (RBP), or just one APR (single, original). Grab the first and last
     * APR if we detect that there's more than one.
     *
     * The language will be different depending on this. Mainly it's a matter of displaying the range of APRs vs.
     * a single APR, but there's some other langauge differences aside from this as well.
     *
     * For now, it can it be expected that if one of the APRs is range, the other will be as well, and v.v.
     */
    const isAPRRange = this.cardProductOffer.purchaseAprs.length > 1;
    const promoApr = this.cardProductOffer.purchaseAprs.find((apr) => apr.isPromo);

    const purchaseAprLowest = this.cardProductOffer.purchaseAprs[0];
    const purchaseAprLowestData = {
      margin: purchaseAprLowest.margin,
      marginId: isAPRRange ? 'purchaseAprMarginLowest' : 'purchaseAprMargin',
    };

    const purchaseAprHighest = this.cardProductOffer.purchaseAprs[this.cardProductOffer.purchaseAprs.length - 1];
    const purchaseAprHighestData = {
      margin: purchaseAprHighest.margin,
      marginId: isAPRRange ? 'purchaseAprMarginHighest' : 'purchaseAprMargin',
    };

    const purchaseAprData = [purchaseAprLowestData];
    if (isAPRRange) {
      purchaseAprData.push(purchaseAprHighestData);
    }

    const variableRatesLabel = (promoApr ? 'How We Calculate' : '') + ' Variable Rates:';
    const marginDisplay = isAPRRange
      ? `
      <span id="${createValueId(purchaseAprData[0].marginId)}">${purchaseAprData[0].margin}%</span> to
      <span id="${createValueId(purchaseAprData[1].marginId)}">${purchaseAprData[1].margin}%</span>
      `
      : `<span id="${createValueId(purchaseAprData[0].marginId)}">${purchaseAprData[0].margin}%</span>`;

    const variableRatesDisplayValue = `
      Variable APRs may increase or decrease each month if the Prime Rate changes.
      Your APR is determined by adding a margin of ${marginDisplay} for Purchases and Cash Advances to the Prime
      Rate based on your creditworthiness. Any new rate will be applied as of the first day of your billing cycle
      during which the Prime Rate has changed. We calculate a variable APR by adding a margin to the highest Prime
      Rate published in The Wall Street Journal on the last business day the month. If the APR increases,
      you will pay a higher interest charge and may pay a higher minimum payment.
      The Prime Rate is simply a reference index and is not the lowest interest rate available.
      `;

    const rules: { items: Item[] } = {
      items: [
        {
          label: 'How we calculate your balance:',
          value: `
          We use a method called Average Daily Balance (including new purchases),
          as more fully explained in the Cardmember Agreement.
          `,
        },
        {
          label: 'Billing Rights:',
          value: `
          Information on your right to dispute transactions and how to exercise those rights is provided
          in your Cardmember Agreement.
          `,
        },
        {
          label: variableRatesLabel,
          value: variableRatesDisplayValue,
        },
        {
          label: 'Rewards:',
          value: `
          Best Egg may offer rewards or other incentives from time to time to new Best Egg cardmembers
          in connection with an application for a new account and to existing Best Egg cardmembers.
          You will find out more about these rewards or other incentive offers and any other terms at the time of the offer.
          `,
        },
        {
          label: 'Cash Advance:',
          value: `
          <span id="${createValueId(
            cashAdvancePctId
          )}">${cashAdvancePct}%</span> of the cash advance transaction (including fees charged by the ATM operator, if any),
          with a minimum of <span id="${createValueId(
            minCashAdvanceFeeId
          )}">$${minCashAdvanceFee}</span>.  We will add this fee to the Cash Advance balance.  We will begin charging
          interest on cash advances on the transaction date.
          `,
        },
        {
          label: 'Penalty Fees:',
          value: `
          Up to <span id="${createValueId(
            multipleViolationFeeId
          )}">$${multipleViolationFee}</span>. A single violation of each type will not exceed <span id="${createValueId(
            singleViolationFeeId
          )}">$${singleViolationFee}</span>. However, if another violation of
          the same type occurs within the next six billing cycles, we will charge <span id="${createValueId(
            multipleViolationFeeId
          )}">$${multipleViolationFee}</span>. The Late Payment and
          Return Payment fees will not exceed the related minimum payment that was due.
          `,
        },
        {
          label: 'Minimum Payment:',
          value: `
          We will calculate the minimum payment as: (1) any past due amounts; PLUS (2) the larger of:
          (a) <span id="${createValueId(
            minPaymentAmtId
          )}">$${minPaymentAmt}</span> (or total amount you owe if less than <span id="${createValueId(
            minPaymentAmtId
          )}">$${minPaymentAmt})</span>; or (b) the sum of: (i) <span id="${createValueId(
            minPaymentPctId
          )}">${minPaymentPct}%</span> of the new balance,
          PLUS (ii) any periodic interest charges and late fees we have billed you on the statement for
          which your minimum payment is calculated.
          `,
        },
      ],
    };

    if (promoApr) {
      rules.items.splice(1, 0, {
        label: 'Loss of Intro APR:',
        value: `
        We will end your introductory APR and apply the standard APR if you do not make your required payment by the due date.
        `,
        templateId: createValueId('loss_of_intro_apr'),
      });
    }

    return rules;
  }

  /**
   * get terms and conditions section
   * @returns item section
   */
  private getTermsAndConditionsSection(): ItemSection {
    const formattedAsOfDate = this.getFormattedAsOfDate();
    const formattedAsOfDateId = 'asOfDate';
    return {
      title: 'Terms & Conditions',
      items: [
        {
          label: null,
          value: `
          You have read the accompanying application, and you affirm that everything you have stated is true and complete.
          You are of legal age to enter into a contract in the state in which you reside. Currently Best Egg Credit Cards
          are not available in the following locations: WV, IA, VT, and Washington, D.C. You authorize FB&T/Best Egg Credit Card (hereinafter "we", "us" or "our") to
          obtain your credit report(s), employment history and any other information in order to approve or decline this application,
          service your account, and manage our relationship with you. Upon request, we will tell you the name and address of any
          consumer reporting agency that provided such report(s) to us. You authorize us to share with others, in accordance with
          our privacy notice, such information and our credit experience with you. In addition, you may, as a customer, later
          indicate a preference to exempt your account from some of the information-sharing with other companies ("opt-out").
          If you accept or use an account, you do so subject to the terms of this application, this Disclosure Summary, and the
          Cardmember Agreement, as it may be amended; you also agree to pay all charges incurred under such terms. Any changes you
          make to the terms of this application will have no effect. You accept that on a periodic basis your account may be considered
          for an automatic upgrade at our discretion. You consent to and authorize us, any of our affiliates, or our marketing associates
          to monitor and/or record any of your phone conversations with any of our representatives. You further consent to our use of
          automatic dialers, text, or prerecorded messages for servicing your account even if the telephone number is a mobile telephone
          number for which the called party is charged.  If additional development is necessary based on the application information provided,
          we will make every attempt to contact you.
        `,
        },
        {
          label: null,
          value: `
          The information in this offer is accurate as of <span id="${createValueId(
            formattedAsOfDateId
          )}">${formattedAsOfDate}</span>, and is subject to change without notice.
        `,
        },
      ],
    };
  }

  /**
   * get presecreen and opt out notice section
   * @returns item section
   */
  private getPrescreenAndOptOutNoticeSection(): ItemSection {
    return {
      title: 'Prescreen & Opt-Out Notice',
      items: [
        {
          label: null,
          value: `
          This "prescreened" offer of credit is based on information in your credit report indicating that you meet certain criteria.
          This offer is not guaranteed if you do not meet our criteria. If you do not want to receive prescreen offers of credit from
          this and other companies, call the consumer reporting agencies toll-free at 888-5-OPTOUT (888-567-8688) or write to any of
          the following reporting agencies: Experian Opt Out, P.O. Box 919, Allen, TX 75013; Equifax, Inc. Options, P.O. Box 740123,
          Atlanta, GA 30374-0123; or Transunion, P.O. Box 505, Woodlyn, PA 19094.
          `,
          style: 'border: 1px solid #000; padding: 0 5px; font-size: 14px',
        },
      ],
    };
  }

  /**
   * get communications section
   * @returns item section
   */
  private getCommunicationsSection(): ItemSection {
    return {
      items: [
        {
          label: 'Mobile Communications:',
          value: `
          By participating in the Best Egg Credit Card Fraud Notification program, you may receive fraud notifications. All messages
          are free. You may opt out at any time by texting STOP to Short Code (54759). By sending STOP to Short Code (54759), you agree
          to one additional confirmation message stating that you've opted out and will no longer receive messages from Best Egg Credit Card.
          To get help, text HELP to Short Code (54759). Get additional support or help by calling 833-707-1226. Data obtained through the
          short code program will not be shared with any third-parties for their marketing reasons/purposes. You must be the mobile
          phone account holder or have permission from the account holder to use this service. You must be 18 years or older or have permission
          from a parent/guardian. By participating in this program, you specifically authorize Best Egg Credit Card to send communications using
          an automatic telephone dialing system or an artificial or prerecorded voice system. You are not required to sign the agreement as a
          condition of purchasing any property, goods or services. The person consenting to receive telemarketing communications also provides
          authorization for the specific telephone number that may be contacted via this program. Carriers are not responsible for any delayed or
          undelivered messages. Messages may be delayed or not delivered due to factors outside of the carrier's control.
          `,
        },
        {
          label: 'PARTICIPATING WIRELESS CARRIERS:',
          value: `
          AT&T, Boost Mobile, MetroPCS, Sprint, T-Mobile, Verizon Wireless, Virgin Mobile USA.
          `,
        },
      ],
    };
  }

  private getFormattedAsOfDate(format = DateTypes.LongDate) {
    return this.dateTimeService.convertApiDateArrayToStr(this.cardProductOffer.asOfDate, format);
  }
}
