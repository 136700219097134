// ngrx
import { createAction, props } from '@ngrx/store';

export enum CardFunnelTermsAvailabilityActionTypes {
  UpdateCardFunnelTermsAvailability = '[Offers] Update Card Funnel Terms Availability',
}

export const updateCardFunnelTermsAvailability = createAction(
  CardFunnelTermsAvailabilityActionTypes.UpdateCardFunnelTermsAvailability,
  props<{ termsErrors: boolean }>()
);

export type CardFunnelTermsAvailabilityAction = ReturnType<typeof updateCardFunnelTermsAvailability>;
