// ngrx
import { createReducer, on } from '@ngrx/store';
import * as fromQuestionsActions from '../actions';
// models
import { Map } from '../../../shared/models';

// key
export const QUESTION_VALUES_STATE_KEY = 'questionValues';

// state
export interface QuestionsValuesState {
  data: Map<string>;
}

export const initialState: QuestionsValuesState = {
  data: null,
};

// reducer
const questionValuesReducer = createReducer(
  initialState,
  // update
  on(
    fromQuestionsActions.updateQuestionValues,
    (state: QuestionsValuesState, action: ReturnType<typeof fromQuestionsActions.updateQuestionValues>) => {
      const data = { ...state.data, ...action.questionValues };
      return { ...state, data };
    }
  ),
  on(fromQuestionsActions.clearQuestionValues, () => initialState)
);

export const reducer = (state: QuestionsValuesState, action: fromQuestionsActions.QuestionsAction) =>
  questionValuesReducer(state, action);

// state selectors
export const selectQuestionValuesStateData = (state: QuestionsValuesState) => state.data;
