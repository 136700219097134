// store
import { createReducer, on } from '@ngrx/store';
import * as fromCardProspectOffersActions from '../actions';
// models
import { CardProspectOffer } from '../../models';
import { Error } from '../../../shared/models';

// key
export const CARD_PROSPECT_OFFER_STATE_KEY = 'cardProspectOffer';

// state
export interface CardProspectOfferState {
  data: CardProspectOffer;
  loaded: boolean;
  loading: boolean;
  error: Error;
}

export const initialState: CardProspectOfferState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

// reducer
const cardProspectOfferReducer = createReducer(
  initialState,
  // load
  on(
    fromCardProspectOffersActions.loadCardProspectOfferById,
    fromCardProspectOffersActions.loadCardProspectOfferByOfferCode,
    fromCardProspectOffersActions.loadCardProspectOfferByParams,
    (state: CardProspectOfferState) => ({ ...state, loading: true })
  ),
  on(
    fromCardProspectOffersActions.loadCardProspectOfferSuccess,
    (
      state: CardProspectOfferState,
      action: ReturnType<typeof fromCardProspectOffersActions.loadCardProspectOfferSuccess>
    ) => ({
      ...state,
      data: action.cardProspectOffer,
      error: null,
      loading: false,
      loaded: true,
    })
  ),
  on(
    fromCardProspectOffersActions.loadCardProspectOfferFail,
    (
      state: CardProspectOfferState,
      action: ReturnType<typeof fromCardProspectOffersActions.loadCardProspectOfferFail>
    ) => ({
      ...state,
      data: null,
      error: action.error?.error,
      loaded: false,
      loading: false,
    })
  ),
  on(fromCardProspectOffersActions.clearCardProspectOffer, (state: CardProspectOfferState) => initialState)
);

export const reducer = (
  state: CardProspectOfferState,
  action: fromCardProspectOffersActions.CardProspectOffersAction
) => cardProspectOfferReducer(state, action);

// state selectors
export const selectCardProspectOfferStateData = (state: CardProspectOfferState) => state.data;
export const selectCardProspectOfferStateLoading = (state: CardProspectOfferState) => state.loading;
export const selectCardProspectOfferStateLoaded = (state: CardProspectOfferState) => state.loaded;
export const selectCardProspectOfferStateError = (state: CardProspectOfferState) => state.error;
