// ngrx
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
// models
import { DocumentFile } from '../../models';

export enum DocumentActionTypes {
  LoadDocuments = '[Application] Load Documents',
  LoadDocumentsSuccess = '[Application] Load Documents Success',
  LoadDocumentsFail = '[Application] Load Documents Fail',
  ClearDocuments = '[Application] Clear Documents',
  UploadDocument = '[Application] Upload Document',
  UploadDocumentProgress = '[Application] Upload Document Progress',
  UploadDocumentSuccess = '[Application] Upload Document Success',
  UploadDocumentFail = '[Application] Upload Document Fail',
  ClearUploadDocument = '[Application] Clear Upload Document',
}

// load documents
export const loadDocuments = createAction(DocumentActionTypes.LoadDocuments);

export const loadDocumentsSuccess = createAction(
  DocumentActionTypes.LoadDocumentsSuccess,
  props<{ documents: DocumentFile[] }>()
);

export const loadDocumentsFail = createAction(
  DocumentActionTypes.LoadDocumentsFail,
  props<{ error: HttpErrorResponse }>()
);

export const clearDocuments = createAction(DocumentActionTypes.ClearDocuments);

// upload document
export const uploadDocument = createAction(DocumentActionTypes.UploadDocument, props<{ file: File }>());

export const uploadDocumentProgress = createAction(
  DocumentActionTypes.UploadDocumentProgress,
  props<{ progress: number }>()
);

export const uploadDocumentSuccess = createAction(DocumentActionTypes.UploadDocumentSuccess);

export const uploadDocumentFail = createAction(
  DocumentActionTypes.UploadDocumentFail,
  props<{ error: HttpErrorResponse }>()
);

export const clearUploadDocument = createAction(DocumentActionTypes.ClearUploadDocument);

export type DocumentAction = ReturnType<
  | typeof loadDocuments
  | typeof loadDocumentsSuccess
  | typeof loadDocumentsFail
  | typeof clearDocuments
  | typeof uploadDocument
  | typeof uploadDocumentProgress
  | typeof uploadDocumentSuccess
  | typeof uploadDocumentFail
  | typeof clearUploadDocument
>;
