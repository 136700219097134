// vendor
import { Injectable } from '@angular/core';
// services
import { SyncService } from '../../shared/services';
// models
import * as fromApplicationModels from '../../application/models';
import * as fromLeadModels from '../models';
import { CardProspectOffer, CardProductOffer, CardProduct } from '../../offers/models';
import { QuestionNameTypes, QuestionValues } from '../../questions/models';
import { LeadResponseV2, Lead } from '../models';

interface SyncLead {
  propertyName: string;
  fn: (param: any) => any;
  appendTo?: string;
}

type SyncLeadMap = {
  [key in QuestionNameTypes]?: SyncLead;
};

@Injectable({
  providedIn: 'root',
})
export class LeadSyncService {
  syncLeadMap: SyncLeadMap;

  constructor(private syncService: SyncService) {
    this.setSyncLeadMap();
  }

  setSyncLeadMap() {
    this.syncLeadMap = {
      // Contact
      // - Full Name
      [QuestionNameTypes.FirstName]: {
        propertyName: 'fullName',
        fn: this.syncService.syncFullName.bind(this.syncService),
      },
      [QuestionNameTypes.LastName]: {
        propertyName: 'fullName',
        fn: this.syncService.syncFullName.bind(this.syncService),
      },
      // - DOB
      [QuestionNameTypes.DateOfBirth]: {
        propertyName: 'dateOfBirth',
        fn: this.syncService.syncDateOfBirth.bind(this.syncService),
      },
      // - Mailing Address
      [QuestionNameTypes.Address]: {
        propertyName: 'mailingAddress',
        fn: this.syncService.syncMailingAddress.bind(this.syncService),
      },
      [QuestionNameTypes.City]: {
        propertyName: 'mailingAddress',
        fn: this.syncService.syncMailingAddress.bind(this.syncService),
      },
      [QuestionNameTypes.State]: {
        propertyName: 'mailingAddress',
        fn: this.syncService.syncMailingAddress.bind(this.syncService),
      },
      [QuestionNameTypes.ZipCode]: {
        propertyName: 'mailingAddress',
        fn: this.syncService.syncMailingAddress.bind(this.syncService),
      },
      // - Primary Phone (mobile)
      [QuestionNameTypes.MobilePhone]: {
        propertyName: 'primaryPhone',
        fn: this.syncService.syncPrimaryPhone.bind(this.syncService),
      },
      // - Secondary Phone (home)
      [QuestionNameTypes.HomePhone]: {
        propertyName: 'secondaryPhone',
        fn: this.syncService.syncSecondaryPhone.bind(this.syncService),
      },
      // Email Address
      [QuestionNameTypes.EmailAddress]: {
        propertyName: 'emailAddress',
        fn: this.syncService.syncEmailAddress.bind(this.syncService),
      },
      // Physical Address
      // - Physical Address
      [QuestionNameTypes.PhysicalAddress]: {
        propertyName: 'physicalAddress',
        fn: this.syncService.syncPhysicalAddress.bind(this.syncService),
      },
      [QuestionNameTypes.PhysicalCity]: {
        propertyName: 'physicalAddress',
        fn: this.syncService.syncPhysicalAddress.bind(this.syncService),
      },
      [QuestionNameTypes.PhysicalState]: {
        propertyName: 'physicalAddress',
        fn: this.syncService.syncPhysicalAddress.bind(this.syncService),
      },
      [QuestionNameTypes.PhysicalZipCode]: {
        propertyName: 'physicalAddress',
        fn: this.syncService.syncPhysicalAddress.bind(this.syncService),
      },

      // Personal Info
      // - Applicant Employment
      [QuestionNameTypes.EmploymentStatus]: {
        propertyName: 'applicantEmployment',
        fn: this.syncService.syncApplicantEmployment.bind(this.syncService),
      },
      // - Employer Phone
      [QuestionNameTypes.EmployerPhone]: {
        propertyName: 'employerPhone',
        fn: this.syncService.syncEmployerPhone.bind(this.syncService),
      },
      // - Employer Name
      [QuestionNameTypes.EmployerName]: {
        propertyName: 'applicantEmployment',
        fn: this.syncService.syncApplicantEmployment.bind(this.syncService),
      },
      // - Income
      [QuestionNameTypes.AnnualIncome]: {
        propertyName: 'income',
        fn: this.syncService.syncIncome.bind(this.syncService),
      },

      // Banking
      // - Bank accounts
      [QuestionNameTypes.Banking]: {
        propertyName: 'applicantBankingInfo',
        fn: this.syncService.syncApplicantBankingInfo.bind(this.syncService),
      },

      // Housing
      // - Applicant residence
      [QuestionNameTypes.HousingType]: {
        propertyName: 'applicantResidence',
        fn: this.syncService.syncApplicantResidence.bind(this.syncService),
      },
      [QuestionNameTypes.HousingMonthlyPayment]: {
        propertyName: 'applicantResidence',
        fn: this.syncService.syncApplicantResidence.bind(this.syncService),
      },

      // Residental Length
      // - Applicant residence
      [QuestionNameTypes.ResidentialLength]: {
        propertyName: 'applicantResidence',
        fn: this.syncService.syncApplicantResidence.bind(this.syncService),
      },

      // Cash Advances
      [QuestionNameTypes.CashAdvances]: {
        propertyName: 'cashAdvances',
        fn: (questionValues: QuestionValues) => questionValues.cashAdvances,
        appendTo: 'root',
      },

      // SSN
      // - Tax Identifier
      [QuestionNameTypes.SocialSecurityNumber]: {
        propertyName: 'taxIdentifier',
        fn: this.syncService.syncTaxIdentifier.bind(this.syncService),
      },

      // Agreement
      // - Accept TOCs..
      [QuestionNameTypes.Agreement]: {
        propertyName: 'acceptTermsAndConditions',
        fn: this.syncService.syncAcceptTermsAndConditions.bind(this.syncService),
        appendTo: 'root',
      },

      // - Paper statement preference
      [QuestionNameTypes.PaperStatementPreference]: {
        propertyName: 'customerApplicationPreferences',
        fn: this.syncService.syncStatementPreferences.bind(this.syncService),
        appendTo: 'root',
      },

      // - Paperless statement preference
      [QuestionNameTypes.PaperlessStatementPreference]: {
        propertyName: 'customerApplicationPreferences',
        fn: this.syncService.syncStatementPreferences.bind(this.syncService),
        appendTo: 'root',
      },
    };
  }

  // Approach to account for data collected between different steps for single sync function
  syncDataToLead(questionValues: QuestionValues): Partial<fromLeadModels.Lead> {
    let partialLead: Partial<fromLeadModels.Lead> = {};

    /**
     * Loop through question names of the question values.
     * Map to the corresponding object containing the function and the property name to reference.
     * Reducing to ignore question names that we don't want mapped.
     */
    const syncObjects: SyncLead[] = Object.keys(questionValues).reduce((syncs: SyncLead[], key: QuestionNameTypes) => {
      if (this.syncLeadMap[key]) {
        syncs.push(this.syncLeadMap[key]);
      }
      return syncs;
    }, [] as SyncLead[]);

    /** Compose the partialLead using each of the functions and property name to set the result to. */
    partialLead = syncObjects.reduce((accPartialLead: Partial<fromLeadModels.Lead>, syncObj: SyncLead) => {
      /** If the associated property has already been added to the
       * partial lead object being built, then forgo the sync mapping for this property and just move on
       **/
      if (accPartialLead[syncObj.propertyName]) {
        return accPartialLead;
      }

      let partialLeadFragmentBody = syncObj.fn(questionValues);
      /**
       * This might be necessary if not all browsers omit undefined properties when evaluating a request object.
       * Some values are primitives and not even objects (like dateOfBirth), so omit this process for those.
       */
      partialLeadFragmentBody =
        typeof partialLeadFragmentBody === 'object' && partialLeadFragmentBody !== null
          ? this.withUndefinedPropertiesRemoved(partialLeadFragmentBody)
          : partialLeadFragmentBody;

      const partialLeadFragment = {
        [syncObj.propertyName]: partialLeadFragmentBody,
      };

      let primaryApplicant: Partial<fromApplicationModels.PrimaryApplicant> = accPartialLead.primaryApplicant || {};

      let rootLevelFields = { ...accPartialLead };

      // Some properties are attached directly to the root.
      if (syncObj.appendTo === 'root') {
        rootLevelFields = {
          ...rootLevelFields,
          ...partialLeadFragment,
        };
      } else {
        // Most properties will be appended to the primaryApplicant object.
        primaryApplicant = {
          ...primaryApplicant,
          ...partialLeadFragment,
        };
      }

      const accPartialLeadNext = {
        ...rootLevelFields,
      };

      // Only append primaryApplicant property if there are values inside
      // TODO_UPDATE: double check if this is actually what the backend will expect.
      if (Object.keys(primaryApplicant).length > 0) {
        accPartialLeadNext.primaryApplicant = primaryApplicant;
      }

      return accPartialLeadNext;
    }, {});

    return partialLead;
  }

  /**
   * Combine the partialLead data with the current data in lead
   * eg. partialLead can be a part of primaryApplicant that only contains new information:
   * { primaryApplicant: { applicantResidence: {...} } }
   * we need to combine primaryApplicant data from both leadData and partialLead,
   * so the lead store can be updated correctly.
   */
  syncPartialLeadForStoreUpdate = (partialLead: Partial<Lead>, leadData: Lead): Partial<Lead> =>
    Object.keys(partialLead).reduce((acc, cur) => {
      let modifiedInput: any;
      if (typeof partialLead[cur] !== 'object') {
        // in case the new input is not object type, like cash advance is a single string
        // override the old value
        modifiedInput = partialLead[cur];
      } else {
        modifiedInput = { ...leadData[cur], ...partialLead[cur] };
      }
      return { ...acc, [cur]: modifiedInput };
    }, {});

  //==========================================================
  // Update Lead process.
  //==========================================================

  withUndefinedPropertiesRemoved(obj): any {
    const alteredObj = Object.assign({}, obj);
    Object.entries(alteredObj).forEach(([key, value]) => {
      if (value === undefined) {
        delete alteredObj[key];
      }
    });
    return alteredObj;
  }

  //==========================================================
  // Create Lead process.
  //==========================================================

  /**
   * sync data to lead create request
   * [Note] email address is currently not a application
   * form field so is not contained in the question values
   * @param applicationMeta
   * @param prospectOffer
   * @param productOffer
   * @param product
   * @returns lead request
   */
  syncDataToLeadCreateRequest(
    applicationMeta: fromApplicationModels.ApplicationMeta,
    prospectOffer: CardProspectOffer,
    productOffer: CardProductOffer,
    product: CardProduct,
    questionValues: QuestionValues
  ): fromLeadModels.LeadCreateRequest {
    let leadRequest: fromLeadModels.LeadCreateRequest = {
      offerCode: this.syncService.syncOfferCode(prospectOffer),
      campaignParticipantCode: this.syncCampaignParticipantCode(prospectOffer),
      channelMediumCode: this.syncChannelMediumCode(prospectOffer),
      prospectOfferId: this.syncService.syncProspectOfferId(prospectOffer),
      productOfferId: this.syncService.syncProductOfferId(productOffer),
      primaryApplicant: {
        emailAddress: this.syncService.syncEmailAddress(applicationMeta),
      },
    };
    // question values to include on lead
    if (questionValues) {
      const accumulatedLead = this.syncDataToLead(questionValues);
      const updatedPrimaryApplicant = this.syncPrimaryApplicantForCreateRequest(leadRequest, accumulatedLead);
      const updatedLeadRequest = { ...leadRequest, ...accumulatedLead };
      updatedLeadRequest.primaryApplicant = updatedPrimaryApplicant;
      leadRequest = updatedLeadRequest;
    }
    return leadRequest;
  }

  /**
   * sync primary applicant for create request
   * @returns partial lead primary applicant for create request
   */
  private syncPrimaryApplicantForCreateRequest(
    leadRequest: fromLeadModels.LeadCreateRequest,
    accumulatedLead: Partial<fromLeadModels.Lead>
  ): Partial<fromApplicationModels.PrimaryApplicant> {
    let primaryApplicant = leadRequest.primaryApplicant;
    const accumulatedPrimaryApplicant = accumulatedLead.primaryApplicant;
    if (accumulatedPrimaryApplicant) {
      primaryApplicant = {
        ...primaryApplicant,
        ...accumulatedPrimaryApplicant,
      };
    }
    return primaryApplicant;
  }

  /**
   * sync campaign participant code
   * @param prospectOffer
   * @returns campaign participant code
   */
  syncCampaignParticipantCode(prospectOffer: CardProspectOffer): string {
    let campaignParticipantCode: string;
    if (prospectOffer) {
      const campaign = prospectOffer.campaign;
      if (campaign) {
        campaignParticipantCode = campaign.campaignParticipantCode;
      }
    }
    return this.syncService.defaultIfUndefined(campaignParticipantCode);
  }

  /**
   * sync channel medium code
   * @param prospectOffer
   * @returns channel medium code
   */
  syncChannelMediumCode(prospectOffer: CardProspectOffer): string {
    let channelMediumCode: string;
    if (prospectOffer) {
      const campaign = prospectOffer.campaign;
      if (campaign) {
        channelMediumCode = campaign.channelMediumCode;
      }
    }
    return this.syncService.defaultIfUndefined(channelMediumCode);
  }

  syncLeadResponseToAppMeta(leadResponse: LeadResponseV2): Partial<Lead> {
    return {} as Partial<Lead>;
  }
}
