// store
import { createReducer, on } from '@ngrx/store';
import * as fromApplicationActions from '../actions';
// models
import { ApplicationSubmitResponse } from '../../models';

// key
export const APPLICATION_SUBMIT_STATE_KEY = 'applicationSubmit';

// state
export interface ApplicationSubmitState {
  data: ApplicationSubmitResponse;
  error: Record<string, any>;
  loaded: boolean;
  loading: boolean;
}

export const initialState: ApplicationSubmitState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

// reducer
const applicationSubmitReducer = createReducer(
  initialState,
  // submit
  on(fromApplicationActions.submitApplication, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(fromApplicationActions.submitApplicationSuccess, (state, action) => ({
    ...state,
    data: action.applicationSubmitResponse,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(fromApplicationActions.submitApplicationFail, (state, action) => ({
    ...state,
    error: action.error.error,
    loaded: false,
    loading: false,
  })),
  on(fromApplicationActions.clearApplicationSubmit, () => initialState)
);

export const reducer = (state: ApplicationSubmitState, action: fromApplicationActions.ApplicationAction) =>
  applicationSubmitReducer(state, action);

// state selectors
export const selectApplicationSubmitStateData = (state: ApplicationSubmitState) => state.data;
export const selectApplicationSubmitStateLoaded = (state: ApplicationSubmitState) => state.loaded;
export const selectApplicationSubmitStateLoading = (state: ApplicationSubmitState) => state.loading;
export const selectApplicationSubmitStateError = (state: ApplicationSubmitState) => state.error;
