// vendor
import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
// services
import { scrollBounceAnimation } from '../../../../shared/helpers/animations';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import { selectDigitalExperienceName } from 'src/app/features/digital-experience/store';
import { filter, first } from 'rxjs/operators';
import { DigitalExperienceTypes } from 'src/app/features/digital-experience/models';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss', '../shared/individual-steps.scss'],
})
export class TermsAndConditionsComponent extends BaseApplicationStepComponent implements AfterViewInit, OnInit {
  @ViewChild('content') content: ElementRef;

  panelTitle = 'Interest Rates and Charges';

  isKcfExperience$ = this.store.select(selectDigitalExperienceName).pipe(
    filter((digitalExperienceName) => digitalExperienceName === DigitalExperienceTypes.BestEggKcf),
    first()
  );

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    scrollBounceAnimation(this.content.nativeElement);
  }
}
