import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { race } from 'rxjs';
import { filter, map, skipWhile } from 'rxjs/operators';
import { selectApplicationData, selectApplicationMetaData } from '../../application/store';
import { selectDigitalExperienceFeature, selectDigitalExperienceName } from '../../digital-experience/store';
import { selectLeadData, selectLeadPrimaryApplicant } from '../../lead/store';
import { selectCardProductOfferData } from '../../offers/store';

@Injectable({
  providedIn: 'root',
})
export class UserDataCollectionService {
  constructor(private readonly store: Store) {}

  applicationData$ = this.store.pipe(
    select(selectApplicationData),
    skipWhile((application) => !application)
  );

  leadData$ = this.store.pipe(
    select(selectLeadData),
    skipWhile((lead) => !lead)
  );

  productOfferData$ = this.store.pipe(
    select(selectCardProductOfferData),
    skipWhile((productOffer) => !productOffer)
  );

  digitalExperienceName$ = this.store.pipe(
    select(selectDigitalExperienceName),
    skipWhile((digitalExperienceName) => !digitalExperienceName)
  );

  digitalExperienceState$ = this.store.pipe(
    select(selectDigitalExperienceFeature),
    skipWhile((digitalExperienceState) => !digitalExperienceState.name)
  );

  emailAddress$ = race(
    this.store.pipe(
      select(selectApplicationMetaData),
      filter((applicationMeta) => applicationMeta?.emailAddress !== undefined),
      map((applicationMeta) => applicationMeta.emailAddress as string)
    ),
    this.store.pipe(
      select(selectLeadPrimaryApplicant),
      skipWhile((primaryApplicant) => !(primaryApplicant && primaryApplicant.emailAddress)),
      map((primaryApplicant) => primaryApplicant.emailAddress)
    )
  );

  firstName$ = this.store.pipe(
    select(selectLeadPrimaryApplicant),
    skipWhile((primaryApplicant) => !(primaryApplicant && primaryApplicant.fullName?.firstName)),
    map((primaryApplicant) => primaryApplicant.fullName.firstName)
  );

  lastName$ = this.store.pipe(
    select(selectLeadPrimaryApplicant),
    skipWhile((primaryApplicant) => !(primaryApplicant && primaryApplicant.fullName?.lastName)),
    map((primaryApplicant) => primaryApplicant.fullName.lastName)
  );

  mailingAddress$ = this.store.pipe(
    select(selectLeadPrimaryApplicant),
    skipWhile((primaryApplicant) => !(primaryApplicant && primaryApplicant.mailingAddress)),
    map((primaryApplicant) => primaryApplicant.mailingAddress)
  );
}
