// vendor
import { Component, Input } from '@angular/core';
// component
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';
// models
import { Option } from '../../../models';

@Component({
  selector: 'app-form-select-dropdown',
  templateUrl: './form-select-dropdown.component.html',
  styleUrls: ['../form-fields.scss'],
})
export class FormSelectDropdownComponent extends BaseFormFieldComponent {
  @Input() options: Option[];
}
