// vendor
import { Component } from '@angular/core';
import { initialConfig } from 'ngx-mask';
// components
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';
// models
import { Map } from '../../../../shared/models';
// configs
import { iconAssetMap } from '../../../../shared/configs';
import { fieldErrorEnterAnimation } from '../../../../shared/helpers/animations';

@Component({
  selector: 'app-form-input',
  styleUrls: ['../form-fields.scss'],
  templateUrl: './form-input.component.html',
  animations: [fieldErrorEnterAnimation],
})
export class FormInputComponent extends BaseFormFieldComponent {
  iconAssetMap: Map<string>;
  defaultSpecialCharacters: string[] | readonly string[];
  prefixIconConfig = {
    height: 22,
    width: 17,
  };
  suffixIconConfig = {
    height: 27,
    width: 25,
  };

  constructor() {
    super();
    this.iconAssetMap = iconAssetMap;
    this.defaultSpecialCharacters = initialConfig.specialCharacters;
  }
}
