// vendor
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
// ngrx
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer, reducers, effects } from './store';
// modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './features/shared/shared.module';
// components
import { AppComponent } from './app.component';
// interceptors
import * as fromInterceptors from './interceptors';
// env
import { environment } from '../environments/environment';
import { FrontendCommonModule } from '@marlettefunding/frontend-common';
import { FakeAuthResponseInterceptor } from './interceptors/fake-auth-response.interceptor';

// Angular 13+ relies on an animations API that is not available in older versions of iOS
// This disables animations for those users
// See https://marlettefundingllc.atlassian.net/browse/CARD-446
// and https://github.com/angular/angular/issues/45016
const disableAnimations =
  !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

const LOCAL_ENV_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: FakeAuthResponseInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 35,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    MatDialogModule,
    SharedModule,
    FrontendCommonModule.forRoot({
      cookieDomain: environment.mainDomain,
      env: environment.enterpriseEventsEnv,
      localDomainOverride: environment.localDpidDomainOverride,
      localEnterpriseEventEndpoint: environment.localEnterpriseEventsEndpoint,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: fromInterceptors.ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: fromInterceptors.ApiInterceptor,
      multi: true,
    },
    CookieService,
    ...(environment.env === 'local' ? LOCAL_ENV_PROVIDERS : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
