// vendor
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// models
import {
  AddressValidation,
  QuestionMap,
  QuestionNameTypes,
  QuestionValidator,
  QuestionValidators,
  ValidatorTypes,
} from '../models';
import { Address } from '../../application/models';
// configs
import * as fromApplicationConfigs from '../../application/configs';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  constructor(private http: HttpClient) {}

  /**
   * validate address
   * @params address
   */
  validateAddress(address: Address, poBoxAllowed: boolean): Observable<AddressValidation> {
    const baseUrl = fromApplicationConfigs.applicationServiceBaseUrl.v1;
    const url = `${baseUrl}/address/validation`;
    const params: HttpParams = new HttpParams().set('poBoxAllowed', poBoxAllowed.toString());
    return this.http.post<AddressValidation>(url, address, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * This will mutate the existing questionMap data!
   * We'd expect changes to this object to have an effect on all other places that reference
   * this questionMap!
   */
  modifyQuestionsData(
    questionMap: QuestionMap,
    questionName: QuestionNameTypes,
    questionValidators: QuestionValidators,
    dictatorValue: string
  ): void {
    if (questionName === QuestionNameTypes.HousingMonthlyPayment) {
      this.modifyHandleHousingPaymentTypeQuestion(questionMap, dictatorValue, questionValidators);
    }
  }

  private modifyHandleHousingPaymentTypeQuestion(
    questionMap: QuestionMap,
    housingTypeValue: string,
    questionValidators: QuestionValidators
  ): void {
    if (housingTypeValue === 'RENT') {
      this.modifyQuestionLanguage(
        questionMap,
        QuestionNameTypes.HousingMonthlyPayment,
        questionValidators,
        'Monthly rent',
        'Monthly rent',
        'Please enter monthly rent'
      );
    } else {
      this.modifyQuestionLanguage(
        questionMap,
        QuestionNameTypes.HousingMonthlyPayment,
        questionValidators,
        'Monthly housing expense',
        'Monthly housing expense',
        'Please enter monthly housing expense'
      );
    }
  }

  private modifyQuestionLanguage(
    questionMap: QuestionMap,
    questionName: QuestionNameTypes,
    questionValidators: QuestionValidators,
    updatedLabel: string,
    updatedPlaceholder: string,
    updatedRequiredErrorMessage: string
  ): void {
    questionMap[questionName].label = updatedLabel;
    questionMap[questionName].placeholder = updatedPlaceholder;
    questionValidators.sync = questionValidators.sync.map((validator: QuestionValidator) => {
      if (validator.validatorName === ValidatorTypes.Required) {
        return {
          ...validator,
          message: updatedRequiredErrorMessage,
        };
      } else {
        return validator;
      }
    });
  }
}
