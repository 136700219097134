import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadResponse, PatchLeadRequest } from '../../../../../fakesl/src/models/generated/leadmanagementservice.model';
import { abTestsBaseUrl } from '../configs';
import { AbTestStatusesUpdates } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AbTestsService {
  constructor(private readonly http: HttpClient) {}

  updateAbTestStatuses(leadId: string, abTestStatusesUpdates: AbTestStatusesUpdates): Observable<LeadResponse> {
    const url = `${abTestsBaseUrl.v1}/${leadId}`;
    const requestBody: Partial<PatchLeadRequest> = {
      abTestStatusMap: { ...abTestStatusesUpdates },
    };

    return this.http.patch<LeadResponse>(url, requestBody);
  }
}
