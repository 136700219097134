// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromOptionsReducer from '../reducers/options.reducer';

// options
export const selectOptionsState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.options
);

// entities
export const selectOptionsEntities = createSelector(
  selectOptionsState as any,
  fromOptionsReducer.selectOptionsEntities
);

// error
export const selectOptionsError = createSelector(selectOptionsState, fromOptionsReducer.selectOptionsStateError);

// loaded
export const selectOptionsLoaded = createSelector(selectOptionsState, fromOptionsReducer.selectOptionsStateLoaded);

// loading
export const selectOptionsLoading = createSelector(selectOptionsState, fromOptionsReducer.selectOptionsStateLoading);
