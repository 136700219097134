export enum DigitalExperienceTypes {
  AffiliateNoBranding = 'affiliate-no-branding',
  AffiliateBankrate = 'bankrate',
  AffiliateCompareCards = 'compare-cards',
  AffiliateCreditSoup = 'credit-soup',
  AffiliateEvenFinancial = 'even-financial',
  AffiliateLendingTree = 'lending-tree',
  AffiliateLightbox = 'lightbox',
  AffiliateSupermoney = 'super-money',
  AffiliateMonevo = 'monevo',
  AffiliateCreditKarma = 'credit-karma',
  BestEggProspect = 'prospect',
  BestEggWalkupEmail = 'walkup-email',
  BestEggWalkupNoEmail = 'walkup-no-email',
  BestEggKcf = 'kcf',
  OpportunityMicrofunnel = 'opportunity-microfunnel',
}

export enum ApplicationStepDirectionTypes {
  Forward = 'forward',
  Backward = 'backward',
}

export enum ApplicationStepNameTypes {
  Begin = 'begin',
  VerifyIdentity = 'verifyIdentity',
  ConfirmInfo = 'confirmInfo',
  ContactInformation = 'contactInformation',
  PhysicalAddress = 'physicalAddress',
  PersonalInformation = 'personalInformation',
  Housing = 'housing',
  ResidentialLength = 'residentialLength',
  CashAdvances = 'cashAdvances',
  Banking = 'banking',
  SocialSecurityNumber = 'socialSecurityNumber',
  AutopayEnrollment = 'autopayEnrollment',
  TermsAndConditions = 'termsAndConditions',
  Agreement = 'agreement',
  OpportunityMicrofunnel = 'opportunityMicrofunnel',
  WalkupInformation = 'walkupInformation',
  AffiliateLanding = 'affiliateLanding',
  BreakingBECCStep1 = 'breakingBECCStep1',
  BreakingBECCStep2 = 'breakingBECCStep2',
}
