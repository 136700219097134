// vendor
import { HttpErrorResponse } from '@angular/common/http';
// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { CardProspectOffer, Prospect } from '../../models';

export enum CardProspectOffersActionTypes {
  LoadCardProspectOfferById = '[Offers] Load Card Prospect Offer (By Id)',
  LoadCardProspectOfferByOfferCode = '[Offers] Load Card Prospect Offer (By Offer Code)',
  LoadCardProspectOfferByParams = '[Offers] Load Card Prospect Offer (By Params)',
  LoadCardProspectOfferSuccess = '[Offers] Load Card Prospect Offer Success',
  LoadCardProspectOfferFail = '[Offers] Load Card Prospect Offer Fail',
  SyncCardProspectToQuestions = '[Offers] Sync Card Prospect To Questions',
  ClearCardProspectOffer = '[Offers] Clear Card Prospect',
}

// load card prospect offer
export const loadCardProspectOfferById = createAction(
  CardProspectOffersActionTypes.LoadCardProspectOfferById,
  props<{ cardProspectOfferId: string }>()
);

export const loadCardProspectOfferByOfferCode = createAction(
  CardProspectOffersActionTypes.LoadCardProspectOfferByOfferCode,
  props<{ offerCode: string }>()
);

export const loadCardProspectOfferByParams = createAction(
  CardProspectOffersActionTypes.LoadCardProspectOfferByParams,
  props<{ emailAddress: string; offerCode: string }>()
);

export const loadCardProspectOfferSuccess = createAction(
  CardProspectOffersActionTypes.LoadCardProspectOfferSuccess,
  props<{ cardProspectOffer: CardProspectOffer }>()
);

export const loadCardProspectOfferFail = createAction(
  CardProspectOffersActionTypes.LoadCardProspectOfferFail,
  props<{ error: HttpErrorResponse }>()
);

// sync prospect to questions
export const syncCardProspectToQuestions = createAction(
  CardProspectOffersActionTypes.SyncCardProspectToQuestions,
  props<{ prospect: Prospect }>()
);

// clear prospect offer
export const clearCardProspectOffer = createAction(CardProspectOffersActionTypes.ClearCardProspectOffer);

export type CardProspectOffersAction = ReturnType<
  | typeof loadCardProspectOfferById
  | typeof loadCardProspectOfferByOfferCode
  | typeof loadCardProspectOfferByParams
  | typeof loadCardProspectOfferSuccess
  | typeof loadCardProspectOfferFail
  | typeof syncCardProspectToQuestions
  | typeof clearCardProspectOffer
>;
