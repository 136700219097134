<button
  class="c-form-tooltip"
  role="tooltip"
  tabindex="0"
  aria-label="tooltip"
  type="button"
  mat-icon-button
  [matTooltip]="tooltip"
  [matTooltipPosition]="tooltipPosition"
  (click)="onToggleTooltip($event)"
>
  <span class="c-form-tooltip__icon">
    <svg viewBox="0 0 24 24" role="tooltip" [class]="iconSize">
      <use href="#info" />
    </svg>
    <span>i</span>
  </span>
  <label class="c-form-tooltip__label" role="tooltip" *ngIf="tooltipLabel">{{ tooltipLabel }}</label>
</button>

<!--
  On mobile long press gesture on tooltip can cause icon img selecting.
  To avoid icon img select set icon as background-image. '?' text here
  acts as alt if background-image load issue and also allows visibility
  on print.
-->
