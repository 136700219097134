// ngrx
import * as fromCoreReducers from '../../core/store/reducers';
const { data: coreData } = fromCoreReducers.initialState;

export const defaultErrorMessages = {
  generalError: `An issue occurred while processing your request. Please contact us at ${coreData.contactPhoneNumber} or try again later.`,
  generalErrorShort: `Please contact us at ${coreData.contactPhoneNumber} or try again later.`,
  pageUnavailable: 'This page is unavailable',
  serviceClosed:
    "Thank you for your interest in the Best Egg Credit Card. We are currently not offering the Card product; however, Best Egg offers other products that may be of interest to you. <br /><br /> For more details, please go to <a href='https://www.bestegg.com' target='_blank'>www.bestegg.com</a>.",
};
