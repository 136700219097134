import { Params } from '@angular/router';
import { Action, createAction, props } from '@ngrx/store';
import { DecisionReasonTypes } from 'src/app/features/application/models';

export enum DashboardLoadProcessActionTypes {
  DashboardLoadProcessStart = '[Dashboard] Dashboard Load Process Start',
  DashboardLoadSuccess = '[Dashboard] Dashboard Load Success',
  DashboardLoadFail = '[Dashboard] Dashboard Load Fail',
  DashboardLeadLoadProcess = '[Dashboard] Dashboard Lead Load Process',
  IsPendDashboard = '[Dashboard] Is pend Dashboard',
}

export const dashboardLoadProcessStart = createAction(
  DashboardLoadProcessActionTypes.DashboardLoadProcessStart,
  props<{ queryParam: Params }>()
);

export const dashboardLeadLoadProcess = createAction(DashboardLoadProcessActionTypes.DashboardLeadLoadProcess);

export const dashboardLoadSuccess = createAction(DashboardLoadProcessActionTypes.DashboardLoadSuccess);

export const dashboardLoadFail = createAction(
  DashboardLoadProcessActionTypes.DashboardLoadFail,
  props<{ action: Action }>()
);

export const isPendDashboard = createAction(
  DashboardLoadProcessActionTypes.IsPendDashboard,
  props<{ isPendDashboard: boolean; pendReason?: DecisionReasonTypes }>()
);

export type DashboardLoadProcessAction = ReturnType<
  | typeof dashboardLoadProcessStart
  | typeof dashboardLoadSuccess
  | typeof dashboardLoadFail
  | typeof dashboardLeadLoadProcess
  | typeof isPendDashboard
>;
