<app-application-step
  class="c-terms-and-conditions"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <app-panel>
      <div class="c-terms-and-conditions__title title">
        {{ panelTitle }}
        <app-print-page-button [contentEl]="content" class="dark-print-button" [link]="true"> </app-print-page-button>
      </div>
      <div
        [class.adjustable_height]="isKcfExperience$ | async"
        id="c-terms-and-conditions__content"
        class="c-terms-and-conditions__content content"
        #content
      >
        <app-default-application-terms></app-default-application-terms>
      </div>
    </app-panel>
    <app-form-step-controls
      class="card-print-none"
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
