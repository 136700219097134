<div class="c-form-input o-form-field" #formField cdkMonitorSubtreeFocus (cdkFocusChange)="onFocusChange($event)">
  <mat-form-field
    [id]="question.name"
    class="mat-form-field"
    appearance="outline"
    [hideRequiredMarker]="true"
    floatLabel="always"
  >
    <mat-label [attr.for]="question.label" *ngIf="showLabel">
      {{ question.label }}
      <app-form-tooltip
        *ngIf="question.tooltip"
        [tooltip]="question.tooltip"
        [tooltipLabel]="question.tooltipLabel"
        tooltipPosition="after"
      ></app-form-tooltip>
    </mat-label>
    <input
      class="c-form-input__control"
      [name]="question.name"
      [attr.aria-label]="question.label"
      [type]="question.inputElType"
      [formControl]="formControl"
      [maxlength]="question.maxlength || null"
      [mask]="question.mask?.mask"
      [patterns]="question.mask?.patterns"
      [specialCharacters]="question.mask?.specialCharacters || defaultSpecialCharacters"
      [dropSpecialCharacters]="question.mask?.dropSpecialCharacters === false ? false : true"
      [showMaskTyped]="question.mask?.showMaskTyped"
      [thousandSeparator]="question.mask?.thousandSeparator"
      [validation]="question.mask?.validation === false ? false : true"
      [placeholder]="question.placeholder || ''"
      [errorStateMatcher]="matcher"
      (focus)="focus()"
      (blur)="blur()"
      matInput
      #matInput="matInput"
    />
    <span matPrefix class="mat-prefix" *ngIf="question.prefix || question.prefixIcon">
      <span [innerHtml]="question.prefix" *ngIf="question.prefix"></span>
      <svg
        class="prefix-icon"
        *ngIf="question.prefixIcon"
        alt=""
        [attr.height]="prefixIconConfig.height"
        [attr.width]="prefixIconConfig.width"
      >
        <use [attr.xlink:href]="iconAssetMap[question.prefixIcon]"></use>
      </svg>
    </span>
    <span matSuffix class="mat-suffix" *ngIf="question.suffix || question.suffixIcon">
      <span *ngIf="question.suffix" [innerHtml]="question.suffix"></span>
      <svg
        class="suffix-icon"
        *ngIf="question.suffixIcon"
        alt=""
        [attr.height]="suffixIconConfig.height"
        [attr.width]="suffixIconConfig.width"
      >
        <use [attr.xlink:href]="iconAssetMap[question.suffixIcon]"></use>
      </svg>
    </span>
    <ng-content select=".inner-fields"></ng-content>
    <mat-error class="mat-error" [class.mat-error--no-content]="!errorValidator.message" *ngIf="errorValidator">
      {{ errorValidator.message }}
    </mat-error>
    <mat-hint [@fieldErrorEnterAnimation] *ngIf="warningValidator">
      {{ warningValidator.message }}
    </mat-hint>
  </mat-form-field>
  <app-form-help-text
    aria-role="region"
    aria-live="polite"
    [expanded]="displayHelpText"
    [helpText]="question.helpText"
    *ngIf="question.helpText"
  >
  </app-form-help-text>
</div>
