// vendor
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
// store
import * as fromQuestionsValuesReducer from './question-values.reducer';
import * as fromValidationsReducer from './validations.reducer';

// key
export const QUESTIONS_FEATURE_KEY = 'questions';

export interface QuestionsState {
  // TODO_UPDATE: computed property
  [id: string]: fromQuestionsValuesReducer.QuestionsValuesState;
}

export const reducers: ActionReducerMap<QuestionsState> = {
  [fromQuestionsValuesReducer.QUESTION_VALUES_STATE_KEY]: fromQuestionsValuesReducer.reducer,
  [fromValidationsReducer.VALIDATIONS_STATE_KEY]: fromValidationsReducer.reducer,
};

export const selectQuestionsFeature = createFeatureSelector(QUESTIONS_FEATURE_KEY);
