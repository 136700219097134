// models
import * as fromQuestionsModels from '../../models';

const maxLength = 36;

export const employerNameQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  name: fromQuestionsModels.QuestionNameTypes.EmployerName,
  label: 'Employer name',
  placeholder: 'Employer name',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter employer name',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MaxLength,
        value: maxLength,
        message: `Maximum of ${maxLength} characters`,
      },
    ],
  },
};
