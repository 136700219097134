// vendor
import { Component, OnInit } from '@angular/core';
import { Router, ResolveStart } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';
import { first, filter, map } from 'rxjs/operators';
import IdleJs from 'idle-js';
// components
import { dialogComponentMap } from './features/shared/components/dialogs';
// models
import { Idle } from './models';
import { DialogTypes } from './features/shared/models';
// environments
import { environment } from '../environments/environment';
// utils
import { convertMinutesToMilliseconds } from './utils';
import { Meta } from '@angular/platform-browser';
import { WindowUtilService } from './features/shared/services/window-util.service';
// consts
const DEFAULT_IDLE_MINUTES = 15;
const DEFAULT_TIMEOUT_MINUTES = 2.5;
const DEFAULT_REDIRECT_URL_ON_TIMEOUT = `https://${environment.bestEggDomain}`;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loadingCompleteStatus$: Observable<boolean>;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private meta: Meta,
    private windowUtilService: WindowUtilService
  ) {}

  ngOnInit() {
    this.disableIosSafariAutoZoom();

    if (!environment.disableIdleTracking) {
      this.initIdleTracking();
    }

    this.loadingCompleteStatus$ = this.router.events.pipe(
      filter((event) => event instanceof ResolveStart),
      first(),
      map(() => true)
    );
  }

  initIdleTracking() {
    const idleTimeMs = convertMinutesToMilliseconds(DEFAULT_IDLE_MINUTES);
    const timeoutMs = convertMinutesToMilliseconds(DEFAULT_TIMEOUT_MINUTES);
    const idle: Idle = new IdleJs({
      idle: idleTimeMs,
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'touchmove', 'scroll'],
      onIdle: () => {
        idle.stop();
        this.dialog.closeAll();
        const dialogComponent = dialogComponentMap[DialogTypes.Idle];
        const idleDialog = this.dialog.open(dialogComponent);
        const timeout$ = timer(timeoutMs).subscribe(() => {
          console.warn('[WARN] user idle timeout');
          window.location.href = DEFAULT_REDIRECT_URL_ON_TIMEOUT;
        });
        idleDialog
          .afterClosed()
          .pipe(first())
          .subscribe(() => {
            // stop timeout
            timeout$.unsubscribe();
            if (idle.idle) {
              idle.reset().start();
            }
          });
      },
    });
    idle.start();
  }

  private disableIosSafariAutoZoom() {
    if (this.isIosWebKit()) {
      const metaViewport = this.meta.getTag('name="viewport"');

      if (metaViewport) {
        // In iOS WebKit browsers only (iOS Safari, iOS Chrome), adding maximum-scale=1 to the meta
        // viewport content will disable the auto-zoom behavior while still allowing pinch-to-zoom.
        // On other mobile browsers, this value will disable punch-to-zoom entirely, so we can only
        // apply it when on iOS Safari. This is an issue with our style guide, since the input font
        // size is smaller than 16px, causing the auto-zoom behavior to kick in. Due to a separate
        // issue with the Angular Material Dropdown
        // (https://marlettefundingllc.atlassian.net/browse/CARD-392), auto-zoom causes the dropdown
        // menu to be positioned partially off screen. Disabling auto-zoom mitigates this, and also
        // makes for a better user experience, since the auto-zoom does not reset on step change.
        // Solution adapted from https://stackoverflow.com/a/46254706
        metaViewport.content += ', maximum-scale=1';
      }
    }
  }

  private isIosWebKit() {
    // iOS WebKit detection adapted from: https://stackoverflow.com/a/29696509
    const userAgent: string = this.windowUtilService.nativeWindow.navigator.userAgent;
    const isIos = Boolean(userAgent.match(/iPad/i)) || Boolean(userAgent.match(/iPhone/i));
    const isWebKit = Boolean(userAgent.match(/WebKit/i));

    return isIos && isWebKit;
  }
}
