/* eslint-disable id-blacklist */
/* eslint-disable id-denylist */
export enum FormElTypes {
  Input = 'input',
  Select = 'select',
  Checkbox = 'checkbox',
  CheckboxGroup = 'checkboxGroup',
  RadioGroup = 'radioGroup',
  AutoComplete = 'autoComplete',
}

export enum InputElTypes {
  Checkbox = 'checkbox',
  Radio = 'radio',
  Email = 'email',
  Number = 'number',
  Text = 'text',
  Tel = 'tel',
  Password = 'password',
}

export enum MaskTypes {
  Money = 'separator.2',
  DateOfBirth = '00/00/0000',
  SocialSecurityNumber = '000-00-0000',
  Last4SocialSecurityNumber = '0000',
  Telephone = '(000) 000-0000',
  ZipCode = '00000-0000',
}

export enum ValidatorTypes {
  Required = 'required',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Min = 'min',
  Max = 'max',
  Pattern = 'pattern',
  ProperName = 'properName',
  ContainsAnd = 'containsAnd',
  DateFormat = 'dateFormat',
  OfAge = 'ofAge',
  OfAgeForState = 'ofAgeForState',
  IsPoBox = 'isPoBox',
  Mask = 'mask',
  MobileOrHomePhoneRequired = 'mobileOrHomePhoneRequired',
  SecondaryPhoneType = 'secondaryPhoneType',
  PhoneNoLeadingDigitOfOne = 'phoneNoLeadingDigitOfOne',
  Address = 'address',
  PhysicalAddress = 'physicalAddress',
  RequiredTrue = 'requiredTrue',
  OverAgeWarning = 'overAgeWarning',
  BackendGeneral = 'backendGeneral',
  StatementPreferenceRequired = 'statementPreferenceRequired',
  UnsupportedState = 'unsupportedState',
  InvalidState = 'invalidState',
}

export enum QuestionOptionTypes {
  States = 'states',
  EmploymentStatuses = 'employmentStatuses',
  EmploymentLengths = 'employmentLengths',
  HousingTypes = 'housingTypes',
  ResidentialLengths = 'residentialLengths',
  CashAdvances = 'cashAdvances',
  BankingAccounts = 'bankingAccounts',
  None = 'NONE',
}

export enum HelpTextTypes {
  AnnualIncome = 'annualIncome',
  CardName = 'cardName',
  Phone = 'phone',
  ResidencyRequirement = 'residencyRequirement',
}

// if new question is introduced, remember to add the new question type to enterprise event schema
// https://github.com/MarletteFunding/enterprise-events
export enum QuestionNameTypes {
  Address = 'address',
  Agreement = 'agreement',
  AnnualIncome = 'annualIncome',
  AutopayEnrollment = 'autopayEnrollment',
  Banking = 'banking',
  CashAdvances = 'cashAdvances',
  City = 'city',
  ConfirmInformationCorrect = 'confirmInformationCorrect',
  DateOfBirth = 'dateOfBirth',
  EmailAddress = 'emailAddress',
  OfferCode = 'offerCode',
  EmploymentStatus = 'employmentStatus',
  EmployerName = 'employerName',
  FirstName = 'firstName',
  HousingMonthlyPayment = 'housingMonthlyPayment',
  HousingType = 'housingType',
  LastFourSocialSecurityNumber = 'lastFourSocialSecurityNumber',
  LastName = 'lastName',
  PaperStatementPreference = 'paperStatementPreference',
  PaperlessStatementPreference = 'paperlessStatementPreference',
  PhysicalAddress = 'physicalAddress',
  PhysicalCity = 'physicalCity',
  PhysicalState = 'physicalState',
  PhysicalZipCode = 'physicalZipCode',
  ResidentialLength = 'residentialLength',
  MobilePhone = 'mobilePhone',
  HomePhone = 'homePhone',
  SecondaryPhoneType = 'secondaryPhoneType',
  EmployerPhone = 'employerPhone',
  SocialSecurityNumber = 'socialSecurityNumber',
  State = 'state',
  ZipCode = 'zipCode',
}
