// vendor
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromRouterStore from '@ngrx/router-store';
import * as fromRouterReducer from './router.reducer';
// exports
export * from './router.reducer';

// key
export const ROUTER_FEATURE_KEY = 'router';

export interface State {
  [id: string]: fromRouterStore.RouterReducerState<fromRouterReducer.RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  [ROUTER_FEATURE_KEY]: fromRouterStore.routerReducer,
};

export const selectRouterFeature =
  createFeatureSelector<fromRouterStore.RouterReducerState<fromRouterReducer.RouterStateUrl>>(ROUTER_FEATURE_KEY);
