/**
 * module: shared
 * desc: used for commonly used directives/pipes/components across modules
 * notes: none
 */

// vendor
import { Injector, NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
// components
import * as fromComponents from './components';
// pipes
import * as fromPipes from './pipes';
// directives
import * as fromDirectives from './directives';
import { EnterpriseAuthModule } from '@marlettefunding/frontend-common';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [...fromComponents.components, ...fromPipes.pipes, ...fromDirectives.directives],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    EnterpriseAuthModule.forRoot(environment.enterpriseAuthAccountEnv),
  ],
  exports: [...fromComponents.components, ...fromPipes.pipes, ...fromDirectives.directives],
  providers: [fromPipes.CurrencySimplePipe, fromPipes.PercentSimplePipe, DecimalPipe, TitleCasePipe],
})
export class SharedModule {
  static injector: Injector;

  constructor(injector: Injector) {
    // Injector added to use to get services in Decorators
    SharedModule.injector = injector;
  }
}
