// models
import * as fromQuestionsModels from '../../models';
import { ValidatorTypes } from '../../models';
// configs
import { formValidatorMsgMap } from '../validators';
import { commonPhoneQuestion } from './common-phone.config';

export const homePhoneQuestion: fromQuestionsModels.Question = {
  ...commonPhoneQuestion,
  name: fromQuestionsModels.QuestionNameTypes.HomePhone,
  label: 'Home phone',
  validators: {
    sync: [
      ...commonPhoneQuestion.validators.sync,
      {
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
        message: formValidatorMsgMap.mobileOrHomePhoneRequired,
        readonly: true,
      },
    ],
  },
};
