// vendor
import { Component, OnInit } from '@angular/core';
import { StylableDialogComponent } from '../stylable-dialog/stylable-dialog.component';

@Component({
  selector: 'app-idle-dialog',
  templateUrl: './idle-dialog.component.html',
})
export class IdleDialogComponent extends StylableDialogComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    this.dialogRef.addPanelClass('c-dialog--less-rounded-border');
  }
}
