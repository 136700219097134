// vendor
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// ngrx
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromApplicationActions from '../actions';
// services
import { ApplicationService } from '../../services';
// models
import { Options } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class OptionsEffects {
  constructor(private applicationService: ApplicationService, private action$: Actions) {}

  // load options
  loadOptionsEffects$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.loadOptions),
      switchMap(() =>
        this.applicationService.getOptions().pipe(
          map((options: Options) => fromApplicationActions.loadOptionsSuccess({ options })),
          catchError((error: HttpErrorResponse) => of(fromApplicationActions.loadOptionsFail({ error })))
        )
      )
    )
  );
}
