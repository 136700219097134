// rxjs
import { createReducer, on } from '@ngrx/store';
import * as fromCardFunnelTermsAvailabilityActions from '../actions';

// key
export const CARD_FUNNEL_TERMS_AVAILABILITY_STATE_KEY = 'cardFunnelTermsAvailability';

// state
export interface CardFunnelTermsAvailabilityState {
  termsErrors: boolean;
  loading: boolean; // not used, but needed to avoid TS errors
}

export const initialState: CardFunnelTermsAvailabilityState = {
  termsErrors: false,
  loading: false,
};

// reducer
const cardFunnelTermsAvailabilityReducer = createReducer(
  initialState,
  // load
  on(fromCardFunnelTermsAvailabilityActions.updateCardFunnelTermsAvailability, (state, action) => ({
    ...state,
    termsErrors: action.termsErrors,
  }))
);

export const reducer = (
  state: CardFunnelTermsAvailabilityState,
  action: fromCardFunnelTermsAvailabilityActions.CardFunnelTermsAvailabilityAction
) => cardFunnelTermsAvailabilityReducer(state, action);

// state selectors
export const selectCardFunnelAvailabilityTermsErrors = (state: CardFunnelTermsAvailabilityState) => state.termsErrors;
