// vendor
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, concatMap, map, catchError, withLatestFrom } from 'rxjs/operators';
// store
import { select, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromLeadActions from '../actions/lead.actions';
import * as fromLeadReducers from '../reducers/lead.reducer';
import * as fromLeadSelectors from '../selectors/lead.selectors';
import * as fromQuestionsStore from '../../../questions/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
// services
import { LeadService, LeadSyncService } from '../../services';
// models
import { Lead } from '../../models';
import { QuestionValues } from '../../../questions/models';
import { CasCustomerData } from 'fakesl/src/models/generated/leadmanagementservice.model';
import { leadHelpers } from '../../services/helpers/lead.helpers';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
import { ApplicationStepNameTypes } from 'src/app/features/digital-experience/models';

const RETRY_LIMIT = 3;

@Injectable({
  providedIn: 'root',
})
export class LeadEffects {
  constructor(
    private leadService: LeadService,
    private leadSyncService: LeadSyncService,
    private EEService: EnterpriseEventTrackingService,
    private actions$: Actions,
    private store: Store<fromLeadReducers.LeadState>
  ) {}

  // load lead
  loadLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLeadActions.loadLead),
      switchMap((action) =>
        this.leadService.getLead(action.leadId).pipe(
          map((lead: Lead) => fromLeadActions.loadLeadSuccess({ lead })),
          catchError((error: HttpErrorResponse) => of(fromLeadActions.loadLeadFail({ error })))
        )
      )
    )
  );

  // load lead by params
  loadLeadByParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLeadActions.loadLeadByParams),
      switchMap((action) => {
        const { emailAddress, offerCode } = action;
        return this.leadService.getLeadByParams(emailAddress, offerCode).pipe(
          map((lead: Lead) => fromLeadActions.loadLeadSuccess({ lead })),
          catchError((error: HttpErrorResponse) => of(fromLeadActions.loadLeadFail({ error })))
        );
      })
    )
  );

  // update lead
  updateLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromLeadActions.updateLead),
      withLatestFrom(this.store.pipe(select(fromLeadSelectors.selectLeadData))),
      map(([action, leadData]: [any, Lead]) => {
        let questionValues: QuestionValues = Object.assign({}, action.questionValues);
        const { id } = leadData;

        /**
         * In some cases we want to attach some fields from a different step to the PATCH payload.
         * For now this is just for clearing fields.
         */
        const extraQuestionsValues = action.extraQuestionsValues;
        if (extraQuestionsValues) {
          questionValues = {
            ...questionValues,
            ...extraQuestionsValues,
          };
        }
        return [id, questionValues, leadData];
      }),
      map(([id, questionValues, leadData]: [any, QuestionValues, Lead]) => {
        const partialLead = this.leadSyncService.syncDataToLead(questionValues);
        return [id, partialLead, leadData];
      }),
      concatMap(([id, partialLead, leadData]: [string, Partial<Lead>, Lead]) =>
        this.leadService.updateLead(id, partialLead).pipe(
          map((lead: Lead) =>
            fromLeadActions.updateLeadSuccess({
              lead: {
                ...lead,
                ...this.leadSyncService.syncPartialLeadForStoreUpdate(partialLead, leadData),
              },
            })
          ),
          catchError((error: HttpErrorResponse) => [
            fromLeadActions.updateLeadFail({ error }),
            fromQuestionsStore.setBackendErrors({ error }),
          ])
        )
      )
    )
  );

  validateKcfVerifyIdentity$ = createEffect(() => {
    let retryTimes = 1;
    return () =>
      this.actions$.pipe(
        ofType(fromLeadActions.validateKcfVerifyIdentity),
        withLatestFrom(this.store.pipe(select(fromLeadSelectors.selectLeadCasData))),
        switchMap(([action, casData]) =>
          this.leadService.validateKcfVerifyIdentity(action.formValue, casData).pipe(
            switchMap((customer: CasCustomerData) => [
              fromLeadActions.validateKcfVerifyIdentitySuccess(),
              fromQuestionsStore.updateQuestionValues({
                questionValues: { ...leadHelpers.mapCustomerToQuestionValue(customer) },
              }),
              fromDigitalExperienceStore.setStepAndUpdateStepsAllowed({
                skipStepsChange: true,
                metaData: {
                  stepName: ApplicationStepNameTypes.VerifyIdentity,
                  actionSubType: 'verifySuccess',
                },
              }),
            ]),
            catchError((error: Record<string, unknown>) => {
              if (retryTimes === RETRY_LIMIT) {
                this.EEService.reportKCFAuthFail();
                return [
                  fromDigitalExperienceStore.setStepAndUpdateStepsAllowed({
                    skipStepsChange: true,
                    metaData: {
                      stepName: ApplicationStepNameTypes.VerifyIdentity,
                      actionSubType: 'verifyFail',
                    },
                  }),
                  fromLeadActions.validateKcfVerifyIdentityFail({ error, retryLimitReached: true }),
                ];
              } else {
                retryTimes++;
                return of(
                  fromLeadActions.validateKcfVerifyIdentityFail({
                    error,
                  })
                );
              }
            })
          )
        )
      );
  });

  // // card offer load process
  // leadLoadProcess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromLeadActions.leadLoadProcess),
  //     switchMap((action) => {
  //       let nextActions = [
  //         // fromOffersActions.clearCardProspectOffer(),
  //         // fromOffersActions.clearCardProductOffer(),
  //         // fromOffersActions.clearCardProduct(),
  //         // fromDigitalExperienceStore.clearDigitalExperience(),
  //       ];
  //       const { emailAddress, offerCode, cpid } = action.applicationMeta;
  //       /*
  //       Override default load action only if both email/code params are undefined,
  //       to avoid server call. Allow remaining instances through for possible
  //       alertness around malformed urls
  //       */
  //       if (emailAddress === undefined && offerCode === undefined) {
  //         // nextAction = fromOffersActions.cardOfferLoadProcessFail();
  //         // console.error('[OFFER_LOAD_PROCESS] params undefined');
  //       }

  //       return this.leadService.getLeadV2(action.applicationMeta).pipe(
  //         map((lead: any) => {
  //           // Once we get back the response containing all the data (lead, campaign and prospect), then return
  //           // actions for populating each of the stores that contain this data.
  //           return fromLeadActions.createLeadSuccess({ lead: { leadId: lead.leadId } });
  //         }),
  //         catchError((error: HttpErrorResponse) =>
  //           of(fromLeadActions.createLeadFail({ error }))
  //         )
  //       )

  //       // return [
  //       //   fromOffersActions.clearCardProspectOffer(),
  //       //   fromOffersActions.clearCardProductOffer(),
  //       //   fromOffersActions.clearCardProduct(),
  //       //   fromDigitalExperienceStore.clearDigitalExperience(),
  //       //   nextAction,
  //       // ];
  //     })
  //   )
  // );
}
