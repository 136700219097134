// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardProductReducer from '../reducers/card-product.reducer';

// card product state
export const selectCardProductState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardProduct
);

// data
export const selectCardProductData = createSelector(
  selectCardProductState,
  fromCardProductReducer.selectCardProductData
);

// loading
export const selectCardProductLoading = createSelector(
  selectCardProductState,
  fromCardProductReducer.selectCardProductLoading
);

// loaded
export const selectCardProductLoaded = createSelector(
  selectCardProductState,
  fromCardProductReducer.selectCardProductLoaded
);
