/**
 * Modals that extend this class will have the `c-dialog-stylable` class added to the CDK pane
 * overlay. This provides for easier overriding of Angular Material styles, and is used heavily in
 * `src/styles/angular-material-overrides/mat-dialog.scss` to apply Best Egg branding design system
 * styles to the Angular Material modal.
 */
import { Directive, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
export abstract class StylableDialogComponent implements OnInit {
  // eslint-disable @angular-eslint/directive-class-suffix
  dialogRef: MatDialogRef<any>;

  constructor(@Inject(Injector) protected injector: Injector) {
    this.setupInjectors();
  }

  setupInjectors(): void {
    this.dialogRef = this.injector.get(MatDialogRef);
  }

  ngOnInit() {
    this.dialogRef.addPanelClass('c-dialog--stylable');
  }
}
