import { AbTestNames, AbTestsConfigMap, BECCBreakPopulations } from '../models';

/**
 * AB test configurations go here. A config is a mapping of AB test names to their expected and
 * default populations.
 */
export const abTestsConfig: AbTestsConfigMap = {
  [AbTestNames.BREAKING_BECC]: {
    testName: AbTestNames.BREAKING_BECC,
    expectedPopulations: {
      BREAKBECC: BECCBreakPopulations.BREAKBECC,
      NOBREAKBECC: BECCBreakPopulations.NOBREAKBECC,
    },
    defaultPopulation: BECCBreakPopulations.NOBREAKBECC,
  },
  [AbTestNames.BUTTON_POP]: {
    testName: AbTestNames.BUTTON_POP,
    expectedPopulations: {
      GRAYEDOUT: 'grayedOut',
      ENABLEDBUTTON: 'enabledButton',
    },
    defaultPopulation: 'grayedOut',
  },
  [AbTestNames.TOGGLE_APPLICATION_SUBMIT]: {
    testName: AbTestNames.TOGGLE_APPLICATION_SUBMIT,
    expectedPopulations: {
      SUBMIT: 'submit',
      NOTSUBMIT: 'notSubmit',
    },
    defaultPopulation: 'submit',
  },
};
