import {
  ApplicationStep,
  ApplicationStepNameTypes,
  DigitalExperienceTypes,
  StepComponentMap,
} from '../../../../models';
import { defaultStepComponentMap } from '../../../shared';
import {
  baseWalkupInformationApplicationStep,
  baseApplicationSteps,
  affiliateLandingStep,
  getAffiliateImage,
} from '../../base-walkup/application-steps.config';

// #walkup-information
const walkupInformationApplicationStep: ApplicationStep = {
  ...baseWalkupInformationApplicationStep,
  title: getAffiliateImage(DigitalExperienceTypes.AffiliateLightbox),
};

export const applicationSteps: ApplicationStep[] = [
  affiliateLandingStep,
  walkupInformationApplicationStep,
  ...baseApplicationSteps,
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
