<app-application-step
  class="c-confirm-information"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <ng-container *ngFor="let sectionConfig of formSectionConfigs | keyvalue : asIsOrder">
      <ng-container
        *ngTemplateOutlet="
          prefillStatus(sectionConfig.key) ? collapsedForm : expandedForm;
          context: { $implicit: sectionConfig }
        "
      >
      </ng-container>
    </ng-container>

    <p class="c-confirm-information__message">Please be thorough as your information may be subject to verification.</p>

    <app-form-checkbox
      [parent]="form"
      [question]="questionMap[questionNameTypes.ConfirmInformationCorrect]"
      sizeClass="large"
    ></app-form-checkbox>

    <app-form-validation [validation]="errorValidator"></app-form-validation>

    <app-form-validation [validatorType]="backendGeneral"></app-form-validation>

    <app-form-step-controls
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>

<ng-template #collapsedForm let-sectionConfig>
  <app-confirm-info-card [displayData]="form.value" [sectionName]="sectionConfig.key" (edit)="onEdit($event)">
  </app-confirm-info-card>
</ng-template>

<ng-template #expandedForm let-sectionConfig>
  <app-panel class="c-confirm-info-card" [attr.id]="expandedSectionId(sectionConfig.key)">
    <div class="title">
      <span class="c-confirm-info-card__name">{{ sectionConfig.key }}</span>
    </div>
    <div class="content">
      <app-dynamic-form
        [form]="form"
        [formConfig]="sectionConfig.value.formConfig"
        [questionOptions]="applicationStepData.applicationQuestionOptions"
        [ignoreBackendErrors]="true"
      ></app-dynamic-form>
    </div>
  </app-panel>
</ng-template>
