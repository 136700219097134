// models
import * as fromQuestionsModels from '../../models';

const minLength = 2;
const maxLength = 32;

export const lastNameQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  name: fromQuestionsModels.QuestionNameTypes.LastName,
  label: 'Last name',
  placeholder: 'Last name',
  maxlength: maxLength,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter last name',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.ProperName,
        message: 'Invalid characters',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MinLength,
        value: minLength,
        message: `Minimum of ${minLength} characters`,
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MaxLength,
        value: maxLength,
        message: `Maximum of ${maxLength} characters`,
      },
    ],
  },
};
