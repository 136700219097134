<div class="c-dialog c-error-dialog">
  <div mat-dialog-title class="c-dialog__header c-dialog__header--small">
    <svg viewBox="0 0 24 24" class="c-dialog__header-icon--error">
      <use href="#error-diamond-bold"></use>
    </svg>
    <h2 class="mat-dialog-title mat-dialog-title--small" cdkFocusInitial tabindex="-1">
      Oops, an error occurred trying to process your request.
    </h2>
    <button type="button" class="c-dialog__header-close" mat-dialog-close>
      <svg viewBox="0 0 24 24">
        <use href="#close"></use>
      </svg>
    </button>
  </div>
  <div class="mat-typography mat-mdc-dialog-content--small mat-mdc-dialog-content" *ngIf="error">
    <p class="c-error-dialog__message" *ngIf="error.message">{{ error.message }}</p>
    <ul *ngIf="error.errors as errors">
      <li class="c-error-dialog__error" *ngFor="let errorItem of errors | keyvalue">
        <strong>{{ errorItem.key }}: </strong>
        {{ errorItem.value }}
      </li>
    </ul>
  </div>
  <div class="mat-mdc-dialog-actions mat-mdc-dialog-actions--small">
    <button mat-button mat-dialog-close><u>Close</u></button>
  </div>
</div>
