<div class="c-form-input o-form-field">
  <mat-form-field
    [id]="question.name"
    class="mat-form-field"
    appearance="outline"
    [hideRequiredMarker]="true"
    floatLabel="always"
  >
    <mat-label [attr.for]="question.label">
      {{ question.label }}
      <app-form-tooltip
        *ngIf="question.tooltip"
        [tooltip]="question.tooltip"
        [tooltipLabel]="question.tooltipLabel"
        tooltipPosition="after"
      ></app-form-tooltip>
    </mat-label>
    <mat-select
      class="c-form-input__control"
      [attr.name]="question.name"
      [attr.aria-label]="question.label"
      [formControl]="formControl"
      [errorStateMatcher]="matcher"
      [placeholder]="question.placeholder"
      panelClass="mat-select-panel-override"
    >
      <mat-option *ngFor="let option of question.options || options" value="{{ option.value }}">
        {{ option.label }}
      </mat-option>
    </mat-select>
    <mat-error class="mat-error" [class.mat-error--no-content]="!errorValidator.message" *ngIf="errorValidator">
      {{ errorValidator.message }}
    </mat-error>
  </mat-form-field>
</div>
