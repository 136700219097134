// models
import { Map } from '../../shared/models';

export const offerBasePath = 'offerservice';
export const productBasePath = 'productservice';

export const offerServiceBaseUrl: Map<string> = {
  v1: `${offerBasePath}/api/v1`,
};

export const productServiceBaseUrl: Map<string> = {
  v1: `${productBasePath}/api/v1`,
};

export const prospectsOfferPartialUrl = 'prospects/offer';
export const productsOfferPartialUrl = 'products/offer';
export const productsOffersPartialUrl = 'products/offers';
