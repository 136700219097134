// ngrx
import { createReducer, on } from '@ngrx/store';
import * as fromCardCardProductOffersActions from '../actions/card-product-offers.actions';
// models
import { CardProductOffer } from '../../models';

// key
export const CARD_PRODUCT_OFFER_STATE_KEY = 'cardProductOffer';

// state
export interface CardProductOfferState {
  data: CardProductOffer;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CardProductOfferState = {
  data: null,
  loaded: false,
  loading: false,
};

// reducer
const cardProductOfferReducer = createReducer(
  initialState,
  // load
  on(
    fromCardCardProductOffersActions.loadCardProductOffer,
    fromCardCardProductOffersActions.loadCardProductDefaultOffer,
    (state: CardProductOfferState) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    fromCardCardProductOffersActions.loadCardProductOfferSuccess,
    (
      state: CardProductOfferState,
      action: ReturnType<typeof fromCardCardProductOffersActions.loadCardProductOfferSuccess>
    ) => ({
      ...state,
      data: action.cardProductOffer,
      loading: false,
      loaded: true,
    })
  ),
  on(
    fromCardCardProductOffersActions.loadCardProductOfferFail,
    (
      state: CardProductOfferState,
      action: ReturnType<typeof fromCardCardProductOffersActions.loadCardProductOfferFail>
    ) => ({
      ...state,
      loaded: false,
      loading: false,
    })
  ),
  on(fromCardCardProductOffersActions.clearCardProductOffer, (state: CardProductOfferState) => initialState)
);

export const reducer = (
  state: CardProductOfferState,
  action: fromCardCardProductOffersActions.CardProductOffersAction
) => cardProductOfferReducer(state, action);

// state selectors
export const selectCardProductOfferStateData = (state: CardProductOfferState) => state.data;
export const selectCardProductOfferStateLoaded = (state: CardProductOfferState) => state.loaded;
export const selectCardProductOfferStateLoading = (state: CardProductOfferState) => state.loading;
