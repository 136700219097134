// vendor
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// models
import {
  Application,
  ApplicationSubmit,
  ApplicationSubmitResponse,
  CreditScoreNoticeEvent,
  DocumentFile,
} from '../models';
import { IAbTestData } from '../../ab-tests/models';
import { getDefaultAbTestData } from '../../ab-tests/services/helpers/get-default-ab-test-data';
import { Brand } from '../../offers/models';
import { Map, Item } from '../../shared/models';
import { ApplicationFromLeadRequest } from 'fakesl/src/models/generated/applicationservice.model';
// configs
import * as fromApplicationConfigs from '../configs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private http: HttpClient) {}

  /**
   * get options
   * @param filter
   * @return observable of options
   */
  getOptions(filter: string = null): Observable<Map<Item[]>> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/options`;
    const params = filter ? new HttpParams().set('filter', filter) : null;
    return this.http.get<Map<Item[]>>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * get application
   * @param applicationId applications identifier
   * @return observable of application
   */
  getApplication(applicationId: string): Observable<Application> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/${applicationId}`;
    return this.http.get<Application>(url).pipe(catchError((err) => throwError(err)));
  }

  /**
   * submit application
   * @param application application
   * @returns observable of submit response
   */
  // TODO_UPDATE: response type
  submitApplication(application: ApplicationSubmit): Observable<ApplicationSubmitResponse> {
    const baseUrl = fromApplicationConfigs.applicationServiceBaseUrl.v2;
    const url = `${baseUrl}/apply`;
    return this.http.post<ApplicationSubmitResponse>(url, application).pipe(catchError((err) => throwError(err)));
  }

  /**
   * submit application from lead, only used in opportunity microfunnel app submit
   * @param application application
   * @returns observable of submit response
   */
  submitApplicationFromLead(application: ApplicationFromLeadRequest): Observable<ApplicationSubmitResponse> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v2}/applyFromLead`;
    return this.http.post<ApplicationSubmitResponse>(url, application).pipe(catchError((err) => throwError(err)));
  }

  /**
   * load documents
   * @param applicationId
   * @returns observable documents
   */
  loadDocuments(applicationId: string, abTestData: IAbTestData = getDefaultAbTestData()): Observable<DocumentFile[]> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/verificationdocument/${applicationId}`;
    return this.http.get<DocumentFile[]>(url).pipe(catchError((err) => throwError(err)));
  }

  /**
   * upload document
   * @param document
   * @returns observable of http event
   */
  uploadDocument(applicationId: string, caseCenterId: string, file: File): Observable<HttpEvent<any>> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/verificationdocument/${applicationId}`;
    const params = new HttpParams().set('caseCenterId', caseCenterId);
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .post<HttpEvent<any>>(url, formData, {
        params,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * select card brand
   * @param cardBrand
   * @returns observable of response
   */
  selectCardBrand(applicationId: string, cardBrand: Brand, employerPhone?: string): Observable<any> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/${applicationId}/selectBrand`;
    const params = employerPhone ? new HttpParams().set('employerPhone', employerPhone) : null;
    return this.http
      .post<any>(url, cardBrand, {
        params,
      })
      .pipe(catchError((err) => throwError(err)));
  }

  /**
   * send credit score notice disclosure event
   * @param creditScoreNoticeEvent
   * @returns observable
   */
  sendCreditScoreNoticeEvent(creditScoreNoticeEvent: CreditScoreNoticeEvent): Observable<any> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/disclosure`;
    return this.http.post<any>(url, creditScoreNoticeEvent).pipe(catchError((err) => throwError(err)));
  }

  getAuthUserToken(applicationId: string): Observable<any> {
    const url = `${fromApplicationConfigs.applicationServiceBaseUrl.v1}/getTokenizedCid/${applicationId}`;
    return this.http.get<any>(url);
  }
}
