// vendor
import { trigger, style, animate, transition, query, sequence, keyframes, state } from '@angular/animations';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
// models
import { ApplicationStepDirectionTypes } from '../../digital-experience/models';
// consts
export const STEP_TRANSITION_DURATION = 300;

/**
 * Used for the help text.
 */
export const enterAnimation = [
  trigger('enterAnimation', [
    transition(':enter', [style({ height: '0', overflow: 'hidden' }), animate('300ms', style({ height: '*' }))]),
  ]),
];

export const enterLeaveAnimation = [
  trigger('enterLeaveAnimation', [
    transition(':enter', [style({ height: '0', overflow: 'hidden' }), animate('500ms', style({ height: '*' }))]),
    transition(':leave', [style({ height: '*' }), animate('500ms', style({ height: '0', overflow: 'hidden' }))]),
  ]),
];

/**
 * spin animation
 */
export const spinAnimation = [
  trigger('spinAnimation', [
    state('true', style({ transform: 'rotate(0deg)' })),
    state('false', style({ transform: 'rotate(360deg)' })),
    transition('true => false', animate('2000ms')),
    transition('false => true', animate('0ms')),
  ]),
];

/**
 * Trying to do what Material does when field errors enter.
 * Used for mat-hints.
 */
export const fieldErrorEnterAnimation = [
  trigger('fieldErrorEnterAnimation', [
    transition(':enter', [
      style({
        transform: 'translateY(-50%)',
        opacity: '0',
      }),
      animate('300ms', style({ transform: 'translateY(0%)', opacity: '1' })),
    ]),
  ]),
];

/**
 * Used for transition between steps.
 * forward/backward refers to the step direction. We have different enter/leave
 * animations depending on step direction.
 */
export const fadeAnimation = [
  trigger('fadeAnimation', [
    transition(
      (fromState, toState) => typeof toState === 'string' && toState.includes(ApplicationStepDirectionTypes.Forward),
      [
        query(
          '.c-application-step__animation-wrapper:enter',
          [
            style({ opacity: 0, transform: 'translateY(5rem)' }),
            sequence([
              animate(
                `${STEP_TRANSITION_DURATION}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({ opacity: 1, transform: 'translateY(-0.5rem)' })
              ),
              animate(
                `${STEP_TRANSITION_DURATION}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({ transform: 'translateY(0)' })
              ),
            ]),
          ],
          { optional: true }
        ),
        query(
          '.c-application-step__animation-wrapper:leave',
          [
            style({ opacity: 1, transform: 'translateY(0)' }),
            animate(`${STEP_TRANSITION_DURATION}ms ease-out`, style({ opacity: 0, transform: 'translateY(-5rem)' })),
          ],
          { optional: true }
        ),
      ]
    ),
    transition(
      (fromState, toState) => typeof toState === 'string' && toState.includes(ApplicationStepDirectionTypes.Backward),
      [
        query(
          '.c-application-step__animation-wrapper:enter',
          [
            style({ opacity: 0, transform: 'translateY(-5rem)' }),
            sequence([
              animate(
                `${STEP_TRANSITION_DURATION}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({ opacity: 1, transform: 'translateY(0.5rem)' })
              ),
              animate(
                `${STEP_TRANSITION_DURATION}ms cubic-bezier(0.35, 0, 0.25, 1)`,
                style({ transform: 'translateY(0)' })
              ),
            ]),
          ],
          { optional: true }
        ),
        query(
          '.c-application-step__animation-wrapper:leave',
          [
            style({ opacity: 1, transform: 'translateY(0)' }),
            animate(`${STEP_TRANSITION_DURATION}ms ease-out`, style({ opacity: 0, transform: 'translateY(5rem)' })),
          ],
          { optional: true }
        ),
      ]
    ),
  ]),
];

export const bounceAnimation = [
  trigger('bounce', [
    transition(':enter', [
      animate(
        '600ms',
        keyframes([
          style({ transform: 'translateY(0)' }),
          style({ transform: 'translateY(-60px)' }),
          style({ transform: 'translateY(0)' }),
        ])
      ),
    ]),
  ]),
];

// physical scroll animation
export const scrollBounceAnimation = (el: HTMLElement, position: number = 150, duration: number = 400): void => {
  interval(duration)
    .pipe(take(2))
    .subscribe(() => {
      el.scroll({ top: position, behavior: 'smooth' });
      position = -position;
    });
};
