<!--
 This looks a little odd but mat-stroked-button and mat-button cannot be applied conditionally
 and work like they should as directives so had to ngIf 2 button tags. The content template is
 used in the router outlet so that didnt' have to be duplicated.

-->
<button
  *ngIf="!link"
  class="o-print-page-button"
  aria-label="print document"
  type="button"
  mat-stroked-button
  (click)="onPrint()"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>

<button
  *ngIf="link"
  class="o-print-page-button button-link-style"
  aria-label="print document"
  type="button"
  mat-button
  (click)="onPrint()"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>

<ng-template #content>
  <div class="o-print-page-button__content">
    <svg class="o-print-page-button__icon" viewBox="0 0 24 24"><use href="#printer"></use></svg>
    <span class="o-print-page-button__label">Print</span>
  </div>
</ng-template>
