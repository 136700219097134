// vendor
import { Component, Injector } from '@angular/core';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import { TextBubbleTypes } from '../../../../shared/configs/text-bubble-types';
import { ApplicationStepNameTypes } from '../../../../digital-experience/models';

@Component({
  selector: 'app-dynamic-step',
  templateUrl: './dynamic-step.component.html',
})
export class DynamicStepComponent extends BaseApplicationStepComponent {
  TextBubbleTypes = TextBubbleTypes;
  ApplicationStepNameTypes = ApplicationStepNameTypes;

  constructor(public injector: Injector) {
    super(injector);
  }
}
