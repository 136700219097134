import { ApplicationStep, ApplicationStepNameTypes, StepComponentMap } from '../../../../models';
import { applicationStepFormConfigMap } from '../prospect';
import * as fromWalkupEmail from '../walkup-email';
import { defaultStepComponentMap } from '../../../shared';

const verifyIdentityStep: ApplicationStep = {
  // title and action message need to be dynamic
  name: ApplicationStepNameTypes.VerifyIdentity,
  title: '',
  order: 0,
  actionMessage: '',
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.VerifyIdentity],
};

export const opportunityMicrofunnel: ApplicationStep = {
  name: ApplicationStepNameTypes.OpportunityMicrofunnel,
  title: 'One last thing...',
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.OpportunityMicrofunnel],
  order: 1,
};

const walkupEmailStepsModified: ApplicationStep[] = fromWalkupEmail.applicationSteps.map((step) => ({
  ...step,
  order: step.order + 2,
}));

export const ODL_ADJUSTABLE_STEPS = walkupEmailStepsModified.map((step) => step.name);

export const applicationSteps: ApplicationStep[] = [
  verifyIdentityStep,
  opportunityMicrofunnel,
  ...walkupEmailStepsModified,
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
