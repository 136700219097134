// models
import { Brand } from '.';
import { Prospect, Campaign } from './card-prospect.model';
import { Apr } from '../../shared/models';

export interface Product {
  id: string;
}

// card product offer
export interface CardProductOffer {
  id: string;
  documentVersion: null;
  insertUser: null;
  insertTs: null;
  modifiedUser: null;
  modifiedTs: null;
  marketOfferId: number;
  campaignParticipantCode: string;
  name: string;
  asOfDate: number[];
  vendorProductCode: string;
  clientProductCode: string;
  primeRate: number;
  purchaseAprs: Apr[];
  cashAdvanceAprs: Apr[];
  isFixedApr: boolean;
  decisionApr: Apr;
  brands: Brand[];
  cashAdvancePct: number;
  providerInformationId: string;
  gracePeriod: number;
  minimumInterestCharge: number;
  annualFee: number;
  applicationFee: number;
  balanceTransferPct: number;
  minBalanceTransferFee: number;
  foreignTransactionFee: number;
  minCashAdvanceFee: number;
  minCashAdvancePercent: number;
  overLimitPenalty: number;
  singleViolationFee: number;
  multipleViolationFee: number;
  returnedPaymentFee: number;
  minPaymentAmt: number;
  minPaymentPct: number;
  mediumCode?: string;
  rewardsProgramId1?: string;
  rewardsProgramId2?: string;
}

// card prospect offer
export interface CardProspectOffer {
  id?: string;
  offerCode: string;
  expirationDate: number[];
  acquired: boolean;
  campaign: Campaign;
  productOfferId: string;
  prospect: Prospect;
}

export enum RewardsProgramId {
  MRW00001 = 'MRW00001',
  MRW00002 = 'MRW00002',
  MRW00003 = 'MRW00003',
  PAYMENT1 = 'PAYMENT1',
}
