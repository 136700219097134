// models
import * as fromQuestionsModels from '../../models';

export const dateOfBirthQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Tel,
  name: fromQuestionsModels.QuestionNameTypes.DateOfBirth,
  mask: {
    mask: fromQuestionsModels.MaskTypes.DateOfBirth,
  },
  placeholder: 'MM/DD/YYYY',
  label: 'Date of birth (mm/dd/yyyy)',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter date of birth',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.DateFormat,
        message: 'Invalid date',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.OfAge,
        message: "You don't meet the age requirements for a Best Egg Card.",
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.OverAgeWarning,
        message: 'You indicated an age of 100 or over, please verify',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.OfAgeForState,
        readonly: true,
      },
    ],
  },
};
