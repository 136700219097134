/* eslint-disable max-len */
// vendor
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
// ngrx
import * as fromOffersStore from '../../../store';
// pipes
import { CurrencySimplePipe, PercentSimplePipe } from '../../../../shared/pipes';
// models
import { CardProductOffer } from '../../../models';
import { ItemSection } from '../../../../shared/models';
// misc/helpers
import { createValueIdGenericFactory } from '../../../../shared/helpers/helper-functions';
// const
const CARD_PRODUCT_OFFER = 'cardProductOffer';
const MAX_APR = 29.99;
const idGeneralLabel = 'table';
const createValueId: (valueId: any) => string = createValueIdGenericFactory(idGeneralLabel);

@Component({
  selector: 'app-default-product-terms',
  templateUrl: './default-product-terms.component.html',
  styleUrls: ['./default-product-terms.component.scss'],
})
export class DefaultProductTermsComponent implements OnChanges {
  productTerms: ItemSection[];
  isArray: typeof Array.isArray;
  @Input() cardProductOffer: CardProductOffer;

  constructor(
    private percentSimplePipe: PercentSimplePipe,
    private currencySimplePipe: CurrencySimplePipe,
    private store: Store<fromOffersStore.OffersState>
  ) {
    this.isArray = Array.isArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes[CARD_PRODUCT_OFFER]) {
      this.setProductTerms();
    }
  }

  /**
   * set product terms
   */
  setProductTerms(): void {
    try {
      this.productTerms = [this.getInterestRatesAndChargesSection(), this.getFeesSection()];
    } catch (err) {
      console.error('[CAW_AUD_PRODUCT_TERMS_COMP_ERR] productTerms ', err);
      this.store.dispatch(
        fromOffersStore.updateCardFunnelTermsAvailability({
          termsErrors: true,
        })
      );
      throw err;
    }
  }

  displayMaxApr(aprValue: string): string {
    return parseFloat(aprValue) > MAX_APR ? MAX_APR.toString() : aprValue;
  }

  /**
   * get interest rates and charges section
   * @returns interest rates and charges section
   */
  private getInterestRatesAndChargesSection(): ItemSection {
    /**
     * We could have either a range of APRs (RBP), or just one APR (single, original). Grab the first and last
     * APR if we detect that there's more than one
     *
     * The language will be different depending on this. Mainly it's a matter of displaying the range of APRs vs.
     * a single APR, but there's some other langauge differences aside from this as well.
     *
     * For now, it can it be expected that if one of the APRs is range, the other will be as well, and v.v.
     */
    const isAPRRange = this.cardProductOffer.purchaseAprs.length > 1;
    const promoApr = this.cardProductOffer.purchaseAprs.find((apr) => apr.isPromo);

    // purchaseAprs
    const purchaseAprLowest = this.cardProductOffer.purchaseAprs[0];
    const purchaseAprLowestData = {
      rate: this.percentSimplePipe.transform(purchaseAprLowest.rate),
      rateId: isAPRRange ? 'purchaseAprRateLowest' : 'purchaseAprRate',
      margin: this.percentSimplePipe.transform(purchaseAprLowest.margin),
      marginId: isAPRRange ? 'purchaseAprMarginLowest' : 'purchaseAprMargin',
    };

    const purchaseAprHighest = this.cardProductOffer.purchaseAprs[this.cardProductOffer.purchaseAprs.length - 1];
    const purchaseAprHighestData = {
      rate: this.percentSimplePipe.transform(purchaseAprHighest.rate),
      rateId: isAPRRange ? 'purchaseAprRateHighest' : 'purchaseAprRate',
      margin: this.percentSimplePipe.transform(purchaseAprHighest.margin),
      marginId: isAPRRange ? 'purchaseAprMarginHighest' : 'purchaseAprMargin',
    };

    const purchaseAprData = [purchaseAprLowestData];
    if (isAPRRange) {
      purchaseAprData.push(purchaseAprHighestData);
    }

    const promoPurchaseAprExtra = promoApr
      ? `
        <span class="promo-label">
          <strong class="ft-sz--large" id="${createValueId('purchasePromoRate')}">${
          promoApr.purchasePromoRate
        }%</strong>
          Introductory APR for the first <span id="${createValueId('purchasePromoRateDuration')}">${
          promoApr.purchasePromoRateDuration
        }</span> months following the opening of your account.
        </span>
        After that, your APR will be
      `
      : '';

    const purchaseAprDisplayValue = isAPRRange
      ? `
      ${promoPurchaseAprExtra}
      <strong class="ft-sz--large">
          <span id="${createValueId(purchaseAprData[0].rateId)}">${purchaseAprData[0].rate}%</span> to
          <span id="${createValueId(purchaseAprData[1].rateId)}" class="ft-sz--large">${this.displayMaxApr(
          purchaseAprData[1].rate
        )}%</span>
      </strong>
      ${promoApr ? '' : 'when you open your account,'} based on your creditworthiness.
      ${
        promoApr ? '<br>This' : 'After that, your'
      } APR will vary with the market based on the Prime Rate. See below for more details.
    `
      : `${promoPurchaseAprExtra}<strong id="${createValueId(
          purchaseAprData[0].rateId
        )}" class="ft-sz--large">${this.displayMaxApr(purchaseAprData[0].rate)}%</strong>
       <br>Your APR will vary with the market based on the Prime Rate. See below for more details.`;

    // cashAdvanceAprs
    const cashAdvanceAprLowest = this.cardProductOffer.cashAdvanceAprs[0];
    const cashAdvanceAprLowestData = {
      rate: this.percentSimplePipe.transform(cashAdvanceAprLowest.rate),
      rateId: isAPRRange ? 'cashAdvanceAprRateLowest' : 'cashAdvanceAprRate',
      margin: this.percentSimplePipe.transform(cashAdvanceAprLowest.margin),
      marginId: isAPRRange ? 'cashAdvanceAprMarginLowest' : 'cashAdvanceAprMargin',
    };

    const cashAdvanceAprHighest =
      this.cardProductOffer.cashAdvanceAprs[this.cardProductOffer.cashAdvanceAprs.length - 1];
    const cashAdvanceAprHighestData = {
      rate: this.percentSimplePipe.transform(cashAdvanceAprHighest.rate),
      rateId: isAPRRange ? 'cashAdvanceAprRateHighest' : 'cashAdvanceAprRate',
      margin: this.percentSimplePipe.transform(cashAdvanceAprHighest.margin),
      marginId: isAPRRange ? 'cashAdvanceAprMarginHighest' : 'cashAdvanceAprMargin',
    };

    const cashAdvanceAprData = [cashAdvanceAprLowestData];
    if (isAPRRange) {
      cashAdvanceAprData.push(cashAdvanceAprHighestData);
    }

    const cashAdvanceAprDisplayValue = isAPRRange
      ? `
      <strong class="ft-sz--large">
        <span id="${createValueId(cashAdvanceAprData[0].rateId)}">${cashAdvanceAprData[0].rate}%</span> to
        <span id="${createValueId(cashAdvanceAprData[1].rateId)}">${this.displayMaxApr(
          cashAdvanceAprData[1].rate
        )}%</span>
      </strong><br>
      ${promoApr ? '' : 'when you open your account,'} based on your creditworthiness.
      ${promoApr ? 'This' : 'After that, your'} APR will vary with the market based on the Prime Rate.
      `
      : `<strong id="${createValueId(cashAdvanceAprData[0].rateId)}" class="ft-sz--large">${this.displayMaxApr(
          cashAdvanceAprData[0].rate
        )}%</strong>
      <br>Your APR will vary with the market based on the Prime Rate.
      `;

    // gracePeriod
    const gracePeriod = this.cardProductOffer.gracePeriod;
    const gracePeriodId = 'gracePeriod';
    // minimumInterestCharge
    const minimumInterestCharge = this.currencySimplePipe.transform(this.cardProductOffer.minimumInterestCharge);
    const minimumInterestChargeId = 'minimumInterestCharge';
    return {
      title: 'interest rates and interest charges',
      items: [
        {
          label: 'Annual Percentage Rate (APR) for Purchases',
          sublabel: '',
          value: purchaseAprDisplayValue,
        },
        {
          label: 'APR for Cash Advances',
          sublabel: '',
          value: cashAdvanceAprDisplayValue,
        },
        {
          label: 'How to Avoid Paying Interest on Purchases',
          sublabel: `Your due date is at least <span id="${createValueId(
            gracePeriodId
          )}">${gracePeriod}</span> days after the close of each billing cycle.
          We will not charge you any interest on purchases if you pay your entire balance by the due date each month.
          We will begin charging interest on cash advances on the transaction date.`,
          value: '',
        },
        {
          label: 'Minimum Interest Charge',
          sublabel: `If you are charged interest, the charge will be no less than <strong id="${createValueId(
            minimumInterestChargeId
          )}">$${minimumInterestCharge}</strong>.`,
          value: '',
        },
        {
          label: 'For Credit Card Tips from the Consumer Financial Protection Bureau',
          sublabel: `<strong>To learn more about factors to consider when applying for or using a credit card, visit
          the website of the Consumer Financial Protection Bureau at <a target="_blank"
          href="http://www.consumerfinance.gov/learnmore">http://www.consumerfinance.gov/learnmore</a></strong>`,
          value: '',
        },
      ],
    };
  }

  /**
   * get fees section
   * @returns fees section
   */
  private getFeesSection(): ItemSection {
    const promoApr = this.cardProductOffer.purchaseAprs.find((apr) => apr.isPromo);

    // annualFee
    const annualFee = this.currencySimplePipe.transform(this.cardProductOffer.annualFee);
    const annualFeeId = 'annualFee';
    // applicationFee
    const applicationFee = this.currencySimplePipe.transform(this.cardProductOffer.applicationFee);
    const applicationFeeId = 'applicationFee';
    // minBalanceTransferFee
    const minBalanceTransferFee = this.currencySimplePipe.transform(this.cardProductOffer.minBalanceTransferFee);
    const minBalanceTransferFeeId = 'minBalanceTransferFee';
    // foreignTransactionFee
    const foreignTransactionFee = this.currencySimplePipe.transform(this.cardProductOffer.foreignTransactionFee);
    const foreignTransactionFeeId = 'foreignTransactionFee';
    // minCashAdvanceFee
    const minCashAdvanceFee = this.currencySimplePipe.transform(this.cardProductOffer.minCashAdvanceFee);
    const minCashAdvanceFeeId = 'minCashAdvanceFee';
    // cashAdvancePct
    const cashAdvancePct = this.percentSimplePipe.transform(this.cardProductOffer.cashAdvancePct);
    const cashAdvancePctId = 'cashAdvancePct';
    // overLimitPenalty
    const overLimitPenalty = this.currencySimplePipe.transform(this.cardProductOffer.overLimitPenalty);
    const overLimitPenaltyId = 'overLimitPenalty';
    // multipleViolationFee
    const multipleViolationFee = this.currencySimplePipe.transform(this.cardProductOffer.multipleViolationFee);
    const multipleViolationFeeId = 'multipleViolationFee';
    // returnedPaymentFee
    const returnedPaymentFee = this.currencySimplePipe.transform(this.cardProductOffer.returnedPaymentFee);
    const returnedPaymentFeeId = 'returnedPaymentFee';
    return {
      title: 'fees',
      items: [
        {
          label: 'Annual Membership Fee',
          sublabel: '',
          value: `<strong id="${createValueId(annualFeeId)}">$${annualFee}</strong>`,
        },
        {
          label: 'Application Fee',
          sublabel: '',
          value: `<strong id="${createValueId(applicationFeeId)}">$${applicationFee}</strong>`,
        },
        {
          value: [
            { label: 'Transaction Fees', value: '&nbsp' },
            {
              sublabel: 'Balance Transfers',
              value: `<strong id="${createValueId(
                minBalanceTransferFeeId
              )}">$${minBalanceTransferFee} (currently not available)</strong>`,
            },
            {
              sublabel: 'Cash Advance',
              value: `Either <strong id="${createValueId(
                minCashAdvanceFeeId
              )}">$${minCashAdvanceFee}</strong> or <strong id="${createValueId(
                cashAdvancePctId
              )}">${cashAdvancePct}%</strong> of the amount of each cash advance, whichever is greater.`,
            },
            {
              sublabel: 'Foreign Transaction',
              value: `<strong id="${createValueId(foreignTransactionFeeId)}">$${foreignTransactionFee}</strong>`,
            },
          ],
        },
        {
          value: [
            { label: 'Penalty Fees', value: '&nbsp' },
            {
              sublabel: 'Late Payment',
              value: `Up to <strong id="${createValueId(multipleViolationFeeId)}">$${multipleViolationFee}</strong>.`,
            },
            {
              sublabel: 'Overlimit',
              value: `<strong id="${createValueId(overLimitPenaltyId)}">$${overLimitPenalty}</strong>`,
            },
            {
              sublabel: 'Returned Payment',
              value: `Up to <strong id="${createValueId(returnedPaymentFeeId)}">$${returnedPaymentFee}</strong>.`,
            },
          ],
        },
      ],
    };
  }
}
