export enum EmploymentStatusTypes {
  SalariedEmployee = 'salaried_employee',
  HourlyWageEmployee = 'hourly_wage_employee',
  SelfEmployed = 'self_employed',
  Contractor = 'contractor',
  Student = 'student',
  Retired = 'retired',
  Unemployed = 'unemployed',
  Disabled = 'disabled',
}
