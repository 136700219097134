import { ApplicationStep, StepComponentMap } from '../../../../models';
import { defaultStepComponentMap } from '../../../shared/application-step-component-mapping.config';
import { baseWalkupInformationApplicationStep, baseApplicationSteps } from '../../base-walkup/application-steps.config';

// #walkup-information
export const walkupInformationApplicationStep: ApplicationStep = {
  ...baseWalkupInformationApplicationStep,
  title: "Let's get started! We just need a little more information about you.",
  supertitle: null,
};

export const applicationSteps: ApplicationStep[] = [walkupInformationApplicationStep, ...baseApplicationSteps];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
