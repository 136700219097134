// vendor
import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromDigitalExperienceStore from '../../../../digital-experience/store';

// environment
import { environment } from '../../../../../../environments/environment';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
// consts
const BEST_EGG_LINK = `https://${environment.bestEggDomain}`;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  stepName$: Observable<string>;
  logoUrl: string;
  @Output()
  clickedSkipLink: EventEmitter<any> = new EventEmitter();

  constructor(protected store: Store<fromDigitalExperienceStore.DigitalExperienceState>) {
    this.logoUrl = BEST_EGG_LINK;
  }

  ngOnInit() {
    this.stepName$ = this.store.pipe(
      select(fromDigitalExperienceStore.selectDigitalExperienceCurrentStep),
      filter((step) => step !== null)
    );
  }

  skip() {
    this.clickedSkipLink.emit();
  }
}
