// vendor
import { Injectable } from '@angular/core';
import { of, Subject, zip } from 'rxjs';
import { switchMap, withLatestFrom, tap, takeUntil, filter, catchError, take, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
// ngrx
import { select, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromDashboardLoadStore from '../../store';
import * as fromApplicationStore from '../../../application/store';
import * as fromLeadStore from '../../../lead/store';
import * as fromAppStore from '../../../../store';
import * as fromOffersStore from '../../../offers/store';
// configs
import { MAIN_URL_SEGMENT, UNAVAILABLE_URL_SEGMENT } from 'src/app/features/main/configs/main.configs';

import { LeadService } from 'src/app/features/lead/services';
import { getAbTestsAction } from 'src/app/features/shared/helpers/helper-functions';
import { Lead, LeadResponseV2 } from 'src/app/features/lead/models';
import { Application } from 'src/app/features/application/models';
import { ApplicationState } from '../../../application/store';
import { dashboardErrorMessages } from 'src/app/features/dashboard/configs';

@Injectable({
  providedIn: 'root',
})
export class DashboardLoadProcessEffects {
  stopDashboardProcessWatch$ = new Subject();

  constructor(
    private action$: Actions,
    private store$: Store<any>,
    private route: ActivatedRoute,
    private leadService: LeadService
  ) {}

  /**
   * process success watch for dashboard page
   * purpose: all actions to watch for successful dashboard start process
   */
  dashboardProcessSuccessWatch$ = zip(
    this.action$.pipe(ofType(fromApplicationStore.loadApplicationSuccess)),
    this.action$.pipe(ofType(fromOffersStore.loadCardProductOfferSuccess)),
    this.action$.pipe(ofType(fromLeadStore.createLeadSuccess)),
    this.action$.pipe(ofType(fromApplicationStore.loadAuthUserTokenSuccess))
  ).pipe(
    takeUntil(this.stopDashboardProcessWatch$),
    tap(() => {
      this.store$.dispatch(fromDashboardLoadStore.dashboardLoadSuccess());
      this.stopDashboardProcessWatch();
    })
  );

  dashboardProcessFailWatch$ = this.action$.pipe(
    ofType(
      fromApplicationStore.loadApplicationFail,
      fromLeadStore.createLeadFail,
      fromOffersStore.loadCardProductOfferFail
    ),
    takeUntil(this.stopDashboardProcessWatch$),
    tap((action) => {
      this.store$.dispatch(fromDashboardLoadStore.dashboardLoadFail({ action }));
      this.stopDashboardProcessWatch();
    })
  );

  /**
   * dashboard load process
   * [data dependencies]: application
   * [purpose]: clearing and init dashboard process actions
   */
  dashboardLoadProcessStart$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromDashboardLoadStore.dashboardLoadProcessStart),
      switchMap((action) => {
        this.startDashboardProcessWatch();
        const applicationId = action.queryParam.applicationId;
        if (!applicationId) {
          console.error('[DASHBOARD] param `applicationId` is undefined');
          return [
            fromAppStore.go({
              path: [MAIN_URL_SEGMENT, UNAVAILABLE_URL_SEGMENT],
              extras: {
                skipLocationChange: true,
                state: {
                  message: dashboardErrorMessages.generalError,
                },
              },
            }),
          ];
        } else {
          return [
            fromLeadStore.clearLead(),
            fromApplicationStore.clearApplication(),
            fromApplicationStore.loadApplication({
              applicationId: action.queryParam.applicationId,
            }),
            fromDashboardLoadStore.dashboardLeadLoadProcess(),
          ];
        }
      })
    )
  );

  leadLoadProcess$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromDashboardLoadStore.dashboardLeadLoadProcess),
      withLatestFrom(this.route.queryParams),
      switchMap(([_, params]) =>
        this.store$.pipe(
          select(fromApplicationStore.selectApplicationState),
          filter((applicationState) => applicationState.loaded),
          take(1), // prevents application polling triggers multiple getLead request
          switchMap((applicationState: ApplicationState) =>
            this.leadService.getLead(applicationState.data.leadId).pipe(
              map(
                (leadRes: Partial<Lead>): Partial<LeadResponseV2> => ({
                  lead: leadRes,
                  abTests: leadRes.abTestList,
                })
              ),
              withLatestFrom(this.store$.select(fromApplicationStore.selectApplicationData)),
              switchMap(([leadRes, application]: [Partial<LeadResponseV2>, Application]) => [
                getAbTestsAction(leadRes, params),
                fromApplicationStore.getAuthUserToken({
                  applicationId: application.id,
                }),
                // load card product offer for approve dashboard rewards
                fromOffersStore.loadCardProductOffer({
                  cardProductOfferId: leadRes.lead.productOfferId,
                }),
                // We should always rely on primaryApplicant from application not from lead
                fromLeadStore.createLeadSuccess({
                  lead: {
                    ...leadRes.lead,
                    primaryApplicant: application.primaryApplicant,
                  },
                }),
              ]),
              catchError((error: HttpErrorResponse) => of(fromLeadStore.createLeadFail({ error })))
            )
          )
        )
      )
    )
  );

  startDashboardProcessWatch() {
    this.dashboardProcessSuccessWatch$.subscribe();
    this.dashboardProcessFailWatch$.subscribe();
  }

  stopDashboardProcessWatch() {
    this.stopDashboardProcessWatch$.next();
  }
}
