<app-application-step
  class="c-dynamic-step"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <app-dynamic-form
      [form]="form"
      [formConfig]="stepFormConfig"
      [questionOptions]="applicationStepData.applicationQuestionOptions"
      [errorValidator]="errorValidator"
    ></app-dynamic-form>
    <app-text-bubble
      *ngIf="applicationStepData.applicationStep.name === ApplicationStepNameTypes.BreakingBECCStep1"
      [designType]="TextBubbleTypes.NOTIFICATION"
    >
      <div class="content">There will be no impact to your credit score if we’re unable to approve you.</div>
    </app-text-bubble>
    <app-form-step-controls
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
