<app-application-step
  class="c-dynamic-step"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <app-dynamic-form
      [form]="form"
      [formConfig]="stepFormConfig"
      [questionOptions]="applicationStepData.applicationQuestionOptions"
      [errorValidator]="errorValidator"
    ></app-dynamic-form>
    <app-text-bubble [designType]="TextBubbleTypes.NOTIFICATION">
      <div class="content">There will be no impact to your credit score if we're unable to approve you.</div>
    </app-text-bubble>

    <ng-container *ngIf="verifyIdentityErrorMessage$ | async as errorMessage">
      <app-text-bubble [designType]="TextBubbleTypes.ERROR">
        <div class="content">
          {{ errorMessage }}
        </div>
      </app-text-bubble>
    </ng-container>

    <app-form-step-controls
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
