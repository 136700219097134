// store
import { createReducer, on } from '@ngrx/store';
import * as fromTokenizedCidActions from '../actions';
// key
export const TOKENIZED_CID_STATE_KEY = 'tokenizedCid';

// state
export interface TokenizedCidState {
  data: {
    tokenizedCid: string;
  };
  loaded: boolean;
}

export const initialState: TokenizedCidState = {
  data: {
    tokenizedCid: null,
  },
  loaded: false,
};

// reducer
const tokenizedCidReducer = createReducer(
  initialState,
  on(fromTokenizedCidActions.loadAuthUserTokenSuccess, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      tokenizedCid: action.tokenizedCid,
    },
    loaded: true,
  }))
);

export const reducer = (state: TokenizedCidState, action: fromTokenizedCidActions.TokenizedCidAction) =>
  tokenizedCidReducer(state, action);

// state selectors
export const selectTokenizedCidStateData = (state: TokenizedCidState) => state.data.tokenizedCid;

export const selectTokenizedCidStateLoaded = (state: TokenizedCidState) => state.loaded;
