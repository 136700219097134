<mat-error
  class="c-form-validation"
  [class.mat-error--no-margin]="noMargin"
  [class.mat-error--no-content]="!validation.message && !validation.errors"
  [@fieldErrorEnterAnimation]
  *ngIf="validation$ | async as validation"
>
  <span class="c-form-validation__message" *ngIf="validation.message">
    {{ validation.message }}
  </span>
  <ng-container *ngIf="validation.errors">
    <div class="c-form-validation__error" *ngFor="let error of validation.errors | keyvalue">
      <small
        ><strong class="c-form-validation__error-label">{{ error.key }}: </strong></small
      >
      <small class="c-form-validation__error-message">{{ error.value }}</small>
    </div>
  </ng-container>
</mat-error>
