<div [class]="'c-application-step c-application-step__' + applicationStep?.name" [@fadeAnimation]="stepDirection">
  <div class="c-application-step__animation-wrapper" *ngIf="!hidden">
    <!-- header begin -->
    <header class="c-application-step__header card-print-none" [class.no-visible-content]="hasNoVisibleHeaderContent">
      <p
        class="c-application-step__supertitle"
        aria-describedby="progress-bar-sr"
        tabindex="-1"
        appAutofocus
        *ngIf="displaySupertitle"
      >
        {{ applicationStep.supertitle }}
      </p>
      <h1
        class="c-application-step__title"
        [class.c-application-step__title--adjust-for-supertitle]="displaySupertitle"
        aria-describedby="progress-bar-sr"
        tabindex="-1"
        [appAutofocus]="!displaySupertitle"
        *ngIf="applicationStep.title"
        [innerHTML]="applicationStep.title"
      ></h1>
      <!-- For screen readers begin -->
      <h1
        class="cdk-visually-hidden"
        aria-describedby="progress-bar-sr"
        tabindex="-1"
        [appAutofocus]="!displaySupertitle"
        *ngIf="!applicationStep.title"
      >
        Application In Progress
      </h1>
      <!-- screen reader end -->
      <p class="c-application-step__subtitle" *ngIf="applicationStep.subtitle">
        {{ applicationStep.subtitle }}
      </p>
      <div class="c-application-step__action" *ngIf="applicationStep.actionMessage">
        <button class="button-link-style" mat-button color="primary" (click)="stepAction()">
          {{ applicationStep.actionMessage }}
        </button>
      </div>
    </header>
    <!--header end -->
    <!-- body begin -->
    <section class="c-application-step__body">
      <div class="c-application-step__details" *ngIf="applicationStep.message || applicationStep.submessage">
        <div class="c-application-step__message" *ngIf="applicationStep.message">
          <p>{{ applicationStep.message }}</p>
        </div>
        <div class="c-application-step__submessage" *ngIf="applicationStep.submessage">
          <p>{{ applicationStep.submessage }}</p>
        </div>
      </div>
      <section class="c-application-step__content">
        <ng-content select=".step-content"></ng-content>
      </section>
    </section>
    <!--body end -->
  </div>
</div>
