// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// modules
import { ApplicationModule } from '../application/application.module';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { MainRoutingModule } from './main-routing.module';
// containers
import * as fromContainers from './containers';

@NgModule({
  declarations: [...fromContainers.containers],
  imports: [CommonModule, MainRoutingModule, ApplicationModule, CoreModule, SharedModule],
})
export class MainModule {}
