// vendor
import { Component } from '@angular/core';
import { TextBubbleTypes } from '../../../../shared/configs/text-bubble-types';
// components
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';

@Component({
  selector: 'app-form-radio-group',
  templateUrl: './form-radio-group.component.html',
  styleUrls: ['../form-fields.scss', './form-radio-group.component.scss'],
})
export class FormRadioGroupComponent extends BaseFormFieldComponent {
  TextBubbleTypes = TextBubbleTypes;

  updateValue(val) {
    this.formControl.setValue(val);
  }
}
