// vendor
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
// models
import { DateTypes } from '../models';
// export model for use
export { DateTypes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  constructor() {
    // support custom formats of input strings
    dayjs.extend(customParseFormat);
  }

  /**
   * get date
   * @param date
   * @param formatSpec
   * @returns date
   */
  getDate(date, formatSpec, strict = true) {
    return dayjs(date, formatSpec, strict);
  }

  /**
   * convert api date array to string
   * api date array - number array in format [YYYY, M, D]
   * @param apiDate
   * @param format
   * @returns formatted date string
   */
  convertApiDateArrayToStr(apiDate: number[], format: string = DateTypes.ISODateNoLeading): string {
    const isoDateNoLeading = apiDate.join('-');
    return this.formatDate(isoDateNoLeading, DateTypes.ISODateNoLeading, format);
  }

  /**
   * convert MMDDYYYY formats to iso date str
   * @param dateStr
   * @returns iso date str
   * create designated MMDDYYYY to ISO (YYYY-MM-DD), manually convert to iso
   * to ensure proper format given this will be used most often to convert
   * different user entered dates (ie Dob)
   */
  convertMMDDYYYYtoISODateStr(dateStr: string): string {
    if (!dateStr) {
      return;
    }
    const formatSpec = DateTypes.MMDDYYYY;
    const date = this.getDate(dateStr, formatSpec, true);
    let convertedDate;
    if (date.isValid()) {
      const mm = dateStr.substr(0, 2);
      const dd = dateStr.substr(2, 2);
      const yyyy = dateStr.substr(4, 4);
      convertedDate = [yyyy, mm, dd].join('-');
    } else {
      convertedDate = DateTypes.InvalidDate;
      console.error('[INVALID_DATE:::convert::iso]', dateStr, formatSpec);
    }
    return convertedDate;
  }

  /**
   * format date
   * @param dateStr
   * @param formatSpec
   * @param format
   * @returns formatted date string
   */
  formatDate(dateStr, formatSpec, format): string {
    const date = this.getDate(dateStr, formatSpec, true);
    const formattedDate = date.format(format);
    if (!date.isValid() || formattedDate.toLowerCase() === DateTypes.InvalidDate.toLowerCase()) {
      console.error('[INVALID_DATE:::format]', dateStr, formatSpec, format);
    }
    return formattedDate;
  }
}
