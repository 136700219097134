// vendor
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const { error: errorObj } = error;

        const { trace, message } = errorObj;
        if (trace) {
          console.error(`[ERROR:::trace] ${message} (trace: ${trace})`);
        }

        const { fake_sl_simulated } = errorObj;
        if (fake_sl_simulated) {
          console.warn(`Error: [${error.url} ${error.status}] is being simulated by fakeSL global settings.`);
        }

        return throwError(error);
      })
    );
  }
}
