// consts
const MIN_TO_SECOND_FACTOR = 60;
const SECOND_TO_MILLISECOND_FACTOR = 1000;

/**
 * convert minutes to milliseconds
 * @param minutes
 * @returns boolean status
 */
export const convertMinutesToMilliseconds = (minutes: number): number => {
  const seconds = convertMinutesToSeconds(minutes);
  return convertSecondsToMilliseconds(seconds);
};

/**
 * convert minutes to seconds
 * @param minutes
 * @returns seconds
 */
export const convertMinutesToSeconds = (minutes: number): number => minutes * MIN_TO_SECOND_FACTOR;

/**
 * convert seconds to milliseconds
 * @param seconds
 * @returns milliseconds
 */
export const convertSecondsToMilliseconds = (seconds: number): number => seconds * SECOND_TO_MILLISECOND_FACTOR;
