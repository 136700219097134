// models
import * as fromQuestionsModels from '../../models';
import { commonPhoneQuestion } from './common-phone.config';
// consts
const phoneDigits = 10;

export const employerPhoneQuestion: fromQuestionsModels.Question = {
  ...commonPhoneQuestion,
  name: fromQuestionsModels.QuestionNameTypes.EmployerPhone,
  label: 'Employer phone',
  validators: {
    sync: [...commonPhoneQuestion.validators.sync],
  },
};
