// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { ApplicationMeta } from '../../../application/models';

export enum CardOfferLoadProcessActionTypes {
  CardOfferLoadProcess = '[Offers] Card Offer Load Process',
  InitCardProspectOfferLoadSequence = '[Offers] Init Card Prospect Offer Load Sequence',
  InitCardProductOfferLoadSequence = '[Offers] Init Card Product Offer Load Sequence',
  CardOfferLoadProcessSuccess = '[Offers] Card Offer Load Process Success',
  CardOfferLoadProcessFail = '[Offers] Card Offer Load Process Fail',
}

// card offer load process
export const cardOfferLoadProcess = createAction(
  CardOfferLoadProcessActionTypes.CardOfferLoadProcess,
  props<{ applicationMeta: ApplicationMeta }>()
);

export const initCardProspectOfferLoadSequence = createAction(
  CardOfferLoadProcessActionTypes.InitCardProspectOfferLoadSequence,
  props<{ emailAddress: string; offerCode: string }>()
);

export const initCardProductOfferLoadSequence = createAction(
  CardOfferLoadProcessActionTypes.InitCardProductOfferLoadSequence,
  props<{ cardProductOfferId?: string }>()
);

export const cardOfferLoadProcessSuccess = createAction(CardOfferLoadProcessActionTypes.CardOfferLoadProcessSuccess);

export const cardOfferLoadProcessFail = createAction(CardOfferLoadProcessActionTypes.CardOfferLoadProcessFail);

export type CardOfferLoadProcessAction = ReturnType<
  | typeof cardOfferLoadProcess
  | typeof initCardProspectOfferLoadSequence
  | typeof initCardProductOfferLoadSequence
  | typeof cardOfferLoadProcessSuccess
  | typeof cardOfferLoadProcessFail
>;
