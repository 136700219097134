export const PII_FIELDS = [
  'dob',
  'firstName',
  'lastName',
  'address',
  'homePhone',
  'socialSecurityNumber',
  'lastFourOfSsn',
  'email',
];

export const DEFAULT_APP_STATUS = 'preoffered';

export enum APP_STATUS_TYPES {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDED = 'pended',
  CLOSED = 'closed',
  BORROWER_WITHDREW = 'borrower_withdrew',
  // TODO status TBD
  FROZEN = 'frozen',
}

export enum APP_PENDED_REASON_TYPES {
  PENDED_UNAVAILABLE = 'VENDOR_UNAVAILABLE',
  PENDED_KYC = 'KYC',
  PENDED_FRAUD = 'FRAUD',
  PENDED_UNKNOWN = 'UNKNOWN',
}

export enum OFFER_EVENTS {
  VIEWED = 'OfferViewed',
  ACCEPTED = 'OfferAccepted',
  PREOFFER_VIEWED_TERMS = 'PreofferViewedTerms',
}

// Note: Using same case as the events in Loan.
export enum TEALIUM_LEAD_EVENTS {
  CREATED = 'leadCreated',
  RESUMED = 'leadResumed',
  SUBMITTED = 'leadSubmitted',
}
