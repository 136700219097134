import { Component, Injector, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LeadState, selectLeadState } from 'src/app/features/lead/store';
import { TextBubbleTypes } from 'src/app/features/shared/configs/text-bubble-types';
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import { ApplicationStepNameTypes } from 'src/app/features/digital-experience/models';

const VERIFY_IDENTITY_ERROR_MESSAGE = 'Please make sure your Date of Birth and ZIP Code are correct and try again';

@Component({
  selector: 'app-verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.scss'],
})
export class VerifyIdentityComponent extends BaseApplicationStepComponent implements OnInit {
  TextBubbleTypes = TextBubbleTypes;
  verifyIdentityErrorMessage$: Observable<string>;
  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.verifyIdentityErrorMessage$ = this.store.pipe(
      select(selectLeadState),
      filter((leadState: LeadState) => !leadState.loaded && !!leadState.error && !leadState.loading),
      map((_) => VERIFY_IDENTITY_ERROR_MESSAGE)
    );
    this.verifyIdentityErrorMessage$.subscribe((_) => {
      this.EEService.reportFormSubmissionError(
        ApplicationStepNameTypes.VerifyIdentity,
        this.questionNameTypes.DateOfBirth,
        VERIFY_IDENTITY_ERROR_MESSAGE
      );
      this.EEService.reportFormSubmissionError(
        ApplicationStepNameTypes.VerifyIdentity,
        this.questionNameTypes.ZipCode,
        VERIFY_IDENTITY_ERROR_MESSAGE
      );
    });
  }
}
