// vendor
import { Component } from '@angular/core';
import { StylableDialogComponent } from '../stylable-dialog/stylable-dialog.component';

/**
 * TODO_ADD: maybe make a base component for dialogs if we're
 * going to have more than one.
 */

@Component({
  selector: 'app-communications-consent-dialog',
  templateUrl: './communications-consent-dialog.component.html',
})
export class CommunicationsConsentDialogComponent extends StylableDialogComponent {}
