// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardProductOfferReducer from '../reducers/card-product-offer.reducer';
// models
import { CardProductOffer } from '../../models';
import { rewardsOffer } from 'src/app/features/shared/configs';

// card product offer state
export const selectCardProductOfferState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardProductOffer
);

// data
export const selectCardProductOfferData = createSelector(
  selectCardProductOfferState,
  fromCardProductOfferReducer.selectCardProductOfferStateData
);

// data -> brands
export const selectCardProductOfferBrands = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) => cardProductOffer?.brands
);

// loading
export const selectCardProductOfferLoading = createSelector(
  selectCardProductOfferState,
  fromCardProductOfferReducer.selectCardProductOfferStateLoading
);

// loaded
export const selectCardProductOfferLoaded = createSelector(
  selectCardProductOfferState,
  fromCardProductOfferReducer.selectCardProductOfferStateLoaded
);

export const selectCardProductIsRewardProgram = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) =>
    cardProductOffer &&
    Object.entries(rewardsOffer).every(([key, fn]) => cardProductOffer[key] && fn(cardProductOffer[key]))
);

export const selectCardProductOfferPromotionalApr = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) => cardProductOffer?.purchaseAprs?.find((apr) => apr.isPromo)
);

// data -> client product code
export const selectCardProductOfferClientProductCode = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) => cardProductOffer?.clientProductCode
);

// data -> rewards program id 1
export const selectCardProductOfferRewardsProgramId1 = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) => cardProductOffer?.rewardsProgramId1
);

// data -> rewards program id 2
export const selectCardProductOfferRewardsProgramId2 = createSelector(
  selectCardProductOfferData,
  (cardProductOffer: CardProductOffer) => cardProductOffer?.rewardsProgramId2
);
