// vendor
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// models
import { Error } from '../../../models';
import { StylableDialogComponent } from '../stylable-dialog/stylable-dialog.component';
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent extends StylableDialogComponent implements OnInit {
  error: Error;

  constructor(protected injector: Injector, @Inject(MAT_DIALOG_DATA) data) {
    super(injector);
    this.error = data?.error;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.dialogRef.addPanelClass('c-dialog--less-rounded-border');
  }
}
