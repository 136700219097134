<ng-container *ngIf="digitalExperienceName$ | async as digitalExperienceName">
  <div class="c-affiliate-landing__wrapper">
    <div class="c-affiliate-landing__content-container">
      <div class="c-affiliate-landing__descriptions-container">
        <div
          class="c-affiliate-landing__content-header"
          *ngIf="!experiencesWithNoGreetingText.includes(digitalExperienceName)"
        >
          <p>Thank you for visiting from</p>
          <div class="c-affiliate-landing__content-header-image">
            <img
              [src]="experienceNameLogoPathMap[digitalExperienceName]['src']"
              [alt]="digitalExperienceName"
              [height]="experienceNameLogoPathMap[digitalExperienceName]['landingPageHeight']"
              [style.bottom]="experienceNameLogoPathMap[digitalExperienceName]['landingPageOffset']"
            />
          </div>
        </div>
        <p class="c-affiliate-landing__title">The credit card with no annual fee</p>
        <p class="c-affiliate-landing__subtitle">Make financial progress with the Best Egg Credit Card.</p>
        <button mat-button type="submit" id="c_affiliate_landing-CTA" (click)="applyNow()">Apply Now</button>
        <p class="c-affiliate-landing__tip">
          No impact to your credit score if you're not approved. <span>Offers subject to credit approval.</span>
        </p>
        <app-card-rewards-link *ngIf="isRewardProgram$ | async"></app-card-rewards-link>
      </div>
      <div class="c-affiliate-landing__card-image-container">
        <svg class="c-affiliate-landing__feature-icon" viewBox="0 0 454 408">
          <use href="#cards" />
        </svg>
      </div>
    </div>
  </div>
</ng-container>

<div class="c-affiliate-landing__features">
  <div class="c-affiliate-landing__feature-container">
    <div class="c-affiliate-landing__feature-item">
      <svg class="c-affiliate-landing__feature-icon" viewBox="0 0 24 24">
        <use href="#seed" />
      </svg>
      <p>No foreign transaction fees</p>
    </div>
  </div>
  <div class="c-affiliate-landing__feature-container">
    <div class="c-affiliate-landing__feature-item">
      <svg class="c-affiliate-landing__feature-icon" viewBox="0 0 24 24">
        <use href="#mobile-with-heart" />
      </svg>
      <p>Mobile app</p>
    </div>
  </div>
  <div class="c-affiliate-landing__feature-container">
    <div class="c-affiliate-landing__feature-item">
      <svg class="c-affiliate-landing__feature-icon" viewBox="0 0 24 24">
        <use href="#pig" />
      </svg>
      <p>No overlimit fees</p>
    </div>
  </div>
</div>
