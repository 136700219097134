// vendor
import { Injectable } from '@angular/core';
// services
import { DateTimeService } from '../../shared/services/date-time.service';
// models
import * as fromApplicationModels from '../../application/models';
import { CardProspectOffer, CardProductOffer } from '../../offers/models';
import { Lead } from '../../lead/models';
import { QuestionValues, PreAddress } from '../../questions/models';
// utils
import { uppercase } from '../../../utils';
import { FormatStringFnNames, helperFns } from '../helpers/helper-functions';
import {
  CustomerApplicationPreferences,
  ApplicantBankingInfo,
  ApplicantEmployment,
  ApplicantResidence,
} from '../../../../../fakesl/src/models/generated/applicationservice.model';
import * as selectOptions from '../../../../../fakesl/src/api/data/options.json';

// consts
const applicationDefaults = {
  DEFAULT_VALUE: null,
};
const { TaxIdentifierTypes, IncomeTypes, CountryCodeTypes, PhoneTypes } = fromApplicationModels;

const { states } = selectOptions;

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(private dateTimeService: DateTimeService) {}

  /**
   * sync prospect offer id
   * @param prospectOffer
   * @returns prospect offer id
   */
  syncProspectOfferId(prospectOffer: CardProspectOffer): string {
    let prospectOfferId;
    if (prospectOffer) {
      prospectOfferId = prospectOffer.id;
    }
    return this.defaultIfUndefined(prospectOfferId);
  }

  /**
   * sync product offer id
   * @param productOffer
   * @returns product offer id
   */
  syncProductOfferId(productOffer: CardProductOffer): string {
    return productOffer.id;
  }

  /**
   * sync lead id
   * @param lead
   * @returns lead
   */
  syncLeadId(lead: Partial<Lead>): string {
    let leadId;
    if (lead) {
      leadId = lead.id;
    }
    return this.defaultIfUndefined(leadId);
  }

  /**
   * sync offer code
   * [Note] check if prospectOffer
   * @param prospectOffer
   * @returns offer code
   */
  syncOfferCode(prospectOffer: CardProspectOffer): string {
    let offerCode;
    if (prospectOffer) {
      offerCode = prospectOffer.offerCode;
    }
    return this.defaultIfUndefined(offerCode);
  }

  /**
   * sync accept terms and conditions
   * @param applicationMeta
   * @returns
   */
  syncAcceptTermsAndConditions(questionValues: QuestionValues): boolean {
    return this.defaultIfFalsy(questionValues.agreement);
  }

  syncStatementPreferences(questionValues: QuestionValues): CustomerApplicationPreferences {
    return {
      paperStatement: questionValues?.paperStatementPreference || false,
      estatement: questionValues?.paperlessStatementPreference || false,
    };
  }

  /**
   * sync email address
   * @param applicationMeta, questionValues
   * @returns email address
   */
  syncEmailAddress(applicationMeta: fromApplicationModels.ApplicationMeta, questionValues?: QuestionValues): string {
    return this.defaultIfUndefined(applicationMeta.emailAddress || questionValues?.emailAddress);
  }

  /**
   * sync date of birth
   * @param questionValues
   * @returns date of birth
   */
  syncDateOfBirth(questionValues: QuestionValues): string {
    return this.dateTimeService.convertMMDDYYYYtoISODateStr(questionValues.dateOfBirth);
  }

  /**
   * syn full name
   * @param questionValues
   * @returns full name
   */
  syncFullName(questionValues: QuestionValues): fromApplicationModels.FullName {
    return {
      // also accounts for empty string
      firstName: this.handleStringFormatting(questionValues.firstName, [FormatStringFnNames.formatApostrophe]),
      lastName: this.handleStringFormatting(questionValues.lastName, [FormatStringFnNames.formatApostrophe]),
    };
  }

  /**
   * sync income
   * @param questionValues
   * @returns income
   */
  syncIncome(questionValues: QuestionValues): fromApplicationModels.Income {
    return {
      // convert to number?
      amount: questionValues.annualIncome,
      incomeType: uppercase(IncomeTypes.Annual),
    };
  }

  /**
   * sync mailing address (ie address to mail to)
   * @param questionValues
   * @returns mailing address
   */
  syncMailingAddress(questionValues: QuestionValues): fromApplicationModels.Address {
    const preAddress = {
      address: questionValues.address,
      city: questionValues.city,
      state: this.handleStateConversion(questionValues.state),
      zipCode: questionValues.zipCode,
    };
    return this.syncAddress(preAddress);
  }

  /**
   * sync physical address
   * @param questionValues
   * @returns physical address
   */
  syncPhysicalAddress(questionValues: QuestionValues): fromApplicationModels.Address {
    let address = applicationDefaults.DEFAULT_VALUE;
    // phy addr exists if po box was entered for address (ie mailing)
    if (questionValues.physicalAddress) {
      const preAddress = {
        address: questionValues.physicalAddress,
        city: questionValues.physicalCity,
        state: this.handleStateConversion(questionValues.physicalState),
        zipCode: questionValues.physicalZipCode,
      };
      address = this.syncAddress(preAddress);
    }
    return address;
  }

  /**
   * sync address
   * @param
   * @return address
   */
  syncAddress(preAddress: PreAddress): fromApplicationModels.Address {
    return {
      // also accounts for empty string
      addressLineOne: this.handleStringFormatting(preAddress.address, [FormatStringFnNames.formatApostrophe]),
      addressLineTwo: applicationDefaults.DEFAULT_VALUE,
      city: this.handleStringFormatting(preAddress.city, [FormatStringFnNames.formatApostrophe]), // also accounts for empty string
      countryCode: uppercase(CountryCodeTypes.US),
      state: this.handleStateConversion(preAddress.state),
      zip: this.syncZipCode(preAddress.zipCode),
    };
  }

  /**
   * sync zip code
   * @param zipCode
   * @return zip code
   */
  syncZipCode(zipCode: string): fromApplicationModels.ZipCode {
    return {
      firstFive: zipCode?.substring(0, 5),
      plusFour: this.defaultIfFalsy(zipCode?.substring(5, 10)), // account for no plus four (ie empty string)
    };
  }

  /**
   * sync primary phone
   * @params questionValues
   * @returns phone
   */
  syncPrimaryPhone(questionValues: QuestionValues): fromApplicationModels.Phone {
    /*
    NOTE: primary inferred as mobile phone in api.
    Mobile phone is optional if home phone provided.
    */
    let syncedPhone = applicationDefaults.DEFAULT_VALUE;
    const { mobilePhone } = questionValues;
    if (mobilePhone) {
      syncedPhone = this.syncPhone(mobilePhone, PhoneTypes.Mobile);
    }
    return syncedPhone;
  }

  /**
   * sync secondary phone
   * @param questionValues
   * @returns phone
   */
  syncSecondaryPhone(questionValues: QuestionValues): fromApplicationModels.Phone {
    /*
    NOTE: secondary inferred as home phone in api.
    Home phone is optional if mobile phone provided.
    */
    let syncedPhone = applicationDefaults.DEFAULT_VALUE;
    const { homePhone } = questionValues;
    if (homePhone) {
      syncedPhone = this.syncPhone(homePhone, PhoneTypes.Home);
    }
    return syncedPhone;
  }

  /**
   * sync employer phone
   * @param questionValues
   * @returns phone
   */
  syncEmployerPhone(questionValues: QuestionValues): fromApplicationModels.Phone {
    /*
    NOTE: employer phone required toggles based
    on selected employment status.
    */
    let syncedPhone = applicationDefaults.DEFAULT_VALUE;
    const { employerPhone } = questionValues;
    if (employerPhone) {
      syncedPhone = this.syncPhone(employerPhone, PhoneTypes.Work);
    }
    return syncedPhone;
  }

  /**
   * sync phone
   * @param phoneNumber
   * @returns phone
   */
  syncPhone(phoneNumber: string, phoneType: string): fromApplicationModels.Phone {
    return {
      areaCode: phoneNumber?.substring(0, 3),
      prefix: phoneNumber?.substring(3, 6),
      lineNumber: phoneNumber?.substring(6, 10),
      countryCode: uppercase(CountryCodeTypes.US),
      phoneType: uppercase(phoneType),
    };
  }

  /**
   * sync tax identifier
   * @params socialSecurityNumber
   * @returns social security number
   */
  syncTaxIdentifier(questionValues: QuestionValues): fromApplicationModels.TaxIdentifier {
    const { socialSecurityNumber } = questionValues;
    return {
      firstDigits: socialSecurityNumber?.substring(0, 3),
      secondDigits: socialSecurityNumber?.substring(3, 5),
      thirdDigits: socialSecurityNumber?.substring(5, 9),
      taxIdentifierType: uppercase(TaxIdentifierTypes.Ssn),
    };
  }

  /**
   * sync applicant employment
   * @params questionValues
   * @returns applicant employment
   */
  syncApplicantEmployment(questionValues: QuestionValues): ApplicantEmployment {
    return {
      employmentStatus: questionValues.employmentStatus,
      employerName: this.handleStringFormatting(questionValues.employerName, [
        FormatStringFnNames.formatNonAlphanumericSpace,
      ]),
    } as ApplicantEmployment;
  }

  /**
   * sync applicant residence
   * account for 0 falsy monthly
   * @params questionValues
   * @returns applicant residence
   */
  syncApplicantResidence(questionValues: QuestionValues): ApplicantResidence {
    return {
      residenceType: questionValues.housingType,
      residentialTerm: questionValues.residentialLength,
      // convert to number ?
      monthlyPaymentAmount: questionValues.housingMonthlyPayment,
    };
  }

  /**
   * sync applicant banking info
   * @params questionValues
   * @returns applicant banking info
   */
  syncApplicantBankingInfo(questionValues: QuestionValues): ApplicantBankingInfo {
    return {
      bankingAccounts: questionValues.banking,
    };
  }

  /**
   * check any obj values exist
   * [Note] value exist considered not undefined/null/empty string
   * @param obj
   * @returns boolean
   */
  checkAnyObjValuesExist(obj: Record<string, unknown>): boolean {
    // use object.keys avoid potential browser compatibility issue
    return Object.keys(obj).some((key) => {
      const value = obj[key];
      return value !== undefined && value !== null && value !== '';
    });
  }

  handleStringFormatting(value: string, includedFnNames: FormatStringFnNames[]): any {
    return value ? helperFns.formatString(value, includedFnNames) : this.defaultIfFalsy(value);
  }

  /**
   * default if undefined
   * @param value
   * @param defaultValue
   * @returns value
   */
  defaultIfUndefined(value, defaultValue = applicationDefaults.DEFAULT_VALUE): any {
    return value === undefined ? defaultValue : value;
  }

  /**
   * default if falsy
   * falsy values can include:
   * undefined || null || NaN || 0 || "" (empty string)
   * @param value
   * @param defaultValue
   * @returns value
   */
  defaultIfFalsy(value, defaultValue = applicationDefaults.DEFAULT_VALUE): any {
    return value || defaultValue;
  }

  handleStateConversion(state: string): any {
    return state && state.length === 2
      ? state.toUpperCase()
      : states.find((s) => s.label.toUpperCase() === state?.toUpperCase())?.value || state;
  }
}
