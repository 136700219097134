<p class="c-agreement__disclosure" *ngFor="let disclosure of disclosures">
  <a
    *ngIf="disclosure.link"
    target="_blank"
    [href]="disclosure.link"
    mat-button
    color="primary"
    class="c-agreement__disclosure-label button-link-style"
    >{{ disclosure.label }}</a
  >
  <button
    *ngIf="disclosure.dialog"
    mat-button
    color="primary"
    type="button"
    class="c-agreement__disclosure-label button-link-style"
    (click)="openDialog(disclosure.dialog)"
  >
    {{ disclosure.label }}
  </button>
  <span *ngIf="disclosure.disclosure" class="c-agreement__disclosure-text" [innerHTML]="disclosure.disclosure"></span>
</p>
