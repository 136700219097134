// vendor
import { Component, Input, Output, EventEmitter } from '@angular/core';
// component
import { BaseFormFieldComponent } from '../base-form-field/base-form-field.component';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['../form-fields.scss', './form-checkbox.component.scss'],
})
export class FormCheckboxComponent extends BaseFormFieldComponent {
  @Input() sizeClass: string;
  @Input() noPadding: boolean;
  @Output() clickLabel: EventEmitter<null>;

  constructor() {
    super();
    this.clickLabel = new EventEmitter();
  }

  /**
   * on click label
   */
  onClickLabel(): void {
    this.clickLabel.emit();
  }
}
