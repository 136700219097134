<div class="c-adverse-action-befh-banner">
  <div class="c-adverse-action-befh-banner__close-button" (click)="closeBanner()">
    <svg viewBox="0 0 24 24">
      <use href="#close"></use>
    </svg>
  </div>
  <div class="c-adverse-action-befh-banner__l-container">
    <div class="c-adverse-action-befh-banner__score-image">
      <img alt="best egg financial health score image" src="/assets/images/befh-banner-score.svg" />
    </div>
    <div class="c-adverse-action-befh-banner__text">
      <p>Learn about how you could strengthen your credit with <span>Best Egg Financial Health.</span></p>
    </div>
  </div>
  <div class="c-adverse-action-befh-banner__r-container">
    <a
      mat-button
      class="c-adverse-action-befh-banner__start-button"
      [href]="befh_url"
      (click)="dispatchEEV2()"
      target="_blank"
      >Get started</a
    >
  </div>
</div>
