// vendor
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
// ngrx
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromQuestionsActions from '../actions/questions.actions';

@Injectable({
  providedIn: 'root',
})
export class QuestionsEffects {
  constructor(private action$: Actions) {}

  // reset question values
  resetQuestionValues$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromQuestionsActions.resetQuestionValues),
      switchMap((action) => {
        const { questionNames } = action;
        const nulledQuestionValues = questionNames.reduce((values, name) => ((values[name] = null), values), {});
        return of(
          fromQuestionsActions.updateQuestionValues({
            questionValues: nulledQuestionValues,
          })
        );
      })
    )
  );
}
