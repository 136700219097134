import { createReducer, on } from '@ngrx/store';
import * as fromDashboardLoadActions from '../actions';
import { IDashboardLoad } from 'src/app/features/dashboard/models';

export const DASHBOARD_LOAD_STATE_KEY = 'dashboardLoad';

export interface DashboardLoadState {
  data: IDashboardLoad;
}

export const initialState: DashboardLoadState = {
  data: {
    isPendDashboard: false,
    pendReason: null,
  },
};

// reducer
const dashboardLoadReducer = createReducer(
  initialState,
  // update
  on(
    fromDashboardLoadActions.isPendDashboard,
    (state: DashboardLoadState, action: ReturnType<typeof fromDashboardLoadActions.isPendDashboard>) => {
      const data = { ...state.data, isPendDashboard: action.isPendDashboard, pendReason: action.pendReason };
      return { ...state, data };
    }
  )
);

export const reducer = (state: DashboardLoadState, action: fromDashboardLoadActions.DashboardLoadProcessAction) =>
  dashboardLoadReducer(state, action);
