import { ApplicationStep, ApplicationStepNameTypes, StepComponentMap } from '../../../../models';
import { defaultStepComponentMap } from '../../../shared/application-step-component-mapping.config';
import { applicationStepFormConfigMap } from '../../best-egg/prospect';
import * as fromWalkupEmail from '../walkup-email';

export const kcfVerifyIdentityStep: ApplicationStep = {
  // title and action message need to be dynamic
  name: ApplicationStepNameTypes.VerifyIdentity,
  title: '',
  order: 1,
  actionMessage: '',
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.VerifyIdentity],
};

export const kcfConfirmInfoStep: ApplicationStep = {
  name: ApplicationStepNameTypes.ConfirmInfo,
  // title need to be dynamic
  title: '',
  order: 2,
  formConfig: applicationStepFormConfigMap[ApplicationStepNameTypes.ConfirmInfo],
};

const walkupEmailStepsModified: ApplicationStep[] = fromWalkupEmail.applicationSteps.map((step) => ({
  ...step,
  order: step.order + 2,
}));

// Steps that can be hidden when kcf (with or w/o express lane) is active and not failed for verification
export const KCF_ADJUSTABLE_STEPS = walkupEmailStepsModified
  .filter((step) => {
    const excludeName = [
      ApplicationStepNameTypes.PhysicalAddress,
      ApplicationStepNameTypes.CashAdvances,
      ApplicationStepNameTypes.Agreement,
      ApplicationStepNameTypes.SocialSecurityNumber,
      ApplicationStepNameTypes.TermsAndConditions,
    ];
    return !excludeName.includes(step.name as ApplicationStepNameTypes);
  })
  .map((step) => step.name);

export const applicationSteps: ApplicationStep[] = [
  kcfVerifyIdentityStep,
  kcfConfirmInfoStep,
  ...walkupEmailStepsModified,
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
