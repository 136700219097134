export const regex = {
  isPoBox: [/.*[P|p]+(OST|ost)*\.*\s*[O|o|0]+(ffice|FFICE)*\.*\s*[B|b][O|o|0][X|x].*/],
  streetAddress: /^(?:[\s\w\.,\/\-#'‘’])*[a-zA-Z](?:[\s\w\.,\/\-#'‘’])*$/,
  unformattedDate: /^(\d{2})(\d{2})(\d{4})$/, // e.g. 01011982
  dateFormat: /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/, // e.g. 01/01/1982
  // Same as the built-in Angular regex: https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
  // eslint-disable-next-line max-len
  email:
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'‘’*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'‘’*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,
  // Permits spaces, dashes, and apostrophes. Requires a letter.
  properNameChar: /[A-Za-z-\s'‘’]/,
  properName: /^\s*([a-zA-Z]+?)([-\s'‘’][a-zA-Z]+)*?\s*$/, // allow leading, trailing spaces
  tenDigitNumber: /^\d{10}$/,
  numericOnly: /^[0-9]+$/,
  decimalNumber: /^[0-9]+$|^[0-9]*\.[0-9]{1,2}$/,
  phoneNoLeadingDigitOfOne: /^[0,2-9]{1}[0-9]*$/,
};
