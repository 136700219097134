// vendor
import { Component, Injector, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
// store
import { select } from '@ngrx/store';
import * as fromOffersStore from '../../../../offers/store';
// components
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
// models
import { SimpleFormConfig } from '../../../../questions/models';
import { TextBubbleTypes } from '../../../../shared/configs/text-bubble-types';

@Component({
  selector: 'app-walkup-information',
  templateUrl: './walkup-information.component.html',
  styleUrls: ['./walkup-information.component.scss'],
})
export class WalkupInformationComponent extends BaseApplicationStepComponent implements OnInit {
  TextBubbleTypes = TextBubbleTypes;
  formConfig: SimpleFormConfig;
  prospectOfferLoaded$: Observable<boolean>;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.prospectOfferLoaded$ = this.store.pipe(select(fromOffersStore.selectCardProspectOfferLoaded));
    this.formConfig = this.applicationStepData.applicationStep.formConfig;
  }
}
