// models
import { environment } from 'src/environments/environment';
import { Item, Link } from '../../shared/models';

export const vendorCerts: Item[] = [
  {
    label: 'better-bussiness-bureau',
    value: 'assets/logos/vendor/better-bussiness-bureau.svg',
  },
  {
    label: 'consumer-affairs',
    value: 'assets/logos/vendor/consumer-affairs.svg',
  },
];

export const privacyPolicyDisclosureLink: Link = {
  label: 'Privacy Policy',
  url: `https://${environment.bestEggDomain}/privacy-policy/`,
};

export const termsOfUseDisclosureLink: Link = {
  label: 'Terms of Use',
  url: `https://${environment.bestEggDomain}/terms-of-use/`,
};

export const applyDisclosures: string[] = [
  `
To help the government fight the funding of terrorism and money laundering activities,
Federal law requires all financial institutions to obtain, verify, and record information
that identifies each person who opens an account. What this means for you: When you open an
account, we will ask for your name, address, date of birth, and other information that will
allow us to identify you. We may also ask to see your driver’s license or other identifying documents.
`,
  `The Best Egg Credit Card is issued by First Bank & Trust, Brookings, SD pursuant to a license by Visa International.
Visa is a registered trademark, and the Visa logo design is a trademark of Visa International Service Association.`,
];
