/**
 * module: core
 * desc: contain singleton services, universal components and
 * other features where there’s only once instance per application
 */

// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatButtonModule } from '@angular/material/button';
// ngrx
import { StoreModule } from '@ngrx/store';
import * as fromCoreStore from './store';
// modules
import { SharedModule } from '../shared/shared.module';
// containers
import * as fromContainers from './containers';
// components
import * as fromComponents from './components';

@NgModule({
  declarations: [...fromContainers.containers, ...fromComponents.components],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCoreStore.CORE_STATE_KEY, fromCoreStore.reducer),
    SharedModule,
    MatButtonModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
  exports: [...fromContainers.containers, ...fromComponents.components],
})
export class CoreModule {}
