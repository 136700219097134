// ngrx
import { Params } from '@angular/router';
import { createAction, props, Action } from '@ngrx/store';
// models
import { QuestionValues } from '../../../questions/models';
import { ApplicationMeta } from '../../models';

export enum ApplicationLoadProcessActionTypes {
  ApplicationLoadProcess = '[Application] Application Load Process',
  CreateLeadWithStoreData = '[Application] Create Lead With Store Data',
  ApplicationLoadProcessSuccess = '[Application] Application Load Process Success',
  ApplicationLoadProcessFail = '[Application] Application Load Process Fail',
  LeadLoadProcess = '[Application] Lead Load Process',
  OpportunityLeadLoadProcess = '[Application] Opportunity Lead Load Process]',
  LeadLoadProcessSuccess = '[Application] Lead Load Process Success',
}

//  load process
export const applicationLoadProcess = createAction(
  ApplicationLoadProcessActionTypes.ApplicationLoadProcess,
  props<{ queryParams: Params }>()
);

export const createLeadWithStoreData = createAction(
  ApplicationLoadProcessActionTypes.CreateLeadWithStoreData,
  (questionValues: QuestionValues = null) => ({ questionValues })
);

// lead load process
export const leadLoadProcess = createAction(
  ApplicationLoadProcessActionTypes.LeadLoadProcess,
  props<{ queryParams: Params }>()
);

export const opportunityLeadLoadProcess = createAction(
  ApplicationLoadProcessActionTypes.OpportunityLeadLoadProcess,
  props<{ queryParams: Params }>()
);

export const leadLoadProcessSuccess = createAction(ApplicationLoadProcessActionTypes.LeadLoadProcessSuccess);

export const applicationLoadProcessSuccess = createAction(
  ApplicationLoadProcessActionTypes.ApplicationLoadProcessSuccess
);

export const applicationLoadProcessFail = createAction(
  ApplicationLoadProcessActionTypes.ApplicationLoadProcessFail,
  props<{ action: Action }>()
);

export type ApplicationLoadProcessAction = ReturnType<
  | typeof applicationLoadProcess
  | typeof createLeadWithStoreData
  | typeof applicationLoadProcessSuccess
  | typeof applicationLoadProcessFail
  | typeof leadLoadProcess
  | typeof opportunityLeadLoadProcess
  | typeof leadLoadProcessSuccess
>;
