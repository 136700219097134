// store
import { createReducer, on } from '@ngrx/store';
import * as fromDigitalExperienceActions from '../actions/digital-experience.actions';
// models
import { ApplicationStep, ApplicationStepDirectionTypes, StepComponentMap } from '../../models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

// key
export const DIGITAL_EXPERIENCE_STATE_KEY = 'digitalExperience';

// state
export interface DigitalExperienceState extends EntityState<ApplicationStep> {
  name: string;
  stepComponentMap: StepComponentMap;
  currentStep: string;
  stepDirection: string;
  isFirstStep: boolean;
  isLastStep: boolean;
  progress: number;
  loaded: boolean;
  loading: boolean;
  digitalExperienceInitiallyUpdated: boolean;
}

export const adapter: EntityAdapter<ApplicationStep> = createEntityAdapter<ApplicationStep>({
  selectId: (a: ApplicationStep) => a.name,
  sortComparer: (a: ApplicationStep, b: ApplicationStep) => a.order - b.order,
});

export const initialState: DigitalExperienceState = adapter.getInitialState({
  name: null,
  stepComponentMap: null,
  currentStep: null,
  stepDirection: null,
  isFirstStep: false,
  isLastStep: false,
  progress: 0,
  loaded: false,
  loading: false,
  digitalExperienceInitiallyUpdated: false,
});

// reducer
const digitalExperienceReducer = createReducer(
  initialState,
  // load
  on(fromDigitalExperienceActions.loadDigitalExperience, (state: DigitalExperienceState) => ({
    ...state,
    loading: true,
  })),
  on(
    fromDigitalExperienceActions.setDigitalExperienceAppSteps,
    (
      state: DigitalExperienceState,
      action: ReturnType<typeof fromDigitalExperienceActions.setDigitalExperienceAppSteps>
    ) => {
      /**
       * Initially make all the steps 'allowed'. Certain logic will determine their
       * allowed status at a later point.
       */
      const allAppSteps = action.applicationSteps.map((step) => ({
        ...step,
        isAllowed: true,
      }));

      return adapter.addMany(allAppSteps, state);
    }
  ),
  on(
    fromDigitalExperienceActions.loadDigitalExperienceSuccess,
    (
      state: DigitalExperienceState,
      action: ReturnType<typeof fromDigitalExperienceActions.loadDigitalExperienceSuccess>
    ) => ({
      ...state,
      stepComponentMap: action.stepComponentMap,
      loading: false,
      loaded: true,
    })
  ),
  on(
    fromDigitalExperienceActions.setDigitalExperienceNameSuccess,
    (
      state: DigitalExperienceState,
      action: ReturnType<typeof fromDigitalExperienceActions.setDigitalExperienceNameSuccess>
    ) => ({
      ...state,
      name: action.name,
      loading: false,
      loaded: false,
    })
  ),
  /**
   * Could we handle this with just one reducer/action, or would it be
   * better to have separate actions?
   */
  on(
    fromDigitalExperienceActions.setApplicationStep,
    (state: DigitalExperienceState, action: ReturnType<typeof fromDigitalExperienceActions.setApplicationStep>) => {
      /**
       * TODO_UPDATE: might be a cleaner/smarter way to handle this,
       * but for now it works.
       */
      const ids = state.ids as string[];
      // When determining relative step, only take into account the currently
      // allowed steps.
      const allowedSteps = ids.filter((stepName) => state.entities[stepName].isAllowed);
      const currentIndex = allowedSteps.indexOf(state.currentStep);
      const firstStep = allowedSteps[0];
      const lastStep = allowedSteps[allowedSteps.length - 1];
      let newStep;

      if (action.payload.stepName && allowedSteps.includes(action.payload.stepName)) {
        /**
         * Explicitly passing a step that we want to set it to. Note that we prevent this
         * from happening if the desired step isn't allowed based on the experience.
         */
        newStep = action.payload.stepName;
      } else if (
        /** For handling natural progression through the funnel steps. */
        action.payload.type === ApplicationStepDirectionTypes.Forward &&
        currentIndex < allowedSteps.length - 1
      ) {
        newStep = allowedSteps[currentIndex + 1];
      } else if (action.payload.type === ApplicationStepDirectionTypes.Backward && currentIndex > 0) {
        /** For handling natural backwards navigation through the funnel steps. */
        newStep = allowedSteps[currentIndex - 1];
      } else if (action.payload.forceFirstStep) {
        /**
         * For when we want to initially set the first step, once the experience
         * has been determined.
         */
        newStep = firstStep;
      } else {
        // Either we're out of range, or something's wrong. Just keep the step the same.
        newStep = state.currentStep;
      }

      /**
       * We indicate "progress" based on the current step we're on in relation
       * to the total number of available steps. In a way, we treat being on a step as the step already being completed.
       * IE: If our CURRENT step is the 9th step, and we have 9 total available steps, then that would indicate 100% completion.
       */
      const progress = Number(((allowedSteps.indexOf(newStep) + 1) * (100 / allowedSteps.length)).toFixed(2));
      const isFirstStep = firstStep === newStep;
      const isLastStep = lastStep === newStep;
      return {
        ...state,
        currentStep: newStep,
        isFirstStep,
        isLastStep,
        progress,
      };
    }
  ),
  on(
    fromDigitalExperienceActions.setApplicationStepDirection,
    (
      state: DigitalExperienceState,
      action: ReturnType<typeof fromDigitalExperienceActions.setApplicationStepDirection>
    ) => {
      /**
       * Also set the current step as part of this string. This is so the animation
       * trigger can detect a value change even when we continue going forward or backwards.
       */
      const { currentStep } = state;
      return {
        ...state,
        stepDirection: `${currentStep}-${action.payload}`,
      };
    }
  ),
  on(
    fromDigitalExperienceActions.updateStepsAllowed,
    (state: DigitalExperienceState, action: ReturnType<typeof fromDigitalExperienceActions.updateStepsAllowed>) => {
      // make multiple updates
      let removals = [];
      let additions = [];
      if (action.payload.stepsOff) {
        removals = action.payload.stepsOff.map((stepName) => ({
          id: stepName,
          changes: {
            isAllowed: false,
          },
        }));
      }
      if (action.payload.stepsOn) {
        additions = action.payload.stepsOn.map((stepName) => ({
          id: stepName,
          changes: {
            isAllowed: true,
          },
        }));
      }
      return adapter.updateMany(removals.concat(additions), state);
    }
  ),
  on(
    fromDigitalExperienceActions.updateStepsContent,
    (state: DigitalExperienceState, action: ReturnType<typeof fromDigitalExperienceActions.updateStepsContent>) =>
      adapter.updateMany(action.payload, state)
  ),
  on(fromDigitalExperienceActions.updateDigitalExperienceSuccess, (state: DigitalExperienceState) => ({
    ...state,
    digitalExperienceInitiallyUpdated: true,
  })),
  on(
    fromDigitalExperienceActions.loadDigitalExperienceFail,
    (
      state: DigitalExperienceState,
      _action: ReturnType<typeof fromDigitalExperienceActions.loadDigitalExperienceFail>
    ) => ({
      ...state,
      loaded: false,
      loading: false,
    })
  ),
  on(fromDigitalExperienceActions.clearDigitalExperience, () => initialState)
);

export const reducer = (state: DigitalExperienceState, action: fromDigitalExperienceActions.DigitalExperienceAction) =>
  digitalExperienceReducer(state, action);

// state selectors
// TODO_UPDATE: break up some of this state
export const digitalExperienceName = (state: DigitalExperienceState) => state.name;
export const digitalExperienceStepComponentMap = (state: DigitalExperienceState) => state.stepComponentMap;
export const digitalExperienceCurrentStep = (state: DigitalExperienceState) => state.currentStep;
export const digitalExperienceStepDirection = (state: DigitalExperienceState) => state.stepDirection;
export const digitalExperienceIsFirstStep = (state: DigitalExperienceState) => state.isFirstStep;
export const digitalExperienceIsLastStep = (state: DigitalExperienceState) => state.isLastStep;
export const digitalExperienceProgress = (state: DigitalExperienceState) => state.progress;
export const digitalExperienceStateLoaded = (state: DigitalExperienceState) => state.loaded;
export const digitalExperienceStateLoading = (state: DigitalExperienceState) => state.loading;
