// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Brand } from '../../../offers/models';

export enum CardBrandActionTypes {
  SelectCardBrand = '[Application] Select Card Brand',
  SelectCardBrandSuccess = '[Application] Select Card Brand Success',
  SelectCardBrandFail = '[Application] Select Card Brand Fail',
  ClearCardBrand = '[Application] Clear Card Brand',
}

// select brand
export const selectCardBrand = createAction(
  CardBrandActionTypes.SelectCardBrand,
  props<{ cardBrand: Brand; employerPhone: string }>()
);

export const selectCardBrandSuccess = createAction(CardBrandActionTypes.SelectCardBrandSuccess);

export const selectCardBrandFail = createAction(
  CardBrandActionTypes.SelectCardBrandFail,
  props<{ error: Record<string, any> }>()
);

// clear card brand
export const clearCardBrand = createAction(CardBrandActionTypes.ClearCardBrand);

export type CardBrandAction = ReturnType<
  typeof selectCardBrand | typeof selectCardBrandSuccess | typeof selectCardBrandFail | typeof clearCardBrand
>;
