// models
import * as fromQuestionsModels from '../../models';
import { ValidatorTypes } from '../../models';
import { formValidatorMsgMap } from '../validators';
// configs
import { commonPhoneQuestion } from './common-phone.config';

export const mobilePhoneQuestion: fromQuestionsModels.Question = {
  ...commonPhoneQuestion,
  name: fromQuestionsModels.QuestionNameTypes.MobilePhone,
  label: 'Mobile phone',
  validators: {
    sync: [
      ...commonPhoneQuestion.validators.sync,
      {
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
        message: formValidatorMsgMap.mobileOrHomePhoneRequired,
        readonly: true,
      },
    ],
  },
};
