<div class="c-form-checkbox-group">
  <div class="c-form-checkbox-group__header">
    <h2 mat-header class="c-form-checkbox-group__label" [innerHTML]="question.label"></h2>
    <p class="c-form-checkbox-group__sublabel" [innerHTML]="question.sublabel" *ngIf="question.sublabel"></p>
  </div>
  <mat-form-field class="mat-form-field-plain">
    <ul class="c-form-checkbox-group__list">
      <li class="c-form-checkbox-group__list-item" *ngFor="let option of question.options">
        <mat-checkbox color="primary" [value]="option.value" [checked]="isChecked(option)" (change)="onChange($event)">
          {{ option.label }}
        </mat-checkbox>
      </li>
    </ul>
    <input
      [formControl]="formControl"
      [errorStateMatcher]="matcher"
      (input)="blockInput()"
      readonly="readonly"
      hidden="true"
      [attr.aria-label]="question.label"
      matInput
    />
    <mat-error class="mat-error" *ngIf="errorValidator">
      {{ errorValidator.message }}
    </mat-error>
  </mat-form-field>
</div>
