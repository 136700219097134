// services
// models
import * as fromQuestionsModels from '../../models';

const minLength = 1;
const maxLength = 24;

/*
NOTE: For now allow larger maxlength (input char cutoff) than validator value
so max length validator error is triggered for potential ability to track users
that may encounter length issues for current vendor max
*/

export const physicalCityQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  name: fromQuestionsModels.QuestionNameTypes.PhysicalCity,
  label: 'City',
  placeholder: 'City',
  maxlength: 50,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter city',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.ProperName,
        message: 'Invalid characters',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MinLength,
        value: minLength,
        message: `Minimum of ${minLength} characters`,
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MaxLength,
        value: maxLength,
        message: `Maximum of ${maxLength} characters`,
      },
    ],
  },
};
