<div id="default-application-terms">
  <section
    class="default-application-terms__disclosure-summary"
    *ngIf="applicationTerms.disclosureSummary as disclosureSummary"
  >
    <div>
      <p
        class="default-application-terms__disclosure-summary--label"
        style="font-size: 14px; font-weight: 600; text-align: center; text-transform: uppercase; margin-bottom: 2px"
      >
        {{ disclosureSummary.label }}
      </p>
      <p class="default-application-terms__disclosure-summary--sublabel" style="text-align: center; font-weight: 600">
        {{ disclosureSummary.sublabel }}
      </p>
    </div>
    <p class="default-application-terms__disclosure-summary--value">
      <!-- wrap in span to prevent text overlap on export for now -->
      <span>{{ disclosureSummary.value }}</span>
    </p>
  </section>
  <section class="default-application-terms__terms">
    <app-default-product-terms [cardProductOffer]="cardProductOffer"></app-default-product-terms>
  </section>
  <section class="default-application-terms__disclosures">
    <div *ngFor="let section of applicationTerms.sections">
      <h3
        class="default-application-terms__disclosures--header"
        style="font-family: Arial, sans-serif; font-size: 14px; margin-bottom: 0px; text-transform: uppercase"
        *ngIf="section.title"
      >
        <strong>{{ section.title }}</strong>
      </h3>
      <p [attr.id]="item.templateId" [style]="item.style" *ngFor="let item of section.items">
        <span
          ><strong>{{ item.label }}</strong></span
        >
        <span [innerHTML]="item.value | domSanitize : 'html'"></span>
      </p>
    </div>
  </section>
  <section>
    <p>©{{ currentYear }} Marlette Marketing, LLC. All rights reserved.</p>
  </section>
</div>
