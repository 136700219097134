// vendor
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
// ngrx
import * as fromQuestionsStore from '../../../../../questions/store';
import * as fromQuestionsReducers from '../../../../../questions/store/reducers';

@Component({
  selector: 'app-annual-income-help-text',
  templateUrl: './annual-income-help-text.component.html',
  styleUrls: ['./annual-income-help-text.component.scss'],
})
export class AnnualIncomeHelpTextComponent {
  constructor(public questionsStore: Store<fromQuestionsReducers.QuestionsState>) {}

  public isWisconsin$: Observable<boolean> = this.questionsStore.pipe(
    select(fromQuestionsStore.selectQuestionValuesData),
    first(),
    map((values) => values.state === 'WI')
  );
}
