import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { helperFns } from '../../../helpers/helper-functions';
import { StylableDialogComponent } from '../stylable-dialog/stylable-dialog.component';

@Component({
  selector: 'app-credit-score-notice-dialog',
  templateUrl: './credit-score-notice-dialog.component.html',
  styles: ['.c-dialog__title { min-height: 20px;}'],
})
export class CreditScoreNoticeDialogComponent extends StylableDialogComponent implements OnInit {
  constructor(protected injector: Injector, @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    helperFns.resetCreatedIdsMap();
  }
}
