// ngrx
import { createReducer, on } from '@ngrx/store';
import * as fromQuestionsActions from '../actions';
// models
import { Validation } from '../../models';
import { Map } from '../../../shared/models';

// key
export const VALIDATIONS_STATE_KEY = 'validations';

// state
export interface ValidationsState {
  data: Map<any>;
}

export const initialState: ValidationsState = {
  data: null,
};

// reducer
const validationsReducer = createReducer(
  initialState,
  // address
  on(fromQuestionsActions.validateAddress, (state, action) => {
    const data = { ...state.data, address: null };
    return { ...state, data };
  }),
  on(fromQuestionsActions.validateAddressSuccess, (state, action) => {
    const address: Validation = {
      status: true,
      data: action.addressValidation,
    };
    const data = { ...state.data, address };
    return { ...state, data };
  }),
  on(fromQuestionsActions.validateAddressFail, (state, action) => {
    const errorResponse = action.error;
    const address: Validation = {
      status: false,
      data: errorResponse.error,
      message: errorResponse.error.message,
      errors: errorResponse.error.errors,
    };
    const data = { ...state.data, address };
    return { ...state, data };
  }),
  // lead backend errors
  on(fromQuestionsActions.setBackendErrors, (state, action) => {
    const errorResponse = action.error;
    const backendGeneral: Validation = {
      status: false,
      data: errorResponse.error,
      message: errorResponse.error.message,
      errors: errorResponse.error.errors,
    };
    const data = { ...state.data, backendGeneral };
    return { ...state, data };
  }),
  on(fromQuestionsActions.resetBackendErrors, (state) => {
    const backendGeneral: Validation = {
      status: true,
      data: null,
      message: null,
      errors: null,
    };
    const data = { ...state.data, backendGeneral };
    return { ...state, data };
  }),
  on(fromQuestionsActions.clearValidations, () => initialState)
);

export const reducer = (state: ValidationsState, action: fromQuestionsActions.QuestionsAction) =>
  validationsReducer(state, action);

// state selectors
export const selectValidationsStateData = (state: ValidationsState) => state.data;
