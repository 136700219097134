// vendor
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
// components
import { CommunicationsConsentDialogComponent } from '../../../../../shared/components/dialogs';
import { helpText } from '../../../../../shared/configs/help-text.config';

@Component({
  selector: 'app-phone-help-text',
  templateUrl: './phone-help-text.component.html',
  styleUrls: ['./phone-help-text.component.scss'],
})
export class PhoneHelpTextComponent {
  constructor(public dialog: MatDialog) {}
  public get phoneHelpText() {
    return helpText.phoneHelpText;
  }
  /**
   * open communications consent dialog
   */
  openCommunicationsConsentDialog() {
    this.dialog.open(CommunicationsConsentDialogComponent);
  }
}
