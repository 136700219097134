<footer id="c-footer" class="c-footer" role="contentinfo">
  <div class="c-footer__content">
    <section class="c-footer__disclosures">
      <p class="c-footer__disclosure" *ngFor="let disclosure of applyDisclosures">
        {{ disclosure }}
      </p>
      <p *ngIf="coreData$ | async as coreData" class="c-footer__contact">
        Questions? Give us a call at
        {{ coreData.contactPhoneNumberWithCountryCode }}
      </p>
    </section>
    <section>
      <div class="c-footer__container" *ngIf="coreData$ | async as coreData">
        <div class="l-container l-container--left">
          <div class="c-footer__details">
            <div class="c-footer__logo">
              <svg alt="logo" viewBox="0 0 24 24">
                <use href="#bestegg-logo"></use>
              </svg>
            </div>
            <div class="c-footer__copyright">Best Egg {{ coreData.trademarkYear }}</div>
          </div>
        </div>
        <div class="l-container l-container--right">
          <div class="c-footer__certs">
            <div class="c-footer__links">
              <a [href]="privacyPolicyDisclosure.url" target="_blank"
                ><span>{{ privacyPolicyDisclosure.label }}</span></a
              >
              <a [href]="termsOfUseDisclosure.url" target="_blank"
                ><span>{{ termsOfUseDisclosure.label }}</span></a
              >
            </div>
            <div class="c-footer__cert-logo-container">
              <ng-container *ngFor="let vendorCert of vendorCerts">
                <img class="c-footer__cert-logo" [src]="vendorCert.value" [alt]="vendorCert.label" />
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</footer>
