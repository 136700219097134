<div class="c-form-step-controls">
  <div [class.right-justified]="isFirstStep">
    <!-- Back button -->
    <app-form-button [ngClass]="btnBack.design" *ngIf="!isFirstStep">
      <button [attr.aria-label]="'go back'" [attr.type]="btnBack.behavior" (click)="onStepAction(btnBack.stepAction)">
        <svg class="back-icon" viewBox="0 0 24 24" alt="_back" stroke="currentColor">
          <use href="#arrow-left"></use>
        </svg>
      </button>
    </app-form-button>

    <!-- Next button -->
    <app-form-button [ngClass]="getButtonDesign()" *ngIf="!isLastStep">
      <button id="submit_btn" [attr.type]="btnNext.behavior" (click)="onStepAction(btnNext.stepAction)">
        <div class="button-content-container next">
          <span class="button-label">Continue</span>
          <span class="button-loading-container">
            <app-loading *ngIf="isLoading" [diameter]="20"></app-loading>
          </span>
        </div>
      </button>
    </app-form-button>

    <app-form-button [ngClass]="btnLink" *ngIf="isLastStep && (isOdlMicroFunnelApplyStep$ | async)">
      <button
        mat-button
        color="primary"
        type="button"
        class="c-form-step-controls__odl-opt-out button-link-style"
        (click)="optOutOdl()"
      >
        No thanks
      </button>
    </app-form-button>

    <!-- Final app submit button (last step) -->
    <app-form-button [ngClass]="getButtonDesign()" *ngIf="isLastStep">
      <button id="submit_btn" [attr.type]="btnSubmit.behavior" (click)="onStepAction(btnSubmit.stepAction)">
        <div class="button-content-container submit">
          <span class="button-label">{{ (isOdlMicroFunnelApplyStep$ | async) ? 'Submit' : 'Submit application' }}</span>
          <span class="button-loading-container" *ngIf="isLoading">
            <app-loading [diameter]="20"></app-loading>
          </span>
        </div>
      </button>
    </app-form-button>
  </div>
</div>
