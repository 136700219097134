import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-agreement-disclosures',
  templateUrl: './agreement-disclosures.component.html',
  styleUrls: ['../agreement/agreement.component.scss'],
})
export class AgreementDisclosuresComponent {
  @Input() disclosures: any[];
  @Output() dialogOpened = new EventEmitter<any>();

  openDialog(dialog: any): void {
    this.dialogOpened.emit(dialog);
  }
}
