import { QuestionNameTypes, ValidatorTypes, SimpleFormConfig } from '../../../questions/models';
import { EmploymentStatusTypes } from '../../../../features/application/models';
import { formValidatorMsgMap } from '../../../questions/configs/validators';

export const EMPLOYMENT_STATUS_WITH_EMPLOYER_PHONE_EXCLUSION = [
  EmploymentStatusTypes.Retired,
  EmploymentStatusTypes.Student,
  EmploymentStatusTypes.Unemployed,
  EmploymentStatusTypes.SelfEmployed,
] as string[];

export const EMPLOYMENT_STATUS_WITH_EMPLOYER_NAME_INCLUSION = [
  EmploymentStatusTypes.SalariedEmployee,
  EmploymentStatusTypes.HourlyWageEmployee,
] as string[];

export const verifyIdentityStepFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.DateOfBirth, QuestionNameTypes.ZipCode],
  validators: {
    sync: [],
    async: [],
  },
};

// #walkup-information
export const baseWalkupInformationStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.EmailAddress,
    QuestionNameTypes.FirstName,
    QuestionNameTypes.LastName,
    QuestionNameTypes.Address,
    QuestionNameTypes.City,
    QuestionNameTypes.State,
    QuestionNameTypes.ZipCode,
    QuestionNameTypes.DateOfBirth,
    QuestionNameTypes.MobilePhone,
    QuestionNameTypes.HomePhone,
  ],
  validators: {
    sync: [
      {
        // validation message provided at mobile/home question level
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
      },
    ],
    async: [
      {
        validatorName: ValidatorTypes.Address,
        message: 'Address validation',
      },
      /**
       * OfAgeForState validation is handled as part of Address validation.
       * It's a different validator since different "incorrect" fields are
       * put in an error state compared to the main Address validation.
       */
      {
        validatorName: ValidatorTypes.OfAgeForState,
        message: '',
        readonly: true,
      },
    ],
  },
  layout: {
    grid: [1, 2, 2, 2, 1, 2],
  },
};

export const landingPageFormConfig: SimpleFormConfig = {
  questionNames: [],
};

// #contact-information
export const contactInformationStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.FirstName,
    QuestionNameTypes.LastName,
    QuestionNameTypes.Address,
    QuestionNameTypes.City,
    QuestionNameTypes.State,
    QuestionNameTypes.ZipCode,
    QuestionNameTypes.DateOfBirth,
    QuestionNameTypes.MobilePhone,
    QuestionNameTypes.HomePhone,
  ],
  validators: {
    sync: [
      {
        // validation message provided at mobile/home question level
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
      },
    ],
    async: [
      {
        validatorName: ValidatorTypes.Address,
        message: 'Address validation',
      },
      /**
       * OfAgeForState validation is handled as part of Address validation.
       * It's a different validator since different "incorrect" fields are
       * put in an error state compared to the main Address validation.
       */
      {
        validatorName: ValidatorTypes.OfAgeForState,
        message: '',
        readonly: true,
      },
    ],
  },
  layout: {
    subgrids: [
      [2, 2, 2],
      [1, 1, 1],
    ],
  },
};

export const breakingBECCStep1FormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.FirstName,
    QuestionNameTypes.LastName,
    QuestionNameTypes.Address,
    QuestionNameTypes.City,
    QuestionNameTypes.State,
    QuestionNameTypes.ZipCode,
  ],
  validators: {
    async: [
      {
        validatorName: ValidatorTypes.Address,
        message: 'Address validation',
      },
      /**
       * OfAgeForState validation is handled as part of Address validation.
       * It's a different validator since different "incorrect" fields are
       * put in an error state compared to the main Address validation.
       */
      {
        validatorName: ValidatorTypes.OfAgeForState,
        message: '',
        readonly: true,
      },
    ],
  },
  layout: {
    grid: [2, 2, 2],
  },
};

export const breakingBECCStep2FormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.EmailAddress,
    QuestionNameTypes.DateOfBirth,
    QuestionNameTypes.MobilePhone,
    QuestionNameTypes.HomePhone,
  ],
  validators: {
    sync: [
      {
        // validation message provided at mobile/home question level
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
      },
    ],
  },
  layout: {
    grid: [2, 2],
  },
};

// #physical-address
export const physicalAddressStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.PhysicalAddress,
    QuestionNameTypes.PhysicalCity,
    QuestionNameTypes.PhysicalState,
    QuestionNameTypes.PhysicalZipCode,
  ],
  validators: {
    async: [
      {
        validatorName: ValidatorTypes.PhysicalAddress,
        message: 'Physical Address validation',
      },
      /**
       * OfAgeForState validation is handled as part of Address validation.
       * It's a different validator since different "incorrect" fields are
       * put in an error state compared to the main Address validation.
       */
      {
        validatorName: ValidatorTypes.OfAgeForState,
        message: '',
        readonly: true,
      },
    ],
  },
  layout: {
    grid: [2, 2],
  },
};

// #personal-information
export const personalInformationStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.EmploymentStatus,
    QuestionNameTypes.EmployerName,
    QuestionNameTypes.EmployerPhone,
    QuestionNameTypes.AnnualIncome,
  ],
  questionDependencies: {
    [QuestionNameTypes.EmployerPhone]: [
      {
        dictatorName: QuestionNameTypes.EmploymentStatus,
        dictatorFunction: (value) => {
          let requiredStatus = false;
          if (value) {
            const normalizedValue = value.toLowerCase();
            requiredStatus = !EMPLOYMENT_STATUS_WITH_EMPLOYER_PHONE_EXCLUSION.includes(normalizedValue);
          }
          return requiredStatus;
        },
      },
    ],
    [QuestionNameTypes.EmployerName]: [
      {
        dictatorName: QuestionNameTypes.EmploymentStatus,
        dictatorFunction: (value) => {
          let requiredStatus = false;
          if (value) {
            const normalizedValue = value.toLowerCase();
            requiredStatus = EMPLOYMENT_STATUS_WITH_EMPLOYER_NAME_INCLUSION.includes(normalizedValue);
          }
          return requiredStatus;
        },
      },
    ],
  },
  layout: {
    orientation: false,
    grid: [1, 1, 1],
  },
};

// #banking
export const bankingSimpleFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.Banking],
};

// #housing
export const housingStepFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.HousingType, QuestionNameTypes.HousingMonthlyPayment],
  questionDependencies: {
    [QuestionNameTypes.HousingMonthlyPayment]: [
      {
        dictatorName: QuestionNameTypes.HousingType,
        dictatorFunction: (value) => {
          let requiresType = false;
          if (value) {
            requiresType = value !== 'OWN_NO_MORTGAGE';
          }
          return requiresType;
        },
        defaultValue: '0',
      },
    ],
  },
};

// #residential-length
export const residentialLengthStepFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.ResidentialLength],
};

// #cash-advances
export const cashAdvancesSimpleFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.CashAdvances],
};

// #terms-and-conditions
export const termsAndConditionsStepFormConfig: SimpleFormConfig = {
  questionNames: [],
};

// #social-security-number
export const socialSecurityNumberStepFormConfig: SimpleFormConfig = {
  questionNames: [QuestionNameTypes.SocialSecurityNumber],
};

// #agreement
export const agreementStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.Agreement,
    QuestionNameTypes.PaperStatementPreference,
    QuestionNameTypes.PaperlessStatementPreference,
  ],
  validators: {
    sync: [
      {
        validatorName: ValidatorTypes.StatementPreferenceRequired,
        message: formValidatorMsgMap.statementPreferenceRequired,
      },
    ],
  },
};

// #opportunity-microfunnel
export const opportunityMicrofunnelStepFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.Agreement,
    QuestionNameTypes.PaperStatementPreference,
    QuestionNameTypes.PaperlessStatementPreference,
    QuestionNameTypes.SocialSecurityNumber,
  ],
  validators: {
    sync: [
      {
        validatorName: ValidatorTypes.StatementPreferenceRequired,
        message: formValidatorMsgMap.statementPreferenceRequired,
      },
    ],
  },
};

export const confirmInfoStepFormConfig: SimpleFormConfig = {
  questionNames: [
    ...baseWalkupInformationStepFormConfig.questionNames,
    ...housingStepFormConfig.questionNames,
    ...residentialLengthStepFormConfig.questionNames,
    ...personalInformationStepFormConfig.questionNames,
    ...bankingSimpleFormConfig.questionNames,
    QuestionNameTypes.ConfirmInformationCorrect,
  ],
  questionDependencies: {
    ...personalInformationStepFormConfig.questionDependencies,
    ...housingStepFormConfig.questionDependencies,
  },
  validators: {
    sync: [
      {
        // validation message provided at mobile/home question level
        validatorName: ValidatorTypes.MobileOrHomePhoneRequired,
        // Add phone error message to the bottom of confirm info step, since confirm info is a long step
        message: 'Either mobile or home phone required',
      },
    ],
    async: [
      {
        validatorName: ValidatorTypes.Address,
        message: 'Address validation',
      },
      /**
       * OfAgeForState validation is handled as part of Address validation.
       * It's a different validator since different "incorrect" fields are
       * put in an error state compared to the main Address validation.
       */
      {
        validatorName: ValidatorTypes.OfAgeForState,
        message: '',
        readonly: true,
      },
    ],
  },
};
