// vendor
import { Input, OnInit, HostListener, Directive, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
// services
import { WithFormGroupErrorStateMatcher } from '../../../services/helpers/form-field-error-state-matcher';
// models
import { FieldInteraction, Question, QuestionValidator } from '../../../models';

@Directive()
export abstract class BaseFormFieldComponent implements OnInit {
  matcher: WithFormGroupErrorStateMatcher;
  focusStatus: boolean;
  activeStatus: boolean;
  showHelpText: boolean;
  @Input() question: Question;
  @Input() parent: UntypedFormGroup;
  @Input() showLabel = true;
  @Output() fieldInteractionChange: EventEmitter<FieldInteraction> = new EventEmitter();
  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  onEvent(event) {
    this.activeStatus = event.type === 'mouseenter' || false;
    if (!this.focusStatus && !this.activeStatus) {
      this.showHelpText = false;
    }

    this.fieldInteractionChange.emit({ questionName: this.question.name, mouseEntered: this.activeStatus });
  }
  get formControl(): AbstractControl {
    return this.parent.get(this.question.name);
  }
  // always return the first error/ warning from the array
  get errorValidator(): QuestionValidator {
    return this.matcher.errorValidators[0];
  }
  get warningValidator(): QuestionValidator {
    return this.matcher.warningValidators[0];
  }
  get displayHelpText() {
    return this.showHelpText;
  }

  ngOnInit() {
    const validators = [...(this.question.validators.sync || []), ...(this.question.validators.async || [])];
    this.matcher = new WithFormGroupErrorStateMatcher(validators);
  }

  /**
   * on focus change
   * @param event
   */
  onFocusChange(event) {
    this.focusStatus = !!event;
    if (this.focusStatus) {
      this.showHelpText = true;
      this.fieldInteractionChange.emit({ questionName: this.question.name, focused: true });
    } else if (!this.focusStatus && !this.activeStatus) {
      this.showHelpText = false;
    }
  }

  /**
   * focus
   */
  focus() {
    this.matcher.focused = true;
  }

  /**
   * blur
   */
  blur() {
    this.matcher.focused = false;
  }

  /**
   * block input
   */
  blockInput(): void {
    this.formControl.setValue(null);
  }
}
