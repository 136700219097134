// imports
import { UnavailableComponent } from './unavailable/unavailable.component';
import * as fromLayoutContainers from './layout';
import { TimeoutComponent } from './timeout/timeout.component';
// exports
export * from './unavailable/unavailable.component';
export * from './timeout/timeout.component';
export * from './layout';
// exports group
export const containers: any = [UnavailableComponent, TimeoutComponent, ...fromLayoutContainers.containers];
