// store
import { createAction, props } from '@ngrx/store';

export type EnterpriseEventSessionsPayload = {
  sessionId: string;
};

export enum FunnelSessionsActionTypes {
  CreateFunnelSessionId = '[Sessions] Create Funnel Session Id',
  CreateFormSessionId = '[Sessions] Create Form Session Id',
  CreateLeadSessionId = '[Sessions] Create Lead Session Id',
  CreatePostSubmitSessionId = '[Sessions] Create Post Submit Session Id',
  ClearFormSessionId = '[Sessions] Clear Form Session Id',
}

export const createFunnelSessionId = createAction(
  FunnelSessionsActionTypes.CreateFunnelSessionId,
  props<EnterpriseEventSessionsPayload>()
);

export const createFormSessionId = createAction(
  FunnelSessionsActionTypes.CreateFormSessionId,
  props<EnterpriseEventSessionsPayload>()
);

export const createLeadSessionId = createAction(
  FunnelSessionsActionTypes.CreateLeadSessionId,
  props<EnterpriseEventSessionsPayload>()
);

export const createPostSubmitSessionId = createAction(
  FunnelSessionsActionTypes.CreatePostSubmitSessionId,
  props<EnterpriseEventSessionsPayload>()
);

export const clearFormSessionId = createAction(FunnelSessionsActionTypes.ClearFormSessionId);

export type FunnelSessionsAction = ReturnType<
  | typeof createFunnelSessionId
  | typeof createFormSessionId
  | typeof createLeadSessionId
  | typeof clearFormSessionId
  | typeof createPostSubmitSessionId
>;
