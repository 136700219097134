import { physicalAddressStepFormConfig } from './application-step-form.config';

// exports
export * from './application-step-form.config';
export * from './application-step-component-mapping.config';

/**
 * In case we need to append extra fields to a lead PATCH that don't exist on the current form.
 * I.e. on the contact page, we also want to attach physical address in case it needs to be cleared.
 *
 * We reference a set of fields based on a "scenario", in case we want to do something differently
 * for multiple questions within the same step. IE: if on contactInformation, we wanted to clear the
 * physical address in one scenario, but also wanted to add, or clear some value from a different
 * step in another scenario.
 */
export enum ExtraFieldsScenarios {
  ClearPhysicalAddress = 'CLEAR_PHYSICAL_ADDRESS',
}

export const applicationStepExtraFieldsConfigMap: Record<ExtraFieldsScenarios, any> = {
  [ExtraFieldsScenarios.ClearPhysicalAddress]: physicalAddressStepFormConfig.questionNames,
};
