// vendor
import { Injectable } from '@angular/core';
// services
import { SyncService } from '../../shared/services';
// models
import * as fromApplicationModels from '../models';
import { CardProspectOffer, CardProductOffer } from '../../offers/models';
import { Lead } from '../../lead/models';
import { QuestionValues } from '../../questions/models';
import {
  ApplicationFromLeadRequest,
  CustomerApplicationPreferences,
} from '../../../../../fakesl/src/models/generated/applicationservice.model';
import { OpportunityData } from 'fakesl/src/models/generated/leadmanagementservice.model';
// utils
import { getIovationBlackboxValues } from '../../analytics/helpers/iovation/iovation-blackboxes';

/*
  [Note] some safety defaults added to account for scenarios
  like if empty strings were to get through form validations
  and api accepts some empty strings as valid values
*/

@Injectable({
  providedIn: 'root',
})
export class ApplicationSyncService {
  constructor(private syncService: SyncService) {}

  /**
   * sync data to application
   * @param applicationMeta
   * @param prospectOffer
   * @param productOffer
   * @param lead
   * @param questionValues
   * @param odlData
   * @returns application
   */
  syncDataToApplication(
    applicationMeta: fromApplicationModels.ApplicationMeta,
    prospectOffer: CardProspectOffer,
    productOffer: CardProductOffer,
    lead: Partial<Lead>,
    questionValues: QuestionValues,
    odlData: OpportunityData = null
  ): fromApplicationModels.ApplicationSubmit | ApplicationFromLeadRequest {
    const commonSubmitData = {
      digitalTrackingData: {
        ...lead.digitalTrackingData,
        blackBoxValue: getIovationBlackboxValues(window),
        subTrackingId: applicationMeta?.subid ? applicationMeta.subid : undefined,
      },
      productOfferId: this.syncService.syncProductOfferId(productOffer),
      leadId: this.syncService.syncLeadId(lead),
      customerApplicationPreferences: this.syncCustomerApplicationPreferences(questionValues),
      acceptTermsAndConditions: this.syncService.syncAcceptTermsAndConditions(questionValues),
    };
    if (odlData) {
      return {
        ...commonSubmitData,
        socialSecurityNumber: questionValues.socialSecurityNumber,
      } as ApplicationFromLeadRequest;
    } else {
      return {
        ...commonSubmitData,
        cashAdvances: questionValues.cashAdvances,
        prospectOfferId: this.syncService.syncProspectOfferId(prospectOffer),
        acquisition: this.syncAcquisition(applicationMeta, prospectOffer),
        primaryApplicant: this.syncPrimaryApplicant(applicationMeta, questionValues),
      } as fromApplicationModels.ApplicationSubmit;
    }
  }

  /**
   * sync acquisition
   * @param applicationMeta
   * @param prospectOffer
   * @returns acquisition
   */
  private syncAcquisition(
    applicationMeta: fromApplicationModels.ApplicationMeta,
    prospectOffer: CardProspectOffer
  ): Partial<fromApplicationModels.Acquisition> {
    return {
      offerCode: this.syncService.syncOfferCode(prospectOffer),
    };
  }

  /**
   * sync primary application
   * @param questionValues question values
   * @returns primary applicant
   */
  private syncPrimaryApplicant(
    applicationMeta: fromApplicationModels.ApplicationMeta,
    questionValues: QuestionValues
  ): fromApplicationModels.PrimaryApplicant {
    return {
      emailAddress: this.syncService.syncEmailAddress(applicationMeta, questionValues), // email address will come from question values for Walkup or Lightbox
      dateOfBirth: this.syncService.syncDateOfBirth(questionValues),
      fullName: this.syncService.syncFullName(questionValues),
      income: this.syncService.syncIncome(questionValues),
      mailingAddress: this.syncService.syncMailingAddress(questionValues),
      physicalAddress: this.syncService.syncPhysicalAddress(questionValues),
      primaryPhone: this.syncService.syncPrimaryPhone(questionValues),
      secondaryPhone: this.syncService.syncSecondaryPhone(questionValues),
      employerPhone: this.syncService.syncEmployerPhone(questionValues),
      taxIdentifier: this.syncService.syncTaxIdentifier(questionValues),
      applicantEmployment: this.syncService.syncApplicantEmployment(questionValues),
      applicantResidence: this.syncService.syncApplicantResidence(questionValues),
      applicantBankingInfo: this.syncService.syncApplicantBankingInfo(questionValues),
    };
  }

  private syncCustomerApplicationPreferences(questionValues: QuestionValues): CustomerApplicationPreferences {
    return {
      estatement: questionValues.paperlessStatementPreference || false,
      paperStatement: questionValues.paperStatementPreference || false,
    };
  }
}
