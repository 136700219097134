import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity';
import { AbTestNames, ABTestPopulationsCombined, IAbTestData } from '../../ab-tests/models';
import { ApplicationStep, ApplicationStepNameTypes, ApplicationStepSettings, DigitalExperienceTypes } from '../models';
import { getAffiliateImage } from '../configs/experiences/base-walkup/application-steps.config';
import { LeadState } from '../../lead/store';
import { applicationStepFormConfigMap } from '../configs/experiences/best-egg/prospect';
import { QuestionNameTypes } from '../../questions/models';
import { SetStepAndUpdateStepsAllowedMetaData } from '../store';
import { KCF_ADJUSTABLE_STEPS } from '../configs/experiences/best-egg/kcf/application-steps.config';
import { Params } from '@angular/router';
import { ODL_ADJUSTABLE_STEPS } from '../configs/experiences/best-egg/opportunity-microfunnel/application-steps.config';

@Injectable({
  providedIn: 'root',
})
export class DigitalExperienceUpdatesService {
  getAffiliateImage = getAffiliateImage;
  constructor() {}

  // keep this function in case we need it in future
  getStepsAllowedChanges(
    digitalExperienceName,
    abTestData: IAbTestData,
    leadState: LeadState,
    queryParams: Params
  ): ApplicationStepSettings {
    const stepsOn = [];
    const stepsOff = [];

    if (
      abTestData[AbTestNames.BREAKING_BECC]?.population === ABTestPopulationsCombined.BREAKBECC &&
      digitalExperienceName !== DigitalExperienceTypes.BestEggProspect
    ) {
      stepsOff.push(ApplicationStepNameTypes.ContactInformation, ApplicationStepNameTypes.WalkupInformation);
    } else {
      stepsOff.push(ApplicationStepNameTypes.BreakingBECCStep1, ApplicationStepNameTypes.BreakingBECCStep2);
    }

    if (digitalExperienceName === DigitalExperienceTypes.BestEggKcf) {
      this.setStepAllowedForExpressLane(abTestData, leadState, stepsOff);
    }

    if (
      digitalExperienceName === DigitalExperienceTypes.OpportunityMicrofunnel &&
      !queryParams?.data &&
      !queryParams?.type
    ) {
      stepsOff.push(ApplicationStepNameTypes.VerifyIdentity);
      stepsOff.push(...ODL_ADJUSTABLE_STEPS);
    }
    return {
      stepsOn,
      stepsOff,
    };
  }

  setStepAllowedForExpressLane(abTestData, leadState: LeadState, stepsOff) {
    if (!leadState.expressLaneData) {
      return;
    }
    // 1. auth is true and kcf is true, skip verify identity step
    // 2. auth is false and kcf is true, land on verify identity step like normal KCF experience (no step change)
    // 3. auth is true and kcf is false,  walk up flow
    // 4. auth is false and kcf is false, walk up flow
    if (leadState.expressLaneData.kcf) {
      if (leadState.expressLaneData.auth) {
        stepsOff.push(ApplicationStepNameTypes.VerifyIdentity);
      }
    } else {
      stepsOff.push(ApplicationStepNameTypes.ConfirmInfo, ApplicationStepNameTypes.VerifyIdentity);
      // Check breaking BECC population to add the correct steps
      if (abTestData[AbTestNames.BREAKING_BECC]?.population === ABTestPopulationsCombined.BREAKBECC) {
        stepsOff.push(ApplicationStepNameTypes.WalkupInformation);
      } else {
        stepsOff.push(ApplicationStepNameTypes.BreakingBECCStep1, ApplicationStepNameTypes.BreakingBECCStep2);
      }
    }
  }

  setStepsSettingForVerifyIdentity(
    stepsAllowData,
    abTestData: IAbTestData,
    metaData: SetStepAndUpdateStepsAllowedMetaData,
    digitalExperienceName: string
  ) {
    if (metaData?.actionSubType === 'verifySuccess') {
      // step off verify identity so user can't go back once they have verified
      stepsAllowData.stepsOff.push(ApplicationStepNameTypes.VerifyIdentity);

      if (digitalExperienceName === DigitalExperienceTypes.OpportunityMicrofunnel) {
        stepsAllowData.stepsOff.push(...ODL_ADJUSTABLE_STEPS);
        return ApplicationStepNameTypes.OpportunityMicrofunnel;
      } else if (digitalExperienceName === DigitalExperienceTypes.BestEggKcf) {
        return ApplicationStepNameTypes.ConfirmInfo;
      }
    }
    stepsAllowData.stepsOff.push(
      ApplicationStepNameTypes.ConfirmInfo,
      ApplicationStepNameTypes.VerifyIdentity,
      ApplicationStepNameTypes.OpportunityMicrofunnel
    );
    if (abTestData[AbTestNames.BREAKING_BECC]?.population === ABTestPopulationsCombined.BREAKBECC) {
      stepsAllowData.stepsOff.push(ApplicationStepNameTypes.WalkupInformation);
      return ApplicationStepNameTypes.BreakingBECCStep1;
    } else {
      return ApplicationStepNameTypes.WalkupInformation;
    }
  }

  setStepsSettingForConfirmInformation(stepsAllowData) {
    // Remove the steps that are not needed when user on Confirm Information step
    // going back from the confirm step is not allowed
    stepsAllowData.stepsOff.push(...KCF_ADJUSTABLE_STEPS);
  }

  getStepContentChanges(digitalExperienceName, abTestData: IAbTestData, lead: LeadState): Update<ApplicationStep>[] {
    // we should keep this function even there is no step content changes for now
    const stepsContentChanges = [];
    if (abTestData[AbTestNames.BREAKING_BECC]?.population === ABTestPopulationsCombined.BREAKBECC) {
      this.breakBECCStepChanges(digitalExperienceName, stepsContentChanges);
    }

    if (digitalExperienceName === DigitalExperienceTypes.OpportunityMicrofunnel) {
      this.opportunityMicroFunnelStepChanges(abTestData, lead, stepsContentChanges);
    }
    if (digitalExperienceName === DigitalExperienceTypes.BestEggKcf) {
      this.kcfStepChanges(lead, stepsContentChanges);
    }

    return stepsContentChanges;
  }

  kcfStepChanges(lead: LeadState, stepsContentChanges) {
    stepsContentChanges.push({
      id: ApplicationStepNameTypes.VerifyIdentity,
      changes: {
        title: `Let's get started, ${lead.data.primaryApplicant?.fullName?.firstName}`,
        actionMessage: `Not ${lead.data.primaryApplicant?.fullName?.firstName}?`,
      },
    });
    stepsContentChanges.push({
      id: ApplicationStepNameTypes.ConfirmInfo,
      changes: {
        title: `${lead.data.primaryApplicant?.fullName?.firstName}, is all of your information correct?`,
      },
    });
  }

  opportunityMicroFunnelStepChanges(abTestData, lead: LeadState, stepsContentChanges) {
    let changes: any;
    if (!lead.opportunityData?.collectSsn) {
      changes = {
        ...changes,
        formConfig: {
          ...applicationStepFormConfigMap[ApplicationStepNameTypes.OpportunityMicrofunnel],
          questionNames: [
            QuestionNameTypes.Agreement,
            QuestionNameTypes.PaperStatementPreference,
            QuestionNameTypes.PaperlessStatementPreference,
          ],
        },
      };
    }
    stepsContentChanges.push({
      id: ApplicationStepNameTypes.OpportunityMicrofunnel,
      changes,
    });

    stepsContentChanges.push({
      id: ApplicationStepNameTypes.VerifyIdentity,
      changes: {
        title: `Let's get started, ${lead.data.primaryApplicant?.fullName?.firstName}`,
        actionMessage: `Not ${lead.data.primaryApplicant?.fullName?.firstName}?`,
      },
    });
  }

  breakBECCStepChanges(digitalExperienceName, stepsContentChanges) {
    const notBreakingBECCExperiences = [DigitalExperienceTypes.BestEggProspect];
    if (notBreakingBECCExperiences.includes(digitalExperienceName)) {
      return;
    }
    let changes: any;
    const breakBECCExperiences = [
      DigitalExperienceTypes.BestEggKcf,
      DigitalExperienceTypes.BestEggWalkupEmail,
      DigitalExperienceTypes.BestEggWalkupNoEmail,
      DigitalExperienceTypes.OpportunityMicrofunnel,
    ];
    if (breakBECCExperiences.includes(digitalExperienceName)) {
      changes = {
        title: "Let's get started! We just need a little more information about you.",
        supertitle: null,
      };
    } else if ([DigitalExperienceTypes.AffiliateCreditSoup].includes(digitalExperienceName)) {
      changes = {
        title: 'Hello. To start, please fill out your basic information below.',
        supertitle: null,
      };
    } else {
      changes = {
        title: this.getAffiliateImage(digitalExperienceName),
        supertitle: 'Thank you for visiting from',
      };
    }
    stepsContentChanges.push({
      id: ApplicationStepNameTypes.BreakingBECCStep1,
      changes,
    });
  }
}
