// store
import { createReducer, on } from '@ngrx/store';
import * as fromApplicationActions from '../actions';
// models
import { DocumentUpload } from '../../models';

// key
export const DOCUMENT_UPLOAD_STATE_KEY = 'documentUpload';

// state
export interface DocumentUploadState {
  data: DocumentUpload;
  error: Record<string, any>;
  loaded: boolean;
  loading: boolean;
}

export const initialState: DocumentUploadState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

// reducer
const documentUploadReducer = createReducer(
  initialState,
  // load
  on(fromApplicationActions.uploadDocument, (state, action) => ({
    ...state,
    data: { fileName: action.file.name, progress: 0 },
    loading: true,
  })),
  on(fromApplicationActions.uploadDocumentProgress, (state, action) => ({
    ...state,
    data: { ...state.data, progress: action.progress },
    error: null,
    loading: true,
    loaded: false,
  })),
  on(fromApplicationActions.uploadDocumentSuccess, (state, action) => ({
    ...state,
    data: { ...state.data, progress: 100 },
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromApplicationActions.uploadDocumentFail, (state, action) => ({
    ...state,
    data: null,
    error: action.error,
    loaded: false,
    loading: false,
  })),
  on(fromApplicationActions.clearUploadDocument, () => initialState)
);

export const reducer = (state: DocumentUploadState, action: fromApplicationActions.DocumentAction) =>
  documentUploadReducer(state, action);

// state selectors
export const selectDocumentUploadStateData = (state: DocumentUploadState) => state.data;
export const selectDocumentUploadStateError = (state: DocumentUploadState) => state.error;
export const selectDocumentUploadStateLoaded = (state: DocumentUploadState) => state.loaded;
export const selectDocumentUploadStateLoading = (state: DocumentUploadState) => state.loading;
