// models
import * as fromQuestionsModels from '../../models';

export const socialSecurityNumberQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  name: fromQuestionsModels.QuestionNameTypes.SocialSecurityNumber,
  mask: {
    mask: fromQuestionsModels.MaskTypes.SocialSecurityNumber,
  },
  placeholder: 'XXX-XX-XXXX',
  label: 'Social Security Number',
  tooltipLabel: 'why?',
  tooltip: `Your social security number will be used to verify and protect
  your identity as well as obtain consumer reports and make credit decisions.`,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter a social security number',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: '([0-9]{9})',
        message: 'Please enter a 9 digit social security number',
      },
    ],
  },
};
