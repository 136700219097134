// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Address } from '../../../application/models';
import { AddressValidation } from '../../models';

export enum ValidationsActionTypes {
  ValidateAddress = '[Questions] Validate Address',
  ValidateAddressSuccess = '[Questions] Validate Address Success',
  ValidateAddressFail = '[Questions] Validate Address Fail',
  SetBackendErrors = '[Questions] Set Backend Errors',
  ResetBackendErrors = '[Questions] Reset Backend Errors',
  ClearValidations = '[Questions] Clear Validations',
}

// validate address
export const validateAddress = createAction(
  ValidationsActionTypes.ValidateAddress,
  props<{ address: Address; poBoxAllowedStatus: boolean }>()
);

export const validateAddressSuccess = createAction(
  ValidationsActionTypes.ValidateAddressSuccess,
  props<{ addressValidation: AddressValidation }>()
);

export const validateAddressFail = createAction(ValidationsActionTypes.ValidateAddressFail, props<{ error: any }>());

// lead backend errors
export const setBackendErrors = createAction(ValidationsActionTypes.SetBackendErrors, props<{ error: any }>());

export const resetBackendErrors = createAction(ValidationsActionTypes.ResetBackendErrors);

// clear
export const clearValidations = createAction(ValidationsActionTypes.ClearValidations);

export type ValidationsAction = ReturnType<
  | typeof validateAddress
  | typeof validateAddressSuccess
  | typeof validateAddressFail
  | typeof setBackendErrors
  | typeof resetBackendErrors
  | typeof clearValidations
>;
