import { ApplicationStep, StepComponentMap } from '../../../../models';
import { defaultStepComponentMap } from '../../../shared';
import {
  baseWalkupInformationApplicationStep,
  baseApplicationSteps,
  affiliateLandingStep,
} from '../../base-walkup/application-steps.config';

// #walkup-information
export const walkupInformationApplicationStep: ApplicationStep = {
  ...baseWalkupInformationApplicationStep,
  title: 'Hello. To start, please fill out your basic information below.',
  supertitle: null,
};

export const applicationSteps: ApplicationStep[] = [
  affiliateLandingStep,
  walkupInformationApplicationStep,
  ...baseApplicationSteps,
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
