// vendor
import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, first, withLatestFrom } from 'rxjs/operators';
// ngrx
import { Store, select, ActionsSubject } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import * as fromAppStore from '../../../../store';
import * as fromApplicationStore from '../../store';
import * as fromLeadStore from '../../../lead/store';
import * as fromOffersStore from '../../../offers/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import { RouterReducerState } from '@ngrx/router-store';

// configs
import { MAIN_URL_SEGMENT, UNAVAILABLE_URL_SEGMENT } from '../../../main/configs';
import { applicationErrorMessages } from '../../configs';
import { defaultErrorMessages } from '../../../shared/configs';
import { environment } from 'src/environments/environment';
// services
import { ApplicationStepNameTypes } from 'src/app/features/digital-experience/models';
import { WindowUtilService } from 'src/app/features/shared/services/window-util.service';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit {
  applicationLoadProcessSuccess$: Observable<any>;
  affiliateLandingStep: string = ApplicationStepNameTypes.AffiliateLanding;
  stepName$: Observable<string>;
  constructor(
    private actionsSubject: ActionsSubject,
    private store: Store<
      | fromAppStore.RouterStateUrl
      | fromApplicationStore.ApplicationState
      | fromOffersStore.OffersState
      | fromDigitalExperienceStore.DigitalExperienceState
    >,
    private windowUtilService: WindowUtilService
  ) {}

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(fromAppStore.selectRouterQueryParams)),
      this.store.pipe(select(fromAppStore.selectRouterState)),
    ])
      .pipe(first())
      .subscribe(([queryParams, routerState]: [Params, RouterReducerState<fromAppStore.RouterStateUrl>]) => {
        if (!Object.keys(queryParams).length && /application/.test(routerState.state.url)) {
          this.windowUtilService.nativeWindow.location.href = `https://${environment.bestEggDomain}/credit-card/`;
        } else {
          this.store.dispatch(fromApplicationStore.applicationLoadProcess({ queryParams }));
        }
      });
    this.applicationLoadProcessSuccess$ = this.actionsSubject.pipe(
      ofType(fromApplicationStore.applicationLoadProcessSuccess)
    );

    const errors$ = merge(
      this.store.pipe(select(fromLeadStore.selectLeadErrorData)), // Error in normal flow with an offercode
      this.store.pipe(select(fromApplicationStore.selectOptionsError)) // Error in lightbox flow
    );

    this.actionsSubject
      .pipe(ofType(fromApplicationStore.applicationLoadProcessFail), withLatestFrom(errors$), first())
      .subscribe(([action, errorRes]) => {
        let errorMessage: string;
        if (errorRes) {
          const error = errorRes.error;
          if (error) {
            errorMessage = error.message;
          }
        }
        this.store.dispatch(
          fromAppStore.go({
            path: [MAIN_URL_SEGMENT, 'unavailable'],
            extras: {
              queryParamsHandling: 'preserve',
              skipLocationChange: true,
              state: {
                message: errorMessage || applicationErrorMessages.applicationLoadProcessFail,
              },
            },
          })
        );
        // logging non standard error
        if (!errorMessage) {
          const message = action.action['error']?.message; // Add a little more detail to this case to help debug in prod.
          console.error('[APPLICATION_LOAD_PROCESS_FAIL] non standard error', message);
        }
      });

    this.store
      .pipe(select(fromOffersStore.selectCardFunnelAvailabilityTermsErrors), filter(Boolean), first())
      .subscribe((res) => {
        this.store.dispatch(
          fromAppStore.go({
            path: [MAIN_URL_SEGMENT, 'unavailable'],
            extras: {
              queryParamsHandling: 'preserve',
              skipLocationChange: true,
              state: {
                message: defaultErrorMessages.generalErrorShort,
              },
            },
          })
        );
      });
    this.stepName$ = this.store.pipe(
      select(fromDigitalExperienceStore.selectDigitalExperienceCurrentStep),
      filter((step) => step !== null)
    );
  }
}
