// models
import * as fromQuestionsModels from '../../models';
import { stateValiatorConfig } from '../../../shared/configs';

export const physicalStateQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.AutoComplete,
  name: fromQuestionsModels.QuestionNameTypes.PhysicalState,
  label: 'State',
  placeholder: 'State',
  optionType: fromQuestionsModels.QuestionOptionTypes.States,
  validators: {
    sync: [
      ...stateValiatorConfig,
      {
        validatorName: fromQuestionsModels.ValidatorTypes.PhysicalAddress,
        readonly: true,
      },
    ],
  },
};
