// vendor
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, withLatestFrom, first, filter } from 'rxjs/operators';
// ngrx
import { Store, select } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import * as fromAppStore from '../../../../store';
import * as fromLeadStore from '../../../lead/store';
import { TextBubbleTypes } from '../../../shared/configs/text-bubble-types';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';

import { leadHelpers } from 'src/app/features/lead/services/helpers/lead.helpers';
import { environment } from 'src/environments/environment';
import { defaultErrorMessages } from 'src/app/features/shared/configs';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss'],
})
export class UnavailableComponent implements OnInit {
  TextBubbleTypes = TextBubbleTypes;
  message$: Observable<string>;
  bestEggDomain = `https://${environment.bestEggDomain}`;
  isWalkupLeadRequestError$: Observable<boolean>;

  isServiceClosed$ = this.store.pipe(
    select(fromLeadStore.selectLeadErrorData),
    filter((errorData) => !!errorData),
    first(),
    map((errorData) => {
      return errorData.error.message === defaultErrorMessages.serviceClosed;
    })
  );

  constructor(private store: Store, private eeService: EnterpriseEventTrackingService) {}

  ngOnInit() {
    // Should show different message title and description for postWalkup error
    // (non affiliate postWalkup were blocked in the backend to prevent bot attack. However, if organic user
    // with walk up link are blocked, we should provide them a better message to guide them to the right place)
    this.isWalkupLeadRequestError$ = this.store.pipe(
      select(fromLeadStore.selectLeadErrorData),
      filter((errorData) => !!errorData),
      withLatestFrom(this.store.pipe(select(fromAppStore.selectRouterState))),
      first(),
      map(([errorData, routerState]) => {
        const { queryParams } = routerState.state;
        // 404 is the error code for blocked postWalkup lead request that backend returns
        return leadHelpers.isPostWalkupLeadRequest(queryParams) && errorData.status === 404;
      })
    );

    this.message$ = this.store.pipe(
      select(fromAppStore.selectRouterState),
      map((routerState: RouterReducerState<fromAppStore.RouterStateUrl>) => {
        const { extras } = routerState.state;
        const message = extras.state?.message;
        this.eeService.reportApplyIntent();
        return message;
      })
    );
  }
}
