import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable()
export class FakeAuthResponseInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Mocks out successful responses for the Best Egg account creation widget, allowing local
    // testing without need to manually generate a token and condition the registrant table/CAS
    if (request.url.includes('auth.sbx.bestegg.com/auth-identity-service')) {
      console.warn(`Faking auth identity service response: 200: ${request.method}: ${request.url}`, request.body);
      return of(new HttpResponse({ status: 200, body: {} }));
    }

    return next.handle(request);
  }
}
