import { BestEggOfferTypes, CreditKarmaOfferTypes, LightboxOfferTypes } from '../../../lead/models';
import { DigitalExperienceTypes } from '../../models';

/**
 * For affiliate PURLs, each expected value of the `offertype` URL params has a corresponding digital experience
 * associated with it. Some `offertype` values have a 1 to 1 relation with an affiliate experience, to the point where
 * they share the same name. Others are 1 to many (ie. the two Lightbox offertype's which map to one 'lightbox' experience),
 * One affiliate, credit-soup, is an experience with basically no branding, so its experience config is based on a general "no brand"
 * experience, which can be extended and altered if needed in the future.
 */

const affiliateExperiencesWithMatchingOfferType = [
  DigitalExperienceTypes.AffiliateBankrate,
  DigitalExperienceTypes.AffiliateCompareCards,
  DigitalExperienceTypes.AffiliateCreditSoup,
  DigitalExperienceTypes.AffiliateEvenFinancial,
  DigitalExperienceTypes.AffiliateLendingTree,
  DigitalExperienceTypes.AffiliateMonevo,
  DigitalExperienceTypes.AffiliateSupermoney,
  DigitalExperienceTypes.BestEggWalkupEmail,
  DigitalExperienceTypes.BestEggWalkupNoEmail,
];

export const validAffiliateExperiences: string[] = [
  ...affiliateExperiencesWithMatchingOfferType,
  DigitalExperienceTypes.AffiliateLightbox,
  DigitalExperienceTypes.AffiliateNoBranding,
  DigitalExperienceTypes.AffiliateCreditKarma,
];

export const validOfferTypes: string[] = [
  ...affiliateExperiencesWithMatchingOfferType,
  LightboxOfferTypes.LBEXB,
  LightboxOfferTypes.LBOSB,
  CreditKarmaOfferTypes.ITA,
];

type OfferTypes = DigitalExperienceTypes | LightboxOfferTypes | CreditKarmaOfferTypes;

export const offerTypeToExperienceMap: Partial<Record<OfferTypes, DigitalExperienceTypes>> = {
  [LightboxOfferTypes.LBEXB]: DigitalExperienceTypes.AffiliateLightbox,
  [LightboxOfferTypes.LBOSB]: DigitalExperienceTypes.AffiliateLightbox,
  [BestEggOfferTypes.WalkupEmail]: DigitalExperienceTypes.BestEggWalkupEmail,
  [BestEggOfferTypes.WalkupNoEmail]: DigitalExperienceTypes.BestEggWalkupNoEmail,
  [DigitalExperienceTypes.BestEggProspect]: DigitalExperienceTypes.BestEggProspect,
  [DigitalExperienceTypes.BestEggKcf]: DigitalExperienceTypes.BestEggKcf,
  [DigitalExperienceTypes.AffiliateBankrate]: DigitalExperienceTypes.AffiliateBankrate,
  [DigitalExperienceTypes.AffiliateEvenFinancial]: DigitalExperienceTypes.AffiliateEvenFinancial,
  [DigitalExperienceTypes.AffiliateCompareCards]: DigitalExperienceTypes.AffiliateCompareCards,
  [DigitalExperienceTypes.AffiliateCreditSoup]: DigitalExperienceTypes.AffiliateCreditSoup,
  [DigitalExperienceTypes.AffiliateLendingTree]: DigitalExperienceTypes.AffiliateLendingTree,
  [DigitalExperienceTypes.AffiliateMonevo]: DigitalExperienceTypes.AffiliateMonevo,
  [DigitalExperienceTypes.AffiliateSupermoney]: DigitalExperienceTypes.AffiliateSupermoney,
  [CreditKarmaOfferTypes.ITA]: DigitalExperienceTypes.AffiliateCreditKarma,
  [DigitalExperienceTypes.OpportunityMicrofunnel]: DigitalExperienceTypes.OpportunityMicrofunnel,
};
