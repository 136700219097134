// services
// models
import * as fromQuestionsModels from '../../models';
// misc
import { commonAddressQuestion } from './common-address-config';

export const addressQuestion: fromQuestionsModels.Question = {
  ...commonAddressQuestion,
  name: fromQuestionsModels.QuestionNameTypes.Address,
  validators: {
    sync: [...commonAddressQuestion.validators.sync],
  },
};
