// store
import { createReducer, on } from '@ngrx/store';
import * as fromApplicationActions from '../actions';
// models
import { Brand } from '../../../offers/models';

// key
export const CARD_BRAND_STATE_KEY = 'cardBrand';

// state
export interface CardBrandState {
  data: Brand;
  error: Record<string, any>;
  loading: boolean;
  loaded: boolean;
}

export const initialState: CardBrandState = {
  data: null,
  error: null,
  loading: false,
  loaded: false,
};

// reducer
const cardBrandReducer = createReducer(
  initialState,
  // select
  on(fromApplicationActions.selectCardBrand, (state, action) => ({
    ...state,
    data: action.cardBrand,
    loading: true,
  })),
  on(fromApplicationActions.selectCardBrandSuccess, (state, action) => ({
    ...state,
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromApplicationActions.selectCardBrandFail, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loaded: false,
  })),
  on(fromApplicationActions.clearCardBrand, () => initialState)
);

export const reducer = (state: CardBrandState, action: fromApplicationActions.CardBrandAction) =>
  cardBrandReducer(state, action);

// state selectors
export const selectCardBrandStateData = (state: CardBrandState) => state.data;
export const selectCardBrandStateError = (state: CardBrandState) => state.error;
export const selectCardBrandStateLoading = (state: CardBrandState) => state.loading;
export const selectCardBrandStateLoaded = (state: CardBrandState) => state.loaded;
