// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Application, ApplicationDisclosureStatusTypes, ApplicationSubmitResponse } from '../../models';
import { ApplicationStep } from '../../../digital-experience/models';
import { Map } from '../../../shared/models';

export enum ApplicationActionTypes {
  LoadApplication = '[Application] Load Application',
  LoadApplicationPolling = '[Application] Load Application Polling',
  LoadApplicationDecisionPolling = '[Application] Load Application Decision Polling',
  StopApplicationPolling = '[Application] Stop Application Polling',
  LoadApplicationSuccess = '[Application] Load Application Success',
  LoadApplicationFail = '[Application] Load Application Fail',
  UpdateApplication = '[Application] Update Application',
  ClearApplication = '[Application] Clear Application',
  SubmitApplication = '[Application] Submit Application',
  SubmitApplicationSuccess = '[Application] Submit Application Success',
  SubmitApplicationFail = '[Application] Submit Application Fail',
  ProcessApplicationStepChange = '[Application] Process Application Step Change',
  SendCreditScoreNoticeEvent = '[Adverse Action] Send Credit Score Notice Event',
  ClearApplicationSubmit = '[Application] Clear Application Submit',
}

// load
export const loadApplication = createAction(ApplicationActionTypes.LoadApplication, props<{ applicationId: string }>());

export const loadApplicationPolling = createAction(
  ApplicationActionTypes.LoadApplicationPolling,
  props<{ interval?: number; attemptLimit?: number }>()
);

export const loadApplicationDecisionPolling = createAction(
  ApplicationActionTypes.LoadApplicationDecisionPolling,
  props<{ applicationId: string; interval?: number; attemptLimit?: number }>()
);

export const stopApplicationPolling = createAction(ApplicationActionTypes.StopApplicationPolling);

export const loadApplicationSuccess = createAction(
  ApplicationActionTypes.LoadApplicationSuccess,
  props<{ application: Application }>()
);

export const loadApplicationFail = createAction(
  ApplicationActionTypes.LoadApplicationFail,
  props<{ error: Record<string, any> }>()
);

// update application
export const updateApplication = createAction(ApplicationActionTypes.UpdateApplication, props<{ values: Map<any> }>());

// submit application
export const submitApplication = createAction(ApplicationActionTypes.SubmitApplication);

export const submitApplicationSuccess = createAction(
  ApplicationActionTypes.SubmitApplicationSuccess,
  props<{ applicationSubmitResponse: ApplicationSubmitResponse }>()
);

export const submitApplicationFail = createAction(
  ApplicationActionTypes.SubmitApplicationFail,
  props<{ error: any }>()
);

// process application step change
export const processApplicationStepChange = createAction(
  ApplicationActionTypes.ProcessApplicationStepChange,
  props<{ applicationStep: ApplicationStep; direction: string; questionValues: any }>()
);

// send credit score notice event
export const sendCreditScoreNoticeEvent = createAction(
  ApplicationActionTypes.SendCreditScoreNoticeEvent,
  props<{ disclosureNameType: ApplicationDisclosureStatusTypes }>()
);

// clear application
export const clearApplication = createAction(ApplicationActionTypes.ClearApplication);

export const clearApplicationSubmit = createAction(ApplicationActionTypes.ClearApplicationSubmit);

export type ApplicationAction = ReturnType<
  | typeof loadApplication
  | typeof loadApplicationPolling
  | typeof loadApplicationDecisionPolling
  | typeof stopApplicationPolling
  | typeof loadApplicationSuccess
  | typeof loadApplicationFail
  | typeof updateApplication
  | typeof submitApplication
  | typeof submitApplicationSuccess
  | typeof submitApplicationFail
  | typeof processApplicationStepChange
  | typeof sendCreditScoreNoticeEvent
  | typeof clearApplication
  | typeof clearApplicationSubmit
>;
