// vendor
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
// models
import { AffiliateParams, LeadResponseV2, LightboxSpecificParams } from '../../lead/models';
import { offerTypeToExperienceMap } from '../configs/experiences';
import { nonAlphaNumericPattern } from '../../shared/helpers/helper-functions';
import * as _ from 'lodash';

export const offerTypeToExperienceMapKeysLowerCase = Object.entries(offerTypeToExperienceMap).reduce(
  (acc, [key, value]) => {
    const keyToLowerCase = key.toLowerCase();
    acc[keyToLowerCase] = value;
    return acc;
  },
  {}
);
@Injectable({
  providedIn: 'root',
})
export class DigitalExperienceService {
  constructor() {}

  isValidLightboxRequest(params: Params): boolean {
    // The following all use the same RegExp, but is done per parameter that different validations can easily added
    return [
      LightboxSpecificParams.affiliateId,
      LightboxSpecificParams.channel,
      LightboxSpecificParams.offerName,
      LightboxSpecificParams.offerVariant,
      LightboxSpecificParams.subTrackingId,
      LightboxSpecificParams.modelId,
    ].every((param) => !nonAlphaNumericPattern.test(params[param]));
  }

  isWalkupRequest(params: Params): boolean {
    /**
     * Given an inbound URL with an emailAddress parameter, but no offerCode, cpid, or offertype parameter:
     *    The “walkup” digital experience (see attached walkup-no-email) will be rendered.
     *      This will function similarly to other affiliates and CK Lightbox, but there is no email field
     *    The emailAddress parameter is passed in through the primaryApplicant object when calling postWalkupLead,
     *      where lead management service will use a configured “default” CPID to get campaign/product data.
     * Given an inbound URL with no emailAddress or offertype parameter
     *    The unbranded affiliate experience (see attached walkup-email) will be rendered.
     *      This is the same experience as an unbranded affiliate (ex. Credit Soup)
     * Given an inbound URL like either of the above above, but with a cpid parameter
     *    The cpid parameters are passed in when calling postWalkupLead, where lead management
     *      will use the provided CPID to get campaign/product data.
     *
     *  In addition, if cid is present in params, meaning the former check for kcf experience has failed,
     *  (casCustomerData not founded), in such case, set as walkup experience
     */
    return Boolean((!params.offerCode && !params[AffiliateParams.offerType] && params.cpid) || params.cid);
  }

  isKcfRequest(leadRes: LeadResponseV2): boolean {
    return Boolean(leadRes.casCustomerData && leadRes.casCustomerData.firstName && leadRes.casCustomerData.lastName);
  }

  isOpportunityRequest(leadRes: LeadResponseV2): boolean {
    return Boolean(leadRes.opportunityData);
  }

  isProspectRequest(params: Params, leadRes: LeadResponseV2): boolean {
    return Boolean(params.offerCode && params.emailAddress && leadRes.prospectOffer);
  }

  isAffiliateRequest(params: Params): boolean {
    return Boolean(params[AffiliateParams.offerType] && !params.offerCode);
  }
}
