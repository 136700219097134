// models
import { CoreData } from '../../models';

// key
export const CORE_STATE_KEY = 'core';

// state
export interface CoreState {
  data: CoreData;
}

export const initialState: CoreState = {
  data: {
    contactEmailAddress: null,
    uploadEmailAddress: 'upload@bestegg.com',
    contactPhoneNumber: '833-707-1226',
    contactPhoneNumberWithCountryCode: '1-833-707-1226',
    contactFaxNumber: '800-673-8024',
    servicingEmailAddress: 'CardServicing@bestegg.com',
    cardDeliveryTimeFrameDays: '7-14',
    trademarkYear: new Date().getFullYear().toString(),
  },
};

export const reducer = () => initialState;

// state selectors
export const selectCoreStateData = (state: CoreState) => state.data;
