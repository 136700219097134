import { DateTypes } from '../../../shared/models';
import { CasCustomerData } from 'fakesl/src/models/generated/leadmanagementservice.model';
import { DateTimeService } from 'src/app/features/shared/services';
import * as _ from 'lodash-es';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';

const dateTimeService = new DateTimeService();

/**
 * map casCustomerData from verify api to questionValueData
 * @param customer
 * @returns questionValueData
 */
const mapCustomerToQuestionValue = (customer: CasCustomerData) => ({
  firstName: customer.firstName,
  lastName: customer.lastName,
  mobilePhone: customer.phoneNumber,
  dateOfBirth: _.isNil(customer.dateOfBirth)
    ? undefined
    : dateTimeService.formatDate(customer.dateOfBirth, 'YYYY-MM-DD', DateTypes.MMDDYYYY),
  address: customer.address?.addressLineOne,
  city: customer.address?.city,
  state: customer.address?.state,
  zipCode: customer.address?.zip?.firstFive,
  housingType: casHousingTypeMap[customer.homeStatus],
  housingMonthlyPayment: _.isNil(customer.housingPayment) ? undefined : customer.housingPayment.toString(),
  employmentStatus: casEmploymentStatusMap[customer.employmentStatus],
  employerName: customer.employerName,
  employerPhone: customer.employerPhone,
  emailAddress: customer.email,
  annualIncome: _.isNil(customer.income) ? undefined : customer.income.toString(),
  banking: customer.bankAccounts,
});

const casHousingTypeMap = {
  RENT: 'RENT',
  OWNWITHMORTGAGE: 'OWN_WITH_MORTGAGE',
  FULLYOWN: 'OWN_NO_MORTGAGE',
  OTHER: 'OTHER',
};

const casEmploymentStatusMap = {
  SALARY: 'SALARIED_EMPLOYEE',
  HOURLY: 'HOURLY_WAGE_EMPLOYEE',
  SELFEMPLOYED: 'SELF_EMPLOYED',
  CONTRACTOR: 'CONTRACTOR',
  STUDENT: 'STUDENT',
  RETIRED: 'RETIRED',
  UNEMPLOYED: 'UNEMPLOYED',
};

const isOpportunityLeadRequest = (queryParams: Params) => {
  const { odl } = queryParams;
  if (isPostExpressOpportunityLeadRequest(queryParams)) {
    return false;
  }
  // If it's not an express opportunity lead request, it's definitely an opportunity lead request given that odl is present
  return !!odl;
};

const isPostExpressOpportunityLeadRequest = (queryParams: Params) => {
  const { cswAuthToken, xl, odl, cid, cpid } = queryParams;
  // xl param is only available in local environment to act like cswAuthToken replacement
  if (environment.env === 'local') {
    return !!(xl && odl && cid && cpid);
  }
  return !!(cswAuthToken && odl && cid && cpid);
};

const isPostExpressLeadRequest = (queryParams: Params) => {
  const { cid, cpid, cswAuthToken, xl, odl } = queryParams;
  // xl param is only available in local environment to act like cswAuthToken replacement
  if (environment.env === 'local') {
    return !!(cid && cpid && xl && !odl);
  }
  return !!(cid && cpid && cswAuthToken && !odl);
};

const isPostLeadRequest = (queryParams: Params) => {
  const { cid, emailAddress, offerCode } = queryParams;
  if (isPostExpressLeadRequest(queryParams) || isPostExpressOpportunityLeadRequest(queryParams)) {
    return false;
  }
  return !!((offerCode && emailAddress) || cid);
};

const isPostWalkupLeadRequest = (queryParams: Params) => {
  const { cpid, emailAddress, offertype } = queryParams;
  if (
    isPostLeadRequest(queryParams) ||
    isPostExpressLeadRequest(queryParams) ||
    isPostExpressOpportunityLeadRequest(queryParams) ||
    isPostEncryptedLeadRequest(queryParams)
  ) {
    return false;
  }

  return !!(emailAddress || offertype || cpid);
};

const isPostEncryptedLeadRequest = (queryParams: Params) => {
  const { type, data } = queryParams;
  return !!(type && data);
};

// Add more to this array if there is new type of post lead request,
// make sure that its condition is not overlapping with other post lead conditions
// (for example postWalkupLead is depending on either email or offertype or cpid, so
// if other post lead request has dependent on any of those, the conditions will have an overlap)
export const postLeadRequestUrlSegmentMap: [(queryParams: Params) => boolean, () => string][] = [
  [isPostWalkupLeadRequest, () => '/postWalkupLead'],
  [isPostLeadRequest, () => '/postLead'],
  [isPostExpressLeadRequest, () => '/postXLLead'],
  [isPostExpressOpportunityLeadRequest, () => '/postXLOpportunityLead'],
  [isOpportunityLeadRequest, () => '/postOpportunityLead'],
  [isPostEncryptedLeadRequest, () => '/postLeadKeyValue'],
];

export const leadHelpers = {
  mapCustomerToQuestionValue,
  isPostLeadRequest,
  isPostWalkupLeadRequest,
  isPostExpressLeadRequest,
  isOpportunityLeadRequest,
  isPostExpressOpportunityLeadRequest,
  isPostEncryptedLeadRequest,
};
