import { Params } from '@angular/router';
import { AbTestsAction } from '../../ab-tests/store';
import { LeadResponseV2 } from '../../lead/models';
import * as fromAbTestsStore from '../../ab-tests/store';
import {
  AbTestNames,
  AbTestStatuses,
  IAbTestData,
  IAbTestDataSingle,
  ABTestPopulationsCombined,
} from '../../ab-tests/models';

export const nonAlphaNumericPattern = new RegExp(/[^a-zA-Z0-9]/, 'g');
export const nonAlphaNumericSpacePattern = new RegExp(/[^a-zA-Z0-9\s]/, 'g');

const formatApostrophe = (value: string): string => value.replace(/[‘’]/g, "'");

const formatNonAlphanumeric = (value: string): string => value.replace(nonAlphaNumericPattern, '');
const formatNonAlphanumericSpace = (value: string): string => value.replace(nonAlphaNumericSpacePattern, '');

export enum FormatStringFnNames {
  formatApostrophe = 'formatApostrophe',
  formatNonAlphanumeric = 'formatNonAlphanumeric',
  formatNonAlphanumericSpace = 'formatNonAlphanumericSpace',
}

export const formatStringFns = {
  [FormatStringFnNames.formatApostrophe]: formatApostrophe,
  [FormatStringFnNames.formatNonAlphanumeric]: formatNonAlphanumeric,
  [FormatStringFnNames.formatNonAlphanumericSpace]: formatNonAlphanumericSpace,
};

/** In case we need to do more kinds of formatting */
const formatString = (value: string, includedFnNames: FormatStringFnNames[]): string => {
  const filteredFormatStringFns = Object.keys(formatStringFns)
    .filter((key: FormatStringFnNames) => includedFnNames.includes(key))
    .reduce((obj, key) => {
      obj[key] = formatStringFns[key];
      return obj;
    }, {} as Record<FormatStringFnNames, any>);
  return Object.values(filteredFormatStringFns).reduce((accValue, currFn) => currFn(accValue), value);
};
/** For automated tests: determine id naming for values that need to be tracked. */
const idGeneralPrefix = 'id';
/**
 * This should be reset whenever the terms are re-rendered, i.e. revisiting the step,
 * or reopening the terms modal.
 */
let createdIdsMap = {};

export const createValueIdGenericFactory = (sectionLabel) => (valueId) => {
  const id = `${idGeneralPrefix}_${valueId}_${sectionLabel}`;
  // set to map, set value as count. increment.
  createdIdsMap[id] = createdIdsMap[id] ? createdIdsMap[id] + 1 : 1;
  // append the count if there's more than one occurance of the id.
  const displayCount = createdIdsMap[id] > 1 ? `_${createdIdsMap[id]}` : '';
  return `${id}${displayCount}`;
};

const resetCreatedIdsMap = () => {
  createdIdsMap = {};
};

export const helperFns = {
  formatString,
  resetCreatedIdsMap,
};

export const getAbTestsAction = (leadRes: Partial<LeadResponseV2>, queryParams: Params): AbTestsAction => {
  let leadId: string;
  if ('leadId' in leadRes) {
    leadId = leadRes.leadId;
  } else if ('lead' in leadRes) {
    leadId = leadRes.lead.id;
  }
  const metadata = { leadId };
  const abTestNames = leadRes.abTests?.map((test) => test.testName) || [];
  // Checks the `ot` query param to see if it is in the format
  // `testName:population,testName:population`. If it is, overrides with AB test store data
  // with whatever is parsed from the URL. Otherwise, processes AB tests returned in the lead
  // response.
  // UPDATE: checks the param, if the test name or test population does not
  // match with the models, abort overriding and process AB tests with loadAbTestsSuccess action.
  if (/^\w+:\w+(,\w+:\w+)?$/.test(queryParams.ot)) {
    const overrideTestData: IAbTestData = queryParams.ot.split(',').reduce((acc, testPopulationString) => {
      const [test, population] = testPopulationString.split(':');
      const testData: IAbTestDataSingle = {
        status: AbTestStatuses.ASSIGNED,
        population,
        providedInLeadResponse: abTestNames.includes(test) ? true : false,
      };

      acc[test] = testData;
      return acc;
    }, {});

    const overrideTestNameWarning = [];
    const overrideTestPopulationWarning = [];
    for (const [testName, testData] of Object.entries(overrideTestData)) {
      if (!(Object.values(AbTestNames) as string[]).includes(testName)) {
        overrideTestNameWarning.push(`Incorrect override ab-test name: ${testName}`);
      }
      if (!(Object.values(ABTestPopulationsCombined) as string[]).includes(testData.population)) {
        overrideTestPopulationWarning.push(`Incorrect override ab-test population: ${testData.population}`);
      }
    }

    if (overrideTestNameWarning.length || overrideTestPopulationWarning.length) {
      // log the warning messages, return the loadAbTestsSuccess action below without overriding the test
      [...overrideTestNameWarning, ...overrideTestPopulationWarning].forEach((warningMessage) =>
        console.warn(warningMessage)
      );
      console.error('Failed to override ab-test, please check ot query params');
    } else {
      return fromAbTestsStore.overrideAbTests({
        overrideTestData,
        metadata,
      });
    }
  }

  return fromAbTestsStore.loadAbTestsSuccess({
    abTestResponseData: leadRes.abTests,
    metadata,
  });
};

export const getIntersectionObserver = (threshold: number, callBack: any) => {
  const options = {
    root: null,
    threshold,
  };

  return new IntersectionObserver(callBack, options);
};
