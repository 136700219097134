// services
// models
import * as fromQuestionsModels from '../../models';
// misc
import { HelpTextTypes } from '../../models';
import { regex } from '../../services/helpers/validators-regex';

const minLength = 1;
const maxLength = 40;

/*
NOTE: For now allow larger maxlength (input char cutoff) than validator value
so max length validator error is triggered for potential ability to track users
that may encounter length issues for current vendor max
*/

export const commonAddressQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Text,
  helpText: HelpTextTypes.ResidencyRequirement,
  label: 'Street address',
  placeholder: 'Street address',
  maxlength: 100,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter street address',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: regex.streetAddress,
        message: 'Invalid street address',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MinLength,
        value: minLength,
        message: `Minimum of ${minLength} characters`,
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.MaxLength,
        value: maxLength,
        message: `Maximum of ${maxLength} characters`,
      },
    ],
  },
};
