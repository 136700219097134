// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromQuestionsStore from './store';
// components
import * as fromComponents from './components';
import { SharedModule } from '../shared/shared.module';
// directives
import * as fromDirectives from './directives';

@NgModule({
  declarations: [...fromComponents.components, ...fromDirectives.directives],
  providers: [
    provideNgxMask(),
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    A11yModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    SharedModule,
    StoreModule.forFeature(fromQuestionsStore.QUESTIONS_FEATURE_KEY, fromQuestionsStore.reducers),
    EffectsModule.forFeature(fromQuestionsStore.effects),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [...fromComponents.components],
})
export class QuestionsModule {}
