// vendor
import { NavigationExtras } from '@angular/router';
// store
import { createAction, props } from '@ngrx/store';

export enum RouterActionTypes {
  Go = '[Router] Go',
  Back = '[Router] Back',
  Forward = '[Router] Forward',
}

export type RouterGoPayload = {
  path: any[];
  query?: Record<string, any>;
  extras?: NavigationExtras;
};

// go
export const go = createAction(RouterActionTypes.Go, props<RouterGoPayload>());

// back
export const back = createAction(RouterActionTypes.Back);

// forward
export const forward = createAction(RouterActionTypes.Forward);

export type RouterAction = ReturnType<typeof go | typeof back | typeof forward>;
