// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { ApplicationMeta } from '../../models';

export enum ApplicationMetaTypes {
  SetApplicationMeta = '[Application] Set Application Meta',
  ClearApplicationMeta = '[Application] Clear Application Meta',
}

// set
export const setApplicationMeta = createAction(
  ApplicationMetaTypes.SetApplicationMeta,
  props<{ applicationMeta: ApplicationMeta }>()
);

// clear
export const clearApplicationMeta = createAction(ApplicationMetaTypes.ClearApplicationMeta);

export type ApplicationMetaAction = ReturnType<typeof setApplicationMeta | typeof clearApplicationMeta>;
