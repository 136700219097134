// vendor
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
// store
import * as fromCardFunnelAvailabilityReducer from './card-funnel-availability.reducer';
import * as fromCardFunnelTermsAvailabilityReducer from './card-funnel-terms-availability.reducer';
import * as fromCardProductOfferReducer from './card-product-offer.reducer';
import * as fromCardProductOffersReducer from './card-product-offers.reducer';
import * as fromCardProspectOfferReducer from './card-prospect-offer.reducer';
import * as fromCardProductReducer from './card-product.reducer';

// key
export const OFFERS_FEATURE_KEY = 'offers';

export interface OffersState {
  // TODO_UPDATE: computed property
  [id: string]:
    | fromCardFunnelAvailabilityReducer.CardFunnelAvailabilityState
    | fromCardFunnelTermsAvailabilityReducer.CardFunnelTermsAvailabilityState
    | fromCardProductOfferReducer.CardProductOfferState
    | fromCardProductOffersReducer.CardProductOffersState
    | fromCardProspectOfferReducer.CardProspectOfferState
    | fromCardProductReducer.CardProductState;
}

export const reducers: ActionReducerMap<OffersState> = {
  [fromCardFunnelAvailabilityReducer.CARD_FUNNEL_AVAILABILITY_STATE_KEY]: fromCardFunnelAvailabilityReducer.reducer,
  [fromCardFunnelTermsAvailabilityReducer.CARD_FUNNEL_TERMS_AVAILABILITY_STATE_KEY]:
    fromCardFunnelTermsAvailabilityReducer.reducer,
  [fromCardProductOfferReducer.CARD_PRODUCT_OFFER_STATE_KEY]: fromCardProductOfferReducer.reducer,
  [fromCardProductOffersReducer.CARD_PRODUCT_OFFERS_STATE_KEY]: fromCardProductOffersReducer.reducer,
  [fromCardProspectOfferReducer.CARD_PROSPECT_OFFER_STATE_KEY]: fromCardProspectOfferReducer.reducer,
  [fromCardProductReducer.CARD_PRODUCT_STATE_KEY]: fromCardProductReducer.reducer,
};

export const selectOffersFeature = createFeatureSelector(OFFERS_FEATURE_KEY);
