// vendor
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// models
import {
  AffiliateParams,
  DigitalTrackingData,
  Lead,
  LeadRequestV2,
  LeadResponseV2,
  LightboxSpecificParams,
} from '../models';
// configs
import * as fromLeadConfigs from '../configs';
import { ApplicationMeta } from '../../application/models';
import { LeadSyncService } from './lead-sync.service';
import { Params } from '@angular/router';
import { CasCustomerData } from 'fakesl/src/models/generated/leadmanagementservice.model';
import { DateTimeService } from '../../shared/services';
import { postLeadRequestUrlSegmentMap } from './helpers/lead.helpers';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LeadService {
  constructor(
    private readonly http: HttpClient,
    private readonly leadSyncService: LeadSyncService,
    private dateTimeService: DateTimeService,
    private cookieService: CookieService
  ) {}

  /**
   * get lead
   * @param leadId
   * @returns observable of lead
   */
  getLead(leadId: string): Observable<Lead> {
    const url = `${fromLeadConfigs.leadServiceBaseUrl.v1}/${leadId}`;
    return this.http.get<Lead>(url).pipe(catchError((err) => throwError(err)));
  }

  postLead(queryParams: Params): Observable<LeadResponseV2> {
    let url = `${fromLeadConfigs.leadServiceBaseUrl.v1}`;
    const cookie = this.cookieService.get(environment.CSW_AUTH_TOKEN_COOKIE_NAME);
    if (cookie) {
      queryParams = { ...queryParams, cswAuthToken: cookie };
    }

    const segment = postLeadRequestUrlSegmentMap.find((item) => item[0](queryParams));
    if (segment) {
      url = url + segment[1]();
    } else {
      throw new Error('invalid param key, fail to determine lead endpoint to call');
    }
    const requestBody = this.buildLeadRequestBody(queryParams);

    const option = {
      params: queryParams.data && queryParams.type ? { dataKey: queryParams.data, type: queryParams.type } : null,
    };

    return this.http.post<LeadResponseV2>(url, requestBody, option).pipe(catchError((err) => throwError(err)));
  }

  buildLeadRequestBody(applicationMeta: ApplicationMeta): LeadRequestV2 {
    /** Key is what is set to the request payload. Value is what we expect the URL param to be. */
    const utmParamsMap = {
      campaignParticipantCode: 'cpid',
      ita: 'ITA',
      customerId: 'cid',
      opportunityDecisionLocator: 'odl',
      content: 'utm_content',
      campaign: 'utm_campaign',
      medium: 'utm_medium',
      source: 'utm_source',
      term: 'utm_term',
    };

    const expectedAffiliateParams = [...Object.entries(AffiliateParams), ...Object.entries(LightboxSpecificParams)];

    const affiliateParamsMap = expectedAffiliateParams.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    /**
     * Only append optional params if they exist (such as UTM and affiliate-related params).
     */
    const optionalParamsMap = {
      ...utmParamsMap,
      ...affiliateParamsMap,
    };

    const { offerCode, cardcpid: incentiveCpid } = applicationMeta;
    const { primaryApplicant } = this.leadSyncService.syncDataToLead(applicationMeta);

    const requestBody: LeadRequestV2 = {
      offerCode,
      primaryApplicant,
      incentiveCpid,
    };

    const digitalTrackingData: DigitalTrackingData = {};

    Object.entries(optionalParamsMap).forEach(([leadReqPropName, appMetaPropName]) => {
      if (![null, undefined].includes(applicationMeta[appMetaPropName])) {
        if (['cpid', 'cid', 'odl'].includes(appMetaPropName)) {
          requestBody[leadReqPropName] = applicationMeta[appMetaPropName];
        } else {
          digitalTrackingData[leadReqPropName] = applicationMeta[appMetaPropName];
          if (appMetaPropName === 'ITA') {
            digitalTrackingData['offerType'] = 'ITA';
          }
        }
      }
    });

    requestBody.digitalTrackingData = digitalTrackingData;

    return requestBody;
  }

  /**
   * get lead by params
   * @param emailAddress
   * @param offerCode
   * @returns observable of lead
   */
  getLeadByParams(emailAddress: string, offerCode: string): Observable<Lead> {
    const url = `${fromLeadConfigs.leadServiceBaseUrl.v1}`;
    const params: HttpParams = new HttpParams().set('emailAddress', emailAddress).set('offerCode', offerCode);
    return this.http.get<Lead>(url, { params }).pipe(catchError((err) => throwError(err)));
  }

  /**
   * update lead
   * @param leadId
   * @param leadData
   * @returns observable of lead
   */
  updateLead(leadId: string, lead: Partial<Lead>): Observable<Lead> {
    const url = `${fromLeadConfigs.leadServiceBaseUrl.v1}/${leadId}`;
    return this.http.patch<Lead>(url, lead).pipe(catchError((err) => throwError(err)));
  }

  validateKcfVerifyIdentity(formValue: Record<string, any>, casData: CasCustomerData): Observable<CasCustomerData> {
    const requestBody = this.buildVerifyIdentityRequestBody(formValue, casData);
    const url = `${fromLeadConfigs.leadServiceBaseUrl.v1}/verify-kcf`;
    return this.http.post<CasCustomerData>(url, requestBody).pipe(catchError((err) => throwError(err)));
  }

  buildVerifyIdentityRequestBody(formValue: Record<string, any>, casData: CasCustomerData) {
    const { zipCode, dateOfBirth } = formValue;
    const formattedDob = this.dateTimeService.convertMMDDYYYYtoISODateStr(dateOfBirth);
    const { firstName, lastName, customerId } = casData;
    return {
      firstName,
      lastName,
      dateOfBirth: formattedDob,
      zip: zipCode,
      customerId,
    };
  }
}
