// vendor
import { Component, Input, Output, EventEmitter } from '@angular/core';
// models
import { ProspectQuestionData } from '../../../questions/models';
import { helpText } from '../../../shared/configs/help-text.config';

@Component({
  selector: 'app-prospect-card',
  templateUrl: './prospect-card.component.html',
  styleUrls: ['./prospect-card.component.scss'],
})
export class ProspectCardComponent {
  @Input() prospectData: ProspectQuestionData;
  @Output() edit: EventEmitter<null>;

  constructor() {
    this.edit = new EventEmitter();
  }

  public get residencyRequirementHelpText() {
    return helpText.residencyRequirement;
  }

  /**
   * format name
   * @returns formatted name
   */
  formatProspectName(): string {
    return `${this.prospectData.firstName} ${this.prospectData.lastName}`;
  }

  /**
   * on edit
   */
  onEdit() {
    this.edit.emit();
  }
}
