<div class="c-dialog c-application-terms-dialog">
  <div class="c-dialog__header-actions">
    <app-dialog-close></app-dialog-close>
  </div>
  <h2 class="c-application-terms-dialog__title mat-dialog-title" cdkFocusInitial tabindex="-1"></h2>
  <div class="mat-typography mat-mdc-dialog-content">
    <app-default-application-terms></app-default-application-terms>
  </div>
  <div class="mat-mdc-dialog-actions mat-mdc-dialog-actions--reverse">
    <button mat-stroked-button mat-dialog-close>Close</button>
  </div>
</div>
