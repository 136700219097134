import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'percent',
})
export class PercentSimplePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string | number, ...args: unknown[]): string {
    return this.decimalPipe.transform(value, '1.0');
  }
}
