// store
import { createReducer, on } from '@ngrx/store';
import * as fromApplicationActions from '../actions';
// models
import { ApplicationMeta } from '../../models';

// key
export const APPLICATION_META_STATE_KEY = 'applicationMeta';

// state
export interface ApplicationMetaState {
  data: ApplicationMeta;
}

export const initialState: ApplicationMetaState = {
  data: null,
};

// reducer
const applicationMetaReducer = createReducer(
  initialState,
  // select
  on(fromApplicationActions.setApplicationMeta, (state, action) => ({
    ...state,
    data: { ...state.data, ...action.applicationMeta },
  })),
  on(fromApplicationActions.clearApplicationMeta, () => initialState)
);

export const reducer = (state: ApplicationMetaState, action: fromApplicationActions.ApplicationMetaAction) =>
  applicationMetaReducer(state, action);

// state selectors
export const selectApplicationMetaStateData = (state: ApplicationMetaState) => state.data;
