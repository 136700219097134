import { HttpErrorResponse } from '@angular/common/http';
// store
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromApplicationActions from '../actions';
// models
import { Item } from '../../../shared/models';

// key
export const OPTIONS_STATE_KEY = 'options';

interface NamedOption {
  name: string;
  options: Item[];
}

// state
export interface OptionsState extends EntityState<NamedOption> {
  error: HttpErrorResponse;
  loading: boolean;
  loaded: boolean;
}

export const initialState: OptionsState = {
  ids: [],
  entities: null,
  error: null,
  loading: false,
  loaded: false,
};

// adapter
export const selectOptionId = (option: any): string => option.name;

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

// reducer
const optionsReducer = createReducer(
  initialState,
  // load
  on(fromApplicationActions.loadOptions, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(fromApplicationActions.loadOptionsSuccess, (state, action) => {
    const { options } = action;
    return {
      ...state,
      ids: Object.keys(options),
      entities: options,
      error: null,
      loading: false,
      loaded: true,
    } as any;
  }),
  on(fromApplicationActions.loadOptionsFail, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loaded: false,
  })),
  on(fromApplicationActions.clearOptions, () => initialState)
);

export const reducer = (state: OptionsState, action: fromApplicationActions.OptionsAction) =>
  optionsReducer(state, action);

// state selectors
const { selectEntities, selectAll } = adapter.getSelectors();
export const selectOptionsEntities = selectEntities;
export const selectOptions = selectAll;
export const selectOptionsStateError = (state: OptionsState) => state.error;
export const selectOptionsStateLoading = (state: OptionsState) => state.loading;
export const selectOptionsStateLoaded = (state: OptionsState) => state.loaded;
