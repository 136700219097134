import * as fromButtonModels from '../models/buttons.model';
import * as fromButtonTypesModels from '../models/button-types.model';
import { Map } from '../../shared/models';

export const nextButton: fromButtonModels.IButton = {
  design: fromButtonTypesModels.ButtonDesignTypes.BtnNext,
  behavior: fromButtonTypesModels.ButtonBehaviorTypes.Submit,
  stepAction: fromButtonTypesModels.StepActionTypes.Next,
};

export const submitButton: fromButtonModels.IButton = {
  design: fromButtonTypesModels.ButtonDesignTypes.BtnSubmit,
  behavior: fromButtonTypesModels.ButtonBehaviorTypes.Submit,
  stepAction: fromButtonTypesModels.StepActionTypes.Submit,
};

export const backButton: fromButtonModels.IButton = {
  design: fromButtonTypesModels.ButtonDesignTypes.BtnBack,
  behavior: fromButtonTypesModels.ButtonBehaviorTypes.Button,
  stepAction: fromButtonTypesModels.StepActionTypes.Back,
};

export const linkButton: fromButtonModels.IButton = {
  design: fromButtonTypesModels.ButtonDesignTypes.BtnLink,
  behavior: fromButtonTypesModels.ButtonBehaviorTypes.Button,
};

export const disabledButton: fromButtonModels.IButton = {
  design: fromButtonTypesModels.ButtonDesignTypes.BtnDisabled,
  behavior: fromButtonTypesModels.ButtonBehaviorTypes.Button,
  stepAction: fromButtonTypesModels.StepActionTypes.Next,
};

export const buttonMap: Map<fromButtonModels.IButton> = {
  [fromButtonTypesModels.ButtonNameTypes.BtnNext]: nextButton,
  [fromButtonTypesModels.ButtonNameTypes.BtnSubmit]: submitButton,
  [fromButtonTypesModels.ButtonNameTypes.BtnBack]: backButton,
  [fromButtonTypesModels.ButtonNameTypes.BtnLink]: linkButton,
  [fromButtonTypesModels.ButtonNameTypes.BtnDisabled]: disabledButton,
};
