// ngrx
import { createSelector } from '@ngrx/store';
import * as fromTokenizedCidReducer from '../reducers/tokenized-cid.reducer';
import * as fromReducers from '../reducers';

export const selectTokenizedCidState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.tokenizedCid
);

export const selectApplicationTokenizedCid = createSelector(
  selectTokenizedCidState,
  fromTokenizedCidReducer.selectTokenizedCidStateData
);

export const selectApplicationTokenizedCidLoaded = createSelector(
  selectTokenizedCidState,
  fromTokenizedCidReducer.selectTokenizedCidStateLoaded
);
