import { DigitalExperienceTypes } from '../../../models';

interface AffiliateLogoConfig {
  id: string;
  altText: string;
  height: number;
  width: number;
  src: string;
  srcset?: string;
  landingPageOffset?: string;
  landingPageHeight?: number;
}

export const AffiliateLogoConfigMap: Record<string, AffiliateLogoConfig> = {
  [DigitalExperienceTypes.AffiliateCreditKarma]: {
    id: 'credit-karma-logo',
    altText: 'Credit Karma',
    height: 50,
    width: 192,
    src: '/assets/branding/brands/creditkarma/logo.svg',
    srcset: '',
    landingPageHeight: 30,
    landingPageOffset: '6px',
  },
  [DigitalExperienceTypes.AffiliateBankrate]: {
    id: 'bankrate-logo',
    altText: 'Bankrate',
    height: 53,
    width: 230,
    src: '/assets/branding/brands/bankrate/bankrate-logo.png',
    srcset: '',
    landingPageHeight: 24,
    landingPageOffset: '5px',
  },
  [DigitalExperienceTypes.AffiliateLightbox]: {
    id: 'credit-karma-logo',
    altText: 'Credit Karma',
    height: 50,
    width: 192,
    src: '/assets/branding/brands/creditkarma/logo.svg',
    srcset: '',
    landingPageHeight: 30,
    landingPageOffset: '6px',
  },
  [DigitalExperienceTypes.AffiliateEvenFinancial]: {
    id: 'even-financial-logo',
    altText: 'Even Financial',
    height: 73,
    width: 236,
    src: '/assets/branding/brands/even-financial/even-financial-logo.svg',
    srcset: '',
    landingPageHeight: 36,
    landingPageOffset: '5px',
  },
  [DigitalExperienceTypes.AffiliateLendingTree]: {
    id: 'lending-tree-logo',
    altText: 'Lending Tree',
    height: 45,
    width: 169,
    src: '/assets/branding/brands/lending-tree/lending-tree-logo.png',
    srcset: '',
    landingPageHeight: 36,
    landingPageOffset: '2px',
  },
  [DigitalExperienceTypes.AffiliateCompareCards]: {
    id: 'compare-cards-logo',
    altText: 'Compare Cards',
    height: 42,
    width: 250,
    src: '/assets/branding/brands/lending-tree/sub-brands/compare-cards/compare-cards-logo.png',
    srcset: '',
    landingPageHeight: 32,
    landingPageOffset: '-3px',
  },
  [DigitalExperienceTypes.AffiliateMonevo]: {
    id: 'monevo-logo',
    altText: 'Monevo',
    height: 71,
    width: 230,
    src: '/assets/branding/brands/monevo/monevo-logo.png',
    srcset: '',
    landingPageHeight: 36,
    landingPageOffset: '5px',
  },
  [DigitalExperienceTypes.AffiliateSupermoney]: {
    id: 'supermoney-logo',
    altText: 'Supermoney',
    height: 57,
    width: 250,
    src: '/assets/branding/brands/supermoney/supermoney-logo.png',
    srcset: '',
    landingPageHeight: 48,
    landingPageOffset: '-10px',
  },
};
