// services
// models
import * as fromQuestionsModels from '../../models';
// misc
import { commonAddressQuestion } from './common-address-config';

export const physicalAddressQuestion: fromQuestionsModels.Question = {
  ...commonAddressQuestion,
  name: fromQuestionsModels.QuestionNameTypes.PhysicalAddress,
  validators: {
    sync: [
      ...commonAddressQuestion.validators.sync,
      {
        validatorName: fromQuestionsModels.ValidatorTypes.IsPoBox,
        message: 'Your physical address cannot be a PO Box',
      },
    ],
  },
};
