<div class="default-product-terms" style="font-family: Arial, sans-serif">
  <div class="default-product-terms__section" *ngFor="let section of productTerms">
    <table
      class="default-product-terms__section-table"
      style="border-collapse: collapse; margin-bottom: 15px; table-layout: fixed; width: 100%"
    >
      <caption
        class="default-product-terms__section-table--caption"
        style="
          background-color: #e8e9e9;
          border: 1px solid;
          border-width: 1px 1px 0 1px;
          font-weight: bold;
          padding: 5px;
          text-align: left;
          text-transform: uppercase;
        "
      >
        {{
          section.title
        }}
      </caption>
      <tbody>
        <tr class="default-product-terms__section-table--tr" *ngFor="let item of section.items">
          <ng-container *ngIf="isArray(item.value); else default">
            <td colspan="2" style="border: 1px solid">
              <table style="border-collapse: collapse; margin: -1px; table-layout: fixed; width: 100%">
                <tbody>
                  <tr *ngFor="let subItem of item.value">
                    <th
                      style="border-right: 1px solid; text-align: left; vertical-align: top; width: 35%; padding: 5px"
                      scope="row"
                    >
                      <div *ngIf="subItem.label">
                        {{ subItem.label }}
                      </div>
                      <!-- account for characters outside ASCII range-->
                      <ul style="margin: 0 0 0 2px; padding: 0; list-style: none">
                        <li
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: left;
                            align-items: center;
                            gap: 3px;
                            font-weight: 600;
                          "
                          *ngIf="subItem.sublabel"
                        >
                          <div style="font-size: 20px; width: 10px; height: 10px; line-height: 0">&#46;</div>
                          <div>
                            {{ subItem.sublabel }}
                          </div>
                        </li>
                      </ul>
                    </th>
                    <td style="padding: 5px" [innerHTML]="subItem.value | domSanitize : 'html'"></td>
                  </tr>
                </tbody>
              </table>
            </td>
          </ng-container>
          <ng-template #default>
            <th
              class="default-product-terms__section-table--td"
              [innerHTML]="item.label"
              style="border: 1px solid; padding: 5px; vertical-align: top; text-align: left; width: 35%"
              scope="row"
            ></th>
            <td
              class="default-product-terms__section-table--td"
              style="border: 1px solid; padding: 5px; vertical-align: top; word-break: break-word"
            >
              <!-- | domSanitize: 'html' -->
              <div
                class="default-product-terms__section-table--value"
                [innerHTML]="item.value | domSanitize : 'html'"
              ></div>
              <div
                class="default-product-terms__section-table--sublabel"
                [innerHTML]="item.sublabel | domSanitize : 'html'"
              ></div>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
  </div>
</div>
