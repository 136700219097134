// rxjs
import { createReducer, on } from '@ngrx/store';
import * as fromCardProductActions from '../actions';
// models
import { CardProduct } from '../../models';

// key
export const CARD_PRODUCT_STATE_KEY = 'cardProduct';

// state
export interface CardProductState {
  data: CardProduct;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CardProductState = {
  data: null,
  loaded: false,
  loading: false,
};

// reducer
const cardProductReducer = createReducer(
  initialState,
  // load
  on(fromCardProductActions.loadCardProduct, (state: CardProductState) => ({
    ...state,
    loading: true,
  })),
  on(
    fromCardProductActions.loadCardProductSuccess,
    (state: CardProductState, action: ReturnType<typeof fromCardProductActions.loadCardProductSuccess>) => ({
      ...state,
      data: action.cardProduct,
      loading: false,
      loaded: true,
    })
  ),
  on(
    fromCardProductActions.loadCardProductFail,
    (state: CardProductState, action: ReturnType<typeof fromCardProductActions.loadCardProductFail>) => ({
      ...state,
      loaded: false,
      loading: false,
    })
  ),
  on(fromCardProductActions.clearCardProduct, (state: CardProductState) => initialState)
);

export const reducer = (state: CardProductState, action: fromCardProductActions.CardProductAction) =>
  cardProductReducer(state, action);

// state selectors
export const selectCardProductData = (state: CardProductState) => state.data;
export const selectCardProductLoading = (state: CardProductState) => state.loading;
export const selectCardProductLoaded = (state: CardProductState) => state.loaded;
