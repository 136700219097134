import { APP_STATUS_TYPES, TEALIUM_LEAD_EVENTS } from '../services/enterprise-event/consts';

interface BaseCardEnterpriseEvent {
  subject?: string;
  parentSubject: string;
  metadata?: Record<string, any>;
}

interface FunnelSessionStartEvent extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.SESSION_START;
  data: { funnelsessionid: string; crossBuyOfferType?: string };
}

interface LeadCreateResumeEvent extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.LEAD_CREATE | CardEnterpriseEventName.LEAD_RESUME;
  data: {
    carddigitalexperiencename: string;
    cardleadid: string;
    cardleadsessionid: string;
    cardoffercode: string;
    cardproductofferid: string;
    cpc: string;
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'LeadEvent';
    tealiumsubeventname: TEALIUM_LEAD_EVENTS.CREATED | TEALIUM_LEAD_EVENTS.RESUMED;
  };
}

interface ApplyIntentEvent extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.APPLY_INTENT;
  data: {
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'ApplyIntentEvent';
  };
}

interface FormFillStart extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.FORM_FILL_START;
  data: {
    cardapplicationformname: string;
    cardapplicationformsessionid: string;
    cardapplicationformtype: string;
    cardapplypostsubmitsessionid?: string;
    cardleadsessionid?: string;
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'FormFillStart';
  };
}

interface FormFieldInteraction extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.FORM_FIELD_INTERACTION;
  data: {
    cardapplicationformfieldname: string;
    cardapplicationformsessionid: string;
  };
  metadata: {
    tealiumeventname: 'FormFieldInteraction';
  };
}

interface FormFieldError extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.FORM_FIELD_ERROR;
  data: {
    cardapplicationformfielderrormessage: string;
    cardapplicationformfieldname: string;
    cardapplicationformsessionid: string;
  };
  metadata: {
    tealiumeventname: 'FormFieldError';
  };
}

interface FormSubmit extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.FORM_SUBMIT;
  data: {
    cardapplicationformfieldvalues: Record<string, any>;
    cardapplicationformsessionid: string;
  };
  metadata: {
    tealiumeventname: 'FormSubmit';
  };
}

interface StepView extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.STEP_VIEW;
  data: {
    cardapplicationstepname: string;
    cardleadsessionid?: string;
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'StepView';
  };
}

interface TermsViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.TERMS_VIEWED;
  data: {
    cardleadsessionid?: string;
    cardofferapr: string;
  };
  metadata: {
    tealiumeventname: 'OfferEvent';
    tealiumsubeventname: 'PreofferViewedTerms';
  };
}

interface LeadSubmit extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.LEAD_SUBMIT;
  data: {
    cardleadsessionid?: string;
  };
  metadata: {
    tealiumeventname: 'LeadEvent';
    tealiumsubeventname: TEALIUM_LEAD_EVENTS.SUBMITTED;
  };
}

interface PostSubmitSessionStart extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.POST_SUBMIT_SESSION_START;
  data: {
    cardapplicationid: string;
    cardapplypostsubmitsessionid: string;
    cardleadid: string;
  };
}

interface AppSubmit extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.APP_SUBMIT;
  data: {
    cardapplicationformfieldvalues?: Record<string, any>;
    cardapplicationstatus: 'submitted';
    cardapplypostsubmitsessionid: string;
  };
  metadata: {
    tealiumeventname: 'AppStatusChanged';
    tealiumsubeventname: 'submitted';
  };
}

interface AppDecision extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.APP_DECISION;
  data: {
    cardapplicationstatus: EnterpriseEventAppStatus;
    cardapplypostsubmitsessionid: string;
  };
  metadata: {
    tealiumeventname: 'AppStatusChanged';
    tealiumsubeventname: APP_STATUS_TYPES;
  };
}

interface OfferViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.OFFER_VIEWED;
  data: {
    cardapplypostsubmitsessionid: string;
    cardofferapr: string;
  };
  metadata: {
    tealiumeventname: 'OfferEvent';
    tealiumsubeventname: 'OfferViewed';
  };
}

interface OfferAccepted extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.OFFER_ACCEPTED;
  data: {
    cardapplypostsubmitsessionid: string;
    cardofferapr: string;
    cardoffercreditlimit: string;
  };
  metadata: {
    tealiumeventname: 'OfferEvent';
    tealiumsubeventname: 'OfferAccepted';
  };
}

interface AdverseActionViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.ADVERSE_ACTION_VIEWED;
  data: {
    cardapplypostsubmitsessionid: string;
  };
}

interface PendingDecisionViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.PENDING_DECISION_VIEWED;
  data: {
    cardapplypostsubmitsessionid: string;
    cardapplypendreason: EnterpriseEventAppPendedStatus;
  };
}

interface VerificationDocumentUploaded extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.VERIFICATION_DOCUMENT_UPLOADED;
  data: {
    cardapplypostsubmitsessionid: string;
    cardapplyverificationdocumenttype: string;
  };
}

interface VerificationDocumentsViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.VERIFICATION_DOCUMENTS_VIEWED;
  data: {
    cardapplypostsubmitsessionid: string;
    cardapplyverificationdocuments: EnterpriseEventVerificationDocument[];
  };
}

interface ChatInitiated extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.CHAT_INITIATED;
  data: {
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'ChatInitiated';
  };
}

interface PostSubmitStepView extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.POST_SUBMIT_STEP_VIEW;
  data: {
    cardapplicationstepname: string;
    cardapplicationstatus: EnterpriseEventAppStatus;
    url: string;
    cardapplypostsubmitsessionid: string;
  };
}

interface KcfAuthPass extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.KCF_AUTH_PASS;
  data: {
    funnelsessionid: string;
    [EnterpriseEventKcfPrefillStatusName.CONTACT_INFO]: EnterpriseEventKcfPrefillStatus;
    [EnterpriseEventKcfPrefillStatusName.EMPLOYMENT]: EnterpriseEventKcfPrefillStatus;
    [EnterpriseEventKcfPrefillStatusName.HOUSING]: EnterpriseEventKcfPrefillStatus;
    [EnterpriseEventKcfPrefillStatusName.INCOME]: EnterpriseEventKcfPrefillStatus;
  };
  metadata: {
    tealiumeventname: 'FormAuthentication';
    tealiumsubeventname: 'Passed';
  };
}

interface KcfAuthFail extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.KCF_AUTH_FAIL;
  data: {
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'FormAuthentication';
    tealiumsubeventname: 'Failed';
  };
}

interface KcfAuthOptOut extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.KCF_AUTH_OPT_OUT;
  data: {
    funnelsessionid: string;
  };
  metadata: {
    tealiumeventname: 'LinkClick';
    tealiumLinkType: 'kcfAuthOptOut';
    tealiumLinkText: string;
  };
}

interface BestEggAccountCreationStart extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_START;
  data: BEFHAccountEventsData;
}

interface BestEggAccountAlreadyExists extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_ALREADY_EXISTS;
  data: BEFHAccountEventsData;
}

interface BestEggAccountSmsVerificationOptIn extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_SMS_VERIFICATION_OPT_IN;
  data: BEFHAccountEventsData;
}

interface BestEggAccountEmailVerificationOptIn extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_EMAIL_VERIFICATION_OPT_IN;
  data: BEFHAccountEventsData;
}

interface BestEggAccountVerify extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_VERIFY;
  data: BEFHAccountEventsData;
}

interface BestEggAccountResendVerificationCode extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_RESEND_VERIFICATION_CODE;
  data: BEFHAccountEventsData;
}

interface BestEggAccountEnrollSkip extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_ENROLL_SKIP;
  data: BEFHAccountEventsData;
}

interface BestEggAccountCreationExit extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_EXIT;
  data: BEFHAccountEventsData;
}

interface BestEggAccountCreationError extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_ERROR;
  data: BEFHAccountEventsData;
}

interface BestEggAccountVerificationOptionsPresented extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_VERIFICATION_OPTIONS_PRESENTED;
  data: BEFHAccountEventsData;
}

interface BestEggAccountCreated extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATED;
  data: BEFHAccountEventsData;
}

interface BefhDeclinedPathBannerShown extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_SHOWN;
  data: BEFHAccountEventsData;
}

interface BefhDeclinedPathBannerClicked extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_CLICKED;
  data: BEFHAccountEventsData;
}

interface BefhDeclinedPathBannerExit extends BaseCardEnterpriseEvent {
  eventName: BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_EXIT;
  data: BEFHAccountEventsData;
}

export interface BEFHAccountEventsData extends BEFHAccountEventsAdditionalData {
  cardapplypostsubmitsessionid: string;
  cardapplicationstatus: EnterpriseEventAppStatus;
  cid: string;
}

export interface TooltipViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.TOOLTIP_VIEWED;
  data: {
    cardApplicationTooltipName: string;
    cardapplicationstepname: string;
    cardapplicationstatus: string;
    funnelsessionid: string;
  };
}
export interface ESignConsentViewed extends BaseCardEnterpriseEvent {
  eventName: CardEnterpriseEventName.E_SIGN_CONSENT_VIEWED;
  data: {
    funnelsessionid: string;
  };
}

export interface BEFHAccountEventsAdditionalData {
  cardApplicationAccountCreationErrorMessage?: string;
  cardApplicationSmsAccountVerificationOptionAvailable?: boolean;
  cardApplicationEmailAccountVerificationOptionAvailable?: boolean;
  cardapplicationstepname?: string;
}

export interface EnterpriseEventVerificationDocument {
  documentType: string;
  documentStatus: EnterpriseEventDocumentStatus;
}

export enum EnterpriseEventDocumentStatus {
  REQUIRED = 'required',
  COMPLETED = 'completed',
}

export type EnterpriseEventKcfPrefillStatus = 'none' | 'partial' | 'full';

export enum EnterpriseEventKcfPrefillStatusName {
  CONTACT_INFO = 'contactInfoPrefillStatus',
  HOUSING = 'housingPrefillStatus',
  EMPLOYMENT = 'employmentPrefillStatus',
  INCOME = 'incomePrefillStatus',
}

// describe pended status in enterprise events
export enum EnterpriseEventAppPendedStatus {
  PENDED_UNAVAILABLE = 'pended_unavailable',
  PENDED_FRAUD = 'pended_fraud',
  PENDED_KYC = 'pended_kyc',
  PENDED_UNKNOWN = 'pended_unknown',
}

export const EnterpriseEventAppStatusEnum = { ...APP_STATUS_TYPES, ...EnterpriseEventAppPendedStatus };

export type EnterpriseEventAppStatus = APP_STATUS_TYPES | EnterpriseEventAppPendedStatus;

export type PostSubmitStepViewStepNames =
  | 'cardSelection'
  | 'cardSelected'
  | 'cardSelectedBEFHRegister'
  | 'befhWidgetDisplayed'
  | 'dashboard'
  | 'befhRegister'
  | 'applicationComplete';

export type CardEnterpriseEvent =
  | AdverseActionViewed
  | AppDecision
  | ApplyIntentEvent
  | AppSubmit
  | ChatInitiated
  | FormFieldError
  | FormFieldInteraction
  | FormFillStart
  | FormSubmit
  | FunnelSessionStartEvent
  | KcfAuthFail
  | KcfAuthPass
  | KcfAuthOptOut
  | LeadCreateResumeEvent
  | LeadSubmit
  | OfferAccepted
  | OfferViewed
  | PendingDecisionViewed
  | PostSubmitSessionStart
  | PostSubmitStepView
  | StepView
  | TermsViewed
  | VerificationDocumentUploaded
  | VerificationDocumentsViewed
  | BestEggAccountCreationStart
  | BestEggAccountAlreadyExists
  | BestEggAccountCreationError
  | BestEggAccountCreationExit
  | BestEggAccountSmsVerificationOptIn
  | BestEggAccountEmailVerificationOptIn
  | BestEggAccountVerify
  | BestEggAccountVerificationOptionsPresented
  | BestEggAccountResendVerificationCode
  | BestEggAccountEnrollSkip
  | BestEggAccountCreated
  | BefhDeclinedPathBannerShown
  | BefhDeclinedPathBannerClicked
  | BefhDeclinedPathBannerExit
  | TooltipViewed
  | ESignConsentViewed;

export enum BEFHAccountCreationEventName {
  BEST_EGG_ACCOUNT_ALREADY_EXISTS = 'bestEggAccountAlreadyExists',
  BEST_EGG_ACCOUNT_CREATION_START = 'bestEggAccountCreationStart',
  BEST_EGG_ACCOUNT_SMS_VERIFICATION_OPT_IN = 'bestEggAccountSmsVerificationOptIn',
  BEST_EGG_ACCOUNT_EMAIL_VERIFICATION_OPT_IN = 'bestEggAccountEmailVerificationOptIn',
  BEST_EGG_ACCOUNT_VERIFY = 'bestEggAccountVerify',
  BEST_EGG_ACCOUNT_VERIFICATION_OPTIONS_PRESENTED = 'bestEggAccountVerificationOptionsPresented',
  BEST_EGG_ACCOUNT_RESEND_VERIFICATION_CODE = 'bestEggAccountResendVerificationCode',
  BEST_EGG_ACCOUNT_ENROLL_SKIP = 'bestEggAccountEnrollSkip',
  BEST_EGG_ACCOUNT_CREATION_ERROR = 'bestEggAccountCreationError',
  BEST_EGG_ACCOUNT_CREATION_EXIT = 'bestEggAccountCreationExit',
  BEST_EGG_ACCOUNT_CREATED = 'bestEggAccountCreated',
  BEFH_DECLINED_PATH_BANNER_SHOWN = 'befhDeclinedPathBannerShown',
  BEFH_DECLINED_PATH_BANNER_CLICKED = 'befhDeclinedPathBannerClicked',
  BEFH_DECLINED_PATH_BANNER_EXIT = 'befhDeclinedPathBannerExit',
}

export enum CardEnterpriseEventName {
  ADVERSE_ACTION_VIEWED = 'adverseActionViewed',
  APP_DECISION = 'appDecision',
  APP_SUBMIT = 'appSubmit',
  APPLY_INTENT = 'applyIntent',
  CHAT_INITIATED = 'chatInitiated',
  FORM_FIELD_ERROR = 'formFieldError',
  FORM_FIELD_INTERACTION = 'formFieldInteraction',
  FORM_FILL_START = 'formFillStart',
  FORM_SUBMIT = 'formSubmit',
  KCF_AUTH_PASS = 'kcfAuthPass',
  KCF_AUTH_FAIL = 'kcfAuthFail',
  KCF_AUTH_OPT_OUT = 'kcfAuthOptOut',
  LEAD_CREATE = 'leadCreate',
  LEAD_RESUME = 'leadResume',
  LEAD_SUBMIT = 'leadSubmit',
  OFFER_ACCEPTED = 'offerAccepted',
  OFFER_VIEWED = 'offerViewed',
  PENDING_DECISION_VIEWED = 'pendingDecisionViewed',
  POST_SUBMIT_SESSION_START = 'postSubmitSessionStart',
  POST_SUBMIT_STEP_VIEW = 'postSubmitStepView',
  SESSION_START = 'funnelSessionStart',
  STEP_VIEW = 'stepView',
  TERMS_VIEWED = 'termsViewed',
  VERIFICATION_DOCUMENT_UPLOADED = 'verificationDocumentUploaded',
  VERIFICATION_DOCUMENTS_VIEWED = 'verificationDocumentsViewed',
  TOOLTIP_VIEWED = 'tooltipViewed',
  E_SIGN_CONSENT_VIEWED = 'eSignConsentViewed',
}
