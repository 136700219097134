// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromCardProductOffersReducer from '../reducers/card-product-offers.reducer';

// card product offers state
export const selectCardProductOffersState = createSelector(
  fromReducers.selectOffersFeature,
  (state: fromReducers.OffersState) => state.cardProductOffers
);

// entities
export const selectCardProductOffersEntities = createSelector(
  selectCardProductOffersState,
  // TDOD_FIX: update with select entities
  fromCardProductOffersReducer.selectCardProductOffersStateEntities
);

// loading
export const selectCardProductOffersLoading = createSelector(
  selectCardProductOffersState,
  fromCardProductOffersReducer.selectCardProductOffersStateLoading
);

// loaded
export const selectCardProductOffersLoaded = createSelector(
  selectCardProductOffersState,
  fromCardProductOffersReducer.selectCardProductOffersStateLoaded
);
