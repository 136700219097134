// vendor
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
// store
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromOffersActions from '../actions';
// services
import { OffersService } from '../../services';
// models
import { CardProductOffer } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CardProductOffersEffects {
  constructor(private offersService: OffersService, private action$: Actions) {}

  // load card product offer
  loadCardProductOffer$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProductOffer),
      switchMap((action: ReturnType<typeof fromOffersActions.loadCardProductOffer>) =>
        this.offersService.getCardProductOffer(action.cardProductOfferId).pipe(
          map((cardProductOffer: CardProductOffer) =>
            fromOffersActions.loadCardProductOfferSuccess({
              cardProductOffer,
            })
          ),
          catchError((error: Record<string, any>) =>
            of(
              fromOffersActions.loadCardProductOfferFail({
                error,
              })
            )
          )
        )
      )
    )
  );

  // load card product default offer
  loadCardProductDefaultOffer$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProductDefaultOffer),
      switchMap((action: ReturnType<typeof fromOffersActions.loadCardProductDefaultOffer>) =>
        this.offersService.getCardProductDefaultOffer().pipe(
          map((cardProductOffer: CardProductOffer) =>
            fromOffersActions.loadCardProductOfferSuccess({
              cardProductOffer,
            })
          ),
          catchError((error: Record<string, any>) =>
            of(
              fromOffersActions.loadCardProductOfferFail({
                error,
              })
            )
          )
        )
      )
    )
  );

  // load card product offers
  loadCardProductOffers$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromOffersActions.loadCardProductOffers),
      switchMap((action: ReturnType<typeof fromOffersActions.loadCardProductOffers>) =>
        this.offersService.getCardProductOffers(action.active).pipe(
          map((cardProductOffers: CardProductOffer[]) =>
            fromOffersActions.loadCardProductOffersSuccess({
              cardProductOffers,
            })
          ),
          catchError((error: Record<string, any>) =>
            of(
              fromOffersActions.loadCardProductOffersFail({
                error,
              })
            )
          )
        )
      )
    )
  );
}
