// vendor
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
// ngrx
import { createEffect, ofType, Actions } from '@ngrx/effects';
import * as fromRouterActions from '../actions';

@Injectable()
export class RouterEffects {
  constructor(private router: Router, private actions$: Actions, public location: Location) {}

  // navigate
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouterActions.RouterActionTypes.Go),
        tap(({ path, query: queryParams, extras }: ReturnType<typeof fromRouterActions.go>) =>
          this.router.navigate(path, { queryParams, ...extras })
        )
      ),
    { dispatch: false }
  );

  // navigate back
  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromRouterActions.RouterActionTypes.Back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  // navigate forward
  navigateForward$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.forward),
      tap(() => this.location.forward())
    )
  );
}
