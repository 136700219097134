import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AuthOtpConfirmComponentInput,
  AuthOtpConfirmComponentOutput,
  AuthSignUpComponentInput,
  AuthSignUpComponentOutput,
  ConfirmationCodeResendResponseOutput,
} from '@marlettefunding/frontend-common/auth/models/enterprise-auth.model';
import { select, Store } from '@ngrx/store';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { PrimaryApplicant } from 'src/app/features/application/models';
import * as _ from 'lodash-es';
import { TextBubbleTypes } from 'src/app/features/shared/configs/text-bubble-types';
import * as fromApplicationStore from '../../../application/store';
import * as fromLeadStore from '../../../lead/store';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
import { BEFHAccountCreationEventName } from 'src/app/features/analytics/models/enterprise-event.model';
import { WindowUtilService } from '../../services/window-util.service';

const EMAIL_AND_SMS_VERIFICATION_METHOD_TEXT =
  'To provide you with the best protection, choose a 2-step Verification method to verify your identity.';
const EMAIL_ONLY_VERIFICATION_METHOD_TEXT =
  'To provide you with the best protection, we use 2-step Verification method to verify your identity.';

@Component({
  selector: 'app-auth-account-creation',
  templateUrl: './auth-account-creation.component.html',
  styleUrls: ['./auth-account-creation.component.scss'],
})
export class AuthAccountCreationComponent implements OnInit {
  @Output() hideAuthCreate = new EventEmitter();
  @Output() registerCompleted = new EventEmitter();
  @Input() isDisplayingMaybeLater = false;
  @Input() showFooter = false;
  // Approve path and pend kyc dashboard should have this element
  @ContentChild('dynamicVerificationMethods', { static: true }) dynamicVerificationMethods: ElementRef;

  authSignUpComponentInput: AuthSignUpComponentInput;
  authSignUpComponentOutput: AuthSignUpComponentOutput;
  authOtpConfirmRequestPayload: AuthOtpConfirmComponentInput;
  authOtpConfirmComponentOutput: AuthOtpConfirmComponentOutput;
  confirmationCodeResendResponseOutput: ConfirmationCodeResendResponseOutput;

  TextBubbleTypes = TextBubbleTypes;
  errorMessage: string;
  codeResentMessage: string;
  constructor(
    private store: Store<any>,
    private eeService: EnterpriseEventTrackingService,
    private windowUtilService: WindowUtilService
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromLeadStore.selectLeadPrimaryApplicant),
        filter<PrimaryApplicant>(Boolean),
        withLatestFrom(this.store.pipe(select(fromApplicationStore.selectApplicationTokenizedCid))),
        first()
      )
      .subscribe(([applicant, tokenizeCid]: [PrimaryApplicant, string]) => {
        const { primaryPhone, emailAddress } = applicant;
        const contactMethodList = [];
        const hasPrimaryPhone = !_.isNil(primaryPhone);
        if (hasPrimaryPhone) {
          contactMethodList.push({
            value: `${primaryPhone.areaCode}${primaryPhone.prefix}${primaryPhone.lineNumber}`,
            type: 'phone',
          });
        }

        if (this.dynamicVerificationMethods) {
          if (hasPrimaryPhone) {
            this.dynamicVerificationMethods.nativeElement.innerText = EMAIL_AND_SMS_VERIFICATION_METHOD_TEXT;
          } else {
            this.dynamicVerificationMethods.nativeElement.innerText = EMAIL_ONLY_VERIFICATION_METHOD_TEXT;
          }
        }
        contactMethodList.push({
          value: emailAddress,
          type: 'email',
        });
        this.eeService.reportBEFHAccountCreationEvents(
          BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_VERIFICATION_OPTIONS_PRESENTED,
          {
            cardApplicationEmailAccountVerificationOptionAvailable: true,
            cardApplicationSmsAccountVerificationOptionAvailable: hasPrimaryPhone ? true : false,
          }
        );
        this.authSignUpComponentInput = {
          cidToken: tokenizeCid,
          contactMethodList,
        };
      });
  }

  getAuthSignUpResponse($event) {
    this.errorMessage = '';
    this.codeResentMessage = '';
    this.authSignUpComponentOutput = $event;
    const { isAuthSignUp, username, contactMethod, password } = this.authSignUpComponentOutput;
    if (isAuthSignUp) {
      this.eeService.reportBEFHAccountCreationEvents(
        contactMethod.type === 'email'
          ? BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_EMAIL_VERIFICATION_OPT_IN
          : BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_SMS_VERIFICATION_OPT_IN
      );
      // If user signup is completed,
      // pass mfc-auth-otp-confirm output event into the <mfc-auth-otp-confirm />
      this.authOtpConfirmRequestPayload = {
        username,
        contactMethod,
        password,
        confirmationSource: 'card-auth',
      };
      this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_VERIFY);
      setTimeout(() => {
        this.windowUtilService.scrollToElementById('c-auth-account-create__verify-code', 150);
      });
    } else {
      // error
      this.errorMessage = this.authSignUpComponentOutput.message;
      this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_ERROR, {
        cardApplicationAccountCreationErrorMessage: this.errorMessage,
      });
    }
  }

  clickMaybeLater() {
    this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_ENROLL_SKIP);
    this.hideAuthCreate.emit(true);
  }

  getAuthOtpConfirmResponse($event) {
    this.errorMessage = '';
    this.codeResentMessage = '';
    this.authOtpConfirmComponentOutput = $event;
    if (this.authOtpConfirmComponentOutput.isAuthOtpConfirmed) {
      this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATED);
      // Route to correct page if user 100% completed sign up and OTP verification
      this.registerCompleted.emit(true);
    } else {
      // User entered incorrect OTP, or some other errors from backend
      this.errorMessage = this.authOtpConfirmComponentOutput.message;
      this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_ERROR, {
        cardApplicationAccountCreationErrorMessage: this.errorMessage,
      });
    }
  }

  getConfirmationCodeResendResponse($event) {
    this.errorMessage = '';
    this.codeResentMessage = '';
    this.confirmationCodeResendResponseOutput = $event;
    if (this.confirmationCodeResendResponseOutput.isConfirmationCodeResent) {
      this.eeService.reportBEFHAccountCreationEvents(
        BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_RESEND_VERIFICATION_CODE
      );
      // A new one-time confirmation code has been sent. notify the user
      this.codeResentMessage = `A new one-time confirmation code has been sent to ${this.confirmationCodeResendResponseOutput.contactMethod.value}`;
    } else {
      // A new one-time confirmation code was not sent due to errors
      this.errorMessage = this.confirmationCodeResendResponseOutput.message;
      this.eeService.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEST_EGG_ACCOUNT_CREATION_ERROR, {
        cardApplicationAccountCreationErrorMessage: this.errorMessage,
      });
    }
  }
}
