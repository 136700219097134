// models
import * as fromQuestionsModels from '../../models';

export const zipCodeQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Tel,
  name: fromQuestionsModels.QuestionNameTypes.ZipCode,
  mask: {
    mask: fromQuestionsModels.MaskTypes.ZipCode,
    /*
    turn off validation to avoid ngx-mask bug around optional
    numbers (00000-9999) allowing special character (-) OR dynamic
    masks (00000||00000-0000) default to non special char option, so
    mask can be set to 00000-0000 and still allow 5/9, below
    sync pattern validator will handle validation.
    */
    validation: false,
  },
  label: 'ZIP code',
  placeholder: 'ZIP code',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter zip code',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: '([0-9]{5}|[0-9]{9})',
        message: 'First 5 or full 9 digit code required',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Address,
        readonly: true,
      },
    ],
  },
};
