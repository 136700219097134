import { Pipe, PipeTransform } from '@angular/core';
import * as selectOptions from '../../../../../fakesl/src/api/data/options.json';

const { states } = selectOptions;

@Pipe({
  name: 'stateTransform',
})
export class StateTransformPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return states.find((s) => s.label === value)?.value || value;
    }
    return value;
  }
}
