<div id="c-main" [class]="'c-main c-main--experience-' + (digitalExperienceName$ | async)">
  <div class="mat-typography c-main__typography-wrapper">
    <app-header (clickedSkipLink)="skipToMain()"></app-header>
    <div #mainRef tabindex="-1"></div>
    <ng-container *ngIf="currentRoute$ | async as currentRoute">
      <main
        class="c-main__content"
        role="main"
        [ngClass]="backgroundColorClass$ | async"
        [class.c-main__arc-background]="
          affiliateLandingStep !== (stepName$ | async) && !isStandAloneDisclosure(currentRoute)
        "
        [class.no-padding]="affiliateLandingStep === (stepName$ | async)"
      >
        <div class="c-main__content-body">
          <router-outlet></router-outlet>
        </div>
      </main>
    </ng-container>
    <app-footer></app-footer>
  </div>
</div>
