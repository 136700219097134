// models
import { QuestionNameTypes, SimpleFormConfig } from '../../questions/models';
import { Map } from '../../shared/models';

export const adverseActionBasePath = 'adverseactionservice';

export const adverseActionBaseUrl: Map<string> = {
  v1: `${adverseActionBasePath}/api/v1/adverse-action`,
};

export const adverseActionFormName = 'aa-verification';

// adverse action verification
export const adverseActionVerificationFormConfig: SimpleFormConfig = {
  questionNames: [
    QuestionNameTypes.LastFourSocialSecurityNumber,
    QuestionNameTypes.DateOfBirth,
    QuestionNameTypes.ZipCode,
  ],
  layout: { grid: [1, 1, 1] },
};
