// models
import * as fromQuestionsModels from '../../models';

export const housingTypeQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.RadioGroup,
  name: fromQuestionsModels.QuestionNameTypes.HousingType,
  label: 'Do you rent or own your home?',
  optionType: fromQuestionsModels.QuestionOptionTypes.HousingTypes,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select housing type',
      },
    ],
  },
};
