import { abTestsConfig } from '../../configs';
import { IAbTestData } from '../../models';

/**
 * Returns a/b test data with the default initial values of each a/b test.
 * Used in stores that house a/b test data, when setting initial a/b test data state.
 * These values can be used as an base source of truth, even if the a/b test is inactive (meaning it
 * won't exist in the payload at all). The a/b tests in the response that do exist
 * will override the population.
 * This is useful in cases where we might explicitly want to check if the a/b test
 * is the default population somewhere in our code, instead of needing to treat
 * an inactive a/b test (which would be missing from the payload) as an indicator that we're technically in the default experience
 * (which could get confusing).
 *
 * It can also be useful in cases where we don't receive any a/b test data due to being in an error state,
 * and we need certain UI to still load, even if this UI is also influenced by a/b test data
 *
 * `status` is just set to null as it shouldn't matter if the test isn't even active.
 */
export const getDefaultAbTestData = (): IAbTestData =>
  Object.entries(abTestsConfig).reduce((acc, [testName, testConfig]) => {
    acc[testName] = {
      population: testConfig.defaultPopulation,
      status: null,
      providedInLeadResponse: false,
    };
    return acc;
  }, {});
