import { HttpErrorResponse } from '@angular/common/http';
// store
import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as fromApplicationActions from '../actions';
// models
import { DocumentFile } from '../../models';

// key
export const DOCUMENTS_STATE_KEY = 'documents';

// state
export interface DocumentsState extends EntityState<DocumentFile> {
  error?: HttpErrorResponse;
  loading: boolean;
  loaded: boolean;
}

export const initialState: DocumentsState = {
  ids: [],
  entities: null,
  error: null,
  loading: false,
  loaded: false,
};

// adapter
export const selectDocumentId = (documentFile: DocumentFile): string => documentFile.uploadedDocName;

export const adapter: EntityAdapter<DocumentFile> = createEntityAdapter<DocumentFile>({
  selectId: selectDocumentId,
});

// reducer
const documentsReducer = createReducer(
  initialState,
  // load
  on(fromApplicationActions.loadDocuments, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(fromApplicationActions.loadDocumentsSuccess, (state, action) =>
    adapter.setAll(action.documents, {
      ...state,
      error: null,
      loading: false,
      loaded: true,
    })
  ),
  on(fromApplicationActions.loadDocumentsFail, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    loaded: false,
  })),
  on(fromApplicationActions.clearDocuments, () => initialState)
);

export const reducer = (state: DocumentsState, action: fromApplicationActions.DocumentAction) =>
  documentsReducer(state, action);

// state selectors
const { selectEntities, selectAll } = adapter.getSelectors();
export const selectDocumentEntities = selectEntities;
export const selectDocuments = selectAll;
export const selectDocumentsStateError = (state: DocumentsState) => state.error;
export const selectDocumentsStateLoading = (state: DocumentsState) => state.loading;
export const selectDocumentsStateLoaded = (state: DocumentsState) => state.loaded;
