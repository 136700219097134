<div *ngIf="applicationDecision" class="credit-score-notice">
  <div>
    <h3 class="credit-score-notice__header">
      <span>Best Egg Visa&reg; Credit Card</span><br />
      Your Credit Score and the Price You Pay for Credit
    </h3>
  </div>

  <div class="credit-score-notice__section">
    <!-- Table 1 -->
    <table class="credit-score-notice__section-table">
      <caption class="credit-score-notice__section-table--caption">
        Your Credit Score
      </caption>
      <tbody>
        <tr class="credit-score-notice__section-table--tr">
          <th class="credit-score-notice__section-table--th" scope="row">Your credit score</th>
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>
                <span [id]="idSelectors.FICO_SCORE">{{ applicationDecision.decisionData.ficoScore }}</span>
              </p>
              <p>
                <span>Source: Experian</span>&nbsp;
                <span
                  >Date:
                  <span [id]="idSelectors.DECISION_DATE">{{
                    applicationDecision.decisionDate * 1000 | date : 'y-M-d'
                  }}</span></span
                >
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Table 2 -->
    <table class="credit-score-notice__section-table">
      <!-- Table header -->
      <caption class="credit-score-notice__section-table--caption">
        Understanding Your Credit Score
      </caption>
      <tbody>
        <!-- Row 1 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">What you should know about credit scores</th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>Your credit score is a number that reflects the information in your credit report.</p>
              <p>
                Your credit report is a record of your credit history. It includes information about whether you pay
                your bills on time and how much you owe to creditors.
              </p>
              <p>Your credit score can change, depending on how your credit history changes.</p>
            </div>
          </td>
        </tr>

        <!-- Row 2 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">How we use your credit score</th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>
                Your credit score can affect whether you can get a loan and how much you will have to pay for that loan.
              </p>
            </div>
          </td>
        </tr>

        <!-- Row 3 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">The range of scores</th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>Scores range from a low of 300 to a high of 850.</p>
              <p>Generally, the higher your score, the more likely you are to be offered better credit terms.</p>
            </div>
          </td>
        </tr>

        <!-- Row 4 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">
            How your score compares to the scores of other consumers
          </th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <img
                class="credit-score-notice__section-table--fico-range-image"
                src="assets/images/fico-range.png"
                alt="credit score range"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Table 3 -->
    <table class="credit-score-notice__section-table">
      <!-- Table header -->
      <caption class="credit-score-notice__section-table--caption">
        Checking Your Credit Report
      </caption>
      <tbody>
        <!-- Row 1 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">
            What if there are mistakes in your credit report?
          </th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>
                You have a right to dispute any inaccurate information in your credit report. If you find mistakes on
                your credit report, contact the consumer reporting agency.
              </p>
              <p>It is a good idea to check your credit report to make sure the information it contains is accurate.</p>
            </div>
          </td>
        </tr>

        <!-- Row 2 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">
            How can you obtain a copy of your credit report?
          </th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>
                Under federal law, you have the right to obtain a free copy of your credit report from each of the
                nationwide consumer reporting agencies once a year.
              </p>
              <p>To order your free annual credit report:</p>
              <ul class="credit-score-notice__section-table--value__contact-list">
                <li class="credit-score-notice__section-table--li">
                  <p><em>By telephone:</em></p>
                  <p>Call toll-free: 1-877-322-8228</p>
                </li>
                <li class="credit-score-notice__section-table--li">
                  <p><em>On the web:</em></p>
                  <p>
                    Visit
                    <a href="https://www.annualcreditreport.com" target="_blank" rel="noopener"
                      >www.annualcreditreport.com</a
                    >
                  </p>
                </li>
                <li class="credit-score-notice__section-table--li">
                  <p><em>By mail:</em></p>
                  <div>
                    <p>
                      Mail your completed Annual Credit Report Request Form (which you can obtain from the Federal Trade
                      Commission's website at
                      <a
                        href="http://www.ftc.gov/bcp/conline/include/requestformfinal.pdf"
                        target="_blank"
                        rel="noopener"
                        >http://www.ftc.gov/bcp/conline/include/requestformfinal.pdf</a
                      >) to:
                    </p>
                    <address>
                      Annual Credit Report Request Service<br />
                      P.O. Box 105281<br />
                      Atlanta, GA 30348-5281
                    </address>
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>

        <!-- Row 3 -->
        <tr class="credit-score-notice__section-table--tr">
          <!-- Left header -->
          <th class="credit-score-notice__section-table--th" scope="row">How can you get more information?</th>

          <!-- Right content -->
          <td class="credit-score-notice__section-table--td">
            <div class="credit-score-notice__section-table--value">
              <p>
                For more information about credit reports and your rights under Federal law, visit the Consumer
                Financial Protection Bureau's website at
                <a href="https://www.consumerfinance.gov/learnmore." target="_blank" rel="noopener"
                  >www.consumerfinance.gov/learnmore.</a
                >
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
