/*
  Wrapper for the calls we make to window.FS for logging, individual tracking, etc.
  Note: we wrap Fullstory calls in try/catch because in the loan funnel we once had an issue with adblockers
  impacting window.FS. We want to make sure there's no user-facing erros if that occurs.
*/

// vendor
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, first, take, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
// models
import { CardProductOffer } from 'src/app/features/offers/models';
import { Application } from '../../../application/models';
import { Lead } from '../../../lead/models';
import { Map } from '../../../shared/models';
import {
  Fullstory,
  FullstoryEventProperties,
  FullstoryEventTypes,
  FullstoryLogLevels,
  FullstoryUserVars,
} from '../../models';
import { UserDataCollectionService } from '../user-data-collection.service';

@Injectable({
  providedIn: 'root',
})
export class FullstoryService {
  fs: Fullstory;

  /**
   * User var tracking-related
   */

  // For tracking and recording application-related info
  private applicationCollectionAndTracking$: Observable<Application> =
    this.userDataCollectionService.applicationData$.pipe(
      tap((applicationData: Application) => {
        const userVars: FullstoryUserVars = {
          cardAppId_str: applicationData.applicationDecision.applicationId,
          cardDecision_str: applicationData.applicationDecision.decisionType,
        };
        this.setUserVars(userVars);
      }),
      first()
    );

  // For tracking and recording lead-related info
  private leadCollectionAndTracking$: Observable<Lead> = this.userDataCollectionService.leadData$.pipe(
    tap((leadData: Lead) => {
      const userVars: FullstoryUserVars = {
        cardLeadId_str: leadData.id,
      };
      this.setUserVars(userVars);
    }),
    first()
  );

  /**
   * For tracking and recording CPID
   * (specifically checking product offer data instead of lead, since the CPID
   * on the latter can be incorrect)
   */
  private cpidCollectionAndTracking$: Observable<CardProductOffer> =
    this.userDataCollectionService.productOfferData$.pipe(
      tap((productOffer: CardProductOffer) => {
        const userVars: FullstoryUserVars = {
          cardCampaignParticipantCode_str: productOffer.campaignParticipantCode,
        };
        this.setUserVars(userVars);
      }),
      first()
    );

  /** For tracking and recording digital experience name */
  private digitalExperienceCollectionAndTracking$: Observable<string> =
    this.userDataCollectionService.digitalExperienceName$.pipe(
      tap((digitalExperienceName: string) => {
        const userVars: FullstoryUserVars = {
          digitalExperienceName_str: digitalExperienceName,
        };
        this.setUserVars(userVars);
      }),
      first()
    );
  //--

  constructor(private readonly userDataCollectionService: UserDataCollectionService) {
    this.fs = (window as any).FS;

    // Uncomment to send FullStory to the Console. Local env only!

    // this.fs = environment.env === 'local' ?
    //   {
    //     identify: (key, value) => console.log('Fullstory.identify', key, value),
    //     log: (type, val) => console.log('Fullstory.log', type, val),
    //     setUserVars: vars => console.log('Fullstory.setUserVars()', vars),
    //     clearUserCookie: () => console.log('Fullstory.clearUserCookie()'),
    //     event: (eventName, props) => console.log('Fullstory.event()', eventName, props),
    //     shutdown: () => console.log('Fullstory.shutdown()'),
    //   } :
    //   (window as any).FS;

    //--
  }

  /**
   * Identifies the user in FS once we have an email address to use as a UID.
   * Once we have more info like appID and decision, we send them as userVars to make FS searches easier.
   * Note: we are using email as the FS UID, but that is not a userVar (hence the odd (email, {email: email}) params).
   * The pipes are set up to require that we get the email address and call .identify before calling .setUserVars.
   */
  public identifyAndSetUserVarsListener(): void {
    this.userDataCollectionService.emailAddress$
      .pipe(
        tap((emailAddress: string) => {
          const email = emailAddress;
          try {
            const initialUserVars: FullstoryUserVars = {
              email,
            };
            this.fs.identify(email, initialUserVars);
          } catch (err) {
            console.error('[FS_ERROR:::identify]', err);
          }
        }),
        take(1),
        concatMap(() =>
          forkJoin([
            this.applicationCollectionAndTracking$,
            this.leadCollectionAndTracking$,
            this.cpidCollectionAndTracking$,
            this.digitalExperienceCollectionAndTracking$,
          ])
        )
      )
      .subscribe();
  }

  /**
   * set user vars
   * @param userVars
   */
  public setUserVars(userVars: Map<any>): void {
    try {
      this.fs.setUserVars(userVars);
    } catch (err) {
      console.error('[FS_ERROR:::setUserVars]', err);
    }
  }

  /**
   * event
   * @param eventName
   * @param eventProperties
   */
  public event(eventName: FullstoryEventTypes, eventProperties: FullstoryEventProperties): void {
    try {
      this.fs.event(eventName, eventProperties);
    } catch (err) {
      console.error('[FS_ERROR:::event]', err);
    }
  }

  /**
   * Logs messages in the Fullstory console.
   * @param [logType] - Severity level. Defaults to 'log'.
   * @param message - Text to be logged.
   */
  public log(logType: FullstoryLogLevels = FullstoryLogLevels.LOG, message: string): void {
    try {
      this.fs.log(logType, message);
    } catch (err) {
      console.error('[FS_ERROR:::log]', err);
    }
  }

  /**
   * shutdown
   */
  public shutdown(): void {
    try {
      this.fs.shutdown();
    } catch (err) {
      console.error('[FS_ERROR:::shutdown]', err);
    }
  }

  /**
   * Clears the FS tracker cookie.
   */
  public clearUserCookie() {
    // TODO_UPDATE: Clear out user cookies upon logout.
  }

  /**
   *  report app version to fullstory (only on production)
   */
  public reportCardAppVersion(): void {
    if (environment.production) {
      this.setUserVars({ cardVersion_str: environment.appVersion });
    }
  }
}
