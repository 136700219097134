// models
import * as fromQuestionsModels from '../../models';

// #cash-advances
export const cashAdvancesQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.RadioGroup,
  name: fromQuestionsModels.QuestionNameTypes.CashAdvances,
  label: 'How many credit card cash advances have you taken in the last 6 months?',
  sublabel: 'A cash advance is a cash loan taken against the line of credit on your credit card.',
  optionType: fromQuestionsModels.QuestionOptionTypes.CashAdvances,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select cash advances',
      },
    ],
  },
};
