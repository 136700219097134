// models
import * as fromQuestionsModels from '../../models';

export const residentialLengthQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.RadioGroup,
  name: fromQuestionsModels.QuestionNameTypes.ResidentialLength,
  label: 'How long have you lived there?',
  optionType: fromQuestionsModels.QuestionOptionTypes.ResidentialLengths,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select residence length',
      },
    ],
  },
};
