<div class="c-dialog c-dialog">
  <div class="c-dialog__header-actions">
    <app-dialog-close></app-dialog-close>
  </div>
  <h2 mat-dialog-title cdkFocusInitial tabindex="-1"></h2>
  <div class="mat-typography mat-mdc-dialog-content">
    <app-credit-score-notice
      *ngIf="data.applicationDecision$ | async as applicationDecision"
      [applicationDecision]="applicationDecision"
    ></app-credit-score-notice>
  </div>
  <div class="mat-mdc-dialog-actions mat-mdc-dialog-actions--reverse">
    <button id="credit-score-disclosure-notice-close" mat-stroked-button mat-dialog-close>Close</button>
  </div>
</div>
