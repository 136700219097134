// vendor
import { Injectable } from '@angular/core';
import { of, timer, Subject } from 'rxjs';
import { switchMap, map, catchError, filter, withLatestFrom, tap, scan, takeUntil, exhaustMap } from 'rxjs/operators';
import * as _ from 'lodash';
// ngrx
import { Store, select, Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromApplicationActions from '../actions';
import * as fromAbTestStore from '../../../ab-tests/store';
import * as fromApplicationSelectors from '../selectors';
import * as fromLeadStore from '../../../lead/store';
import * as fromOffersStore from '../../../offers/store';
import * as fromQuestionsStore from '../../../questions/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import * as fromAppStore from '../../../../store';
// services
import { ApplicationService, ApplicationSyncService, ApplicationStepService } from '../../services';
// models
import { Application, ApplicationSubmitResponse, ApplicationSubmit } from '../../models';
import { AbTestNames, AbTestStatuses, ButtonPopPopulations, IAbTestData } from 'src/app/features/ab-tests/models';
import { ApplicationFromLeadRequest } from 'fakesl/src/models/generated/applicationservice.model';

import {
  ApplicationStepSettings,
  ApplicationStepDirectionTypes,
  ApplicationStepNameTypes,
  DigitalExperienceTypes,
} from '../../../digital-experience/models';
// configs
import { MAIN_URL_SEGMENT, TIMEOUT_URL_SEGMENT } from '../../../main/configs';
import { applicationErrorMessages } from '../../configs';
import { Lead } from 'src/app/features/lead/models';
// consts
const SEC_TO_MS_FACTOR = 1000;
const DEFAULT_POLLING_INTERVAL = 3000;
const DEFAULT_POLLING_ATTEMPT_LIMIT = 20;
const DEFAULT_POLLING_ERROR_LIMIT = 5;

@Injectable({
  providedIn: 'root',
})
export class ApplicationEffects {
  stopPolling$: Subject<boolean>;

  constructor(
    private applicationService: ApplicationService,
    private applicationSyncService: ApplicationSyncService,
    private applicationStepService: ApplicationStepService,
    private action$: Actions,
    private store: Store<any>
  ) {
    this.stopPolling$ = new Subject();
  }

  // process application step change
  processApplicationStepChange$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.processApplicationStepChange),
      withLatestFrom(
        this.store.pipe(select(fromQuestionsStore.selectQuestionValuesData)),
        this.store.pipe(select(fromQuestionsStore.selectValidationsData)),
        this.store.pipe(select(fromDigitalExperienceStore.selectAppStepEntities))
      ),
      map(([action, questionValues, questionValidations, experienceSteps]) => {
        const applicationStepSettings = this.applicationStepService.processApplicationStepChange(
          action.applicationStep,
          action.direction,
          questionValues,
          questionValidations,
          experienceSteps
        );
        return [action, applicationStepSettings];
      }),
      switchMap(([action, applicationStepSettings]: [any, ApplicationStepSettings]) => {
        const updateStepsAllowedAction = fromDigitalExperienceStore.updateStepsAllowed({
          payload: applicationStepSettings,
        });

        /** Only updateStepsAllowed would be guaranteed. The other two should be dynamically
         * added to this returned array depending on certain things. */
        const returnedActions: Action[] = [updateStepsAllowedAction];

        if (
          action.applicationStep.name === ApplicationStepNameTypes.ConfirmInfo &&
          action.direction === ApplicationStepDirectionTypes.Forward
        ) {
          returnedActions.push(fromDigitalExperienceStore.setStepAndUpdateStepsAllowed({ skipStepsChange: true }));
        }
        /** resetQuestionValues dispatch check */
        const shouldMakeResetQuestionValuesDispatch = applicationStepSettings.extraQuestionsValues;
        if (shouldMakeResetQuestionValuesDispatch) {
          const resetQuestionsAction = fromQuestionsStore.updateQuestionValues({
            questionValues: applicationStepSettings.extraQuestionsValues,
          });

          returnedActions.push(resetQuestionsAction);
        }

        /** updateLead dispatch check */
        /**
         * TODO_UPDATE
         * We previously had some form values that we did't want to PATCH to the lead.
         * We currently don't need it for anything, so we might be able to remove it, but FYI..
         * We prevent the dispatch altogether if one of the omitted question exists in the form
         * (we don't need to check every form field - can just short circuit). This was just for the
         * agreement step/question, so the logic might need to be altered if we get another use-case for this.
         *
         * And we can implement by including another conditional to `shouldMakeLeadDispatch`:
         * * const omittedQuestions = [];
         * Condition:
         * * !Object.keys(action.questionValues).some((question: QuestionNameTypes) => omittedQuestions.includes(question))
         */
        const shouldMakeLeadDispatch =
          action.direction === ApplicationStepDirectionTypes.Forward && Object.keys(action.questionValues).length; // Avoid lead requests on form-less pages
        if (shouldMakeLeadDispatch) {
          const leadAction: Action = fromLeadStore.updateLead({
            questionValues: action.questionValues,
            // extraQuestionsValues may or may not exist.
            extraQuestionsValues: applicationStepSettings.extraQuestionsValues,
          });
          returnedActions.push(leadAction);
        }
        return returnedActions;
      })
    )
  );

  // load application
  loadApplication$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.loadApplication),
      switchMap((action) =>
        this.applicationService.getApplication(action.applicationId).pipe(
          map((application: Application) => fromApplicationActions.loadApplicationSuccess({ application })),
          catchError((error: Record<string, any>) => of(fromApplicationActions.loadApplicationFail({ error })))
        )
      )
    )
  );

  // load application polling (for pend kyc dashboard)
  loadApplicationPolling$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.loadApplicationPolling),
      withLatestFrom(this.store.pipe(select(fromApplicationSelectors.selectApplicationData))),
      switchMap(([action, application]) => {
        let errorCount = 0;
        return timer(0, action.interval * SEC_TO_MS_FACTOR || DEFAULT_POLLING_INTERVAL).pipe(
          scan((attemptCount) => ++attemptCount, 0),
          tap((attemptCount) => {
            if (action.attemptLimit && attemptCount > action.attemptLimit) {
              this.stopPolling$.next();
              console.error('[GEN_APP_POLLING:::attempt_limit]');
            }
          }),
          takeUntil(this.stopPolling$),
          exhaustMap(() =>
            this.applicationService.getApplication(application.id).pipe(
              map((application) =>
                fromApplicationActions.loadApplicationSuccess({
                  application,
                })
              ),
              catchError((error: Record<string, any>) => {
                errorCount++;
                if (errorCount === DEFAULT_POLLING_ERROR_LIMIT) {
                  this.stopPolling$.next();
                  console.error('[GEN_APP_POLLING:::error_limit]');
                }
                return of(fromApplicationActions.loadApplicationFail({ error }));
              })
            )
          )
        );
      })
    )
  );

  // load application decision polling
  loadApplicationDecisionPolling$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.loadApplicationDecisionPolling),
      switchMap((action) =>
        timer(0, action.interval || DEFAULT_POLLING_INTERVAL).pipe(
          scan((attemptCount) => ++attemptCount, 0),
          tap((attemptCount) => {
            if (attemptCount > (action.attemptLimit || DEFAULT_POLLING_ATTEMPT_LIMIT)) {
              this.stopPolling$.next();
              console.error('[ERROR] polling app decision limit reached');
              this.store.dispatch(
                fromAppStore.go({
                  path: [MAIN_URL_SEGMENT, TIMEOUT_URL_SEGMENT],
                  extras: {
                    // prevent browser back to /decision to avoid trigger polling again
                    replaceUrl: true,
                    state: {
                      message: applicationErrorMessages.loadApplicationPollingLimit,
                    },
                  },
                })
              );
            }
          }),
          takeUntil(this.stopPolling$),
          exhaustMap(() =>
            this.applicationService.getApplication(action.applicationId).pipe(
              filter((application) => !!application.applicationDecision?.decisionType),
              tap((application) => {
                // sync the lead store applicant with data from application,
                // required for odl experience, dispatch this action for all experience
                this.store.dispatch(
                  fromLeadStore.updateLeadSuccess({
                    lead: {
                      primaryApplicant: application.primaryApplicant,
                    } as Lead,
                  })
                );
              }),
              map((application) => {
                this.stopPolling$.next();
                return fromApplicationActions.loadApplicationSuccess({
                  application,
                });
              }),
              catchError((error: Record<string, any>) => of(fromApplicationActions.loadApplicationFail({ error })))
            )
          )
        )
      )
    )
  );

  // stop polling
  stopApplicationPolling$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(fromApplicationActions.stopApplicationPolling),
        tap(() => this.stopPolling$.next())
      ),
    { dispatch: false }
  );

  // submit application
  submitApplication$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.submitApplication),
      withLatestFrom(
        this.store.pipe(select(fromApplicationSelectors.selectApplicationMetaData)),
        this.store.pipe(select(fromOffersStore.selectCardProspectOfferData)),
        this.store.pipe(select(fromOffersStore.selectCardProductOfferData)),
        this.store.pipe(select(fromLeadStore.selectLeadData)),
        this.store.pipe(select(fromQuestionsStore.selectQuestionValuesData)),
        this.store.pipe(select(fromLeadStore.selectLeadOdlData))
      ),
      map(([_action, applicationMeta, cardProspectOffer, cardProductOffer, lead, questionValues, odlData]) =>
        this.applicationSyncService.syncDataToApplication(
          applicationMeta,
          cardProspectOffer,
          cardProductOffer,
          lead,
          questionValues,
          odlData
        )
      ),
      withLatestFrom(
        this.store.pipe(select(fromAbTestStore.selectAbTestsData)),
        this.store.pipe(select(fromDigitalExperienceStore.selectDigitalExperienceName))
      ),
      switchMap(
        ([application, abTestData, digitalExperienceName]: [
          ApplicationSubmit | ApplicationFromLeadRequest,
          IAbTestData,
          string
        ]) => {
          if ('primaryApplicant' in application) {
            return this.applicationService.submitApplication(application).pipe(
              switchMap((applicationSubmitResponse: ApplicationSubmitResponse) => [
                fromApplicationActions.submitApplicationSuccess({
                  applicationSubmitResponse,
                }),
                this.kcfButtonPopAbTestStatusCompleteAction(digitalExperienceName, abTestData),
              ]),
              catchError((error: Record<string, any>) => of(fromApplicationActions.submitApplicationFail({ error })))
            );
          } else {
            return this.applicationService.submitApplicationFromLead(application).pipe(
              switchMap((applicationSubmitResponse: ApplicationSubmitResponse) => [
                fromApplicationActions.submitApplicationSuccess({
                  applicationSubmitResponse,
                }),
                this.kcfButtonPopAbTestStatusCompleteAction(digitalExperienceName, abTestData),
              ]),
              catchError((error: Record<string, any>) => of(fromApplicationActions.submitApplicationFail({ error })))
            );
          }
        }
      )
    )
  );

  // send credit score notice event
  sendCreditScoreNoticeEvent$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(fromApplicationActions.sendCreditScoreNoticeEvent),
        withLatestFrom(this.store.pipe(select(fromApplicationSelectors.selectApplicationData))),
        switchMap(([action, applicationData]) => {
          const creditScoreNoticeEvent = {
            applicationId: applicationData.id,
            disclosureNameType: action.disclosureNameType,
          };
          return this.applicationService.sendCreditScoreNoticeEvent(creditScoreNoticeEvent);
        })
      ),
    { dispatch: false }
  );

  kcfButtonPopAbTestStatusCompleteAction(digitalExperienceName, abTestData) {
    return digitalExperienceName === DigitalExperienceTypes.BestEggKcf &&
      abTestData.buttonPop.population === ButtonPopPopulations.ENABLEDBUTTON
      ? fromAbTestStore.updateAbTestsStatuses({
          abTestStatusesUpdates: {
            [AbTestNames.BUTTON_POP]: AbTestStatuses.COMPLETED,
          },
        })
      : fromAppStore.noop();
  }
}
