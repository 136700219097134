// Enums for ab test populations go here. Create a separate enum for each ab test
export enum BECCBreakPopulations {
  BREAKBECC = 'break',
  NOBREAKBECC = 'noBreak',
}

export enum ButtonPopPopulations {
  GRAYEDOUT = 'grayedOut',
  ENABLEDBUTTON = 'enabledButton',
}

export enum ToggleApplicationSubmitPopulations {
  SUBMIT = 'submit',
  NOTSUBMIT = 'notSubmit',
}

// Combine all AB test enums using the spread operator
export const ABTestPopulationsCombined = {
  ...BECCBreakPopulations,
  ...ButtonPopPopulations,
  ...ToggleApplicationSubmitPopulations,
};
