// vendor
import { Component, ViewChild, OnInit } from '@angular/core';
// services
import { FullstoryService } from '../../../analytics/services';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
import { select, Store } from '@ngrx/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
import { IAbTestData } from '../../../ab-tests/models';
import { combineLatest, Observable, of } from 'rxjs';
import { selectDigitalExperienceName } from '../../../digital-experience/store';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';
import { DataDogService } from '../../../analytics/services/data-dog.service';
import { ApplicationStepNameTypes } from 'src/app/features/digital-experience/models';
import { AdaChatbotService } from 'src/app/features/analytics/services/ada-chatbot/ada-chatbot.service';
import { selectRouterState } from 'src/app/store';
import { CREDIT_PROFILE_AUTHORIZATION_SEGMENT, E_SIGN_CONSENT_SEGMENT } from '../../configs/main.configs';
import { selectDashboardLoadData } from 'src/app/features/application/store/selectors/dashboard-load.selector';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  @ViewChild('mainRef', { static: true })
  private mainRef;
  abTestData: IAbTestData;
  stepName$: Observable<string>;
  affiliateLandingStep: string = ApplicationStepNameTypes.AffiliateLanding;
  standAloneDisclosureSegment = [CREDIT_PROFILE_AUTHORIZATION_SEGMENT, E_SIGN_CONSENT_SEGMENT];

  digitalExperienceName$ = this.store.select(selectDigitalExperienceName).pipe(
    filter<string>(Boolean),
    take(1),
    // Delay necessary to avoid dreaded ExpressionChangedAfterItHasBeenCheckedError
    // TODO: Determine the correct way to fix this that avoids the delay
    delay(0)
  );

  currentRoute$ = this.store.select(selectRouterState).pipe(map((routerState) => routerState.state.url));

  // This observable need to check and emit value each time either currentRoute or dashboardLoadData changes
  backgroundColorClass$ = combineLatest([this.currentRoute$, this.store.select(selectDashboardLoadData)]).pipe(
    map(([currentRoute, dashboardLoadData]) => {
      if (this.isStandAloneDisclosure(currentRoute)) {
        return 'c-main__content-stand-alone-disclosure-background-color';
      } else if (!dashboardLoadData.isPendDashboard) {
        return 'c-main__content-general-background-color';
      }
      return 'c-main__content-pending-dashboard-background-color';
    })
  );

  constructor(
    private fullstoryService: FullstoryService,
    private EEService: EnterpriseEventTrackingService,
    private store: Store,
    private readonly dataDogService: DataDogService,
    private adaChatbotService: AdaChatbotService
  ) {}

  ngOnInit() {
    this.stepName$ = this.store.pipe(
      select(fromDigitalExperienceStore.selectDigitalExperienceCurrentStep),
      filter((step) => this.checkIfNotNull(step))
    );
    this.EEService.initEnterpriseEventTracking();
    this.fullstoryService.reportCardAppVersion();
    this.fullstoryService.identifyAndSetUserVarsListener();
    this.dataDogService.collectUserSessionData();
    this.dataDogService.startSessionReplayRecording();
    this.adaChatbotService.init();
  }

  checkIfNotNull(str: string) {
    return str !== null;
  }

  skipToMain() {
    setTimeout(() => {
      if (this.mainRef) {
        this.mainRef.nativeElement.focus();
      }
    }, 100);
  }

  isStandAloneDisclosure(currentRoute: string) {
    return this.standAloneDisclosureSegment.some((segment) => currentRoute.includes(segment));
  }
}
