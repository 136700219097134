// vendor
import { Component, OnInit } from '@angular/core';
// services
import { FullstoryService } from '../../../analytics/services';
// models
import { FullstoryEventTypes } from '../../../analytics/models';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
// consts
const MAINTENANCE_NAME = 'maintenance';

// CURRENTLY NOT IN USE, Redirection logic in main.guard, which did not add to router config
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  maintenanceMessage: string;

  constructor(private fullstoryService: FullstoryService, private eeService: EnterpriseEventTrackingService) {
    // All one line to avoid line breaks being reported to enterprise event.
    // eslint-disable-next-line max-len
    this.maintenanceMessage =
      "We're experiencing a temporary technical outage but we're working to restore your great experience. Please try back later and thank you for your patience.";
  }

  ngOnInit() {
    this.fullstoryService.event(FullstoryEventTypes.MaintenanceView, {
      name_str: MAINTENANCE_NAME,
    });

    this.eeService.reportApplyIntent();
  }
}
