/**
 * module: offers
 * desc: prospect/product offers
 * notes: none
 */

// vendor
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromOffersStore from './store';
// components
import * as fromComponents from './components';
// modules
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [...fromComponents.components],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromOffersStore.OFFERS_FEATURE_KEY, fromOffersStore.reducers),
    EffectsModule.forFeature(fromOffersStore.effects),
    SharedModule,
  ],
  exports: [...fromComponents.components],
})
export class OffersModule {}
