// ngrx
import { createSelector } from '@ngrx/store';
import * as fromReducers from '../reducers';
import * as fromDocumentsReducer from '../reducers/documents.reducer';

// documents
export const selectDocumentsState = createSelector(
  fromReducers.selectApplicationFeature,
  (state: fromReducers.ApplicationState) => state.documents
);

// entities
export const selectDocumentEntities = createSelector(
  selectDocumentsState as any,
  fromDocumentsReducer.selectDocumentEntities
);

// documents
export const selectDocuments = createSelector(selectDocumentsState as any, fromDocumentsReducer.selectDocuments);

// error
export const selectDocumentsError = createSelector(
  selectDocumentsState,
  fromDocumentsReducer.selectDocumentsStateError
);

// loaded
export const selectDocumentsLoaded = createSelector(
  selectDocumentsState,
  fromDocumentsReducer.selectDocumentsStateLoaded
);

// loading
export const selectDocumentsLoading = createSelector(
  selectDocumentsState,
  fromDocumentsReducer.selectDocumentsStateLoading
);
