import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FullstoryEventTypes } from 'src/app/features/analytics/models';
import { BEFHAccountCreationEventName } from 'src/app/features/analytics/models/enterprise-event.model';
import { FullstoryService } from 'src/app/features/analytics/services';
import { AdaChatbotService } from 'src/app/features/analytics/services/ada-chatbot/ada-chatbot.service';
import { EnterpriseEventTrackingService } from 'src/app/features/analytics/services/enterprise-event/enterprise-event.service';
import { environment } from 'src/environments/environment';
import { WindowUtilService } from '../../services/window-util.service';

@Component({
  selector: 'app-adverse-action-befh-banner',
  templateUrl: './adverse-action-befh-banner.component.html',
  styleUrls: ['./adverse-action-befh-banner.component.scss'],
})
export class AdverseActionBefhBannerComponent implements OnInit, OnDestroy {
  befh_url = environment.BEFH_URL;
  portrait: MediaQueryList;
  adjustChatbotBind: () => unknown;

  @Output() closeBannerEvent = new EventEmitter();

  constructor(
    private windowUtilService: WindowUtilService,
    private chatbotService: AdaChatbotService,
    private EEV2Service: EnterpriseEventTrackingService,
    private fullStoryService: FullstoryService
  ) {
    this.adjustChatbotBind = this.adjustChatbotOffset.bind(this);
  }

  ngOnInit(): void {
    this.EEV2Service.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_SHOWN);
    this.fullStoryService.event(FullstoryEventTypes.BEFHBannerShown, {});
    this.adjustChatbotOffset();
    // https://fjolt.com/article/javascript-detecting-device-orientation
    this.portrait = this.windowUtilService.nativeWindow.matchMedia('(orientation: portrait)');
    this.portrait.addEventListener('change', this.adjustChatbotBind);
  }

  ngOnDestroy(): void {
    this.portrait.removeEventListener('change', this.adjustChatbotBind);
  }

  dispatchEEV2() {
    this.EEV2Service.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_CLICKED);
  }

  closeBanner() {
    this.EEV2Service.reportBEFHAccountCreationEvents(BEFHAccountCreationEventName.BEFH_DECLINED_PATH_BANNER_EXIT);
    this.closeBannerEvent.next(true);
    this.chatbotService.adjustAdaChatbotMobileOffset('0');
  }

  adjustChatbotOffset() {
    const viewPortWidth = this.windowUtilService.nativeWindow.innerWidth;
    switch (true) {
      case viewPortWidth < 325:
        this.chatbotService.adjustAdaChatbotMobileOffset('260px');
        break;
      case viewPortWidth < 960:
        this.chatbotService.adjustAdaChatbotMobileOffset('200px');
        break;
      default:
        this.chatbotService.adjustAdaChatbotMobileOffset('160px');
        break;
    }
  }
}
