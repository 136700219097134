// ngrx
import { createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import * as fromRouterReducer from '../reducers';

// router state
export const selectRouterState = createSelector(fromRouterReducer.selectRouterFeature, (routerState) => routerState);

export const selectRouterQueryParams = createSelector(
  selectRouterState,
  (routerState: RouterReducerState<fromRouterReducer.RouterStateUrl>) => {
    const query = routerState?.state?.queryParams;
    // replace 'amp;' with empty string from query params that mess up the query params keys
    if (Object.keys(query).some((key) => key.includes('amp;'))) {
      return Object.entries(query).reduce((acc, entry) => {
        let key = entry[0];
        key = key.replace(/amp;/g, '');
        acc[key] = entry[1];
        return acc;
      }, {});
    } else {
      return query;
    }
  }
);
