// models
import * as fromQuestionsModels from '../../models';

// #last-four-social-security-number
export const lastFourSocialSecurityNumberQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Input,
  inputElType: fromQuestionsModels.InputElTypes.Password,
  name: fromQuestionsModels.QuestionNameTypes.LastFourSocialSecurityNumber,
  mask: {
    mask: fromQuestionsModels.MaskTypes.Last4SocialSecurityNumber,
  },
  placeholder: 'Enter last 4 of social security',
  label: 'Last 4 of Social Security Number',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please enter last 4 of social security',
      },
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Pattern,
        value: '[0-9]{4}',
        message: 'Please enter 4 digits',
      },
    ],
  },
};
