// vendor
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
// models
import * as fromQuestionsModels from '../../../models';
import { Map } from '../../../../shared/models';
// config
import { FieldInteraction, QuestionMap } from '../../../models';
import { defaultQuestionMap } from '../../../configs';
import { IAbTestData } from '../../../../ab-tests/models';
// consts
const FORM_CONFIG = 'formConfig';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnChanges {
  abTestData: IAbTestData;
  questionSections: fromQuestionsModels.Question[][];
  questionMap: QuestionMap;
  backendGeneral: fromQuestionsModels.ValidatorTypes = fromQuestionsModels.ValidatorTypes.BackendGeneral;
  fieldsInteracted: string[] = [];

  @Input() form: UntypedFormGroup;
  @Input() formConfig: fromQuestionsModels.SimpleFormConfig;
  @Input() questionOptions: Map<fromQuestionsModels.Option>;
  @Input() ignoreBackendErrors: boolean;
  @Input() errorValidator: fromQuestionsModels.QuestionValidator;

  ngOnChanges(changes: SimpleChanges) {
    /**
     * Redefining this.questionSections will re-render form, causing focus to be lost so that
     * users cannot up/down arrow key through options. Be careful to only modify
     * this.questionSections in-place.
     */
    if (changes[FORM_CONFIG]) {
      if (!changes[FORM_CONFIG].isFirstChange()) {
        this.questionMap = defaultQuestionMap;
        const currentNames = changes[FORM_CONFIG].currentValue.questionNames;
        const previousNames = changes[FORM_CONFIG].previousValue.questionNames;
        // First find what question names have been added or removed
        const addedNames = currentNames.filter((name) => !previousNames.includes(name));
        const removedNames = previousNames.filter((name) => !currentNames.includes(name));
        const dependencies = changes[FORM_CONFIG].currentValue?.questionDependencies;

        if (this.questionSections && dependencies) {
          this.handleAddedFields(addedNames, dependencies);
          this.handleRemovedFields(removedNames);
          return;
        }
      }
      this.configureForm(this.formConfig);
    }
  }

  public handleAddedFields(addedNames: string[], dependencies): void {
    if (!addedNames.length || !dependencies) {
      return;
    }
    const whereToAdd = addedNames.reduce((acc, curr) => {
      /**
       * ZERO will work for now since we don't yet have a question dependent on multiple questions.
       * If/when we add that we'll also need a way to determine where the new question should be positioned.
       * Currently we are just positioning the dynamic question after the question it is dependent on.
       */
      const dictatorQuestion = dependencies[curr][0].dictatorName;
      if (acc[dictatorQuestion]) {
        acc[dictatorQuestion].push(curr);
      } else {
        acc[dictatorQuestion] = [curr];
      }
      return acc;
    }, {} as Record<string, string[]>);

    Object.entries(whereToAdd).forEach(([dictator, questions]: [string, string[]]) => {
      const index =
        this.questionSections.findIndex((section) => section.some((question) => question.name === dictator)) + 1;
      const questionsForDictator = questions.map((questionName) => [this.questionMap[questionName]]);
      this.questionSections.splice(index, 0, ...questionsForDictator);
    });
  }

  public handleRemovedFields(removedNames: string[]): void {
    if (removedNames.length === 0) {
      return;
    }
    /**
     * loop through questions of each section.
     * Remove question from array of questions.
     * Remove section if no questions remain.
     */
    this.questionSections.forEach((section) => {
      section.forEach((question, i) => {
        if (removedNames.includes(question.name)) {
          section.splice(i, 1);
        }
      });
    });
    // clean our sections with no questions. Re-assignment with filter doesn't trigger re-render
    this.questionSections = this.questionSections.filter((section) => section.length > 0);
  }
  /**
   * configure form
   * @params formConfig
   */
  private configureForm(formConfig: fromQuestionsModels.SimpleFormConfig): void {
    const { questionNames, nonQuestionItems, layout } = formConfig;
    this.questionMap = defaultQuestionMap;
    const questions = questionNames.map((questionName: string) => {
      let question: fromQuestionsModels.Question = this.questionMap[questionName];
      if (question.optionType) {
        question = {
          ...question,
          options: this.questionOptions[question.optionType] as fromQuestionsModels.Option[],
        };
      }
      return question;
    });

    /**
     * 'nonQuestionItems' would include things like independent help text.
     */
    if (nonQuestionItems) {
      nonQuestionItems.forEach((nonQuestionItem) => {
        const insertIndex = questionNames.indexOf(nonQuestionItem.insertAfter);
        if (insertIndex >= 0) {
          questions.splice(insertIndex + 1, 0, nonQuestionItem);
        }
      });
    }

    this.configureQuestionSections(questions, layout);
  }

  /**
   * configure question sections
   * @param questions
   * @param layout
   */
  private configureQuestionSections(
    questions: fromQuestionsModels.Question[],
    layout: fromQuestionsModels.FormLayout
  ): void {
    const tempQuestions = [...questions];
    let tempQuestionSections = tempQuestions.map((question: fromQuestionsModels.Question) => [question]);
    if (layout) {
      // check for layout/total question mismatch
      const grid = [...layout.grid];
      const totalQuestions = tempQuestions.length;
      const totalGrid = grid.reduce((sum, num) => sum + num, 0);
      const dif = totalQuestions - totalGrid;
      if (dif > 0) {
        Array(dif)
          .fill(1)
          .forEach(() => grid.push(1));
      }
      tempQuestionSections = grid.map((value: number) => tempQuestions.splice(0, value));
    }
    this.questionSections = tempQuestionSections;
  }

  public handleFieldInteractionChange(fieldInteraction: FieldInteraction) {
    /**
     * Keep a record of all the fields that have been focused in this form.
     * This could be used for things like determining whether we should display help text.
     *
     * TODO: we can also consider other points of interaction, like `mouseenter` events, if
     * we wanted.
     */
    if (fieldInteraction.focused && !this.fieldsInteracted.includes(fieldInteraction.questionName)) {
      this.fieldsInteracted.push(fieldInteraction.questionName);
    }
  }
}
