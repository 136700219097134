import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AbTestsState, AB_TESTS_FEATURE_KEY } from '../reducers';
import { AbTestNames } from '../../models';

// feature selector
export const selectAbTestsFeature = createFeatureSelector<AbTestsState>(AB_TESTS_FEATURE_KEY);

// ab test data
export const selectAbTestsData = createSelector(selectAbTestsFeature, (state: AbTestsState) => state.data);

// ab test metadata
export const selectAbTestsMetaData = createSelector(selectAbTestsFeature, (state: AbTestsState) => state.metadata);

export const selectAbTestsOverride = createSelector(selectAbTestsFeature, (state: AbTestsState) => state.override);

export const selectAbTestLoaded = createSelector(selectAbTestsFeature, (state: AbTestsState) => state.loaded);
