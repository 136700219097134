// rxjs
import { createReducer, on } from '@ngrx/store';
import * as fromCardFunnelAvailabilityActions from '../actions';
// models
import { CardFunnelAvailability } from '../../models';

// key
export const CARD_FUNNEL_AVAILABILITY_STATE_KEY = 'cardFunnelAvailability';

// state
export interface CardFunnelAvailabilityState {
  data: CardFunnelAvailability;
  error: Record<string, any>;
  loaded: boolean;
  loading: boolean;
}

export const initialState: CardFunnelAvailabilityState = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
};

// reducer
const cardFunnelAvailabilityReducer = createReducer(
  initialState,
  // load
  on(fromCardFunnelAvailabilityActions.loadCardFunnelAvailability, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromCardFunnelAvailabilityActions.loadCardFunnelAvailabilitySuccess, (state, action) => ({
    ...state,
    data: action.cardFunnelAvailability,
    error: null,
    loading: false,
    loaded: true,
  })),
  on(fromCardFunnelAvailabilityActions.loadCardFunnelAvailabilityFail, (state, action) => ({
    ...state,
    error: action.error.error,
    loaded: false,
    loading: false,
  })),
  on(fromCardFunnelAvailabilityActions.clearCardFunnelAvailability, (state) => initialState)
);

export const reducer = (
  state: CardFunnelAvailabilityState,
  action: fromCardFunnelAvailabilityActions.CardFunnelAvailabilityAction
) => cardFunnelAvailabilityReducer(state, action);

// state selectors
export const selectCardFunnelAvailabilityData = (state: CardFunnelAvailabilityState) => state.data;
export const selectCardFunnelAvailabilityLoading = (state: CardFunnelAvailabilityState) => state.loading;
export const selectCardFunnelAvailabilityLoaded = (state: CardFunnelAvailabilityState) => state.loaded;
