// services
import { regex } from './validators-regex';
import { DateTimeService } from '../../../shared/services';
// models
import { QuestionValidators, QuestionValidator } from '../../models';
import { DateTypes } from '../../../shared/models';
import { stateLabels } from '../../../application/configs/states.config';
import * as selectOptions from '../../../../../../fakesl/src/api/data/options.json';
// const
const YEAR_MINIMUM = 1900;
const dateTimeService = new DateTimeService();
const { states } = selectOptions;

/**
 * is valid date fn
 * @param input
 * @returns validate date status
 */
const isValidDate = (dateStr: string): boolean => {
  let validationStatus = false;
  if (regex.unformattedDate.test(dateStr)) {
    const date = dateTimeService.getDate(dateStr, DateTypes.MMDDYYYY, true);
    const validYear = date.year() > YEAR_MINIMUM;
    validationStatus = date.isValid() && validYear;
  }
  return validationStatus;
};

/**
 * format dob fn
 * @param dob
 * @returns formatted dob
 */
// Convert to DD/MM/YYYY format (just for testing valid date in front end)
const formatDob = (dob: string): string => {
  if (dob.indexOf('/') === -1) {
    return regex.unformattedDate.exec(dob).slice(1, 4).join('/');
  }
  return dob;
};

/**
 * is po box
 * @param address
 * @returns is po box status
 */
const isPoBox = (address: string): boolean => regex.isPoBox.some((pattern) => pattern.test(address));

/**
 * is of age
 * @param dateOfBirth
 * @param minAge required min age
 * @param maxAge optional max age
 * @returns of age status
 */
const isOfAge = (dateOfBirth: string, minAge: number, maxAge: number = null): boolean => {
  const age = validatorHelpers.getAge(dateOfBirth);
  let isOfAgeStatus = age >= minAge;
  if (maxAge) {
    isOfAgeStatus = isOfAgeStatus && age <= maxAge;
  }
  return isOfAgeStatus;
};

/**
 * get age
 * @param bday
 * @returns age
 */
const getAge = (bday: string): number => {
  const formatted = formatDob(bday);
  const today = new Date();
  const birthdate = new Date(formatted);
  // Perform data arithmatic.
  let age = today.getFullYear() - birthdate.getFullYear();
  const m = today.getMonth() - birthdate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    age--;
  }
  return age;
};

const containsAnd = (value: string): boolean => value.toLowerCase().includes(' and ');

const isUnsupportedState = (value: string): boolean =>
  ['DC', 'IA', 'VT', 'WV'].includes(states.find((s) => s.label.toUpperCase() === value.toUpperCase())?.value || value);

const isInvalidState = (value: string): boolean =>
  !(states.find((s) => s.value === value.toUpperCase()) || stateLabels.includes(value.toUpperCase()));

/**
 * combine
 * @param validators
 * @return combined validators
 */
const combine = (validators: QuestionValidators): QuestionValidator[] => {
  let combinedValidators = [];
  if (validators) {
    combinedValidators = [...(validators.sync || []), ...(validators.async || [])];
  }
  return combinedValidators;
};

export const validatorHelpers = {
  isValidDate,
  formatDob,
  isPoBox,
  isOfAge,
  getAge,
  containsAnd,
  combine,
  isUnsupportedState,
  isInvalidState,
};
