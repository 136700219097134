<div class="c-form-input o-form-field">
  <mat-form-field
    [id]="question.name"
    class="mat-form-field"
    appearance="outline"
    [hideRequiredMarker]="true"
    floatLabel="always"
  >
    <mat-label [attr.for]="question.label">
      {{ question.label }}
      <app-form-tooltip
        *ngIf="question.tooltip"
        [tooltip]="question.tooltip"
        [tooltipLabel]="question.tooltipLabel"
        tooltipPosition="after"
      ></app-form-tooltip>
    </mat-label>

    <input
      type="text"
      placeholder="{{ question.label }}"
      matInput
      [name]="question.name"
      [formControl]="formControl"
      [errorStateMatcher]="matcher"
      [matAutocomplete]="auto"
    />
    <mat-icon matSuffix class="mat-select-arrow"></mat-icon>
    <mat-autocomplete class="c-form-autocomplete" autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
    <mat-error class="mat-error" [class.mat-error--no-content]="!errorValidator.message" *ngIf="errorValidator">
      {{ errorValidator.message }}
    </mat-error>
  </mat-form-field>
</div>
