// vendor
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'currencySimple',
})
export class CurrencySimplePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  /**
   * If there's no decimal numbers (or they're present but with 0 value), then it should display as a whole number.
   * If there's any decimal values (1 or 2 places), then always display as 2 decimal places. This
   * behavior can be explicitly overridden by providing a digitsInfo string as a second parameter.
   */
  transform(value: number, digitsInfo?: string, ...args: unknown[]): unknown {
    const digitsInfoToUse = digitsInfo ?? (this.countDecimals(value) === 0 ? '1.0-0' : '1.2-2');
    return this.decimalPipe.transform(value, digitsInfoToUse);
  }

  private countDecimals(value: number) {
    if (Math.floor(value) === value) {
      return 0;
    }
    return value.toString().split('.')[1].length || 0;
  }
}
