<app-application-step
  class="c-social-security-number"
  [hidden]="hidden"
  [stepDirection]="stepDirection$ | async"
  [applicationStep]="applicationStepData.applicationStep"
>
  <div class="step-content">
    <app-dynamic-form
      [form]="form"
      [formConfig]="applicationStepData.applicationStep.formConfig"
      [questionOptions]="applicationStepData.applicationQuestionOptions"
      [errorValidator]="errorValidator"
    ></app-dynamic-form>

    <app-ssn-details></app-ssn-details>

    <p class="c-social-security-number__disclosure">
      *If approved, your card will arrive in
      <span>{{ (coreData$ | async).cardDeliveryTimeFrameDays }}</span>
      business days. By using your card, you agree to the Terms and Conditions included in the Cardmember Agreement.
    </p>

    <app-form-step-controls
      [isLoading]="form.pending || isSubmitting"
      [formValidStatus]="form.valid"
      [isDisabled]="stepChangeStatus"
      (stepAction)="onStepAction($event)"
      *ngIf="applicationStepData.applicationStepIndicators as applicationStepIndicators"
    ></app-form-step-controls>
  </div>
</app-application-step>
