<div class="c-timeout">
  <h1 class="c-timeout__header--title">Sorry, this is taking longer than usual.</h1>
  <h2 class="c-timeout__header--subtitle">
    <p>
      We’re sorry, it’s taking a little longer than usual to process your application. However, we have saved your
      information and will provide an update to your email address soon.
    </p>
    <p>If you have any questions, please contact us at 1-833-707-1226.</p>
  </h2>
</div>
