// vendor
import { Component, Input, AfterViewInit } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
// consts
const EXPANSION_PANEL_ANIMATION_TIMING = '425ms cubic-bezier(0.4,0.0,0.2,1)';

@Component({
  selector: 'app-form-help-text',
  templateUrl: './form-help-text.component.html',
  styleUrls: ['./form-help-text.component.scss'],
  animations: [],
})
export class FormHelpTextComponent implements AfterViewInit {
  disabledAnimation: boolean;
  @Input() helpText: string;
  @Input() expanded: boolean;

  // TODO_UPDATE: temp dirty override to test altering animation, review simpler ways
  constructor() {
    const definitions = matExpansionAnimations.bodyExpansion.definitions;
    const animation = definitions.find((definition) => definition.type === 1)['animation'];
    animation.timings = EXPANSION_PANEL_ANIMATION_TIMING;
  }

  ngAfterViewInit() {
    setTimeout(() => (this.disabledAnimation = false));
  }
}
