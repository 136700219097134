// vendor
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, withLatestFrom, map } from 'rxjs/operators';
// ngrx
import { Store, select } from '@ngrx/store';
import * as fromApplicationStore from '../../../application/store';
import * as fromDigitalExperienceStore from '../../../digital-experience/store';
// services
import { WebSocketService } from 'src/app/features/shared/services';
// models
import { ApplicationStepData } from '../../../digital-experience/models';

@Component({
  selector: 'app-application-stepper',
  templateUrl: './application-stepper.component.html',
  styleUrls: ['./application-stepper.component.scss'],
})
export class ApplicationStepperComponent implements OnInit {
  applicationProgress$: Observable<number>;
  applicationStepData$: Observable<ApplicationStepData>;
  @Input() affiliateLandingStep: string;
  @Input() stepName$: Observable<string>;

  constructor(
    private webSocketService: WebSocketService,
    private store: Store<fromDigitalExperienceStore.DigitalExperienceState>
  ) {}

  ngOnInit() {
    this.applicationProgress$ = this.store.pipe(select(fromDigitalExperienceStore.selectDigitalExperienceProgress));
    this.applicationStepData$ = this.store.pipe(
      select(fromDigitalExperienceStore.selectDigitalExperienceCurrentApplicationStepData),
      filter(Boolean),
      distinctUntilChanged(
        (prev: ApplicationStepData, curr: ApplicationStepData) => prev.applicationStep === curr.applicationStep
      ),
      withLatestFrom(this.store.pipe(select(fromApplicationStore.selectOptionsEntities))),
      map(([applicationStepData, applicationQuestionOptions]) => ({
        ...applicationStepData,
        applicationQuestionOptions,
      }))
    );

    /*
     Initialize websocket connection
     TODO_UPDATE: might be handled in an NgRx effect.
     this.connectWebSocket();
    */
  }

  /**
   * Testing websocket
   */
  connectWebSocket() {
    // TODO_UPDATE: might be handled in an NgRx effect.
    this.webSocketService.connect().subscribe((res) => {
      console.log('subscribing to connection$, res', res);
    });
  }
}
