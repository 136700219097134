import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
// ngrx
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromApplicationActions from '../actions';
// services
import { ApplicationService } from '../../services';
// models

@Injectable({
  providedIn: 'root',
})
export class TokenizedCidEffects {
  constructor(private action$: Actions, private applicationService: ApplicationService) {}

  getAuthUserToken$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.getAuthUserToken),
      switchMap((action) =>
        this.applicationService.getAuthUserToken(action.applicationId).pipe(
          switchMap((res) => {
            const returnedActions = [];
            returnedActions.push(fromApplicationActions.loadAuthUserTokenSuccess({ tokenizedCid: res?.tokenizedCid }));
            return returnedActions;
          }),
          catchError((error: Record<string, any>) => {
            console.error(`[GET_TOKENIZED_CID] Failed to get tokenizedCid: ${error.message}`);
            // Fail gracefully to the current BAU(business as usual)
            return of(fromApplicationActions.loadAuthUserTokenSuccess({ tokenizedCid: null }));
          })
        )
      )
    )
  );
}
