// models
import * as fromQuestionsModels from '../../models';

export const autopayEnrollmentQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.RadioGroup,
  name: fromQuestionsModels.QuestionNameTypes.AutopayEnrollment,
  options: [
    {
      label: 'Yes, I will Enroll in Autopay',
      sublabel:
        'If approved, I agree to create an online account and set up automatic monthly payments for my Best Egg Credit Card.',
      value: true,
    },
    {
      label: 'No, thanks',
      sublabel: "I'd prefer not to enroll in autopay.",
      value: false,
    },
  ],
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select enrollment option',
      },
    ],
  },
};
