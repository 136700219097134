// vendor
import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-print-page-button',
  templateUrl: './print-page-button.component.html',
  styleUrls: ['./print-page-button.component.scss'],
})
export class PrintPageButtonComponent implements OnDestroy {
  iframe: HTMLIFrameElement;
  @Input() sectionId: string;
  @Input() contentEl: HTMLElement;
  @Input() link: false;
  /**
   * FYI: The styles that we declare in our app aren't accessible from inside the iframe w/o further intervention.
   * Carrying over some styles that can be used in the iframe that's generated so it more closely resembles the
   * web rendition.
   */
  private styles = `
    /* general */
    .ft-sz--small { font-size: 14px; }

    .ft-sz--large { font-size: 18px; }


    /* terms-and-conditions */
    #default-application-terms,
    .default-product-terms tr { font-size: 12px; }

    .promo-label { display: block; padding-bottom: 4px; }

    /* adverse-action */
    /* (None needed for now) */


    /* esign-consent-dialog */
    /* (None needed for now) */
  `;

  ngOnDestroy() {
    // IE does not support el.remove
    if (this.iframe) {
      const { parentNode } = this.iframe;
      parentNode.removeChild(this.iframe);
    }
  }

  /**
   * on print window method
   * Notes: mobile blocks window.open() as pop up?
   * requires in template onclick="window.open('', '_blank')"
   */
  onPrintAlt(): void {
    const printWindow = window.open('');
    const { document: printDocument } = printWindow;
    printDocument.write(this.contentEl.innerHTML);
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  /**
   * on print (iframe)
   */
  onPrint(): void {
    if (!this.iframe) {
      this.iframe = document.createElement('iframe');
      this.iframe.setAttribute('id', 'temp-print-iframe');
      this.iframe.style.display = 'none';
      document.body.appendChild(this.iframe);
    }
    // TODO_UPDATE: temp light general styling until look into including styles
    const styleEl = document.createElement('style');
    styleEl.textContent = this.styles;
    const { document: iDocument } = this.iframe.contentWindow;
    iDocument.open();
    iDocument.write(this.contentEl.innerHTML);
    iDocument.close();
    iDocument.head.appendChild(styleEl);
    this.printIframeContent();
  }

  printIframeContent() {
    const { contentWindow } = this.iframe;
    // exec command for IE compatibility
    const result = contentWindow.document.execCommand('print', false, null);
    if (!result) {
      contentWindow.focus();
      contentWindow.print();
    }
  }
}
