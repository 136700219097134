// vendor
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationsConsentDialogComponent } from 'src/app/features/shared/components/dialogs';
import { helpText } from 'src/app/features/shared/configs/help-text.config';
import * as _ from 'lodash-es';
// models
import { formSections } from '../individual-steps/confirm-info/confirm-info.component';

@Component({
  selector: 'app-confirm-info-card',
  templateUrl: './confirm-info-card.component.html',
  styleUrls: ['./confirm-info-card.component.scss'],
})
export class ConfirmInfoCardComponent {
  lodash = _;
  @Input() displayData: Record<string, any>;
  @Input() sectionName: string;
  @Output() edit: EventEmitter<string> = new EventEmitter<string>();
  public get phoneHelpText() {
    return helpText.phoneHelpText;
  }
  formSection: typeof formSections = formSections;

  constructor(private dialog: MatDialog) {}

  onEdit() {
    this.edit.emit(this.sectionName);
  }

  openCommunicationsConsentDialog() {
    this.dialog.open(CommunicationsConsentDialogComponent);
  }

  transformEmploymentStatus(employmentStatus: string) {
    return _.capitalize(employmentStatus).replace(/_/g, ' ');
  }
}
