// models
import * as fromQuestionsModels from '../../models';

export const bankingQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.CheckboxGroup,
  name: fromQuestionsModels.QuestionNameTypes.Banking,
  label: 'Do you have any bank accounts?',
  sublabel: 'Please select all that apply.',
  multiSelect: true,
  optionType: fromQuestionsModels.QuestionOptionTypes.BankingAccounts,
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.Required,
        message: 'Please select bank accounts',
      },
    ],
  },
};
