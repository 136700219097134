// vendor
import { Directive, Input, OnInit, ViewContainerRef, ComponentRef } from '@angular/core';
// components
import * as fromFormHelpTextComponents from '../components/forms/form-help-texts';
// models
import { HelpTextTypes } from '../models';
import { Map } from '../../shared/models';

@Directive({
  selector: '[dynamicFormHelpText]',
})
export class DynamicFormHelpTextDirective implements OnInit {
  componentMap: Map<any>;
  component: ComponentRef<any>;
  @Input() helpText: string;

  constructor(private container: ViewContainerRef) {
    this.componentMap = {
      [HelpTextTypes.AnnualIncome]: fromFormHelpTextComponents.AnnualIncomeHelpTextComponent,
      [HelpTextTypes.CardName]: fromFormHelpTextComponents.CardNameHelpTextComponent,
      [HelpTextTypes.Phone]: fromFormHelpTextComponents.PhoneHelpTextComponent,
      [HelpTextTypes.ResidencyRequirement]: fromFormHelpTextComponents.ResidencyRequirementHelpTextComponent,
    };
  }

  ngOnInit() {
    const component = this.componentMap[this.helpText];
    this.container.clear();
    this.component = this.container.createComponent(component);
  }
}
