// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { Map } from '../../../shared/models';

export enum QuestionsActionTypes {
  UpdateQuestionValues = '[Questions] Update Question Values',
  ResetQuestionValues = '[Questions] Reset Question Values',
  ClearQuestionValues = '[Question] Clear Question Values',
}

// update question values
export const updateQuestionValues = createAction(
  QuestionsActionTypes.UpdateQuestionValues,
  props<{ questionValues: Record<string, any> }>()
);

// reset question values
export const resetQuestionValues = createAction(
  QuestionsActionTypes.ResetQuestionValues,
  props<{ questionNames: string[] }>()
);

// clear question values
export const clearQuestionValues = createAction(QuestionsActionTypes.ClearQuestionValues);

export type QuestionsAction = ReturnType<
  typeof updateQuestionValues | typeof resetQuestionValues | typeof clearQuestionValues
>;
