/**
 * Iovation is a third-party service used for fraud detection.
 *
 * Its scripts generate a 'black box value', which we need to collect for
 * any user in the process of applying.
 *
 * From the Iovation documentation:
    Implementing the getBlackbox Collection Method
    The last method for obtaining a blackbox is to use the getBlackbox function. This function returns an object
    that contains the current value of the blackbox along with a flag indicating whether the collection process has
    completed. This method is useful when the value is needed after the collection process has completed. It is
    also useful as a way to obtain the best value after some maximum amount of time to avoid any further delays in
    the user experience.
 */

interface getBlackboxResponse {
  blackbox: string;
  finished: boolean;
}

export const getIovationBlackboxValues = (window): string => {
  let blackboxResponse: getBlackboxResponse;

  try {
    blackboxResponse = window.IGLOO.getBlackbox();
    return blackboxResponse.blackbox;
  } catch (err) {
    return '';
  }
};
