// ngrx
import { createSelector } from '@ngrx/store';
import { Lead } from '../../models';
import * as fromReducers from '../reducers';
import * as fromLeadReducer from '../reducers/lead.reducer';

// lead state
export const selectLeadState = fromReducers.selectLeadFeature;

// data
export const selectLeadData = createSelector(selectLeadState, fromLeadReducer.selectLeadStateData);

export const selectLeadLoading = createSelector(selectLeadState, fromLeadReducer.selectLeadStateLoading);

export const selectLeadLoaded = createSelector(selectLeadState, fromLeadReducer.selectLeadStateLoaded);

export const selectLeadErrorData = createSelector(selectLeadState, fromLeadReducer.selectLeadStateError);

export const selectLeadCasData = createSelector(selectLeadState, fromLeadReducer.selectLeadStateCasData);

export const selectLeadOdlData = createSelector(selectLeadState, fromLeadReducer.selectLeadStateOdlData);

export const selectLeadPrimaryApplicant = createSelector(selectLeadData, (lead: Lead) => lead?.primaryApplicant);
