// vendor
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
// store
import * as fromCardFunnelSessionsReducer from './funnel-sessions.reducer';

// key
export const SESSIONS_FEATURE_KEY = 'sessions';

export interface FunnelSessionsState {
  [id: string]: fromCardFunnelSessionsReducer.FunnelSessionsState;
}

export const reducers: ActionReducerMap<FunnelSessionsState> = {
  [fromCardFunnelSessionsReducer.FUNNEL_SESSIONS_STATE_KEY]: fromCardFunnelSessionsReducer.reducer,
};

export const selectFunnelSessions = createFeatureSelector(SESSIONS_FEATURE_KEY);
