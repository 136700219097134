export enum FullstoryEventTypes {
  ApplicationStepView = '[Card] Application Step View',
  DecisionView = '[Card] Decision View',
  DashboardView = '[Card] Dashboard View',
  MaintenanceView = '[Card] Maintenance View',
  AdaChatbotOpened = '[Card] Ada Chatbot Opened',
  BEFHAccountCreationStart = '[Card] BEFH Account Creation Start',
  BEFHBannerShown = '[Card] BEFH Banner Shown',
  AbTestData = '[Card] A/B Test Data',
  ESignConsentView = '[Card] E-Sign Consent View',
}

export enum FullstoryLogLevels {
  LOG = 'log',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}
