// vendor
import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { switchMap, withLatestFrom, filter, first } from 'rxjs/operators';
// store
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromDigitalExperienceActions from '../actions/digital-experience.actions';
import * as fromDigitalExperienceSelector from '../selectors/digital-experience.selectors';
import * as fromAppStore from '../../../../store';
// services
import { DigitalExperienceService, offerTypeToExperienceMapKeysLowerCase } from '../../services';
import { DigitalExperienceUpdatesService } from '../../services/digital-experience-updates.service';
// models
import { ApplicationStep, ApplicationStepNameTypes, DigitalExperienceTypes } from '../../models';
// configs
import { digitalExperienceConfigMap } from '../../configs';
import { noop, selectRouterQueryParams } from 'src/app/store';
import { Action, select, Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { selectDigitalExperienceFeature } from '../selectors';
import { selectAbTestsFeature } from '../../../ab-tests/store/selectors';
import { AbTestsState } from '../../../ab-tests/store';
import { DigitalExperienceState } from '../reducers';
import { AffiliateParams, BestEggOfferTypes, LightboxOfferTypes } from 'src/app/features/lead/models';
import { MAIN_URL_SEGMENT, UNAVAILABLE_URL_SEGMENT } from 'src/app/features/main/configs';
import { LeadState, clearLeadExtraData, selectLeadState } from 'src/app/features/lead/store';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DigitalExperienceEffects {
  constructor(
    private digitalExperienceService: DigitalExperienceService,
    private digitalExperienceUpdatesService: DigitalExperienceUpdatesService,
    private action$: Actions,
    private store$: Store
  ) {}

  // load digital experience
  loadDigitalExperience$ = createEffect(() =>
    this.action$
      .pipe(
        ofType(fromDigitalExperienceActions.loadDigitalExperience),
        switchMap((_) => this.store$.pipe(select(fromDigitalExperienceSelector.selectDigitalExperienceName)))
      )
      .pipe(
        filter(Boolean),
        switchMap((name: string) => {
          const digitalExperienceConfig = digitalExperienceConfigMap[name];
          if (!digitalExperienceConfig) {
            return [
              fromDigitalExperienceActions.loadDigitalExperienceFail({
                error: { message: 'failed to get digital experience name, please check query param' },
              }),
            ];
          }
          const stepComponentMap = digitalExperienceConfig.stepComponentMap;
          const applicationSteps = this.sortApplicationSteps(digitalExperienceConfig.applicationSteps);
          return [
            // set the app steps
            fromDigitalExperienceActions.setDigitalExperienceAppSteps({
              applicationSteps,
            }),
            // set the success state
            fromDigitalExperienceActions.loadDigitalExperienceSuccess({
              stepComponentMap,
            }),
          ];
        })
      )
  );

  setStepAndUpdateStepsAllowed$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromDigitalExperienceActions.setStepAndUpdateStepsAllowed),
      withLatestFrom(
        this.store$.select(fromDigitalExperienceSelector.selectDigitalExperienceCurrentStep),
        this.store$.pipe(select(selectAbTestsFeature)),
        this.store$.pipe(select(fromDigitalExperienceSelector.selectDigitalExperienceName))
      ),
      switchMap(([action, currentStepName, abTestState, digitalExperienceName]) => {
        const returnActions: Action[] = [];
        let targetStepName: ApplicationStepNameTypes;
        const stepAllowed = {
          stepsOn: [],
          stepsOff: [],
        };
        const { data: abTestData } = abTestState;

        // Action dispatched when user perform actions on verifyIdentity step (Not xxx? button click, verify success or failure)
        // determine next step & steps to be allowed
        if (currentStepName === ApplicationStepNameTypes.VerifyIdentity) {
          targetStepName = this.digitalExperienceUpdatesService.setStepsSettingForVerifyIdentity(
            stepAllowed,
            abTestData,
            action.metaData,
            digitalExperienceName
          );
          // We should clear all extra data (odl, cas, express lane) if the user does not
          // pass or opt out on the verify identity step
          if (action.metaData?.actionSubType === 'verifyFail') {
            returnActions.push(clearLeadExtraData());
          }
        }

        // Action dispatched when continue button is clicked, change completed question steps to be not allowed (banking, housing,
        // residential length and personal info)
        if (currentStepName === ApplicationStepNameTypes.ConfirmInfo) {
          this.digitalExperienceUpdatesService.setStepsSettingForConfirmInformation(stepAllowed);
        }

        returnActions.push(
          fromDigitalExperienceActions.updateStepsAllowed({
            payload: stepAllowed,
          })
        );

        if (!action.skipStepsChange && targetStepName) {
          returnActions.push(
            fromDigitalExperienceActions.setApplicationStep({
              payload: {
                type: 'explicit',
                stepName: targetStepName,
              },
            })
          );
        }
        return returnActions;
      })
    )
  );

  /**
   * Grab the a/b test data from the store and set it to the digital experience store.
   * But wait until we know that both the initial state of the digital experience store exists,
   * and a/b test data in the a/b test store has been updated before proceeding.
   */
  updateDigitalExperience$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromDigitalExperienceActions.updateDigitalExperience),
      switchMap((action: typeof fromDigitalExperienceActions.updateDigitalExperience) =>
        forkJoin([
          of(action),
          this.store$.pipe(
            select(selectDigitalExperienceFeature),
            filter((state: DigitalExperienceState) => state.loaded),
            first()
          ),
          this.store$.pipe(
            select(selectAbTestsFeature),
            filter((state: AbTestsState) => state.loaded),
            first()
          ),
          this.store$.pipe(
            select(selectLeadState),
            filter((state: LeadState) => state.loaded),
            first()
          ),
          this.store$.pipe(select(selectRouterQueryParams), first()),
        ])
      ),
      switchMap(
        ([_action, digitalExperienceState, abTestState, lead, queryParams]: [
          typeof fromDigitalExperienceActions.updateDigitalExperience,
          DigitalExperienceState,
          AbTestsState,
          LeadState,
          Params
        ]) => {
          const { data: abTestData } = abTestState;
          /**
           * In local environments, allow starting step to be explicity set via `s` query parameter.
           * Note that the setApplicationStep reducer won't set this step if it isn't detected as an allowed step, just as an
           * extra safeguard in case it's a junk value (otherwise the page will break).
           */
          let forcedStartingStep;
          if (environment.env === 'local' && queryParams.s) {
            forcedStartingStep = queryParams.s;
          }
          /**
           * We can create other service methods (like getStepChangesFromAbTests) that handle more complex logic, which would return
           * multiple (synchronous) actions that would also update parts of the digital experience directly rather than just set the a/b
           * test data.
           * The last action dispatched would always have to be updateDigitalExperienceSuccess though.
           * This is an action that would be added to our application process watchers,
           * since we want to make sure that the digital experience is completely up-to-date before removing
           * the initial loading state and displaying the page.
           */

          const stepsContentChangesCombined = [
            ...this.digitalExperienceUpdatesService.getStepContentChanges(
              digitalExperienceState.name,
              abTestData,
              lead
            ),
          ];

          const stepsAllowed = this.digitalExperienceUpdatesService.getStepsAllowedChanges(
            digitalExperienceState.name,
            abTestData,
            lead,
            queryParams
          );

          return [
            fromDigitalExperienceActions.updateStepsAllowed({
              payload: stepsAllowed,
            }),
            fromDigitalExperienceActions.updateStepsContent({
              payload: stepsContentChangesCombined,
            }),
            fromDigitalExperienceActions.setApplicationStep({
              payload: {
                type: 'explicit',
                stepName: forcedStartingStep,
                forceFirstStep: true,
              },
            }),
            fromDigitalExperienceActions.updateDigitalExperienceSuccess(),
          ];
        }
      )
    )
  );

  setDigitalExperienceName$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromDigitalExperienceActions.setDigitalExperienceName),
      withLatestFrom(this.store$.pipe(select(fromAppStore.selectRouterState))),
      switchMap(([action, routerState]) => {
        let returnAction: Action;
        const { leadRes } = action;
        const params = routerState.state.queryParams;

        if (this.digitalExperienceService.isOpportunityRequest(leadRes)) {
          returnAction = fromDigitalExperienceActions.setDigitalExperienceNameSuccess({
            name: offerTypeToExperienceMapKeysLowerCase[DigitalExperienceTypes.OpportunityMicrofunnel],
          });
        } else if (this.digitalExperienceService.isKcfRequest(leadRes)) {
          returnAction = fromDigitalExperienceActions.setDigitalExperienceNameSuccess({
            name: offerTypeToExperienceMapKeysLowerCase[DigitalExperienceTypes.BestEggKcf],
          });
        } else if (this.digitalExperienceService.isProspectRequest(params, leadRes)) {
          returnAction = fromDigitalExperienceActions.setDigitalExperienceNameSuccess({
            name: offerTypeToExperienceMapKeysLowerCase[DigitalExperienceTypes.BestEggProspect],
          });
        } else if (this.digitalExperienceService.isWalkupRequest(params)) {
          const walkupExperience = params.emailAddress
            ? DigitalExperienceTypes.BestEggWalkupNoEmail
            : DigitalExperienceTypes.BestEggWalkupEmail;

          returnAction = fromDigitalExperienceActions.setDigitalExperienceNameSuccess({
            name: offerTypeToExperienceMapKeysLowerCase[walkupExperience],
          });
        } else if (this.digitalExperienceService.isAffiliateRequest(params)) {
          /**
           * If there is no offerCode and there is an offerType that is one of those expected
           * then this is an affiliate experience. A small attempt to prevent random users from stumbling
           * onto what is in essence a Walk Up experience.
           * Also note that the postWalkupLead endpoint requires an offerType.
           * Find the corresponding experience based on the offertype.
           * */
          const offerTypeLowerCase = (params[AffiliateParams.offerType] || '').toLowerCase();
          let digitalExperienceName: DigitalExperienceTypes;
          if (
            /**
             * It's either offertype maps to one of the mapped experiences but not LBEXB/ LBOSB
             * OR offertype maps to LBEXB/ LBOSB and it's a valid lightbox request
             * */
            (offerTypeToExperienceMapKeysLowerCase[offerTypeLowerCase] &&
              ![LightboxOfferTypes.LBEXB, LightboxOfferTypes.LBOSB].includes(offerTypeLowerCase)) ||
            ([LightboxOfferTypes.LBEXB, LightboxOfferTypes.LBOSB].includes(offerTypeLowerCase) &&
              this.digitalExperienceService.isValidLightboxRequest(params))
          ) {
            digitalExperienceName = offerTypeToExperienceMapKeysLowerCase[offerTypeLowerCase];
          } else {
            digitalExperienceName = offerTypeToExperienceMapKeysLowerCase[BestEggOfferTypes.WalkupEmail];
          }
          returnAction = fromDigitalExperienceActions.setDigitalExperienceNameSuccess({
            name: digitalExperienceName,
          });
        } else {
          returnAction = noop();
          console.error('[DIGITAL_EXPERIENCE_EFFECT]', 'invalid param value, fail to set digital experience name');
          this.store$.dispatch(
            fromAppStore.go({
              path: [MAIN_URL_SEGMENT, UNAVAILABLE_URL_SEGMENT],
              extras: {
                skipLocationChange: true,
              },
            })
          );
        }
        return [returnAction];
      })
    )
  );
  /**
   * sort application steps
   * @param applicationSteps
   */
  sortApplicationSteps(applicationSteps: ApplicationStep[]): ApplicationStep[] {
    // copy array, to avoid mutation of redux immutable state on rerun instances
    return [...applicationSteps].sort((stepA: ApplicationStep, stepB: ApplicationStep) => stepA.order - stepB.order);
  }
}
