// models
import * as fromQuestionsModels from '../../models';

export const agreementQuestion: fromQuestionsModels.Question = {
  formElType: fromQuestionsModels.FormElTypes.Checkbox,
  inputElType: fromQuestionsModels.InputElTypes.Checkbox,
  name: fromQuestionsModels.QuestionNameTypes.Agreement,
  label: 'E-Sign Act Consent',
  sublabel: 'You will receive all disclosures and other documents from Best Egg electronically.',
  validators: {
    sync: [
      {
        validatorName: fromQuestionsModels.ValidatorTypes.RequiredTrue,
        message: 'You must agree in order to proceed',
      },
    ],
  },
};
