export enum DialogTypes {
  Communications = 'communications',
  CreditScoreNotice = 'creditScoreNotice',
  DocUpload = 'docUpload',
  Error = 'error',
  Idle = 'idle',
}

// move to explicit naming
export enum DateTypes {
  MMDDYYYY = 'MMDDYYYY',
  ISODate = 'YYYY-MM-DD',
  ISODateNoLeading = 'YYYY-M-D',
  ShortDate = 'MM/DD/YYYY',
  ShortDateNoLeading = 'M/D/YYYY',
  MediumDate = 'MMM D, YYYY',
  LongDate = 'MMMM D, YYYY',
  InvalidDate = 'Invalid Date',
}

export enum IconTypes {
  DollarSign = 'dollarSign',
  CheckMark = 'checkMark',
}
