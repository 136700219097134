<div class="c-auth-account-create__control-bar">
  <ng-content select=".control-bar"> </ng-content>
</div>

<ng-container *ngIf="!authSignUpComponentOutput?.isAuthSignUp; else authSignUpSection">
  <div class="c-auth-account-create">
    <div class="c-auth-account-create__header">
      <div class="c-auth-account-create__header-icon">
        <ng-content select=".header-icon"></ng-content>
      </div>
      <p class="c-auth-account-create__header-title">
        <ng-content select=".header-title"></ng-content>
      </p>
      <p class="c-auth-account-create__header-subtitle">
        <ng-content select=".header-subtitle"></ng-content>
      </p>
    </div>
    <mfc-auth-sign-up
      class="c-auth-account-create__body-content"
      btnText="Get started"
      [authSignUpRequestPayload]="authSignUpComponentInput"
      (authSignUpResponse)="getAuthSignUpResponse($event)"
      [isDisplayingMaybeLater]="isDisplayingMaybeLater"
      (onMaybeLaterClick)="clickMaybeLater()"
    >
    </mfc-auth-sign-up>
    <ng-container *ngTemplateOutlet="hintsAndErrors"></ng-container>
  </div>
</ng-container>

<ng-template #authSignUpSection>
  <div class="c-auth-account-create" id="c-auth-account-create__verify-code">
    <div class="c-auth-account-create__header">
      <p class="c-auth-account-create__header-title">Enter verification code</p>
      <p class="c-auth-account-create__header-subtitle">
        Enter the verification code, we just sent to you so we know it's you.
      </p>
    </div>

    <mfc-auth-otp-confirm
      class="c-auth-account-create__body-content"
      btnText="Continue"
      [authOtpConfirmRequestPayload]="authOtpConfirmRequestPayload"
      (authOtpConfirmResponse)="getAuthOtpConfirmResponse($event)"
      (confirmationCodeResendResponse)="getConfirmationCodeResendResponse($event)"
      [isDisplayingMaybeLater]="isDisplayingMaybeLater"
      (onMaybeLaterClick)="clickMaybeLater()"
    >
    </mfc-auth-otp-confirm>
    <ng-container *ngTemplateOutlet="hintsAndErrors"></ng-container>
  </div>
</ng-template>

<ng-template #hintsAndErrors>
  <app-text-bubble [designType]="TextBubbleTypes.NOTIFICATION" *ngIf="codeResentMessage">
    <div class="content">
      {{ codeResentMessage }}
    </div>
  </app-text-bubble>
  <span class="c-auth-account-create__error-message" *ngIf="errorMessage">{{ errorMessage }}</span>
</ng-template>

<div class="c-auth-account-create__footer" *ngIf="showFooter">
  <p class="c-auth-account-create__disclosure">
    <ng-content select=".disclosure"> </ng-content>
  </p>
  <p class="c-auth-account-create__feedback-message">Questions?</p>
  <p class="c-auth-account-create__feedback-contact">
    Give us a call at <a mat-button href="tel:855-282-6353" class="button-link-style"> 855-282-6353</a>.
  </p>
</div>
