// vendor
import { Component, Output, EventEmitter, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import * as fromDigitalExperienceStore from '../../../../digital-experience/store';
import * as fromLeadStore from '../../../../lead/store';
import * as fromABTestStore from '../../../../ab-tests/store';
// models
import { ButtonNameTypes, IButton } from '../../../models';
import { Map } from '../../../../shared/models';
// configs
import { buttonMap } from '../../../configs';
import { select, Store } from '@ngrx/store';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApplicationStepNameTypes, DigitalExperienceTypes } from 'src/app/features/digital-experience/models';
import { WindowUtilService } from 'src/app/features/shared/services/window-util.service';
import { validate as uuidValidate } from 'uuid';
import { ButtonPopPopulations, IAbTestData } from 'src/app/features/ab-tests/models';

@Component({
  selector: 'app-form-step-controls',
  templateUrl: './form-step-controls.component.html',
  styleUrls: ['./form-step-controls.component.scss'],
})
export class FormStepControlsComponent implements OnDestroy, OnInit, AfterViewInit {
  buttonMap: Map<IButton>;
  buttonNameTypes: any;
  isFirstStep: boolean;
  isLastStep: boolean;
  ngUnsubscribe$ = new Subject<boolean>();
  abTestData: IAbTestData;
  digitalExperienceName: string;
  validLeadIdOnSubmit = false;
  isOdlMicroFunnelApplyStep$ = this.store.pipe(
    select(fromDigitalExperienceStore.selectDigitalExperienceCurrentStep),
    filter((stepName) => stepName === ApplicationStepNameTypes.OpportunityMicrofunnel),
    first()
  );
  @Input() formValidStatus: boolean;
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Output() stepAction: EventEmitter<string>;
  get btnNext() {
    return buttonMap[ButtonNameTypes.BtnNext];
  }
  get btnSubmit() {
    return buttonMap[ButtonNameTypes.BtnSubmit];
  }
  get btnBack() {
    return buttonMap[ButtonNameTypes.BtnBack];
  }
  get btnDisabled() {
    return buttonMap[ButtonNameTypes.BtnDisabled];
  }

  get btnLink() {
    return buttonMap[ButtonNameTypes.BtnLink];
  }

  constructor(private store: Store, private windowUtilService: WindowUtilService) {
    this.buttonMap = buttonMap;
    this.buttonNameTypes = ButtonNameTypes;
    this.stepAction = new EventEmitter();
    this.store
      .pipe(select(fromDigitalExperienceStore.selectDigitalExperienceName), first())
      .subscribe((digitalExperienceName) => {
        this.digitalExperienceName = digitalExperienceName;
      });
    this.store
      .select(fromABTestStore.selectAbTestLoaded)
      .pipe(filter(Boolean), withLatestFrom(this.store.select(fromABTestStore.selectAbTestsData)), first())
      .subscribe(([_, abTestData]) => (this.abTestData = abTestData));
  }

  ngAfterViewInit(): void {
    this.store
      .pipe(
        select(fromLeadStore.selectLeadData),
        filter((lead) => !!lead.id),
        map((lead) => lead.id),
        first()
      )
      .subscribe((leadId) => {
        if (this.isLastStep) {
          if (uuidValidate(leadId)) {
            this.validLeadIdOnSubmit = true;
          } else {
            console.warn('Missing essential lead id.');
          }
        }
      });
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromDigitalExperienceStore.selectDigitalExperienceStepIndicators), takeUntil(this.ngUnsubscribe$))
      .subscribe((stepIndicator) => {
        this.isFirstStep = stepIndicator.isFirstStep;
        this.isLastStep = stepIndicator.isLastStep;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next(true);
  }

  /**
   * get button design
   */
  getButtonDesign(): string {
    let btnDesign = this.btnDisabled.design;
    if (!this.formValidStatus && this.digitalExperienceName === DigitalExperienceTypes.BestEggKcf) {
      btnDesign =
        this.abTestData.buttonPop.population === ButtonPopPopulations.ENABLEDBUTTON
          ? this.btnNext.design
          : this.btnDisabled.design;
    } else if (this.formValidStatus) {
      if (this.validLeadIdOnSubmit) {
        btnDesign = this.btnSubmit.design;
      } else if (!this.isLastStep) {
        btnDesign = this.btnNext.design;
      }
    }
    return btnDesign;
  }

  /**
   * on click
   */
  onStepAction(stepActionType: string): void {
    if (!this.isDisabled) {
      if (this.validLeadIdOnSubmit || !this.isLastStep) {
        this.stepAction.emit(stepActionType);
      }
    }
  }

  optOutOdl() {
    this.windowUtilService.nativeWindow.close();
  }
}
