<!-- The current step component will load in this directive: -->
<div class="c-application-stepper">
  <div
    class="c-application-stepper__progress-bar-container"
    *ngIf="affiliateLandingStep !== (stepName$ | async) && (applicationProgress$ | async) as applicationProgress"
  >
    <mat-progress-bar
      aria-hidden="true"
      class="mat-progress-bar"
      [class.mat-progress-bar--last-step]="(applicationStepData$ | async)?.applicationStepIndicators.isLastStep"
      mode="determinate"
      [value]="applicationProgress"
    ></mat-progress-bar>
    <p id="progress-bar-sr" class="cdk-visually-hidden">
      Application {{ applicationProgress | number : '1.0-0' }}% complete.
    </p>
  </div>
  <div class="c-application-stepper__step-content-container">
    <ng-container applicationStep [applicationStepData]="applicationStepData$ | async"></ng-container>
  </div>
</div>
