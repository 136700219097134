// models
import { DigitalExperienceTypes, DigitalExperienceConfig } from '../models';
import { Map } from '../../shared/models';
// configs
import * as fromExperiences from './experiences';

export const digitalExperienceBasePath = 'digitalexperienceservice';

export const digitalExperienceBaseUrl: Map<string> = {
  v1: `${digitalExperienceBasePath}/api/v1/digitalexperience`,
};

export const digitalExperienceConfigMap: Map<DigitalExperienceConfig> = {
  [DigitalExperienceTypes.BestEggProspect]: {
    applicationSteps: fromExperiences.prospectExperience.applicationSteps,
    stepComponentMap: fromExperiences.prospectExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateNoBranding]: {
    applicationSteps: fromExperiences.noBrandingExperience.applicationSteps,
    stepComponentMap: fromExperiences.noBrandingExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateBankrate]: {
    applicationSteps: fromExperiences.bankrateExperience.applicationSteps,
    stepComponentMap: fromExperiences.bankrateExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateCompareCards]: {
    applicationSteps: fromExperiences.compareCardsExperience.applicationSteps,
    stepComponentMap: fromExperiences.compareCardsExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateCreditSoup]: {
    applicationSteps: fromExperiences.creditSoupExperience.applicationSteps,
    stepComponentMap: fromExperiences.creditSoupExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateEvenFinancial]: {
    applicationSteps: fromExperiences.evenfinancialExperience.applicationSteps,
    stepComponentMap: fromExperiences.evenfinancialExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateLendingTree]: {
    applicationSteps: fromExperiences.lendingTreeExperience.applicationSteps,
    stepComponentMap: fromExperiences.lendingTreeExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateLightbox]: {
    applicationSteps: fromExperiences.lightboxExperience.applicationSteps,
    stepComponentMap: fromExperiences.lightboxExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateMonevo]: {
    applicationSteps: fromExperiences.monevoExperience.applicationSteps,
    stepComponentMap: fromExperiences.monevoExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateSupermoney]: {
    applicationSteps: fromExperiences.supermoneyExperience.applicationSteps,
    stepComponentMap: fromExperiences.supermoneyExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.AffiliateCreditKarma]: {
    applicationSteps: fromExperiences.creditKarmaExperience.applicationSteps,
    stepComponentMap: fromExperiences.creditKarmaExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.BestEggWalkupEmail]: {
    applicationSteps: fromExperiences.walkupEmail.applicationSteps,
    stepComponentMap: fromExperiences.walkupEmail.stepComponentMap,
  },
  [DigitalExperienceTypes.BestEggWalkupNoEmail]: {
    applicationSteps: fromExperiences.walkupNoEmail.applicationSteps,
    stepComponentMap: fromExperiences.walkupNoEmail.stepComponentMap,
  },
  [DigitalExperienceTypes.BestEggKcf]: {
    applicationSteps: fromExperiences.kcfExperience.applicationSteps,
    stepComponentMap: fromExperiences.kcfExperience.stepComponentMap,
  },
  [DigitalExperienceTypes.OpportunityMicrofunnel]: {
    applicationSteps: fromExperiences.odlExperience.applicationSteps,
    stepComponentMap: fromExperiences.odlExperience.stepComponentMap,
  },
};
