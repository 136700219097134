import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbTestNames, ABTestPopulationsCombined, AbTestStatuses, AbTestStatusesUpdates, IAbTestData } from '../models';
import { updateAbTestsStatuses } from '../store';
import { ApplicationStepNameTypes, DigitalExperienceTypes } from '../../digital-experience/models';

@Injectable({
  providedIn: 'root',
})
export class AbTestsStatusUpdatesService {
  constructor(private store: Store<any>) {}

  /**
   * Initial A/B test status updates that would occur when the step component first loads.
   * This will run for each step component, and we will make a status update request if there are any status updates we need to run
   * for that step.
   */
  public buildInitialAbTestStatusChangesAndUpdate(
    stepName: string,
    digitalExperienceName: string,
    abTestData: IAbTestData
  ) {
    let abTestStatusesUpdates: AbTestStatusesUpdates = {};
    if (abTestData[AbTestNames.BREAKING_BECC]?.population === ABTestPopulationsCombined.BREAKBECC) {
      if (stepName === ApplicationStepNameTypes.BreakingBECCStep1) {
        abTestStatusesUpdates = {
          ...abTestStatusesUpdates,
          [AbTestNames.BREAKING_BECC]: AbTestStatuses.DISPLAYED,
        };
      } else if (stepName === ApplicationStepNameTypes.BreakingBECCStep2) {
        abTestStatusesUpdates = {
          ...abTestStatusesUpdates,
          [AbTestNames.BREAKING_BECC]: AbTestStatuses.COMPLETED,
        };
      }
    }

    if (abTestData[AbTestNames.BUTTON_POP]?.population === ABTestPopulationsCombined.ENABLEDBUTTON) {
      if (
        [ApplicationStepNameTypes.VerifyIdentity, ApplicationStepNameTypes.ConfirmInfo].includes(
          stepName as ApplicationStepNameTypes
        ) &&
        abTestData.buttonPop.status === AbTestStatuses.ASSIGNED
      ) {
        abTestStatusesUpdates = {
          ...abTestStatusesUpdates,
          [AbTestNames.BUTTON_POP]: AbTestStatuses.DISPLAYED,
        };
      } else if (
        digitalExperienceName !== DigitalExperienceTypes.BestEggKcf &&
        abTestData.buttonPop.status !== AbTestStatuses.DISQUALIFIED
      ) {
        abTestStatusesUpdates = {
          ...abTestStatusesUpdates,
          [AbTestNames.BUTTON_POP]: AbTestStatuses.DISQUALIFIED,
        };
      }
    }

    /** Only make the status update request if we have statuses to update for this step. */
    if (Object.keys(abTestStatusesUpdates).length) {
      this.store.dispatch(
        updateAbTestsStatuses({
          abTestStatusesUpdates,
        })
      );
    }
  }
}
