import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbTestStatusesUpdates } from '../../models';
import { AbTestsService } from '../../services/ab-tests.service';
import {
  overrideAbTests,
  updateAbTestsStatuses,
  updateAbTestsStatusesFail,
  updateAbTestsStatusesSuccess,
} from '../actions';
import { AbTestsState } from '../reducers';
import { selectAbTestsData, selectAbTestsMetaData, selectAbTestsOverride } from '../selectors';

@Injectable({
  providedIn: 'root',
})
export class AbTestsEffects {
  constructor(private abTestsService: AbTestsService, private store: Store<AbTestsState>, private actions$: Actions) {}

  updateAbTestsStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAbTestsStatuses),
      withLatestFrom(
        this.store.pipe(select(selectAbTestsMetaData)),
        this.store.pipe(select(selectAbTestsOverride)),
        this.store.pipe(select(selectAbTestsData))
      ),
      concatMap(([action, metadata, override, abTestData]) => {
        const { abTestStatusesUpdates } = action;
        const { leadId } = metadata;

        if (override && environment.env === 'prod') {
          console.warn('AB test override active! Mocking call to update AB test statuses', abTestStatusesUpdates);
          return of(updateAbTestsStatusesSuccess({ abTestStatusesUpdates }));
        }

        const updatesToMake: AbTestStatusesUpdates = {};

        // Check if AB tests to update were provided in the lead response. We don't want to attempt
        // to update tests that have not yet been configured (CARD-207)
        Object.entries(abTestStatusesUpdates).forEach(([test, status]) => {
          if (abTestData[test].providedInLeadResponse) {
            updatesToMake[test] = status;
          } else {
            console.warn(
              `AB test ${test} is not provided in lead response, and update of status ${status} will be blocked in the request`
            );
          }
        });

        // If we end up not having to make any updates, don't actually call the service. We will
        // still update the store with the correct status, though.
        if (Object.keys(updatesToMake).length === 0) {
          return of(updateAbTestsStatusesSuccess({ abTestStatusesUpdates }));
        }

        return this.abTestsService.updateAbTestStatuses(leadId, updatesToMake).pipe(
          map(() => updateAbTestsStatusesSuccess({ abTestStatusesUpdates })),
          catchError((error: HttpErrorResponse) => of(updateAbTestsStatusesFail()))
        );
      })
    )
  );

  // Simple effect to log a warning if AB test override is in effect
  overrideAbTests$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(overrideAbTests),
        tap(() => {
          console.warn('Overriding AB Test Data! All subsequent calls to update test statuses will be mocked');
        })
      ),
    { dispatch: false }
  );
}
