import { ApplicationStep, ApplicationStepNameTypes, StepComponentMap } from '../../../../models';
import { defaultStepComponentMap } from '../../../shared';
import { QuestionNameTypes } from '../../../../../../features/questions/models';
import {
  baseWalkupInformationApplicationStep,
  baseApplicationSteps,
  breakingBECCStep2,
} from '../../base-walkup/application-steps.config';

const walkupNoEmailQuestionName: string[] = baseWalkupInformationApplicationStep.formConfig.questionNames.filter(
  (questionName: string) => questionName !== QuestionNameTypes.EmailAddress
);

const breakingBECCStep2NoEmailQuestionName: string[] = breakingBECCStep2.formConfig.questionNames.filter(
  (questionName: string) => questionName !== QuestionNameTypes.EmailAddress
);

export const walkupInformationNoEmailApplicationStepFormConfig = {
  ...baseWalkupInformationApplicationStep.formConfig,
  questionNames: walkupNoEmailQuestionName,
  layout: {
    grid: [2, 2, 2, 1, 2],
  },
};

export const breakingBECCStep2NoEmailApplicationStepFormConfig = {
  ...breakingBECCStep2.formConfig,
  questionNames: breakingBECCStep2NoEmailQuestionName,
  name: ApplicationStepNameTypes.BreakingBECCStep2,
  order: 3,
  layout: {
    grid: [1, 2],
  },
};

// #walkup-information
export const walkupInformationApplicationStep: ApplicationStep = {
  ...baseWalkupInformationApplicationStep,
  title: "Let's get started! We just need a little more information about you.",
  supertitle: null,
  formConfig: walkupInformationNoEmailApplicationStepFormConfig,
};

export const breakingBECCStep2ApplicationStep: ApplicationStep = {
  ...breakingBECCStep2,
  title: 'This additional information will help verify your identity.',
  supertitle: null,
  formConfig: breakingBECCStep2NoEmailApplicationStepFormConfig,
};

export const applicationSteps: ApplicationStep[] = [
  walkupInformationApplicationStep,
  breakingBECCStep2ApplicationStep,
  ...baseApplicationSteps.filter(
    (applicationStep: ApplicationStep) => applicationStep.name !== ApplicationStepNameTypes.BreakingBECCStep2
  ),
];

export const stepComponentMap: StepComponentMap = {
  ...defaultStepComponentMap,
};
