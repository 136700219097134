import { createAction, props } from '@ngrx/store';
import { ABTestWeightDiceRollResult } from '../../../../../../fakesl/src/models/generated/leadmanagementservice.model';
import { AbTestStatusesUpdates, IAbTestData, IAbTestsMetadata } from '../../models';

export enum AbTestsActionTypes {
  LoadAbTestsSuccess = '[A/B Tests] Load A/B Tests Success',
  UpdateAbTestsStatuses = '[A/B Tests] Update A/B Tests Statuses',
  UpdateAbTestsStatusesSuccess = '[A/B Tests] Update A/B Tests Statuses Success',
  UpdateAbTestsStatusesFail = '[A/B Tests] Update A/B Tests Statuses Fail',
  ClearAbTests = '[A/B Tests] Clear A/B Tests',
  OverrideAbTests = '[A/B Tests] Override A/B Tests',
}

export const loadAbTestsSuccess = createAction(
  AbTestsActionTypes.LoadAbTestsSuccess,
  props<{
    abTestResponseData: ABTestWeightDiceRollResult[];
    metadata: IAbTestsMetadata;
  }>()
);

export const updateAbTestsStatuses = createAction(
  AbTestsActionTypes.UpdateAbTestsStatuses,
  props<{
    abTestStatusesUpdates: AbTestStatusesUpdates;
  }>()
);

export const updateAbTestsStatusesSuccess = createAction(
  AbTestsActionTypes.UpdateAbTestsStatusesSuccess,
  props<{
    abTestStatusesUpdates: AbTestStatusesUpdates;
  }>()
);

export const updateAbTestsStatusesFail = createAction(AbTestsActionTypes.UpdateAbTestsStatusesFail);

export const clearAbTests = createAction(AbTestsActionTypes.ClearAbTests);

export const overrideAbTests = createAction(
  AbTestsActionTypes.OverrideAbTests,
  props<{
    overrideTestData: IAbTestData;
    metadata: IAbTestsMetadata;
  }>()
);

export type AbTestsAction = ReturnType<
  | typeof loadAbTestsSuccess
  | typeof updateAbTestsStatuses
  | typeof updateAbTestsStatusesSuccess
  | typeof updateAbTestsStatusesFail
  | typeof clearAbTests
  | typeof overrideAbTests
>;
