// import
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// ngrx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromLeadStore from './store';
// modules
import { QuestionsModule } from '../questions/questions.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromLeadStore.LEAD_FEATURE_KEY, fromLeadStore.reducer),
    EffectsModule.forFeature(fromLeadStore.effects),
    QuestionsModule,
  ],
  exports: [],
})
export class LeadModule {}
