// ngrx
import { createAction, props } from '@ngrx/store';
// models
import { CardProductOffer } from '../../models';

export enum CardProductOffersActionTypes {
  LoadCardProductOffer = '[Offers] Load Card Product Offer',
  LoadCardProductDefaultOffer = '[Offers] Load Card Product Default Offer',
  LoadCardProductOfferSuccess = '[Offers] Load Card Product Offer Success',
  LoadCardProductOfferFail = '[Offers] Load Card Product Offer Fail',
  LoadCardProductOffers = '[Offers] Load Card Product Offers',
  LoadCardProductOffersSuccess = '[Offers] Load Card Product Offers Success',
  LoadCardProductOffersFail = '[Offers] Load Card Product Offers Fail',
  ClearCardProductOffer = '[Offers] Clear Card Product Offer',
  ClearCardProductOffers = '[Offers] Clear Card Product Offers',
}

// load card product offer
export const loadCardProductOffer = createAction(
  CardProductOffersActionTypes.LoadCardProductOffer,
  props<{ cardProductOfferId: string }>()
);

export const loadCardProductDefaultOffer = createAction(CardProductOffersActionTypes.LoadCardProductDefaultOffer);

export const loadCardProductOfferSuccess = createAction(
  CardProductOffersActionTypes.LoadCardProductOfferSuccess,
  props<{ cardProductOffer: any }>()
);

export const loadCardProductOfferFail = createAction(
  CardProductOffersActionTypes.LoadCardProductOfferFail,
  props<{ error: Record<string, any> }>()
);

// load card product offers
export const loadCardProductOffers = createAction(
  CardProductOffersActionTypes.LoadCardProductOffers,
  props<{ active: boolean }>()
);

export const loadCardProductOffersSuccess = createAction(
  CardProductOffersActionTypes.LoadCardProductOffersSuccess,
  props<{ cardProductOffers: CardProductOffer[] }>()
);

export const loadCardProductOffersFail = createAction(
  CardProductOffersActionTypes.LoadCardProductOffersFail,
  props<{ error: Record<string, any> }>()
);

// clear product offer(s)
export const clearCardProductOffer = createAction(CardProductOffersActionTypes.ClearCardProductOffer);

export const clearCardProductOffers = createAction(CardProductOffersActionTypes.ClearCardProductOffers);

export type CardProductOffersAction = ReturnType<
  | typeof loadCardProductOffer
  | typeof loadCardProductDefaultOffer
  | typeof loadCardProductOfferSuccess
  | typeof loadCardProductOfferFail
  | typeof loadCardProductOffers
  | typeof loadCardProductOffersSuccess
  | typeof loadCardProductOffersFail
  | typeof clearCardProductOffer
  | typeof clearCardProductOffers
>;
