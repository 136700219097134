// vendor
import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
// ngrx
import { Store, select, Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import * as fromApplicationActions from '../actions';
import * as fromApplicationReducer from '../reducers';
import * as fromApplicationSelectors from '../selectors';
import * as fromABTestStore from '../../../ab-tests/store';
// services
import { ApplicationService } from '../../services';
// models
import { DocumentFile } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class DocumentsEffects {
  constructor(
    private applicationService: ApplicationService,
    private action$: Actions,
    private store: Store<fromApplicationReducer.ApplicationState>
  ) {}

  // load documents
  loadDocuments$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.loadDocuments),
      withLatestFrom(
        this.store.pipe(select(fromApplicationSelectors.selectApplicationData)),
        this.store.pipe(select(fromABTestStore.selectAbTestsData))
      ),
      switchMap(([action, application, abTestData]) => {
        const { id: applicationId } = application;
        return this.applicationService.loadDocuments(applicationId, abTestData).pipe(
          map((documents: DocumentFile[]) => fromApplicationActions.loadDocumentsSuccess({ documents })),
          catchError((error: HttpErrorResponse) => of(fromApplicationActions.loadDocumentsFail({ error })))
        );
      })
    )
  );

  // upload document
  uploadDocument$ = createEffect(() =>
    this.action$.pipe(
      ofType(fromApplicationActions.uploadDocument),
      withLatestFrom(this.store.pipe(select(fromApplicationSelectors.selectApplicationData))),
      switchMap(([action, application]) => {
        const { file } = action;
        const { id: applicationId, applicationDecision } = application;
        const { caseCenterId } = applicationDecision.decisionData;
        return this.applicationService.uploadDocument(applicationId, caseCenterId, file).pipe(
          filter((event) => [HttpEventType.UploadProgress, HttpEventType.Response].includes(event.type)),
          switchMap((event: HttpEvent<any>) => {
            const actions: Action[] = [];
            if (event.type === HttpEventType.UploadProgress) {
              const progress = Math.round((100 * event.loaded) / event.total);
              actions.push(
                fromApplicationActions.uploadDocumentProgress({
                  progress,
                })
              );
            }
            if (event.type === HttpEventType.Response) {
              const { body: documents } = event;
              actions.push(fromApplicationActions.loadDocumentsSuccess({ documents }));
              actions.push(fromApplicationActions.uploadDocumentSuccess());
            }
            return actions;
          }),
          catchError((error: HttpErrorResponse) => of(fromApplicationActions.uploadDocumentFail({ error })))
        );
      })
    )
  );
}
