import { Component, OnInit } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import * as fromQuestionValueStore from '../../../../questions/store';
import { QuestionNameTypes } from 'src/app/features/questions/models';
import { BaseApplicationStepComponent } from '../../base-application-step/base-application-step.component';
import {
  bankingSimpleFormConfig,
  baseWalkupInformationStepFormConfig,
  housingStepFormConfig,
  personalInformationStepFormConfig,
  residentialLengthStepFormConfig,
} from 'src/app/features/digital-experience/configs';
import { filter, first } from 'rxjs/operators';
import * as _ from 'lodash-es';
import * as fromQuestionsModels from '../../../../questions/models';
import {
  EnterpriseEventKcfPrefillStatus,
  EnterpriseEventKcfPrefillStatusName,
} from 'src/app/features/analytics/models/enterprise-event.model';

export enum formSections {
  CONTACT_INFO = 'Contact Information',
  HOUSING = 'Housing',
  EMPLOYMENT = 'Employment',
  INCOME = 'Income',
}

const sectionNameToEEv2StatusName = {
  [formSections.CONTACT_INFO]: EnterpriseEventKcfPrefillStatusName.CONTACT_INFO,
  [formSections.EMPLOYMENT]: EnterpriseEventKcfPrefillStatusName.EMPLOYMENT,
  [formSections.HOUSING]: EnterpriseEventKcfPrefillStatusName.HOUSING,
  [formSections.INCOME]: EnterpriseEventKcfPrefillStatusName.INCOME,
};

// the panel question configs for employment, housing and income
// are different than their step component question config
const housingQuestionName = [...housingStepFormConfig.questionNames, ...residentialLengthStepFormConfig.questionNames];

const employmentInfoQuestionNames = personalInformationStepFormConfig.questionNames.filter(
  (question) => question !== QuestionNameTypes.AnnualIncome
);

const incomeInfoQuestionNames = [QuestionNameTypes.AnnualIncome, ...bankingSimpleFormConfig.questionNames];

// Adjust the order of email, move it to the last & remove DOB question
const contactInfoQuestionName = [
  ...baseWalkupInformationStepFormConfig.questionNames.filter(
    (question) =>
      ![QuestionNameTypes.EmailAddress, QuestionNameTypes.DateOfBirth].includes(question as QuestionNameTypes)
  ),
  QuestionNameTypes.EmailAddress,
];

@Component({
  selector: 'app-confirm-info',
  templateUrl: './confirm-info.component.html',
  styleUrls: ['./confirm-info.component.scss', '../../confirm-info-card/confirm-info-card.component.scss'],
})
export class ConfirmInfoComponent extends BaseApplicationStepComponent implements OnInit {
  formSectionConfigs = {
    [formSections.CONTACT_INFO]: {
      editStatus: false,
      formConfig: {
        questionNames: contactInfoQuestionName,
        validators: null,
        layout: {
          grid: [2, 2, 2, 2, 1],
        },
      },
    },
    [formSections.HOUSING]: {
      editStatus: true,
      formConfig: {
        questionNames: housingQuestionName,
        questionDependencies: housingStepFormConfig.questionDependencies,
        validators: null,
      },
    },
    [formSections.EMPLOYMENT]: {
      editStatus: false,
      formConfig: {
        questionNames: employmentInfoQuestionNames,
        validators: null,
        questionDependencies: personalInformationStepFormConfig.questionDependencies,
        layout: {
          grid: [1, 1, 1],
        },
      },
    },
    [formSections.INCOME]: {
      editStatus: false,
      formConfig: {
        questionNames: incomeInfoQuestionNames,
        validators: null,
      },
    },
  };

  // key-value pipe option to stop the default sorting when looping formSectionConfigs in template.
  asIsOrder() {
    return 0;
  }
  backendGeneral: fromQuestionsModels.ValidatorTypes = fromQuestionsModels.ValidatorTypes.BackendGeneral;
  prospectOfferLoaded$: Observable<boolean>;

  ngOnInit(): void {
    super.ngOnInit();
    this.setInitialPanelStatusToExpand();
  }

  /**
   * Set panel status to be expand if questionValueData has met certain requirement for a specific form section
   * (note the contact information section should be collapsed when loaded, and the housing section should be expanded,
   * hence skip checking these two sections here)
   */
  setInitialPanelStatusToExpand() {
    this.store
      .pipe(select(fromQuestionValueStore.selectQuestionValuesData), filter(Boolean), first())
      .subscribe((questionValue: Record<string, any>) => {
        const sectionsToBeEdited = [];
        if (
          !questionValue[QuestionNameTypes.AnnualIncome] ||
          !questionValue[QuestionNameTypes.Banking] ||
          questionValue[QuestionNameTypes.Banking].length === 0
        ) {
          sectionsToBeEdited.push(formSections.INCOME);
        }
        if (!questionValue[QuestionNameTypes.EmploymentStatus]) {
          sectionsToBeEdited.push(formSections.EMPLOYMENT);
        } else {
          const dependencies = this.formSectionConfigs[formSections.EMPLOYMENT].formConfig.questionDependencies;
          [QuestionNameTypes.EmployerPhone, QuestionNameTypes.EmployerName].forEach((name) =>
            this.checkAgainstEmploymentQuestionDependencies(name, sectionsToBeEdited, questionValue, dependencies)
          );
        }
        this.checkIfInvalidFormDataInCollapsedSection(sectionsToBeEdited);

        if (sectionsToBeEdited.length) {
          sectionsToBeEdited.forEach((sectionName) => {
            this.formSectionConfigs[sectionName] = {
              ...this.formSectionConfigs[sectionName],
              editStatus: true,
            };
          });
        }
        const prefillStatus = this.getEEv2PrefillStatus(questionValue);
        this.EEService.reportKCFAuthSuccess(prefillStatus);
      });
  }

  getEEv2PrefillStatus(questionValue) {
    // The formSectionConfigs at this point should be filtered in dynamic-form component, dependency question names that are not
    // required are removed.
    return Object.entries(this.formSectionConfigs).reduce((acc, [sectionName, sectionData]) => {
      if (sectionData.editStatus) {
        const allFieldsMissing = sectionData.formConfig.questionNames.every(
          (questionName) => !questionValue[questionName]
        );
        acc[sectionNameToEEv2StatusName[sectionName]] = allFieldsMissing ? 'none' : 'partial';
      } else {
        acc[sectionNameToEEv2StatusName[sectionName]] = 'full';
      }
      return acc;
    }, {} as Record<EnterpriseEventKcfPrefillStatusName, EnterpriseEventKcfPrefillStatus>);
  }

  private checkIfInvalidFormDataInCollapsedSection(sectionsToBeEdited) {
    // Check for cases such as invalid customer data provided from backend belong to a
    // collapsed sections (phone number started with 1), the sync validator will flag the form as invalid
    // hence need to expand to section for edit
    if (this.form.invalid) {
      for (const key in this.form.controls) {
        if (this.form.controls.hasOwnProperty(key) && !_.isNil(this.form.get(key).errors)) {
          Object.entries(this.formSectionConfigs).forEach(([sectionName, sectionData]) => {
            if (sectionData.formConfig.questionNames.includes(key) && !sectionData.editStatus) {
              sectionsToBeEdited.push(sectionName);
            }
          });
        }
      }
    }
  }

  private checkAgainstEmploymentQuestionDependencies(
    questionName: QuestionNameTypes,
    sectionsToBeEdited: string[],
    questionValue: Record<string, any>,
    dependencies: Record<string, fromQuestionsModels.DependencyConfig[]>
  ) {
    // The employment section should be pushed into sectionsToBeEdited if:
    // 1. the question value store has no value of employment phone/ name
    // 2. the dictator function return true, indicating it's required for employment phone/ name
    // 3. the sectionsToBeEdited does not include employment already
    if (
      !questionValue[questionName] &&
      dependencies[questionName][0].dictatorFunction(questionValue[QuestionNameTypes.EmploymentStatus]) &&
      sectionsToBeEdited.indexOf(formSections.EMPLOYMENT) < 0
    ) {
      sectionsToBeEdited.push(formSections.EMPLOYMENT);
    }
  }

  removeQuestion(questionName: QuestionNameTypes) {
    // Although the dynamic form in confirm info step is built from the component member formSectionConfigs
    // and calling the base removeQuestion updates the base member stepFormConfig which shouldn't have impact on
    // the dynamic forms in this step. Still, call the base method just to update the form control
    super.removeQuestion(questionName);
    Object.values(this.formSectionConfigs).forEach((section) => {
      if (section.formConfig.questionNames.includes(questionName)) {
        const filtered = section.formConfig.questionNames.filter((name) => name !== questionName);
        section.formConfig = { ...section.formConfig, questionNames: filtered };
      }
    });
  }

  addQuestion(
    questionName: QuestionNameTypes,
    questionValidators: fromQuestionsModels.QuestionValidators,
    index: number = null,
    dictatorValue: string
  ) {
    super.addQuestion(questionName, questionValidators, index, dictatorValue);
    if (
      // added extra check to make sure the question not added to wrong section
      // (e.g. housing monthly payment added to employment section)
      personalInformationStepFormConfig.questionNames.includes(questionName) &&
      !this.formSectionConfigs[formSections.EMPLOYMENT].formConfig.questionNames.includes(questionName)
    ) {
      this.addToConfig(formSections.EMPLOYMENT, questionName);
    } else if (
      housingStepFormConfig.questionNames.includes(questionName) &&
      !this.formSectionConfigs[formSections.HOUSING].formConfig.questionNames.includes(questionName)
    ) {
      this.addToConfig(formSections.HOUSING, questionName);
    }
  }

  addToConfig(section: formSections, questionName: QuestionNameTypes) {
    const config = this.formSectionConfigs[section].formConfig;
    this.formSectionConfigs[section].formConfig = {
      ...config,
      questionNames: [...config.questionNames, questionName],
    };
  }

  prefillStatus(sectionName): boolean {
    return !this.formSectionConfigs[sectionName].editStatus;
  }

  onNext(formValue: any): void {
    super.onNext(formValue);
    // Scroll view into the first error control
    if (this.form.invalid) {
      for (const key in this.form.controls) {
        if (this.form.controls.hasOwnProperty(key) && !_.isNil(this.form.get(key).errors)) {
          this.windowUtilService.scrollToElementById(key, 150);
          break;
        }
      }
    }
  }

  /**
   * on edit
   */
  onEdit(sectionName) {
    this.formSectionConfigs[sectionName].editStatus = true;
    timer(0).subscribe(() => {
      const expandedSectionId = this.expandedSectionId(sectionName);
      const expandedSectionHeader = document.querySelector(`#${expandedSectionId} .c-panel__header`) as HTMLElement;
      // Only scroll to the section top if the section header not in the viewport
      if (!this.windowUtilService.isElementInViewport(expandedSectionHeader)) {
        this.windowUtilService.scrollToElementById(expandedSectionId, 50);
      }
    });
    this.liveAnnouncer.announce('Form fields are now editable');
  }

  expandedSectionId(sectionName) {
    return sectionName.replace(/\s/g, '');
  }
}
