import { Component } from '@angular/core';
import { helpText } from '../../../../../shared/configs/help-text.config';

@Component({
  selector: 'app-residency-requirement-help-text',
  templateUrl: './residency-requirement-help-text.component.html',
  styleUrls: ['./residency-requirement-help-text.component.scss'],
})
export class ResidencyRequirementHelpTextComponent {
  public get residencyRequirementHelpText() {
    return helpText.residencyRequirement;
  }
}
