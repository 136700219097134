<div class="c-form-checkbox" [class]="sizeClass" [ngClass]="{ 'mat-form-field-no-padding': noPadding }">
  <mat-form-field [id]="question.name" class="mat-form-field-plain">
    <mat-checkbox class="mat-checkbox" color="primary" [formControl]="formControl">
      <div class="c-form-checkbox__label-container">
        <button
          *ngIf="!question.noButton"
          mat-button
          color="primary"
          type="button"
          class="c-form-checkbox__label button-link-style"
          (click)="onClickLabel()"
        >
          {{ question.label }}
        </button>
        <span class="c-form-checkbox__label--no-button" *ngIf="question.noButton">
          {{ question.label }}
        </span>
        <div class="c-form-checkbox__sublabel" [innerHTML]="question.sublabel" *ngIf="question.sublabel"></div>
      </div>
    </mat-checkbox>
    <input
      class="mat-input"
      [formControl]="formControl"
      [errorStateMatcher]="matcher"
      (input)="blockInput()"
      readonly="readonly"
      hidden="true"
      [attr.aria-label]="question.label"
      matInput
    />
    <mat-error class="mat-error" *ngIf="errorValidator && !question.hideError">
      {{ errorValidator.message }}
    </mat-error>
  </mat-form-field>
</div>
