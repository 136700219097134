import { FormElTypes, InputElTypes, Question, QuestionNameTypes, ValidatorTypes } from '../../models';

export const paperlessStatementPreferenceQuestion: Question = {
  formElType: FormElTypes.Input,
  inputElType: InputElTypes.Checkbox,
  name: QuestionNameTypes.PaperlessStatementPreference,
  label: 'I would like to receive Paperless Statements via email',
  hideError: true,
  noButton: true,
  validators: {
    sync: [
      {
        validatorName: ValidatorTypes.StatementPreferenceRequired,
        readonly: true,
      },
    ],
  },
};
