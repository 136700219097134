import { FormElTypes, InputElTypes, Question, QuestionNameTypes, ValidatorTypes } from '../../models';

export const paperStatementPreferenceQuestion: Question = {
  formElType: FormElTypes.Input,
  inputElType: InputElTypes.Checkbox,
  name: QuestionNameTypes.PaperStatementPreference,
  label: 'I would like to receive Paper Statements',
  noButton: true,
  hideError: true,
  defaultValue: true,
  validators: {
    sync: [
      {
        validatorName: ValidatorTypes.StatementPreferenceRequired,
        readonly: true,
      },
    ],
  },
};
