// models
import { Map } from '../../../shared/models';
import { ApplicationDialogTypes } from '../../models';

// imports
import { ApplicationTermsDialogComponent } from './application-terms-dialog/application-terms-dialog.component';
// exports
export * from './application-terms-dialog/application-terms-dialog.component';
// exports group
export const components: any[] = [ApplicationTermsDialogComponent];

export const applicationDialogComponentMap: Map<any> = {
  [ApplicationDialogTypes.ApplicationTerms]: ApplicationTermsDialogComponent,
};
